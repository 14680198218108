import React, { useEffect, useState, useRef } from 'react';
import {
    Table
} from 'antd';

import { useTranslation } from 'react-i18next';

import '../style.less';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { usrListResponse } from '../../../models/sample-log/selectors';
import { getUsersList } from '../../../models/sample-log/actions';

const RegistriesTable = ({
    tableData, setSelectedItems, dataSize,
    paginationUpdate, pageSize, page
}) => {
    const { t } = useTranslation('fsm registries');
    const history = useHistory();
    const dispatch = useDispatch();

    const users = useSelector(state => usrListResponse(state));

    const [fullUsersList, setFullUsersList] = useState([]);
    const [usersPage, setUsersPage] = useState(1);

    useEffect(() => {
        dispatch(getUsersList({
            page: usersPage,
            searchString: ''
        }));
    }, [usersPage]);

    useEffect(() => {
        const listPortion = users?.results;

        if (listPortion?.length > 0) {
            setFullUsersList([...fullUsersList, ...listPortion]);
        }

        const nextPage = users?.next;
        if (nextPage?.length > 0) {
            setUsersPage(usersPage + 1);
        }
    }, [users]);

    const columns = [
        {
            title: t('id'),
            dataIndex: 'id',
            key: 'id',
            sorter: (a, b) => a.id - b.id,
            sortDirections: ['ascend', 'descend']
        },
        {
            title: t('name'),
            dataIndex: ['fito_calculator', 'name'],
            key: 'name',
            sorter: (a, b) => a?.fito_calculator?.name?.localeCompare(b?.fito_calculator?.name),
            sortDirections: ['ascend', 'descend'],
            render: value => value || '-'
        },
        // {
        //     title: t('date of test'),
        //     dataIndex: 'test_date',
        //     key: 'test_date',
        //     sorter: (a, b) => moment(a.test_date).unix() - moment(b.test_date).unix(),
        //     sortDirections: ['descend', 'ascend'],
        //     render: (text) => text ? moment(text).format('DD.MM.YYYY') : '-'
        // },
        {
            title: t('sample date'),
            dataIndex: ['fito_calculator', 'sample_date'],
            key: 'sample_date',
            sorter: (a, b) => moment(a?.fito_calculator?.sample_date).unix() - moment(b?.fito_calculator?.sample_date).unix(),
            sortDirections: ['descend', 'ascend'],
            render: (text) => text ? moment(text).format('DD.MM.YYYY') : '-'
        },
        {
            title: t('owner'),
            dataIndex: 'owner',
            key: 'owner',
            sorter: (a, b) => fullUsersList.filter(usr => usr.id === parseInt(a.owner, 10))[0]?.name.localeCompare(fullUsersList.filter(usr => usr.id === parseInt(b.owner, 10))[0]?.name),
            render: (val) => {
                return fullUsersList?.length > 0 ? fullUsersList.filter(usr => usr.id === parseInt(val, 10))[0]?.name : val
            }
        },
        {
            title: t('field'),
            dataIndex: 'vega_key',
            key: 'vega_key',
            sorter: (a, b) => a?.vega_key?.localeCompare(b?.vega_key),
            sortDirections: ['descend', 'ascend']
        },
        {
            title: t('diseases'),
            dataIndex: 'diseases',
            key: 'diseases',
            render: value => value ? t('discovered') : t('undiscovered')
        },
        {
            title: t('culture'),
            dataIndex: ['fito_calculator', 'culture', 'name'],
            key: 'culture',
            sortDirections: ['descend', 'ascend']
        },
        {
            title: t('status'),
            dataIndex: 'archived',
            key: 'public',
            // filters: [
            //     {
            //         text: t('archived'),
            //         value: 'archived'
            //     },
            //     {
            //         text: t('published'),
            //         value: 'published'
            //     },
            //     {
            //         text: t('edition'),
            //         value: 'edition'
            //     }
            // ],
            // onFilter: (value, record) => {
            //     if (value === 'archived') {
            //         return record?.deleted;
            //     }
            //     if (value === 'published') {
            //         return !record?.deleted && record?.public;
            //     }
            //     if (value === 'edition') {
            //         return !record?.deleted && !record?.public;
            //     }
            // },
            render: (val, record) => record?.archived ? (
                <div className="publication-status__archived">
                    {t('archived')}
                </div>
            ) : val ? (
                <div className="publication-status__published">
                    {t('published')}
                </div>
            ) : (
                <div className="publication-status__edition">
                    {t('edition')}
                </div>
            )
        }
    ];

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedItems(selectedRows);
        },
        getCheckboxProps: (record) => ({
            disabled: record?.name === 'test block',
            id: record?.id
        })
    };

    return (
        <Table
            rowKey="id"
            onRow={(record) => {
                return {
                    onClick: () => {
                        history.push(`/fsm-registry/${record.id}`);
                        setTimeout(() => window.location.reload(), 200);
                    }
                };
            }}
            pagination={page ? {
                defaultCurrent: 1,
                defaultPageSize: 10,
                pageSize: pageSize,
                current: page,
                onChange: paginationUpdate,
                total: dataSize,
                showSizeChanger: true,
                pageSizeOptions: [
                    '10', '25', '50'
                ]
            } : {}}
            rowSelection={{
                type: 'checkbox',
                ...rowSelection
            }}
            dataSource={tableData}
            columns={columns} />
    );
};

export default RegistriesTable;
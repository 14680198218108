import { getUrl } from '../utils';
import request from '../../utils/request';
import options from './options';

const url = options.apiUrl;
const urlPath = path => `${url}${path}`;

export const login = body => request.post({
    url: urlPath('jwt/create/'),
    body
});

export const register = body => request.post({
    url: urlPath('users/'),
    body
});

export const verify = body => request.post({
    url: urlPath('jwt/verify/'),
    body
});

export const refresh = body => request.post({
    url: urlPath('jwt/refresh/'),
    body
});

export const userActivation = body => request.post({
    url: urlPath('users/activation/'),
    body
});

export const resetPassword = body => request.post({
    url: urlPath('/users/reset_password/'),
    body
});

export const resetPasswordConfirm = body => request.post({
    url: urlPath('/users/reset_password_confirm/'),
    body
});


export const getProfile = body => request.get({
    url: options.agrohubUrl
});

export const getProfileInfo = body => request.get({
    url: urlPath('users/me/')
});

export const updateProfile = body => request.put({
    url: urlPath('users/me/'),
    body
});

export const linkMapToUser = body => request.post({
    url: '/api/v1/teleagronom_user/users/set_map/',
    body: {
        map_id: body.mapId
    }
});
import { combineReducers } from 'redux';

import samplesList from './samples-list';
import sampleRow from './sample-row';
import uploadStatus from './upload-status';
import createdRow from './created-row';
import updatedRow from './updated-row';
import deletedRow from './deleted-row';
import createdUser from './created-user';
import usersList from './users-list';
import userCreated from './user-created';
import cardDefaults from './card-defaults';

export default combineReducers({
    samplesList,
    sampleRow,
    uploadStatus,
    createdRow,
    updatedRow,
    deletedRow,
    createdUser,
    usersList,
    userCreated,
    cardDefaults
});

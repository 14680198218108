import Keycloak from 'keycloak-js';
import { finishRequestQueue } from './request';

const keycloak = new Keycloak('/keycloak.json');

// keycloak.redirectUri = "//";

export const keycloakEventHandler = (eventName, error) => {
    // console.log('[KEYCLOAK] event handler', eventName, error, keycloak);
    // debugger;
    
    if (eventName === 'onTokenExpired') {
        keycloak.updateToken(30).then(() => {
            // console.log('successfully get a new token', keycloak.token);
            // console.log('successfully get a new refresh token', keycloak.refreshToken);
        }).catch(() => {
            console.log('ERROR getting a new token', keycloak.token);
        });
    }

    if (eventName === 'onReady') {
        if (!keycloak.authenticated) {
            // debugger;
            keycloak.login();
        } else {
            // finishRequestQueue();
            let oldObj = JSON.parse(localStorage.getItem('teleagronom-relogin'));
            if (!oldObj) {
                oldObj = {};
            }
            if (oldObj[keycloak?.idTokenParsed?.email]) {
                oldObj[keycloak?.idTokenParsed?.email] = false;
                localStorage.setItem('teleagronom-relogin', JSON.stringify(oldObj));
                window.location.replace("/");
            }
        }
    }

    if (eventName === 'onAuthSuccess') {
        if (keycloak?.idTokenParsed?.email) {
            let oldObj = JSON.parse(localStorage.getItem('teleagronom-relogin'));
            if (!oldObj) {
                oldObj = {};
            }
            if (oldObj[keycloak?.idTokenParsed?.email]) {
                oldObj[keycloak?.idTokenParsed?.email] = false;
                localStorage.setItem('teleagronom-relogin', JSON.stringify(oldObj));
                window.location.replace("/");
            }
            const alreadyAuth = localStorage.getItem('agrohub-already-logged-in-before')
            let usrArr = null;
            if (alreadyAuth && alreadyAuth?.length > 0) {
                usrArr = JSON.parse(alreadyAuth);
            }
            if (usrArr) {
                if (usrArr?.includes(keycloak?.idTokenParsed?.email)) {
                    localStorage.setItem('agrohub-is-second-login', true);
                } else {
                    usrArr.push(keycloak?.idTokenParsed?.email);
                    localStorage.setItem('agrohub-is-second-login', false);
                    localStorage.setItem('agrohub-already-logged-in-before', JSON.stringify(usrArr));
                }
            } else {
                localStorage.setItem('agrohub-is-second-login', true);
                localStorage.setItem('agrohub-already-logged-in-before', JSON.stringify([keycloak?.idTokenParsed?.email]));
            }
        }
    }
};

export const keycloakTokenHandler = (tokenObject, error) => {
    // localStorage.setItem('token', tokenObject.access_token);
    localStorage.setItem('token', tokenObject.token);
    localStorage.setItem('refresh', tokenObject.refreshToken);
};

export const initOptions = {
    onLoad: 'check-sso',
    silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
    pkceMethod: 'S256',
};

export default keycloak;
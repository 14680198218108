import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Layout
} from 'antd';
import { useClassName } from 'utils/cn';
import { PanelLayout, Title } from '../../../components';
import '../style.less';
import { GEOCODE_MAP_URL_BASE } from '../../../environment';

const { PanelHeader, PanelContent } = PanelLayout;

const AgrohubMaps = ({mapsIframeUrl}) => {
    const { t } = useTranslation('agrohub maps');
    const cn = useClassName('');

    const iframeMap = useRef(null);

    useEffect(() => {
        window.addEventListener('geohubmapopened', function(event) {
            console.log("Opening the map from geohub" + event.data); // Message received from child
            window.location.replace(`/layer-objects/${event.data}`)
            // dispatch(getLayersByMap({ mapId: event?.data }))
        });
    }, []);
    

    const handleIframeOnload = () => {
        console.log('LOADED', iframeMap.current);
        // console.log('LOADED LOC', iframeMap.current.contentWindow.location.href);
        // iframeMap.current.contentWindow.addEventListener('click', e => {
        //     console.log('EEE', e);
        //     console.log('EEE TARET', e.target)
        // })
        window.addEventListener("message", function (e) {
            if (
                e?.data?.version === "1" && e?.data?.data?.type === 'read'
                && e?.data?.data?.entityType === 'map' && GEOCODE_MAP_URL_BASE?.startsWith(e?.origin)
                && e?.data?.data?.entityId
            ) {
                localStorage.setItem('teleagronom-last-opened-map', e?.data?.data?.entityId);
                window.location.replace(`/field-registry/${e?.data?.data?.entityId}`);
                console.log('THI IS THE MAP ID!!!!!', e?.data?.data?.entityId);
            }
        }, false);
    }

    return (
        <div className='mapContainer'>
            <iframe
                sandbox='allow-scripts allow-same-origin'
                ref={iframeMap}
                onLoad={handleIframeOnload}
                // style={{ width: '100%', height: '100%', position: 'absolute', top: '0px', left: '0px', zIndex: '15' }}
                src={mapsIframeUrl}
                width="100%"
                height="100%">
            </iframe>
        </div>
    );
};

export default AgrohubMaps;
import * as constants from '../constants';
import converter from '../converter';

const initialState = [];

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case constants.updateObjectsPage.success: {
            return action.payload;
        }

        case constants.updateObjectsPage.error: {
            return { error: 'error' };
        }

        case constants.updateObjectsPage.try:
        default:
            return state;
    }
}

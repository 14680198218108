import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';
import { Popover, Button } from '../../../components';

import cn from '../../../utils/cn';
import * as constants from '../../../constants';

@withRouter
@withTranslation('action popover calculator')
@cn('action-popover')
export default class ActionPopover extends Component {
    state = {
        visible: false
    };

    handleVisibleChange = visible => {
        this.setState({ visible });
        setTimeout(() => this.setState({ visible: false }), 3000);
    };

    hide = () => {
        this.setState({
            visible: false
        });
    };

    closeAndDelete = () => {
        const { deleteClick } = this.props;

        this.hide();

        deleteClick();
    };

    goTo = () => {
        const { id, history, url } = this.props;
        history.push(`/${url}/${id}`);
    };

    content = () => {
        const { cn } = this;
        const {
            t, id, status, history,
            openMap = () => {}
        } = this.props;

        return (
            <div className={cn()}>
                <div onClick={openMap}>{t('open')}</div>
                {/* <div onClick={this.goTo}>{t('edit')}</div> */}
                {/* { !status
                && (<div onClick={this.goTo}>{t('edit')}</div>)} */}
            </div>
        );
    };

    render() {
        const { t, ...rest } = this.props;
        const { visible } = this.state;
        return (
            <Popover
                content={this.content()}
                visible={visible}
                onVisibleChange={this.handleVisibleChange}
                {...rest}>
                <Button>{t('action')}</Button>
            </Popover>
        );
    }
}
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import {
    Space, Radio
} from 'antd';
import { useDispatch } from 'react-redux';
import ChartRepresentation from './chart';
import TableRepresentation from './table';
import { getMeteodata } from '../../../../models/item-meteodata/actions';

const MeteodataAnalysis = () => {
    const match = useRouteMatch();
    const { t } = useTranslation('meteodata');
    const dispatch = useDispatch();

    const [meteodataMode, setMeteodataMode] = useState('chart');
    const [isGeneralDateSet, setIsGeneralDateSet] = useState(false);

    const [generalDates, setGeneralDates] = useState({
        start_date: '',
        end_date: ''
    });

    const { id, year } = match.params;

    useEffect(() => {
        if (generalDates.start_date.length > 0 && generalDates.end_date.length > 0) {
            setIsGeneralDateSet(true);
            dispatch(getMeteodata({
                vega_key: id,
                body: { ...generalDates, year }
            }));
        } else {
            const todayDate = new Date();
            const weekBefore = new Date();
            weekBefore.setDate(weekBefore.getDate() - 7);

            dispatch(getMeteodata({
                vega_key: id,
                body: {
                    year,
                    start_date: weekBefore.toISOString().split('T')[0],
                    end_date: todayDate.toISOString().split('T')[0]
                }
            }));
        }
    }, [generalDates]);

    const switchOptions = [
        {
            label: t('chart'),
            value: 'chart'
        },
        {
            label: t('table'),
            value: 'table'
        }
    ];


    return (
        <Space direction="vertical" style={{ position: 'relative', width: '100%' }}>
            <Radio.Group
                options={switchOptions}
                onChange={(newMode) => setMeteodataMode(newMode.target.value)}
                value={meteodataMode}
                optionType="button"
                style={{
                    position: 'absolute',
                    right: '0px'
                }} />
            {meteodataMode === 'chart' && (
                <div>
                    <ChartRepresentation
                        isGeneralDateSet={isGeneralDateSet}
                        generalDates={generalDates}
                        setGeneralDates={setGeneralDates} />
                </div>
            )}
            {meteodataMode === 'table' && (
                <TableRepresentation
                    isGeneralDateSet={isGeneralDateSet}
                    generalDates={generalDates}
                    setGeneralDates={setGeneralDates} />
            )}
        </Space>
    );
};

export default MeteodataAnalysis;
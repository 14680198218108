import React, {
    useEffect, useState, useMemo,
    useCallback
} from 'react';
import { useClassName } from 'utils/cn';
import './style.less';
import { useTranslation } from 'react-i18next';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    LineController,
    BarController,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';
import { Line, Chart } from 'react-chartjs-2';
import {
    Checkbox,
    Col,
    Row,
    Spin,
    Switch
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { toFixed } from '../../../utils/utils';
import { getAnalysisData, getEffectiveData, getPrecipitationData } from '../../../models/item-meteodata/actions';
import {
    curAnalysisMeteodata,
    curEffectiveMeteodata, curPrecipitationMeteodata,
    effectiveIsLoading, meteodataIsLoading
} from '../../../models/item-meteodata/selectors';
import moment from 'moment';
import zoomPlugin from 'chartjs-plugin-zoom';

const ChartRepresentation = ({ isModal = false, chartLegend, chartData, chartType, chartName, currFieldName, selectedFields, userProbs }) => {
    const dispatch = useDispatch();
    const match = useRouteMatch();
    const cn = useClassName('meteodata');
    const { t } = useTranslation('meteodata');

    const isLoadingAll = useSelector(state => meteodataIsLoading(state));
    const isLoadingEffective = useSelector(state => effectiveIsLoading(state));

    useEffect(() => {
        console.log('THIS IS WHAT CRASHES ME', chartData);
    }, [chartData]);
    

    ChartJS.register(
        zoomPlugin,
        CategoryScale,
        LinearScale,
        PointElement,
        BarElement,
        LineElement,
        Title,
        Tooltip,
        Legend,
        LineController,
        BarController
    );

    const formatXValue = (xPoint) => {
        return `${t('date')}: ${xPoint?.[0]?.label?.split('T')?.[0]}`;
    };
    // const formatYValue = (yPoint) => {
    //     console.log('YPYPYP', yPoint)
    //     return `${t('date')}:`;
    // };

    const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            // xAxes: [{
            //     // barPercentage: 1
            //     barThickness : 25
            // }],
            x: {
                ticks: {
                    // callback: (givenMoment) => `${moment(givenMoment).day()}.${moment(givenMoment).month()}`
                    callback: function (givenMoment) {
                        if (chartType === 'gtk') {
                            const dates1 = moment(this.getLabelForValue(givenMoment)?.split("/")[0])?.format('DD.MM.YYYY');
                            const dates2 = moment(this.getLabelForValue(givenMoment)?.split("/")[1])?.format('DD.MM.YYYY');
                            return `${dates1}-${dates2}`;
                        }

                        const dates = this.getLabelForValue(givenMoment)?.split("T")[0];
                        return `${dates?.split("-")[2]}.${dates?.split("-")[1]}`;
                    }
                },
                barThickness : 75
                // barPercentage: 1
            },
            // y0: {
            //     // ticks: {
            //     //     // callback: (givenMoment) => `${moment(givenMoment).day()}.${moment(givenMoment).month()}`
            //     //     callback: function (givenMoment) {
            //     //         if (chartType === 'gtk') {
            //     //             const dates1 = moment(this.getLabelForValue(givenMoment)?.split("/")[0])?.format('DD.MM.YYYY');
            //     //             const dates2 = moment(this.getLabelForValue(givenMoment)?.split("/")[1])?.format('DD.MM.YYYY');
            //     //             return `${dates1}-${dates2}`;
            //     //         }

            //     //         const dates = this.getLabelForValue(givenMoment)?.split("T")[0];
            //     //         return `${dates?.split("-")[2]}.${dates?.split("-")[1]}`;
            //     //     }
            //     // },
            //     barPercentage: 1
            // },
            x2: {
                ticks: {
                    display: false,
                  },
            
                  // to remove the x-axis grid
                  grid: {
                    drawBorder: false,
                    display: false,
                  },
            },
            y2: {
                // max: (scale) => {
                //     console.log('SCALE', scale);
                //     return 100;
                // },
                // max: (scale) => (
                //     scale.chart.data.datasets.reduce((acc, curr) => {
                //       const max = Math.max(...curr.data);
                //       acc = max > acc ? max : acc;
                //       console.log('jnfefjg', max, acc, curr)
                //       return acc;
                //     }, Number.MIN_SAFE_INTEGER)),
                ticks: {
                    display: false,
                  },
            
                  // to remove the x-axis grid
                  grid: {
                    drawBorder: false,
                    display: false,
                  },
            }
        },
        plugins: {
            legend: null,
            tooltip: {
                callbacks: {
                    title: (xDatapoint) => {return formatXValue(xDatapoint)},
                    // label: (yDatapoint) => {return formatYValue(yDatapoint)},
                }
            },
            zoom: {
                pan: {
                    enabled: true,
                    mode: 'x'
                },
                zoom: {
                    pinch: {
                        enabled: true       // Enable pinch zooming
                    },
                    wheel: {
                        enabled: true       // Enable wheel zooming
                    },
                    mode: 'x',
                }
            }
        }
    };

    const [currentDatasets, setCurrentDatasets] = useState({
        labels: [],
        datasets: []
    });
    const [currentSwitch, setCurrentSwitch] = useState({});

    const lineNames = [
        'atmTemperatureFact', 'minDailyAtmTemp', 'avgDailyAtmTemp', 'maxDailyAtmTemp',
        'soilTempSurface', 'windDirection', 'gtk_week', 'gtk_month', 'gtk_year'
    ];
    const barNames = [
        'actualSumActiveTempOver5', 'actualSumActiveTempOver10',
        'soilHumidity20Cm', 'soilHumidity40Cm', 'soilHumidity60Cm',
        'falloutVolMm'
    ];
    const insectNames = [];

    const getLineType = (kName) => {
        if (lineNames?.includes(kName)) {
            return 'line';
        }

        if (barNames?.includes(kName)) {
            return 'bar';
        }
    };

    const getHarfmulData = (indx) => {
        indx++;
        return userProbs?.[indx++];
    };

    const getGtkData = (actKey) => {
        console.log('FOEWNFOJWNEF', actKey);
        let gtkNewDataset = [];
        if (actKey === 'gtk_week') {
            return chartData.data;
        }
        if (actKey === 'gtk_month') {
            return [];
            // return chartData.data;
        }
        if (actKey === 'gtk_year') {
            return [];
            // return chartData.data;
        }
        return [];
    };

    const getWindDirectionDataset = (activeK) => {
        if (activeK === 'windDirection') {
            return chartData?.windDirection?.map(() => 99999999999);
        }
    };

    const getWindDirectionTicks = () => {
        console.log('jcoiefjn', chartData?.windDirection?.map((degVal, degIdx) => {
            const windImg = new Image();
            windImg.src="/images/wind-direction.svg";
            windImg.style=`${chartData?.windGust[degIdx] > 0  ? 'filter: invert(21%) sepia(49%) saturate(4991%) hue-rotate(358deg) brightness(99%) contrast(77%); ' : ''}transform: rotate(${degVal?.toString()}deg)`;
            return windImg;
        }));
        return chartData?.windDirection?.map((degVal, degIdx) => {
            const windImg = new Image();
            windImg.src="/images/wind-direction.svg";
            windImg.style=`${chartData?.windGust[degIdx] > 0  ? 'filter: invert(21%) sepia(49%) saturate(4991%) hue-rotate(358deg) brightness(99%) contrast(77%); ' : ''}transform: rotate(${degVal?.toString()}deg)`;
            return windImg;
        });
    };

    useEffect(() => {
        console.log('CurrentSwitchChange!!!', currentSwitch);
        const activeKeys = Object.entries(currentSwitch).filter(x => x?.[1]?.isShown === true)?.map(x => x[0]);
        console.log('ACTIVE KEYS', activeKeys);

        let newDataset = {
            // labels: chartData?.date?.forEach((dt) => `${moment(dt).day()}.${moment(dt).month()}`),
            labels: chartType === 'gtk' ? chartData?.weeks?.reverse() : chartData?.date?.reverse(),
            datasets: activeKeys?.map(activeK => {
                const colorsObj = getColors(activeK);
                let harmfulIterator = 0;
                return {
                    type: getLineType(activeK),
                    label: currentSwitch?.[activeK]?.description,
                    data: chartType === 'harmful' ? getHarfmulData(harmfulIterator) : chartType === 'gtk' ? getGtkData(activeK) : chartType === 'wind' ? getWindDirectionDataset(activeK) : [...chartData[activeK]].map((dataItem, idx) => {
                        if (chartType === 'wind' && (activeK === 'windSpeed' || activeK === 'windDirection')) {
                            return {};
                        }
                        return {
                            // x: `${moment(chartData?.date?.[idx]).day()}.${moment(chartData?.date?.[idx]).month()}`,
                            x: chartData?.date?.[idx],
                            y: dataItem,
                        }
                    }).reverse(),
                    borderColor: activeK === 'windDirection' && null || (colorsObj?.borderColor || 'red'),
                    backgroundColor: activeK === 'windDirection' && null || (colorsObj?.backgroundColor || 'red'),
                    borderDash: activeK === 'windDirection' && [] || (colorsObj?.borderDash || []),
                    yAxisID: activeK === 'windDirection' ? 'y2' : (activeK === 'actualSumActiveTempOver5' || activeK === 'actualSumActiveTempOver10') ? 'y2' : 'y',
                    xAxisID: activeK === 'windDirection' ? 'x2' : 'x',
                    lineTension: 0.3,
                    pointStyle: activeK === 'windDirection' ? getWindDirectionTicks() : 'circle',
                    showLine: activeK === 'windDirection' ? false : true,
                    parsing: activeK === 'windDirection' ? {} : { xAxisKey: 'x', yAxisKey: 'y' }
                }
            })
        };

        console.log('Setting dataset', newDataset);

        setCurrentDatasets(newDataset);
    }, [currentSwitch, chartType, chartData]);

    const getColors = (keyName) => {
        if (keyName === 'atmTemperatureFact') {
            return {
                borderColor: '#03B575',
                backgroundColor: '#03B575',
                borderDash: [3, 1]
            }
        }

        if (keyName === 'atmTemperatureFact') {
            return {
                borderColor: '#03B575',
                backgroundColor: '#03B575',
                borderDash: [1, 1]
            }
        }

        if (keyName === 'minDailyAtmTemp') {
            return {
                borderColor: '#F2C94C',
                backgroundColor: '#F2C94C'
            }
        }

        if (keyName === 'avgDailyAtmTemp') {
            return {
                borderColor: '#AB741B',
                backgroundColor: '#AB741B'
            }
        }

        if (keyName === 'maxDailyAtmTemp') {
            return {
                borderColor: '#D33C21',
                backgroundColor: '#D33C21'
            }
        }

        if (keyName === 'actualSumActiveTempOver5') {
            return {
                borderColor: '#F6FF48',
                backgroundColor: '#F6FF48'
            }
        }

        if (keyName === 'actualSumActiveTempOver10') {
            return {
                borderColor: '#F7C457',
                backgroundColor: '#F7C457'
            }
        }

        if (keyName === 'soilHumidity20Cm') {
            return {
                borderColor: '#90D9BC',
                backgroundColor: '#90D9BC'
            }
        }

        if (keyName === 'soilHumidity40Cm') {
            return {
                borderColor: '#7EB5B1',
                backgroundColor: '#7EB5B1'
            }
        }

        if (keyName === 'soilHumidity60Cm') {
            return {
                borderColor: '#5E7E7C',
                backgroundColor: '#5E7E7C'
            }
        }

        if (keyName === 'soilTempSurface') {
            return {
                borderColor: '#D33C21',
                backgroundColor: '#D33C21'
            }
        }

        if (keyName === 'falloutVolMm') {
            return {
                borderColor: '#6891AB',
                backgroundColor: '#6891AB'
            }
        }

        if (keyName === 'gtkWeek') {
            return {
                borderColor: '#F2C94C',
                backgroundColor: '#F2C94C'
            }
        }

        if (keyName === 'gtkMonth') {
            return {
                borderColor: '#AB741B',
                backgroundColor: '#AB741B'
            }
        }

        if (keyName === 'gtkYear') {
            return {
                borderColor: '#D33C21',
                backgroundColor: '#D33C21'
            }
        }
    };

    const airNameSet = [
        'atmTemperatureFact',
        // 't воздуха прогнозируемая',
        'minDailyAtmTemp',
        'avgDailyAtmTemp',
        'maxDailyAtmTemp',
        'actualSumActiveTempOver5',
        'actualSumActiveTempOver10',
        // 'сумма эффективных °С'
    ];

    const soilNameSet = [
        'soilHumidity20Cm',
        'soilHumidity40Cm',
        'soilHumidity60Cm',
        // 'показать координаты станции',
        'soilTempSurface'
    ];

    const precipitationNameSet = [
        // 'относительная влажность на высоте 1,5 м',
        // 'относительная влажность на высоте 2 м',
        // 'средняя влажность воздуха',
        'falloutVolMm',
        // 'сумма накопленных осадков'
    ];

    const windNameSet = [
        'windDirection',
        'windSpeed',
        'windGust',
        // 'облачность',
        // 'атмосферное давление у поверхности земли',
        // 'атмосферное давление, приведенное к среднему уровню моря',
        // 'атмосферное давление на уровне станции',
        // 'скорость ветра на высоте 10-12 м над уровнем земли',
        // 'максимальное значение порыва ветра'
    ];

    const gtkNameSet = [
        'gtk_week',
        'gtk_month',
        'gtk_year'
    ];

    useEffect(() => {
        let switchObject = {};

        if (chartType === 'air') {
            airNameSet.map(fieldName => {
                const findingEntry = chartLegend?.filter(x => x?.name === fieldName);
                if (findingEntry?.length > 0) {
                    switchObject[fieldName] = {
                        description: findingEntry?.[0]?.description,
                        isShown: true
                    };
                }
            });
        }
        if (chartType === 'soil') {
            soilNameSet.map(fieldName => {
                const findingEntry = chartLegend?.filter(x => x?.name === fieldName);
                if (findingEntry?.length > 0) {
                    switchObject[fieldName] = {
                        description: findingEntry?.[0]?.description,
                        isShown: true
                    };
                }
            });
        }
        if (chartType === 'precipitation') {
            precipitationNameSet.map(fieldName => {
                const findingEntry = chartLegend?.filter(x => x?.name === fieldName);
                if (findingEntry?.length > 0) {
                    switchObject[fieldName] = {
                        description: findingEntry?.[0]?.description,
                        isShown: true
                    };
                }
            });
        }
        if (chartType === 'wind') {
            windNameSet.map(fieldName => {
                const findingEntry = chartLegend?.filter(x => x?.name === fieldName);
                if (findingEntry?.length > 0) {
                    switchObject[fieldName] = {
                        description: findingEntry?.[0]?.description,
                        isShown: true
                    };
                }
            });
        }
        if (chartType === 'harmful') {
            selectedFields?.map(fieldName => {
                console.log('FNAME', fieldName);
                if (fieldName && fieldName !== undefined) {
                    if (fieldName?.vega_key) {
                        switchObject[fieldName?.vega_key] = {
                            description: fieldName?.vega_key,
                            isShown: true
                        };
                    } else {
                        switchObject[fieldName] = {
                            description: fieldName,
                            isShown: true
                        };
                    }
                }
            });
        }
        if (chartType === 'gtk') {
            gtkNameSet.map(fieldName => {
                // const findingEntry = chartLegend?.filter(x => x?.name === fieldName);
                // if (findingEntry?.length > 0) {
                    switchObject[fieldName] = {
                        description: fieldName,
                        isShown: true
                    };
                // }
            });
        }

        setCurrentSwitch(switchObject);
    }, [chartData, chartType, selectedFields]);

    const handleToggleShown = (fieldName) => {
        const objCpy = {...currentSwitch};
        objCpy[fieldName].isShown = !objCpy?.[fieldName]?.isShown;
        setCurrentSwitch(objCpy);
    };

    const getGlyph = (glyphName) => {
        let bgColor = 'red';

        if (glyphName === 'field') {
            const items = [
                '#2B638B', '#4DCFFA', '#03B575', '#F79357',
                '#4DCFFA', '#A5AD9C', '#F2C94C', '#AB741B',
                '#D33C21'
            ];

            bgColor = items[Math.floor(Math.random()*items.length)] || 'red';

            return (
                <div style={{ margin: '5px', height: '2px', width: '20px', backgroundColor: bgColor }} />
            )
        }

        if (glyphName === 'atmTemperatureFact') {
            bgColor = '#03B575';
        }

        if (glyphName === 'atmTemperatureFact') {
            bgColor = '#03B575';
        }

        if (glyphName === 'minDailyAtmTemp') {
            bgColor = '#F2C94C';
        }

        if (glyphName === 'avgDailyAtmTemp') {
            bgColor = '#AB741B';
        }

        if (glyphName === 'maxDailyAtmTemp') {
            bgColor = '#D33C21';
        }

        if (glyphName === 'actualSumActiveTempOver5') {
            bgColor = '#F6FF48';
        }

        if (glyphName === 'actualSumActiveTempOver10') {
            bgColor = '#F7C457';
        }

        if (glyphName === 'soilHumidity20Cm') {
            bgColor = '#90D9BC';
        }

        if (glyphName === 'soilHumidity40Cm') {
            bgColor = '#7EB5B1';
        }

        if (glyphName === 'soilHumidity60Cm') {
            bgColor = '#5E7E7C';
        }

        if (glyphName === 'soilTempSurface') {
            bgColor = '#D33C21';
        }

        if (glyphName === 'falloutVolMm') {
            bgColor = '#6891AB';
        }

        if (glyphName === 'gtk_week') {
            bgColor = '#F2C94C';
        }

        if (glyphName === 'gtk_month') {
            bgColor = '#AB741B';
        }

        if (glyphName === 'gtk_year') {
            bgColor = '#D33C21';
        }

        if (lineNames?.includes(glyphName)) {
            return (
                <div style={{ margin: '5px', height: '2px', width: '20px', backgroundColor: bgColor }} />
            )
        }

        if (barNames?.includes(glyphName)) {
            return (
                <div style={{ margin: '5px', height: '10px', width: '20px', backgroundColor: bgColor }} />
            )
        }
        
        if (gtkNameSet?.includes(glyphName)) {
            return (
                <div style={{ margin: '5px', height: '2px', width: '20px', backgroundColor: bgColor }} />
            )
        }

        return (
            <div style={{ margin: '5px', height: '12px', width: '20px', backgroundColor: 'aliceblue' }} />
        )
    }
    
    const SwitchPanelNode = useCallback(() => {
        if (chartType === 'air') {
            return (
                <>
                    <Row>
                        <Col span={8}>
                            <Row>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <Switch onClick={() => handleToggleShown('atmTemperatureFact')} checked={currentSwitch?.atmTemperatureFact?.isShown} />
                                {getGlyph('atmTemperatureFact')}
                                {currentSwitch?.atmTemperatureFact?.description}
                                </div>
                            </Row>
                            {/* <Row>
                                <Checkbox onClick={() => handleToggleShown('atmTemperatureFact')} checked={switchObject?.atmTemperatureFact?.isShown} />
                                {switchObject?.atmTemperatureFact?.description} PROGNOZ
                            </Row> */}
                        </Col>
                        <Col span={8}>
                            <Row>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <Switch onClick={() => handleToggleShown('minDailyAtmTemp')} checked={currentSwitch?.minDailyAtmTemp?.isShown} />
                                {getGlyph('minDailyAtmTemp')}
                                {currentSwitch?.minDailyAtmTemp?.description}
                                </div>
                            </Row>
                            <Row>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <Switch onClick={() => handleToggleShown('avgDailyAtmTemp')} checked={currentSwitch?.avgDailyAtmTemp?.isShown} />
                                {getGlyph('avgDailyAtmTemp')}
                                {currentSwitch?.avgDailyAtmTemp?.description}
                                </div>
                            </Row>
                            <Row>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <Switch onClick={() => handleToggleShown('maxDailyAtmTemp')} checked={currentSwitch?.maxDailyAtmTemp?.isShown} />
                                {getGlyph('maxDailyAtmTemp')}
                                {currentSwitch?.maxDailyAtmTemp?.description}
                                </div>
                            </Row>
                            {/* <Row>
                                <Checkbox onClick={() => handleToggleShown('atmTemperatureFact')} checked={switchObject?.atmTemperatureFact?.isShown} />
                                {switchObject?.atmTemperatureFact?.description} PROGNOZ
                            </Row> */}
                        </Col>
                        <Col span={8}>
                            <Row>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <Switch onClick={() => handleToggleShown('actualSumActiveTempOver5')} checked={currentSwitch?.actualSumActiveTempOver5?.isShown} />
                                {getGlyph('actualSumActiveTempOver5')}
                                {currentSwitch?.actualSumActiveTempOver5?.description}
                                </div>
                            </Row>
                            <Row>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <Switch onClick={() => handleToggleShown('actualSumActiveTempOver10')} checked={currentSwitch?.actualSumActiveTempOver10?.isShown} />
                                {getGlyph('actualSumActiveTempOver10')}
                                {currentSwitch?.actualSumActiveTempOver10?.description}
                                </div>
                            </Row>
                            {/* <Row>
                                <Checkbox onClick={() => handleToggleShown('atmTemperatureFact')} checked={switchObject?.atmTemperatureFact?.isShown} />
                                {switchObject?.atmTemperatureFact?.description} PROGNOZ
                            </Row> */}
                        </Col>
                    </Row>
                </>
            );
        }


        if (chartType === 'precipitation') {
            return (
                <>
                    <Row>
                        <Col span={8}>
                            {/* <Row>
                                <Checkbox onClick={() => handleToggleShown('atmTemperatureFact')} checked={currentSwitch?.atmTemperatureFact?.isShown} />
                                {currentSwitch?.atmTemperatureFact?.description}
                            </Row>
                            <Row>
                                <Checkbox onClick={() => handleToggleShown('atmTemperatureFact')} checked={currentSwitch?.atmTemperatureFact?.isShown} />
                                {currentSwitch?.atmTemperatureFact?.description}
                            </Row>
                            <Row>
                                <Checkbox onClick={() => handleToggleShown('atmTemperatureFact')} checked={currentSwitch?.atmTemperatureFact?.isShown} />
                                {currentSwitch?.atmTemperatureFact?.description}
                            </Row> */}
                        </Col>
                        <Col span={8}>
                            <Row>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <Switch onClick={() => handleToggleShown('falloutVolMm')} checked={currentSwitch?.falloutVolMm?.isShown} />
                                {getGlyph('falloutVolMm')}
                                {currentSwitch?.falloutVolMm?.description}
                                </div>
                            </Row>
                            {/* <Row>
                                <Checkbox onClick={() => handleToggleShown('avgDailyAtmTemp')} checked={currentSwitch?.avgDailyAtmTemp?.isShown} />
                                {currentSwitch?.avgDailyAtmTemp?.description}
                            </Row> */}
                        </Col>
                    </Row>
                </>
            );
        }

        if (chartType === 'wind') {
            return (
                <>
                    <Row>
                        <Col span={8}>
                            <Row>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <Switch onClick={() => handleToggleShown('windDirection')} checked={currentSwitch?.windDirection?.isShown} />
                                {getGlyph('windDirection')}
                                {currentSwitch?.windDirection?.description}
                                </div>
                            </Row>
                            <Row>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <Switch onClick={() => handleToggleShown('windSpeed')} checked={currentSwitch?.windSpeed?.isShown} />
                                {getGlyph('windSpeed')}
                                {currentSwitch?.windSpeed?.description}
                                </div>
                            </Row>
                            <Row>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <Switch onClick={() => handleToggleShown('windGust')} checked={currentSwitch?.windGust?.isShown} />
                                {getGlyph('windGust')}
                                {currentSwitch?.windGust?.description}
                                </div>
                            </Row>
                            {/* <Row>
                                <Checkbox onClick={() => handleToggleShown('atmTemperatureFact')} checked={currentSwitch?.atmTemperatureFact?.isShown} />
                                {currentSwitch?.atmTemperatureFact?.description}
                            </Row> */}
                        </Col>
                        <Col span={8}>
                            {/* <Row>
                                <Checkbox onClick={() => handleToggleShown('minDailyAtmTemp')} checked={currentSwitch?.minDailyAtmTemp?.isShown} />
                                {currentSwitch?.minDailyAtmTemp?.description}
                            </Row>
                            <Row>
                                <Checkbox onClick={() => handleToggleShown('avgDailyAtmTemp')} checked={currentSwitch?.avgDailyAtmTemp?.isShown} />
                                {currentSwitch?.avgDailyAtmTemp?.description}
                            </Row>
                            <Row>
                                <Checkbox onClick={() => handleToggleShown('maxDailyAtmTemp')} checked={currentSwitch?.maxDailyAtmTemp?.isShown} />
                                {currentSwitch?.maxDailyAtmTemp?.description}
                            </Row> */}
                        </Col>
                        <Col span={8}>
                            {/* <Row>
                                <Checkbox onClick={() => handleToggleShown('actualSumActiveTempOver5')} checked={currentSwitch?.actualSumActiveTempOver5?.isShown} />
                                {currentSwitch?.actualSumActiveTempOver5?.description}
                            </Row>
                            <Row>
                                <Checkbox onClick={() => handleToggleShown('actualSumActiveTempOver10')} checked={currentSwitch?.actualSumActiveTempOver10?.isShown} />
                                {currentSwitch?.actualSumActiveTempOver10?.description}
                            </Row> */}
                        </Col>
                    </Row>
                </>
            );
        }





        if (chartType === 'soil') {
            return (
                <>
                    <Row>
                        <Col span={8}>
                            <Row>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <Switch onClick={() => handleToggleShown('soilHumidity20Cm')} checked={currentSwitch?.soilHumidity20Cm?.isShown} />
                                {getGlyph('soilHumidity20Cm')}
                                {currentSwitch?.soilHumidity20Cm?.description}
                                </div>
                            </Row>
                            <Row>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <Switch onClick={() => handleToggleShown('soilHumidity40Cm')} checked={currentSwitch?.soilHumidity40Cm?.isShown} />
                                {getGlyph('soilHumidity40Cm')}
                                {currentSwitch?.soilHumidity40Cm?.description}
                                </div>
                            </Row>
                            <Row>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <Switch onClick={() => handleToggleShown('soilHumidity60Cm')} checked={currentSwitch?.soilHumidity60Cm?.isShown} />
                                {getGlyph('soilHumidity60Cm')}
                                {currentSwitch?.soilHumidity60Cm?.description}
                                </div>
                            </Row>
                        </Col>
                        <Col span={8}>
                            <Row>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <Switch onClick={() => handleToggleShown('soilTempSurface')} checked={currentSwitch?.soilTempSurface?.isShown} />
                                {getGlyph('soilTempSurface')}
                                {currentSwitch?.soilTempSurface?.description}
                                </div>
                            </Row>
                            {/* <Row>
                                <Checkbox onClick={() => handleToggleShown('avgDailyAtmTemp')} checked={currentSwitch?.avgDailyAtmTemp?.isShown} />
                                {currentSwitch?.avgDailyAtmTemp?.description}
                            </Row>
                            <Row>
                                <Checkbox onClick={() => handleToggleShown('maxDailyAtmTemp')} checked={currentSwitch?.maxDailyAtmTemp?.isShown} />
                                {currentSwitch?.maxDailyAtmTemp?.description}
                            </Row> */}
                        </Col>
                    </Row>
                </>
            );
        }

        if (chartType === 'harmful') {
            return (
                <>
                    <Row>
                        {selectedFields?.map(oneField => (
                            <Col span={8}>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <Switch onClick={() => handleToggleShown(oneField?.vega_key)} checked={currentSwitch?.[oneField?.vega_key]?.isShown} />
                                    {getGlyph('field')}
                                    {oneField?.vega_key}
                                </div>
                            </Col>
                        ))}
                    </Row>
                </>
            );
        }

        if (chartType === 'gtk') {
            return (
                <>
                    <Row>
                        <Col span={8}>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <Switch onClick={() => handleToggleShown('gtk_week')} checked={currentSwitch?.gtk_week?.isShown} />
                            {getGlyph('gtk_week')}
                            <p>за неделю</p>
                        </div>
                        </Col>
                        <Col span={8}>
                        {/* <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <Switch onClick={() => handleToggleShown('gtk_month')} checked={currentSwitch?.gtk_month?.isShown} />
                            {getGlyph('gtk_month')}
                            <p>за месяц</p>
                        </div> */}
                        </Col>
                        <Col span={8}>
                        {/* <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <Switch onClick={() => handleToggleShown('gtk_year')} checked={currentSwitch?.gtk_year?.isShown} />
                            {getGlyph('gtk_year')}
                            <p>за год</p>
                        </div> */}
                        </Col>
                    </Row>
                </>
            );
        }

        return (
            <>
                <Row>
                    DESC
                </Row>
            </>
        );
    }, [currentSwitch, selectedFields, chartData, chartLegend, chartType]);

    return (
        <div style={{ width: '100%' }}>
            <Row style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <img src={`/images/charttypes/${chartType}.svg`} />
                <Col style={{ marginLeft: '5px' }}>
                    <h3>{chartName}</h3>
                    {(chartType !== 'gtk' && chartType !== 'harmful' && !isModal) && (
                        <div>{currFieldName}</div>
                    )}
                </Col>
            </Row>
            
            <Row style={{ marginTop: '16px' }}>
                {chartType === 'wind' && (
                    <div style={{ overflow: 'scroll', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-evenly' }}>
                        <div style={{ width: '25px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-evenly' }}>
                            {chartData?.windDirection?.map((degreeVal, degIdx) => degreeVal ? (
                                <img src="/images/wind-direction.svg" style={{ filter: chartData?.windGust[degIdx] > 0 ? 'invert(21%) sepia(49%) saturate(4991%) hue-rotate(358deg) brightness(99%) contrast(77%)' : '', transform: `rotate(${degreeVal}deg)` }} />
                            ) : (<div style={{ backgroundColor: '#DADADA', width: '14px', height: '2px' }} />))}
                        </div>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-evenly' }}>
                            {chartData?.windSpeed?.map(speedVal => speedVal ? (
                                <div style={{ display: 'flex', width: '25px', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-evenly' }}>
                                    <p>{speedVal || '-'}</p>
                                    <p>м/c</p>
                                </div>
                            ) : (<div style={{ backgroundColor: '#DADADA', width: '14px', height: '2px' }} />))}
                        </div>
                    </div>
                )}
            </Row>
            <Row>
                <Chart
                    style={{ width: '100%' }}
                    height={isModal ? "100px" : "300px"}
                    type="bar"
                    // data={analysisChartData}
                    data={currentDatasets}
                    options={chartOptions}
                />
            </Row>
            <Row>
                <SwitchPanelNode />
            </Row>
        </div>
    );
};

export default ChartRepresentation;
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { translate, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Icon from '@ant-design/icons';

import {
    Rate, Row, Col, Collapse
} from 'antd';

import jsPDF from 'jspdf';
import 'jspdf-autotable';

import { rus2Latin } from '../../../utils/utils';
import cn from '../../../utils/cn';
import Button from '../../../components/button';
import '../style.less';
import * as constants from '../../../constants';
import { selectors, actions } from '../../../models';
import { InfoMessage, RoundButton } from '../../../components';
import Images from '../../../components/images';

const { Panel } = Collapse;

const {
    getDisease
} = actions;
const {
    getCurrentDisease
} = selectors;

const mstp = (state) => ({
    disease: getCurrentDisease(state)
});
const mdtp = ({
    getDisease
});

@withRouter
@connect(mstp, mdtp)
@withTranslation('disease modal')
@cn('disease-detail')
export default class DiseaseDetail extends Component {
    componentDidMount() {
        const { getDisease, match } = this.props;
        const { id } = match.params;
        getDisease(id);
    }

    jsPdfGenerator = () => {
        const { t, disease } = this.props;
        const doc = new jsPDF('p', 'pt', 'a4', true);
        doc.addFileToVFS('Arial-normal.ttf', constants.FONT);
        doc.addFont('Arial-normal.ttf', 'Arial', 'normal');

        doc.setFont('Arial');
        const { pageSize } = doc.internal;
        const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
        const text = doc.splitTextToSize(disease.name, pageWidth - 35, {});
        const finalY = text.length * 30;
        doc.text(text, 40, 40);

        const body = [
            [t('disease type'), disease.subgroup.group.name],
            [t('disease cause'), disease.causative_agent.name],
            [t('leaves'), disease.manifestations_leaves],
            [t('stalks'), disease.manifestations_stems],
            [t('seedlings sings'), disease.manifestations_shoots],
            [t('root system'), disease.manifestations_root_system],
            [t('reproductive organs'), disease.manifestations_reproductive_organs],
            [t('pathogen morphology'), disease.pathogen_morphology],
            [t('pathogen distribution biology'), disease.biology_spread_pathogen],
            [t('diseases ecology'), disease.ecology_of_disease],
            [t('disease harmfulness'), disease.disease_harmfulness],
            [t('fight ways'), disease.fight_ways],
            [t('economic threshold of harmfulness'), disease.economic_threshold_of_harmfulness]
        ];
        doc.autoTable({
            startY: finalY + 40,
            columnStyles: {
                0: { font: 'Arial', cellPadding: 10 },
                1: { font: 'Arial', cellWidth: pageWidth / 2, cellPadding: 10 }
            },
            body
        });
        const fileName = rus2Latin(disease.name);
        doc.save(`${fileName}.pdf`);
    };

    goBack = () => {
        const { history } = this.props;
        history.push('/diseases');
    };

    render() {
        const { cn } = this;
        const {
            t,
            onTabChange,
            disease
        } = this.props;
        if (!disease) return <div />;
        const images = (disease && disease.related_images) ? disease.related_images.map(item => item.image) : [];
        const { pageNum, pageSize, filterOptions } = this.props?.location;

        return (

            <div className={cn()}>
                <div className={cn('content')}>
                    <Link
                        className={cn('back')}
                        to={{
                            pathname: '/diseases',
                            pageNum,
                            pageSize,
                            filterOptions
                        }}>
                        {t('back')}
                    </Link>
                    <Row className="item-cards">
                        {disease?.disease?.name && (
                            <div className="item-card">
                                <span>{t('disease name')}</span>
                                <div>
                                    <span><b>{disease?.disease?.name}</b></span>
                                </div>
                            </div>
                        )}
                        {disease?.disease?.subgroup?.group?.name && (
                            <div className="item-card">
                                <span>{t('disease type')}</span>
                                <div>
                                    <span><b>{disease?.disease?.subgroup?.group?.name}</b></span>
                                </div>
                            </div>
                        )}
                        {disease?.causative_agent?.name && (
                            <div className="item-card item-card__big">
                                <span>{t('disease cause')}</span>
                                <div>
                                    <span><b>{disease?.causative_agent?.name}</b></span>
                                </div>
                            </div>
                        )}
                    </Row>
                    {(
                        !disease?.biology_spread_pathogen || disease?.biology_spread_pathogen?.length < 1
                        // !disease?.manifestations_shoots || !disease?.manifestations_leaves || !disease?.manifestations_stems
                        // || !disease?.manifestations_roots || !disease?.manifestations_root_system || !disease?.manifestations_reproductive_organs
                    ) ? (
                        <Row className="row-in-development">
                            <div className="text-in-development">
                                {t('in development')}
                            </div>
                        </Row>
                    ) : (
                        <Row className={cn('information')}>
                            <Col span={10} className={cn('symptoms')}>
                                <h3>{t('disease area and symptoms')}</h3>
                                <Collapse>
                                    {disease.manifestations_shoots && (
                                        <Panel key={0} header={t('seedlings sings')}>
                                            <p>{disease.manifestations_shoots}</p>
                                        </Panel>
                                    )}
                                    {disease.manifestations_leaves && (
                                        <Panel key={1} header={t('leaves')}>
                                            <p>{disease.manifestations_leaves}</p>
                                        </Panel>
                                    )}
                                    {disease.manifestations_stems && (
                                        <Panel key={2} header={t('stalks')}>
                                            <p>{disease.manifestations_stems}</p>
                                        </Panel>
                                    )}
                                    {disease.manifestations_roots && (
                                        <Panel key={3} header={t('root crop signs')}>
                                            <p>{disease.manifestations_roots}</p>
                                        </Panel>
                                    )}
                                    {disease.manifestations_root_system && (
                                        <Panel key={4} header={t('root system')}>
                                            <p>{disease.manifestations_root_system}</p>
                                        </Panel>
                                    )}
                                    {disease.manifestations_reproductive_organs && (
                                        <Panel key={5} header={t('reproductive organs')}>
                                            <p>{disease.manifestations_reproductive_organs}</p>
                                        </Panel>
                                    )}
                                </Collapse>
                            </Col>
                            <Col span={14} className={cn('catalog')}>
                                <h3>{t('catalog information about disease')}</h3>
                                <Collapse>
                                    {disease.causes_of_occurrence && (
                                        <Panel key={0} header={t('causes of occurrence')}>
                                            <p>{disease.causes_of_occurrence}</p>
                                        </Panel>
                                    )}

                                    {disease.pathogen_morphology && (
                                        <Panel key={1} header={t('pathogen morphology')}>
                                            <p>{disease.pathogen_morphology}</p>
                                        </Panel>
                                    )}
                                    {disease.biology_spread_pathogen && (
                                        <Panel key={2} header={t('pathogen distribution biology')}>
                                            <p>{disease.biology_spread_pathogen}</p>
                                        </Panel>
                                    )}
                                    {disease.ecology_of_disease && (
                                        <Panel key={3} header={t('diseases ecology')}>
                                            <p>{disease.ecology_of_disease}</p>
                                        </Panel>
                                    )}
                                    {disease.disease_harmfulness && (
                                        <Panel key={4} header={t('disease harmfulness')}>
                                            <p>{disease.disease_harmfulness}</p>
                                        </Panel>
                                    )}
                                    {disease.fight_ways && (
                                        <Panel key={5} header={t('fight ways')}>
                                            <p>{disease.fight_ways}</p>
                                        </Panel>
                                    )}
                                    {disease.economic_threshold_of_harmfulness && (
                                        <Panel key={6} header={t('economic threshold of harmfulness')}>
                                            <p>{disease.economic_threshold_of_harmfulness}</p>
                                        </Panel>
                                    )}
                                </Collapse>
                            </Col>
                        </Row>
                    )}
                    {
                        images?.length > 0 && (
                            <Row className={cn('photos')}>

                                <Images images={images} onRemove={this.onRemove} />

                            </Row>
                        )
                    }
                </div>

                <div className={cn('footer')}>
                    <div>
                        { /* <Button
                            onClick={() => onTabChange('3', true)}
                            type="primary">
                            {t('fertilizer')}
                        </Button>
                        <Button
                            onClick={() => onTabChange('3', true)}
                            type="default">
                            {t('expert')}
                        </Button>
                        <Button
                            onClick={() => onTabChange('3', true)}
                            type="default">
                            {t('send review')}
                        </Button> */ }
                    </div>
                    <div>
                        <Button
                            onClick={this.jsPdfGenerator}
                            type="link">
                            <Icon type="download" />
                            {t('generate')}
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

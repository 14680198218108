import React, { Component } from 'react';
import { connect } from 'react-redux';

import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import { Button, Icon } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';

import cn from '../../../utils/cn';
import { Title, Tabs, PanelLayout } from '../../../components';

import ProfileData from './profile-data';
import ProfilePassword from './profile-password';
import { selectors, actions } from '../../../models';
import OrganisationData from './organisation-data';

const {
    updateProfile
} = actions;
const {
    getUser
} = selectors;

const { TabPane } = Tabs;
const { PanelHeader, PanelContent } = PanelLayout;

const mstp = (state) => ({
    user: getUser(state)
});
const mdtp = ({
    updateProfile
});


@withRouter
@withTranslation('profile detail')
@connect(mstp, mdtp)
@cn('profile-detail')
export default class ProfileDetail extends Component {
    state = {
        tab: '1'
    };

    onTabChange = (key) => {
        this.setState({ tab: key });
    };

    render() {
        const { cn } = this;
        const {
            t, user, setOrgForm, setMeForm, handleSaving, isButtonDisabled, setIsButtonDisabled
        } = this.props;
        const { tab } = this.state;

        return (
            <PanelLayout>
                <PanelHeader>
                    <Title title={t('title', { value: user?.owners?.[0]?.name })} />
                </PanelHeader>
                <PanelContent>
                    <Tabs
                        onChange={this.onTabChange}
                        activeKey={tab}>
                        <TabPane
                            tab={(
                                <span>
                                    <UserOutlined />
                                    {t('profile data')}
                                </span>
                            )}
                            key="1">
                                <Tabs
                                    onChange={this.onTabChange}
                                    activeKey={tab} style={{ marginBottom: '-40px' }}>
                                    <TabPane
                                        tab={t('my data')}
                                        key="1" />
                                </Tabs>
                                <ProfileData setIsButtonDisabled={setIsButtonDisabled} giveFormRef={setMeForm} onTabChange={this.onTabChange} />
                                <Tabs
                                    onChange={this.onTabChange}
                                    activeKey={tab} style={{ marginBottom: '-40px', marginTop: '15px' }}>
                                    <TabPane
                                        tab={t('org data')}
                                        key="1" />
                                </Tabs>
                                <OrganisationData setIsButtonDisabled={setIsButtonDisabled} giveFormRef={setOrgForm} onTabChange={this.onTabChange} />
                                {/* <OrganisationData onTabChange={this.onTabChange} /> */}

                                <Button disabled={isButtonDisabled} type='primary' onClick={handleSaving}>{t('save changes')}</Button>
                        </TabPane>
                        {/* <TabPane
                            tab={(
                                <span>
                                    <LockOutlined />
                                    {t('profile password')}
                                </span>
                            )}
                            key="2">
                            <ProfilePassword onTabChange={this.onTabChange} />
                        </TabPane> */}
                    </Tabs>
                </PanelContent>
            </PanelLayout>
        );
    }
}
import options from './options';

const { name } = options;

export const curSamplesList = state => state.models[name].samplesList;
export const curSampleRow = state => state.models[name].sampleRow;
export const curUploadStatus = state => state.models[name].uploadStatus;
export const curNewRow = state => state.models[name].createdRow;
export const curUpdatedRow = state => state.models[name].updatedRow;
export const curDeleteCount = state => state.models[name].deletedRow;
export const curNewUsr = state => state.models[name].createdUser;
export const usrListResponse = state => state.models[name].usersList;
export const usrCreationResult = state => state.models[name].userCreated;
export const curCardDefaults = state => state.models[name].cardDefaults;
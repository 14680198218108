import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { withRouter } from 'react-router-dom';
import {
    Row, Col, Form, Input, Select
} from 'antd';

import { get } from 'leaflet/src/dom/DomUtil';
import cn from '../../../utils/cn';
import {
    Button
} from '../../../components';

import { object2Form } from '../../../utils/form';
import { selectors, actions } from '../../../models';

import { regions } from '../../../regions';

import keycloak from '../../../utils/keycloak';

const { TextArea } = Input;
const {
    updateProfile,
    getRegions
} = actions;
const {
    getUser,
    getRegionsCatalog
} = selectors;

const mstp = (state) => ({
    user: getUser(state),
    regions: getRegionsCatalog(state)
});
const mdtp = ({
    updateProfile,
    getRegions
});

@withRouter
@connect(mstp, mdtp)
@withTranslation('profile detail')
@cn('profile-detail')
export default class ProfileData extends Component {
    formRef = React.createRef();

    componentDidMount() {
        const { getRegions } = this.props;
        getRegions('?page_size=100');
        this.initForm();
    }

    onSubmit = (values) => {
        const { updateProfile, user } = this.props;
        updateProfile(values);
    };

    initForm = () => {
        const { match, user, giveFormRef } = this.props;
        const { id, type } = match.params;
        const {
            email,
            region,
            phone,
            about
        } = user;

        giveFormRef(this.formRef);

        this.formRef.current.setFieldsValue({
            email,
            region: region && region.id,
            phone,
            about
        });
    };

    filterOption = (inputName, option) => {
        const optionName = option.props.children.toLowerCase();

        inputName = inputName.toLowerCase();

        return optionName.indexOf(inputName) !== -1;
    };

    render() {
        const { cn } = this;
        const { t, user, regions, setIsButtonDisabled } = this.props;

        return (
            <Form ref={this.formRef}
            onValuesChange={() => setIsButtonDisabled(false)}
                labelCol={{ span: 24 }}
                // className={cn('data')}
                onFinish={this.onSubmit}
                name="profile-form">
                <div>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item label={t('first name')}
                                name="first_name">
                                <Input
                                    // disabled
                                    value={user?.first_name}
                                    isRequired={false}
                                    placeholder={t('first name placeholder')}
                                    validator="phone" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label={t('last name')}
                                name="last_name">
                                    <Input
                                // disabled
                                value={user?.last_name}
                                isRequired={false}
                                placeholder={t('last name placeholder')}
                                validator="phone" />
                                </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item label={t('email')}
                                name="email">
                                    <Input
                                disabled
                                value={keycloak?.idTokenParsed?.email}
                                isRequired={false}
                                placeholder={t('email placeholder')}
                                validator="phone" />
                                </Form.Item>
                        </Col>
                    </Row>
                    {/* <Row gutter={20}>
                        <Col span={8}>
                            <div className="field">
                                <Form.Item label={t('region')}
                                    name="region">
                                    <Select
                                        filterOption={this.filterOption}>
                                        {regions.map(item => (
                                            <Select.Option value={item.id} key={item.id}>
                                                {item.name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </div>
                        </Col>
                        <Col span={8}>
                            <div className="field">
                                <Form.Item label={t('phone')}
                                    isRequired={false}
                                    name="phone">
                                    <Input
                                        isRequired={false}
                                        placeholder={t('phone placeholder')}
                                        validator="phone" />
                                </Form.Item>
                            </div>
                        </Col>
                        <Col span={8}>
                            <div className="field">
                                <Form.Item label={t('email')}
                                    isRequired={false}
                                    name="email">
                                    <Input
                                        disabled
                                        value={keycloak?.idTokenParsed?.email}
                                        isRequired={false}
                                        placeholder={t('email placeholder')}
                                        validator="phone" />
                                </Form.Item>
                            </div>
                        </Col>
                    </Row> */}
                    {/* <Row gutter={20}>
                        <Col span={8}>
                            <div className="field">
                                <Form.Item name="about"
                                    isRequired={false}
                                    label={t('about')}>
                                    <TextArea
                                        autoSize={{ minRows: 3 }}
                                        placeholder={t('about placeholder')} />
                                </Form.Item>
                            </div>
                        </Col>
                        <Col span={24} className={cn('button-block')}>
                            <Button
                                type="primary"
                                htmlType="submit">
                                {t('save')}
                            </Button>
                        </Col>
                    </Row> */}
                </div>
                { /* <Row gutter={20}>
                    <Col span={24} className={cn('info')}>
                        <div className={cn('info', 'inner')}>{t('info')}</div>
                    </Col>
                </Row> */ }
            </Form>

        );
    }
}
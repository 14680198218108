import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import {
    Col, Form, Input, Row, Select
} from 'antd';
import Icon from '@ant-design/icons';
import {
    CatalogTitle,
    InfoMessage, Table, TableFooter, Tooltip
} from '../../../components';
import Popover from './action-popover';
import cn from '../../../utils/cn';

import { selectors, actions } from '../../../models';

const {
    getCalculatorsMordovia,
    clearCalculatorMordovia,
    getCultures
} = actions;
const {
    getCalculatorsMordoviaCatalog,
    getCalculatorsMordoviaCatalogCount,
    getUser,
    getCulturesCatalog
} = selectors;

const mstp = state => ({
    dataSource: getCalculatorsMordoviaCatalog(state),
    dataSize: getCalculatorsMordoviaCatalogCount(state),
    user: getUser(state),
    cultures: getCulturesCatalog(state)
});

const mdtp = {
    getCalculatorsMordovia,
    clearCalculatorMordovia,
    getCultures
};

const ROW_DIVIDER = 10;
const MAX_RECORDS_IN_PAGINATOR = 40;
const DEFAULT_TABLE_PAGE = 1;

@withRouter
@connect(mstp, mdtp)
@withTranslation('calculators table')
@cn('calculators-table')
export default class CalculatorsTable extends Component {
    state = {
        page: DEFAULT_TABLE_PAGE,
        page_size: ROW_DIVIDER,
        infoMessageVisible: false,
        deleteId: null
    };

    mockFields = [
        {
            is_done: false,
            created: '11.11.2023',
            culture: 'Culture123',
            vega_key: 'Test field'
        }
    ]

    componentDidMount() {
        const { clearCalculatorMordovia, getCultures } = this.props;
        getCultures();
        clearCalculatorMordovia();
        this.getFilteredFields();
    }

    onPageSizeChange = (page_size) => {
        this.setState({
            page_size
        }, () => this.getFilteredFields());
    };

    onCurrentPageChange = (page) => {
        this.setState({
            page
        }, () => this.getFilteredFields());
    };

    get getYears() {
        const date = new Date();
        let year = Number(date.getFullYear());
        let step = 10;
        const years = [];
        while (step >= 0) {
            years.push(year--);
            step--;
        }
        return years;
    }

    get initRequest() {
        const { page, page_size } = this.state;
        const { user } = this.props;
        const page_size_url = `page_size=${page_size}`;
        const page_url = `page=${page}`;
        return `?filter={"user":${user.id}}&${page_size_url}&${page_url}`;
    }

    getFilteredFields = () => {
        const { getCalculatorsMordovia, user } = this.props;
        const {
            page_size,
            page,
            name,
            culture,
            year
        } = this.state;
        // const urlArr = [`"user":${user?.id && typeof user?.id === 'number' ? : }`];
        const urlArr = [`"user":${user?.id}`];
        name ? urlArr.push(`"vega_key.icontains":"${name}"`) : '';
        // culture ? urlArr.push(`"culture.name.icontains":"${culture}"`) : '';
        const page_size_url = `page_size=${page_size}`;
        const page_url = `page=${page}`;
        getCalculatorsMordovia(`?${page_size_url}&${page_url}${year ? `&year=${year}` : ''}${culture ? `&culture=${culture}` : ''}&filter={${urlArr.join(',')}}`);
    };

    onChange = (value, values) => {
        this.setState(value, () => {
            this.setState({ page: 1 }, () => {
                this.getFilteredFields();
            });
        });
    };

    onMount = (form, { formInit, formFieldsClear, formClear }) => {
        this.setState({
            form, formInit, formFieldsClear, formClear
        });
    };

    get requestsColumns() {
        const { cn } = this;
        const { t, history } = this.props;
        const { year } = this.state;
        const date = new Date();

        return [
            {
                title: t('title'),
                dataIndex: 'vega_key',
                key: 'vega_key',
                className: cn('title'),
                render: (text, record) => (
                    <Tooltip
                        placement="bottomLeft"
                        title={text}
                        onClick={() => history.push(`/representation/${year ?? Math.max(...record.years)}/${record.vega_key}`)}>
                        {text}
                    </Tooltip>
                )

            },
            {
                title: t('culture'),
                dataIndex: 'culture',
                key: 'culture',
                className: cn('culture'),
                render: (culture) => culture && culture.name
            },
            {
                title: t('create date'),
                dataIndex: 'created',
                key: 'creation_date',
                className: cn('createDate'),
                render: (text) => moment(text).format('DD.MM.YYYY')
            },
            {
                title: t('status'),
                dataIndex: 'is_done',
                key: 'is_done',
                className: cn('status'),
                render: (text) => (
                    <>
                        {text && t('COMPLETED')}
                        {!text && t('IN_PROGRESS')}
                    </>
                )
            },
            {
                title: '',
                dataIndex: 'action',
                key: 'action',
                className: cn('action'),
                fixed: 'right',
                render: (text, record) => (
                    <div className={cn('button')}>
                        <Popover
                            status={record.status}
                            id={record.vega_key}
                            placement="bottomRight"
                            trigger="click"
                            url="representation"
                            deleteClick={() => this.showDeleteModal(record.vega_key)} />
                    </div>
                )
            }
        ];
    }

    removeCalculator = () => {
        const { removeCalculatorAGRO, getCalculatorsAGRO } = this.props;
        const { deleteId } = this.state;

        removeCalculatorAGRO(deleteId);

        this.hideDeleteModal();
    };

    hideDeleteModal = () => {
        this.setState({ infoMessageVisible: false });
    };

    showDeleteModal = id => {
        this.setState({
            deleteId: id,
            infoMessageVisible: true
        });
    };

    get filterItems() {
        const { dataSize } = this.props;
        const items = [];

        for (let i = 1; i < dataSize; i += 1) {
            if (i % ROW_DIVIDER === 0) {
                items.push({ value: `${i}`, title: `${i}` });
            }

            if (i > MAX_RECORDS_IN_PAGINATOR) break;
        }

        return items;
    }

    render() {
        const { cn } = this;
        const {
            dataSource,
            dataSize,
            cultures,
            t
        } = this.props;
        const { infoMessageVisible, page_size, page } = this.state;
        if (false) {
            return (
                <div className={cn('empty')}>
                    {t('no')}
                </div>
            );
        }
        return (
            <div className={cn()}>
                <Form
                    ref={this.formRef}
                    onValuesChange={this.onChange}
                    name="fields">
                    <Row gutter={20}>
                        <Col span={6} className="field">
                            <Form.Item name="year" label={t('years')}>
                                <Select>
                                    {
                                        this.getYears.map(year =>
                                            <Select.Option value={year}>{year}</Select.Option>)
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6} className="field">
                            <Form.Item name="name" label={t('field')}>
                                <Input
                                    validator="anyReg"
                                    allowClear
                                    suffix={<Icon type="search" />} />
                            </Form.Item>
                        </Col>
                        <Col span={6} className="field">
                            <Form.Item name="culture" label={t('culture')}>
                                <Input
                                    validator="anyReg"
                                    allowClear />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <Table
                    // dataSource={dataSource}
                    dataSource={this.mockFields}
                    columns={this.requestsColumns}
                    pagination={false} />
                {dataSize > 1 && (
                    <TableFooter
                        filterItems={this.filterItems}
                        showSizeChanger={false}
                        onSelect={this.onPageSizeChange}
                        onChange={this.onCurrentPageChange}
                        pageSize={page_size}
                        current={page}
                        rowDivider={ROW_DIVIDER.toString()}
                        dataSize={dataSize} />
                )}
                <InfoMessage
                    visible={infoMessageVisible}
                    title={t('delete title')}
                    okBtn={t('delete')}
                    cancelBtn={t('cancel')}
                    onOk={this.removeCalculator}
                    onCancel={this.hideDeleteModal} />
            </div>
        );
    }
}
import request from '../../utils/request';

export const getMeteodataRegistry = body => request.post({
    url: `/api/meteo/public/meteo/meteo_telemetry/`,
    body
});

export const getMeteodataStations = body => request.post({
    url: `/api/meteo/public/meteo/meteo_stations/`,
    body
});
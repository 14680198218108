import React, { Component, useState, useEffect, useRef, useContext } from 'react';
import { Table, Form, Input, InputNumber, Select, DatePicker } from 'antd';
import moment from 'moment';
import '../style.less';
import { CloseOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { currSoilTypes } from '../../../models/mordovia/selectors';
import { getSoils } from '../../../models/mordovia/actions';

export default ({
    soilTypeOptions = [],
    isDataEdited = false, setIsDataEdited = () => {},
    tableColumns = [], tableStretch = 0,
    tableData = [], setTableData = () => {},
    tablePage = 1, tablePageSize = 10,
    tableDataSize = 0, districtOptions = [],
    setTablePage = () => {},
    setTablePageSize = () => {},
    cultureOptions = [],
    regionOptions = [],
    mechanicalOptions = [],
    moistureOptions = [],
    actionOptions = [],
    handleSaveChanges = () => {},
    setIsAfterPagination = () => {}
}) => {

    const EditableContext = React.createContext(null);
    const EditableRow = ({ index, ...props }) => {
        const [form] = Form.useForm();
        return (
            <Form form={form} component={false}>
                <EditableContext.Provider value={form}>
                    <tr {...props} />
                </EditableContext.Provider>
            </Form>
        );
    };
    const EditableCell = ({
        title,
        editable,
        children,
        dataIndex,
        record,
        key,
        parentKey,
        handleSave,
        ...restProps
    }) => {
        const inputDataIndices = [
            'owner_user', 'owner'
        ];

        const numberDataIndices = [
            'area', 'planned_yield',
            'depth_of_arable_layer'
        ];

        const selectDataIndices = [
            'region', 'mechanical_composition', 'degree_of_soil_moisture', 'soil_type',
            'culture', 'preceding_culture', 'fertilizer_action_year', 'district'
        ];
        
        const datepickerDataIndices = [
            'sowing_date', 
        ];

        const yearedIndices = [
            'sowing_date', 'culture', 'preceding_culture',
            'depth_of_arable_layer', 'planned_yield', 'fertilizer_action_year'
        ]

        const soils = useSelector(state => currSoilTypes(state));
        
        const [editing, setEditing] = useState(false);
        const inputRef = useRef(null);
        const form = useContext(EditableContext);

        useEffect(() => {
            if (editing) {
                inputRef?.current?.focus();
            }
        }, [editing]);

        const toggleEdit = () => {
            setEditing(!editing);
            let resValll = record?.[dataIndex];
            if (resValll?.value) {
                resValll = resValll?.value;
            }
            if (resValll?.id) {
                resValll = resValll?.id;
            }
            form.setFieldsValue({
                [dataIndex]: resValll?.name ? resValll?.name : resValll,
            });
        };

        const [savedSoil, setSavedSoil] = useState(null);

        useEffect(() => {
            save(false, yearedIndices?.includes(parentKey), false, true);
        }, [savedSoil]);
        
        const save = async (isDate = false, isYear = false, optionalClearing = false, optionalSoil = false) => {
            try {
                setIsDataEdited(true);
                const values = await form.validateFields();

                if (optionalSoil && savedSoil) {
                    values.soil_type = savedSoil;
                    setSavedSoil(null);
                }

                const yearKey = Object.keys(values)?.[0];
                
                if (isDate) {
                    if (yearKey) {
                        values[yearKey].sowing_date = values?.[yearKey].sowing_date?.format("YYYY-MM-DD");
                    }
                }
                if (yearKey && values?.depth_of_arable_layer === null) {
                    values[yearKey].depth_of_arable_layer = 0;
                }
                if (yearKey && values?.planned_yield === null) {
                    values[yearKey].planned_yield = 0;
                }
                toggleEdit();
                if (isYear) {
                    const requiredYearKey = Object.keys(values)?.[0];
                    const recCpy = {...record};
                    recCpy[requiredYearKey] = {
                        ...recCpy[requiredYearKey],
                        ...values[requiredYearKey]
                    };
                    handleSave({...recCpy}, values)
                } else {
                    handleSave({
                        ...record,
                        ...values,
                    }, values);
                }
            } catch (errInfo) {
                // console.log('Save failed:', errInfo);
            }
        };

        let childNode = children;

        let selectOptions = [{
            label: 'No options',
            value: null
        }];

        let currentDefaultValue = null;

        if (record && record?.[dataIndex] && record?.[dataIndex] !== undefined) {
            let resValll = record?.[dataIndex];
            if (resValll?.id) {
                resValll = resValll?.id;
            }
            if (resValll?.value) {
                resValll = resValll?.value;
            }
            form.setFieldsValue({
                [dataIndex]: resValll?.name ? resValll?.name : resValll,
            });
        }

        if (typeof dataIndex?.[0] === 'number') {
            const findingYear = record?.year_group?.years?.filter(x => x?.year === dataIndex?.[0])?.[0];
            
            if (
                parentKey === 'fertilizer_action_year' || parentKey === 'culture'
                || parentKey === 'preceding_culture' || parentKey === 'degree_of_soil_moisture'
                || parentKey === 'mechanical_composition' || parentKey === 'owner_user'
                || parentKey === 'region'
            ) {
                let preValue = findingYear?.[dataIndex?.[1]];
                currentDefaultValue = preValue?.name;
            } else if (parentKey === 'sowing_date') {
                let preValue = findingYear?.[dataIndex?.[1]];
                currentDefaultValue = preValue ? moment(preValue?.name) : null;
            } else if (parentKey === 'depth_of_arable_layer' || parentKey === 'planned_yield') {
                let preValue = findingYear?.[dataIndex?.[1]];
                currentDefaultValue = preValue || 0;
            } else {
                currentDefaultValue = findingYear?.[dataIndex?.[1]];
            }
        } else {
            if (
                parentKey === 'fertilizer_action_year' || parentKey === 'culture'
                || parentKey === 'preceding_culture' || parentKey === 'degree_of_soil_moisture'
                || parentKey === 'mechanical_composition' || parentKey === 'owner_user'
                || parentKey === 'region'
            ) {
                let preValue = record?.[dataIndex?.[0]];
                currentDefaultValue = preValue?.[dataIndex?.[1]];
            } else {
                currentDefaultValue = record?.[dataIndex?.[0]];
            }
        }

        if (parentKey === 'soil_type') {
            selectOptions = soils?.map(x => {
                return {
                    label: x?.name || "",
                    value: x?.id || 0
                }
              });
        }

        if (parentKey === 'district') {
            selectOptions = districtOptions;
        }
        if (parentKey === 'region') {
            selectOptions = regionOptions;
        }
        if (parentKey === 'mechanical_composition') {
            selectOptions = mechanicalOptions;
        }
        if (parentKey === 'degree_of_soil_moisture') {
            selectOptions = moistureOptions;
        }
        if (parentKey === 'fertilizer_action_year') {
            selectOptions = actionOptions;
        }
        if (parentKey === 'culture' || parentKey === 'preceding_culture') {
            selectOptions = cultureOptions;
        }

        if (currentDefaultValue === undefined || (currentDefaultValue && typeof currentDefaultValue === 'object' && Object.keys(currentDefaultValue)?.length === 0)) {
            currentDefaultValue = null;
        }

        if (editable) {
            if (editing) {
                if (datepickerDataIndices?.includes(parentKey)) {
                    childNode = (
                        <Form.Item
                            style={{
                            margin: 0,
                            }}
                            name={dataIndex}
                        >
                            <DatePicker format={"DD.MM.YYYY"} style={{ width: '100%', height: '100%' }} defaultValue={currentDefaultValue} ref={inputRef} onChange={() => save(true, yearedIndices?.includes(parentKey))} />
                        </Form.Item>
                    );
                }

                if (selectDataIndices?.includes(parentKey)) {
                    childNode = (
                        <Form.Item
                            style={{
                            margin: 0,
                            }}
                            name={dataIndex}
                        >
                            <Select
                                showSearch
                                filterOption={(input, option) => (option?.label ?? '')?.toLowerCase().includes(input?.toLowerCase())}
                                allowClear
                                style={{ width: '100%', height: '100%' }}
                                options={selectOptions}
                                onChange={() => save(false, yearedIndices?.includes(parentKey))}
                                ref={inputRef} />
                        </Form.Item>
                    );
                }

                if (numberDataIndices?.includes(parentKey)) {
                    childNode = (
                        <Form.Item
                            style={{
                            margin: 0,
                            }}
                            name={dataIndex}
                        >
                            {currentDefaultValue ? (
                                <InputNumber style={{ width: '100%', height: '100%' }} defaultValue={currentDefaultValue || null} ref={inputRef} onBlur={() => save(false, yearedIndices?.includes(parentKey))} />
                            ) : (
                                <InputNumber style={{ width: '100%', height: '100%' }} ref={inputRef} onBlur={() => save(false, yearedIndices?.includes(parentKey))} />
                            )}
                        </Form.Item>
                    );
                }

                if (inputDataIndices?.includes(parentKey)) {
                    childNode = (
                        <Form.Item
                            style={{
                            margin: 0,
                            }}
                            name={dataIndex}
                        >
                            <Input
                                allowClear
                                style={{ width: '100%', height: '100%' }}
                                onChange={(e) => {
                                    if (e.target.value === undefined) {
                                        save(false, yearedIndices?.includes(parentKey))
                                    }
                                }} defaultValue={currentDefaultValue} ref={inputRef}
                                onPressEnter={() => save(false, yearedIndices?.includes(parentKey))}
                                onBlur={() => save(false, yearedIndices?.includes(parentKey))} />
                        </Form.Item>
                    );
                }
            } else {
                childNode = (
                    <div
                        className="editable-cell-value-wrap"
                        style={{
                        // paddingRight: 24,
                        }}
                        onClick={toggleEdit}
                    >
                        {children}
                    </div>
                );
            }
        }
        
        return <td {...restProps}>{childNode?.value ? childNode?.value : childNode}</td>;
    };

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };

    const [preparedColumns, setPreparedColumns] = useState([]);

    const handleSaveNew = (row, resultingValue = {}) => {
        setIsDataEdited(true);
        const newData = tableData
        const index = newData.findIndex((item) => {
            return row.id === item.id;
        });
        const item = newData[index];
        newData[index] = {
            ...item,
            ...row,
            ...resultingValue
        };
        setTableData([...newData]);
    };

    useEffect(() => {
        const mapColumns = col => {
            const newCol = {
                ...col
            };
            return newCol;
        };

        const desiredResult = tableColumns?.map(mapColumns);
        
        setPreparedColumns(desiredResult);
    }, [tableColumns]);

    const checkChange = (newPage, newPageSize) => {
        if (newPageSize !== tablePageSize) {
            if (isDataEdited) {
                setIsAfterPagination(true);
            }
            setTablePage(1);
            setTablePageSize(newPageSize);
        } else {
            if (isDataEdited) {
                setIsAfterPagination(true);
            }
            setTablePage(newPage);
        }
    };

    return (
        <Table
            rowKey="id"
            components={components}
            rowClassName={() => "editable-row"}
            pagination={{
                current: tablePage,
                pageSize: tablePageSize,
                total: tableDataSize,
                onChange: checkChange,
                showSizeChanger: true,
                pageSizeOptions: [
                    '10', '25', '50'
                ]
            }}
            bordered
            scroll={{ x: tableStretch, y: 500 }}
            columns={preparedColumns}
            dataSource={tableData}
        />
    );
};
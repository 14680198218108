import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import {
    Collapse, Form, Layout, Spin
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useClassName } from 'utils/cn';
import './style.less';

import moment from 'moment';

import { PanelLayout, Title } from '../../components';
import PanelHeader from '../../components/panel-layout/panel-header';
import PanelContent from '../../components/panel-layout/panel-content';
import CalculatorDetails from './components/calculator-details';
import { getDrugsPhyto, getProtocolPhyto } from '../../models/calculators-phyto/actions';
import {
    currentContaminationCreatedPhyto,
    currentProtocolPhyto, drugsPhyto, updatedPhotoPhyto
} from '../../models/calculators-phyto/selectors';
import { makeResponse } from './components/utils';
import { getUser } from '../../models/account/selectors';

export default () => {
    const cn = useClassName('all-calculators');
    const { t } = useTranslation('phyto calculator');
    const match = useRouteMatch();

    const currentCalculator = useSelector(state => currentProtocolPhyto(state));
    const currentUser = useSelector(state => getUser(state));
    const createdContamination = useSelector(state => currentContaminationCreatedPhyto(state));
    const updatedImage = useSelector(state => updatedPhotoPhyto(state));

    const [showRecalculateButtons, setShowRecalculateButtons] = useState(false);
    const [calculationDetailsContinueDisabled, setCalculationDetailsContinueDisabled] = useState(false);
    const [currentCalculatorId, setCurrentCalculatorId] = useState(0);
    const [currentCalculatorEntity, setCurrentCalculatorEntity] = useState({});
    const [calculatorName, setCalculatorName] = useState(null);
    const [externalImages, setExternalImages] = useState([]);
    const [internalImages, setInternalImages] = useState([]);
    const [seedImages, setSeedImages] = useState([]);
    const [externalPathogens, setExternalPathogens] = useState([]);
    const [internalPathogens, setInternalPathogens] = useState([]);
    const [internalPathogenStage, setInternalPathogenStage] = useState(1);
    const [qualityRepetitions, setQualityRepetitions] = useState(4);

    const fertilizersList = useSelector(state => drugsPhyto(state));

    const [fertilizersFullList, setFertilizersFullList] = useState([]);
    const [fertilizersPage, setFertilizersPage] = useState(1);

    useEffect(() => {
        fertilizersPage && fertilizersPage > 0 && dispatch(getDrugsPhyto({ page: fertilizersPage }));
    }, [fertilizersPage]);
    useEffect(() => {
        const dataPortion = fertilizersList?.results?.length > 0 ? fertilizersList?.results : [];
        dataPortion?.length > 0 && setFertilizersFullList([...fertilizersFullList, ...dataPortion]);
        fertilizersList?.next?.length > 0 && setFertilizersPage(fertilizersPage + 1);
    }, [fertilizersList]);

    useEffect(() => {
        if (currentCalculator?.lab_germination?.id || currentCalculator?.germination_power?.id) {
            setShowRecalculateButtons(true);
        }
    }, [currentCalculator]);
    

    const [form] = Form.useForm();

    const dispatch = useDispatch();

    useEffect(() => {
        if (updatedImage?.id) {
            setSeedImages(seedImages?.map(img => {
                if (img?.id === updatedImage?.id) {
                    return updatedImage;
                }

                return img;
            }));
            setInternalImages(internalImages?.map(img => {
                if (img?.id === updatedImage?.id) {
                    return updatedImage;
                }

                return img;
            }));
            setExternalImages(externalImages?.map(img => {
                if (img?.id === updatedImage?.id) {
                    return updatedImage;
                }

                return img;
            }))
        }
    }, [updatedImage]);
    
    useEffect(() => {
        if (createdContamination?.external_pathogens) {
            setExternalPathogens(createdContamination?.external_pathogens);
            setInternalPathogens(createdContamination?.internal_pathogens);
        }
    }, [createdContamination]);
    
    useEffect(() => {
        form.resetFields();
    }, []);

    useEffect(() => {
        if (currentCalculatorEntity?.id > 0) {

            setExternalPathogens(currentCalculatorEntity?.fito_calculator?.external_pathogens);
            setInternalPathogens(currentCalculatorEntity?.fito_calculator?.internal_pathogens);
            const formVals = makeResponse(currentCalculatorEntity);

            form?.setFieldsValue(formVals);
            
            formVals.germination_power.calculation_date = formVals?.germination_power?.calculation_date ? moment(formVals?.germination_power?.calculation_date) : null;
            formVals.lab_germination.calculation_date = formVals?.lab_germination?.calculation_date ? moment(formVals?.lab_germination?.calculation_date) : null;
            
            if (formVals?.qualityRepetitionsNumber) {
                setQualityRepetitions(formVals?.qualityRepetitionsNumber);
            }

            const phytoCalcValues = currentCalculatorEntity?.fito_calculator;
            if (phytoCalcValues?.id > 0) {
                setCurrentCalculatorId(phytoCalcValues?.id);

                if (phytoCalcValues?.total_pathogens_percentage) {
                    setInternalPathogenStage(3);
                }

                phytoCalcValues.culture = phytoCalcValues?.culture?.id;
                phytoCalcValues.region = phytoCalcValues?.region?.id;
                phytoCalcValues.reproduction = phytoCalcValues?.reproduction?.id;
                phytoCalcValues.sort = phytoCalcValues?.sort?.id;
                phytoCalcValues.analysis_date = phytoCalcValues?.analysis_date ? moment(phytoCalcValues?.analysis_date) : moment(null);
                phytoCalcValues.sample_date = phytoCalcValues?.sample_date ? moment(phytoCalcValues?.sample_date) : moment(null);
                phytoCalcValues.seed_collection_date = phytoCalcValues?.seed_collection_date ? moment(phytoCalcValues?.seed_collection_date) : moment(null);

                if (phytoCalcValues?.germination_power?.calculation_date) {
                    phytoCalcValues.germination_power.calculation_date = phytoCalcValues?.germination_power?.calculation_date ? moment(phytoCalcValues.germination_power.calculation_date) : moment(null);
                }

                if (phytoCalcValues?.lab_germination?.calculation_date) {
                    phytoCalcValues.lab_germination.calculation_date = phytoCalcValues?.lab_germination?.calculation_date ? moment(phytoCalcValues?.lab_germination?.calculation_date) : moment(null);
                }

                phytoCalcValues?.id > 0 && form?.setFieldsValue(phytoCalcValues);
            }
        }
    }, [currentCalculatorEntity]);

    useEffect(() => {
        if (currentCalculator?.id > 0) {
            setSeedImages(currentCalculator?.seed_images);
            setExternalImages(currentCalculator?.external_pathogen_images);
            setInternalImages(currentCalculator?.internal_pathogen_images);
            setCurrentCalculatorEntity(currentCalculator);
        }
    }, [currentCalculator]);

    const onFormFinish = (val1, val2, val3) => {
        // console.log('Form finish', val1, val2, val3);
    };

    const checkNextStepAvailability = (formVals) => {
        // if (isLaborant()) {
            return formVals?.address?.length > 0 && formVals?.culture > 0 && formVals?.year > 0 && formVals?.reproduction > 0 && formVals?.region > 0 && formVals?.seed_collection_date?.format('yyyy')?.length > 0;
        // }
        // return formVals?.hundred_seeds_weight > 0 && formVals?.germination > 0 && formVals?.purity > 0 && formVals?.address?.length > 0 && formVals?.culture > 0 && formVals?.year > 0 && formVals?.reproduction > 0 && formVals?.region > 0 && formVals?.seed_collection_date?.format('yyyy')?.length > 0;
    };

    const onFormValuesChange = (changedVal, newVals) => {
        const stepAvailability = checkNextStepAvailability(newVals);
        !stepAvailability && setCalculationDetailsContinueDisabled(true);
        stepAvailability && setCalculationDetailsContinueDisabled(false);

        const keyToChange = Object.keys(changedVal)[0];

        keyToChange === 'name' && setCalculatorName(changedVal[keyToChange]);
    };

    useEffect(() => {
        if (match?.params?.id) {
            dispatch(getProtocolPhyto(match?.params?.id));
        }
    }, [match])
    

    return (
        <Layout key="page-fsm-registry" className={cn()}>
            <PanelLayout>
                <PanelHeader>
                    <Title title={calculatorName?.length > 0 ? calculatorName : t('calculator name')} />
                </PanelHeader>
                <PanelContent>
                    <Form
                        form={form}
                        layout="vertical"
                        name="basic"
                        onValuesChange={onFormValuesChange}
                        onFinish={onFormFinish}
                        autoComplete="off">
                        <CalculatorDetails
                            showRecalculateButtons={showRecalculateButtons}
                            qualityRepetitions={qualityRepetitions}
                            setQualityRepetitions={setQualityRepetitions}
                            fertilizersFullList={fertilizersFullList}
                            internalPathogenStage={internalPathogenStage}
                            setInternalPathogenStage={setInternalPathogenStage}
                            externalPathogens={externalPathogens}
                            internalPathogens={internalPathogens}
                            curProto={currentCalculator}
                            calculatorId={currentCalculatorId}
                            seedImages={seedImages}
                            externalImages={externalImages}
                            internalImages={internalImages}
                            form={form}
                            detailsNextStepDisabled={calculationDetailsContinueDisabled} />
                    </Form>
                </PanelContent>
            </PanelLayout>
        </Layout>
    );
};
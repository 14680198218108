import React, { Component, useState, useEffect } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';

import cn, { useClassName } from '../../utils/cn';
import './style.less';
import { Menu, Tooltip } from 'antd';
import keycloak from '../../utils/keycloak';
import Icon, { DeleteOutlined } from '@ant-design/icons';
import LogoutIcon from '../../../public/images/sidebar/logout.svg';
import CatalogIcon from '../../../public/images/sidebar/catalog.svg';
import Logo from '../logo';
import SubMenu from 'antd/lib/menu/SubMenu';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import ProfileIcon from '../../../public/images/sidebar/profile.svg';

const MapsSideBar = ({onMenuChange,mapsList}) => {
    const { t } = useTranslation('vertical menu');
    const cn = useClassName('menu');
    const cn2 = useClassName('side-bar');

    const [currentActiveSubmenu, setCurrentActiveSubmenu] = useState([]);
    const [currentActiveRows, setCurrentActiveRows] = useState([]);

    const onLogout = () => {
        keycloak?.logout();
    };

    const MapsIcon = () => (
        <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.72624 0.0821122C8.94522 -0.0273797 9.20298 -0.0273703 9.42196 0.0821377L13.1876 1.96529C13.4316 2.08721 13.6368 2.27466 13.7803 2.50663C13.9238 2.7386 13.9999 3.00594 14 3.2787V10.7209C13.9999 10.9712 13.9358 11.2178 13.8138 11.4364C13.6918 11.655 13.516 11.8388 13.3031 11.9704C13.0901 12.1019 12.847 12.1769 12.597 12.1882C12.3469 12.1994 12.0981 12.1466 11.8742 12.0347L9.07411 10.635L5.27376 12.5352C5.05478 12.6447 4.79702 12.6447 4.57804 12.5351L0.812432 10.652C0.81236 10.652 0.812505 10.652 0.812432 10.652C0.568534 10.5301 0.363161 10.3426 0.219713 10.1107C0.0762234 9.87869 0.000145753 9.61135 1.23624e-07 9.33859L0 1.8964C0.000133638 1.64607 0.064229 1.39951 0.186204 1.18091C0.30818 0.96231 0.483989 0.778502 0.69695 0.646929C0.90991 0.515356 1.15296 0.440379 1.40303 0.429116C1.65311 0.417853 1.90191 0.470676 2.12584 0.582572L4.92589 1.98229L8.72624 0.0821122ZM4.14815 3.33259L1.55556 2.03658V9.2844L4.14815 10.5809V3.33259ZM5.7037 10.581L8.2963 9.28474V2.03625L5.7037 3.33255V10.581ZM9.85185 2.03635V9.28469L12.4444 10.5807V3.33289L9.85185 2.03635Z" fill="#ffffffa6" />
        </svg>
    )

    const submenuKeys = [
        'owned',
        // 'shared', 'deleted'
    ];

    return (
        <div className={cn2()}>
            <Logo />
            <div className={cn()}>
                <Menu
                    style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
                    onOpenChange={openKeys => setCurrentActiveSubmenu(openKeys?.filter(oKey => oKey === 'owned'))}
                    onSelect={menuItem => {
                        if (menuItem?.key === 'owned') {
                            setCurrentActiveSubmenu([menuItem?.key]);
                        } else {
                            setCurrentActiveRows([menuItem?.key]);
                        }
                    }}
                    openKeys={currentActiveSubmenu}
                    selectedKeys={currentActiveRows}
                    mode="inline">
                    <Menu.Item key="profile" id="p_log">
                        <Icon component={ProfileIcon} />
                        <span>{keycloak?.idTokenParsed?.preferred_username}</span>
                    </Menu.Item>
                    <SubMenu
                        disabled
                        onClick={() => onMenuChange('owned')}
                        id="owned"
                        key="owned"
                        title={(
                            <Tooltip title={t('blocked tab text')} placement="right">
                                <span style={{ color: 'rgba(255, 255, 255, 0.65)' }}>
                                    <Icon component={MapsIcon} />
                                    <span>{t('my maps')}</span>
                                </span>
                            </Tooltip>
                        )}>
                            {mapsList?.filter(x => x?.perm === 'owner')?.map(x => (
                                <Menu.Item key={`${x?.id}=${x?.name}`} id={`${x?.id}=${x?.name}`}>
                                    <Link to={`/field-registry/${x?.id}`}>
                                        {/* <Icon component={DeterminantIcon} /> */}
                                        {x?.name}
                                    </Link>
                                </Menu.Item>
                            ))}
                    </SubMenu>
                    <SubMenu
                        disabled
                        id="shared"
                        key="shared"
                        onClick={() => onMenuChange('shared')}
                        title={(
                            <Tooltip title={t('blocked tab text')} placement="right">
                                <span style={{ color: 'rgba(255, 255, 255, 0.65)' }}>
                                    <Icon component={MapsIcon} />
                                    <span>{t('available maps')}</span>
                                </span>
                            </Tooltip>
                        )}>
                            {mapsList?.filter(x => x?.perm === 'readwrite')?.map(x => (
                                <Menu.Item key={`${x?.id}=${x?.name}`} id={`${x?.id}=${x?.name}`}>
                                    <Link to={`/field-registry/${x?.id}`}>
                                        {/* <Icon component={DeterminantIcon} /> */}
                                        {x?.name}
                                    </Link>
                                </Menu.Item>
                            ))}
                    </SubMenu>
                    <SubMenu
                        disabled
                        id="deleted"
                        key="deleted"
                        onClick={() => onMenuChange('deleted')}
                        title={(
                            <Tooltip title={t('blocked tab text')} placement="right">
                                <span style={{ color: 'rgba(255, 255, 255, 0.65)' }}>
                                    <DeleteOutlined />
                                    <span>{t('trashcan')}</span>
                                </span>
                            </Tooltip>
                        )}>
                            {mapsList?.filter(x => x?.deleteAt)?.map(x => (
                                <Menu.Item key={`${x?.id}=${x?.name}`} id={`${x?.id}=${x?.name}`}>
                                    <Link to={`/field-registry/${x?.id}`}>
                                        {/* <Icon component={DeterminantIcon} /> */}
                                        <MapsIcon />
                                        {x?.name}
                                    </Link>
                                </Menu.Item>
                            ))}
                    </SubMenu>
                    <Menu.Item key="profile-exit" onClick={onLogout} id="p_logout">
                        <Icon component={LogoutIcon} />
                        {t('profile exit')}
                    </Menu.Item>
                </Menu>
            </div>
        </div>
    );
}

export default MapsSideBar;
import { all, takeLatest } from 'redux-saga/effects';
import { defaultSaga } from '../utils';

import * as constants from './constants';
import * as api from './api';

export default function* rootSaga() {
    yield all([
        yield takeLatest(
            constants.getUsers.toString(),
            defaultSaga({
                constants: constants.getUsers,
                api: api.getUsers
            })
        ),
        yield takeLatest(
            constants.resetPassword.toString(),
            defaultSaga({
                constants: constants.resetPassword,
                api: api.resetPassword
            })
        ),
        yield takeLatest(
            constants.savePassword.toString(),
            defaultSaga({
                constants: constants.savePassword,
                api: api.savePassword
            })
        ),
        yield takeLatest(
            constants.updateUser.toString(),
            defaultSaga({
                constants: constants.updateUser,
                api: api.updateUser
            })
        ),
        yield takeLatest(
            constants.updateOrg.toString(),
            defaultSaga({
                constants: constants.updateOrg,
                api: api.updateOrg
            })
        ),
        yield takeLatest(
            constants.createOrg.toString(),
            defaultSaga({
                constants: constants.createOrg,
                api: api.createOrg
            })
        )
    ]);
}
import {
    put, call, all, takeLatest, select
} from 'redux-saga/effects';
import { defaultSaga, getActions } from '../utils';
import { getCultures } from '../cultures/actions';

import * as constants from './constants';
import * as actions from './actions';
import * as api from './api';
import converter from './converter';
import {
    getCausativeAgents,
    getFertilizersPhyto,
    getVarietiesPhyto,
    removeCalculatorPhyto, updateRegistryFiles
} from './api';
import { getPurposesPhyto } from './constants';

function* uploadRegistryFileSaga({ payload }) {
    const [start, success, failure] = getActions(
        constants.uploadRegistryFile.try,
        constants.uploadRegistryFile.success,
        constants.uploadRegistryFile.error
    );
    try {
        const {
            values, type, protocolId, images, imageType
        } = payload;
        yield put(start());
        const data = yield call(api.uploadRegistryFile, { values, type });
        yield put(success({ image_type: imageType, uploaded_file: data }));
    } catch (error) {
        yield put(failure(error));
    }
}

export default function* rootSaga() {
    yield all([
        yield takeLatest(
            constants.getCalculatorPhyto.toString(),
            defaultSaga({
                constants: constants.getCalculatorPhyto,
                api: api.getCalculatorPhyto
            })
        ),
        yield takeLatest(
            constants.getCalculatorsPhyto.toString(),
            defaultSaga({
                constants: constants.getCalculatorsPhyto,
                api: api.getCalculatorsPhyto
            })
        ),
        yield takeLatest(
            constants.createCalculatorPhyto.toString(),
            defaultSaga({
                constants: constants.createCalculatorPhyto,
                api: api.createCalculatorPhyto
            })
        ),
        yield takeLatest(
            constants.getReproductionsPhyto.toString(),
            defaultSaga({
                constants: constants.getReproductionsPhyto,
                api: api.getReproductionsPhyto
            })
        ),
        yield takeLatest(
            constants.getVarietiesPhyto.toString(),
            defaultSaga({
                constants: constants.getVarietiesPhyto,
                api: api.getVarietiesPhyto
            })
        ),
        yield takeLatest(
            constants.removeCalculatorPhyto.toString(),
            defaultSaga({
                constants: constants.removeCalculatorPhyto,
                api: api.removeCalculatorPhyto
            })
        ),
        yield takeLatest(
            constants.getCulturesPhyto.toString(),
            defaultSaga({
                constants: constants.getCulturesPhyto,
                api: api.getCulturesPhyto
            })
        ),
        yield takeLatest(
            constants.getFertilizersPhyto.toString(),
            defaultSaga({
                constants: constants.getFertilizersPhyto,
                api: api.getFertilizersPhyto
            })
        ),
        yield takeLatest(
            constants.getPurposesPhyto.toString(),
            defaultSaga({
                constants: constants.getPurposesPhyto,
                api: api.getPurposesPhyto
            })
        ),
        yield takeLatest(
            constants.getRegionsPhyto.toString(),
            defaultSaga({
                constants: constants.getRegionsPhyto,
                api: api.getRegionsPhyto
            })
        ),
        yield takeLatest(
            constants.updateCalculatorPhyto.toString(),
            defaultSaga({
                constants: constants.updateCalculatorPhyto,
                api: api.updateCalculatorPhyto
            })
        ),
        yield takeLatest(
            constants.getCausativeAgents.toString(),
            defaultSaga({
                constants: constants.getCausativeAgents,
                api: api.getCausativeAgents
            })
        ),
        yield takeLatest(
            constants.addInternalContamination.toString(),
            defaultSaga({
                constants: constants.addInternalContamination,
                api: api.addInternalContamination
            })
        ),
        yield takeLatest(
            constants.addExternalContamination.toString(),
            defaultSaga({
                constants: constants.addExternalContamination,
                api: api.addExternalContamination
            })
        ),
        yield takeLatest(
            constants.addCausativeAgent.toString(),
            defaultSaga({
                constants: constants.addCausativeAgent,
                api: api.addCausativeAgent
            })
        ),
        yield takeLatest(
            constants.addDisease.toString(),
            defaultSaga({
                constants: constants.addDisease,
                api: api.addDisease
            })
        ),
        yield takeLatest(
            constants.uploadRegistryFile.toString(),
            uploadRegistryFileSaga
        ),
        yield takeLatest(
            constants.updateRegistryFiles.toString(),
            defaultSaga({
                constants: constants.updateRegistryFiles,
                api: api.updateRegistryFiles
            })
        ),
        yield takeLatest(
            constants.getProtocolsPhyto.toString(),
            defaultSaga({
                constants: constants.getProtocolsPhyto,
                api: api.getProtocolsPhyto
            })
        ),
        yield takeLatest(
            constants.getProtocolPhyto.toString(),
            defaultSaga({
                constants: constants.getProtocolPhyto,
                api: api.getProtocolPhyto
            })
        ),
        yield takeLatest(
            constants.createRegistryPhyto.toString(),
            defaultSaga({
                constants: constants.createRegistryPhyto,
                api: api.createRegistryPhyto
            })
        ),
        yield takeLatest(
            constants.updateRegistryPhyto.toString(),
            defaultSaga({
                constants: constants.updateRegistryPhyto,
                api: api.updateRegistryPhyto
            })
        ),
        yield takeLatest(
            constants.getUsersPhyto.toString(),
            defaultSaga({
                constants: constants.getUsersPhyto,
                api: api.getUsersPhyto
            })
        ),
        yield takeLatest(
            constants.updateInternalContamination.toString(),
            defaultSaga({
                constants: constants.updateInternalContamination,
                api: api.updateInternalContamination
            })
        ),
        yield takeLatest(
            constants.updateExternalContamination.toString(),
            defaultSaga({
                constants: constants.updateExternalContamination,
                api: api.updateExternalContamination
            })
        ),
        yield takeLatest(
            constants.updatePhotoDescription.toString(),
            defaultSaga({
                constants: constants.updatePhotoDescription,
                api: api.updatePhotoDescription
            })
        ),
        yield takeLatest(
            constants.newConnectionPhyto.toString(),
            defaultSaga({
                constants: constants.newConnectionPhyto,
                api: api.newConnectionPhyto
            })
        ),
        yield takeLatest(
            constants.newKindPhyto.toString(),
            defaultSaga({
                constants: constants.newKindPhyto,
                api: api.newKindPhyto
            })
        ),
        yield takeLatest(
            constants.newDiseasePhyto.toString(),
            defaultSaga({
                constants: constants.newDiseasePhyto,
                api: api.newDiseasePhyto
            })
        ),
        yield takeLatest(
            constants.newAgentPhyto.toString(),
            defaultSaga({
                constants: constants.newAgentPhyto,
                api: api.newAgentPhyto
            })
        ),
        yield takeLatest(
            constants.getSubgroupsPhyto.toString(),
            defaultSaga({
                constants: constants.getSubgroupsPhyto,
                api: api.getSubgroupsPhyto
            })
        ),
        yield takeLatest(
            constants.getSymptomsPhyto.toString(),
            defaultSaga({
                constants: constants.getSymptomsPhyto,
                api: api.getSymptomsPhyto
            })
        ),
        yield takeLatest(
            constants.getVegPhasesPhyto.toString(),
            defaultSaga({
                constants: constants.getVegPhasesPhyto,
                api: api.getVegPhasesPhyto
            })
        ),
        yield takeLatest(
            constants.getDrugsPhyto.toString(),
            defaultSaga({
                constants: constants.getDrugsPhyto,
                api: api.getDrugsPhyto
            })
        ),
        yield takeLatest(
            constants.deleteProtocolPhyto.toString(),
            defaultSaga({
                constants: constants.deleteProtocolPhyto,
                api: api.deleteProtocolPhyto
            })
        ),
        yield takeLatest(
            constants.getCausativeAgentItems.toString(),
            defaultSaga({
                constants: constants.getCausativeAgentItems,
                api: api.getCausativeAgentItems
            })
        ),
        yield takeLatest(
            constants.getDiseasesPhyto.toString(),
            defaultSaga({
                constants: constants.getDiseasesPhyto,
                api: api.getDiseasesPhyto
            })
        )
    ]);
}
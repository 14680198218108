import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Icon from '@ant-design/icons';
import {
    Layout, Spin, Collapse
} from 'antd';
import { Link, withRouter } from 'react-router-dom';
import { Chart } from '@antv/g2';
import { connect } from 'react-redux';
import {
    Title, Tabs, SideBar, PanelLayout
} from '../../components';
import Calculator from '../../../public/images/sidebar/calculator.svg';

import cn from '../../utils/cn';

import './style.less';
import { actions, selectors } from '../../models';

const { PanelHeader, PanelContent } = PanelLayout;
const { Panel } = Collapse;

const {
    getUser
} = selectors;

const mstp = (state) => ({
    user: getUser(state)
});
const mdtp = ({});

@withTranslation('calculator-info')
@connect(mstp, mdtp)
@cn('calculator-info')
export default class Catalog extends Component {
    state = {
        isFasie: false
    }

    componentDidMount() {
        const { user } = this.props;
        const { groups = [] } = user;
        this.setState({ isFasie: groups.includes('fasie') });
    }

    render() {
        const { cn } = this;
        const { isFasie } = this.state;

        const { t, history } = this.props;

        return (
            <Layout key="page-calculators-info" className={cn()}>
                <PanelLayout>
                    <PanelHeader>
                        <Title title={t('title')} />
                    </PanelHeader>
                    <PanelContent>
                        <Collapse expandIconPosition="right">
                            {isFasie && (
                                <Panel header={(
                                    <div className={cn('item')}>
                                        <div className={cn('icon-wrap')}>
                                            <Icon component={Calculator} />
                                        </div>
                                        <span className={cn('text-wrap')}>
                                            {t('calculator rb')}
                                        </span>
                                    </div>
                                )}
                                key="1">
                                    <div>
                                        {t('calculator rb text')}
                                        <Link to="/calculator/5">{t('start')}</Link>
                                    </div>
                                </Panel>
                            )}
                            <Panel header={(
                                <div className={cn('item')}>
                                    <div className={cn('icon-wrap')}>
                                        <Icon component={Calculator} />
                                    </div>
                                    <span className={cn('text-wrap')}>
                                        {t('calculator norm')}
                                    </span>
                                </div>
                            )}
                            key="2">
                                <div>
                                    {t('calculator norm text')}
                                    <Link to="/calculator/6">{t('start')}</Link>
                                </div>
                            </Panel>
                            {!isFasie && (
                                <>
                                    <Panel header={(
                                        <div className={cn('item')}>
                                            <div className={cn('icon-wrap')}>
                                                <Icon component={Calculator} />
                                            </div>
                                            <span className={cn('text-wrap')}>
                                                {t('calculator fluid')}
                                            </span>
                                        </div>
                                    )}
                                    key="3">
                                        <div>
                                            {t('calculator fluid text')}
                                            <Link to="/calculator-fluid">{t('start')}</Link>
                                        </div>
                                    </Panel>
                                    <Panel header={(
                                        <div className={cn('item')}>
                                            <div className={cn('icon-wrap')}>
                                                <Icon component={Calculator} />
                                            </div>
                                            <span className={cn('text-wrap')}>
                                                {t('calculator fed')}
                                            </span>
                                        </div>
                                    )}
                                    key="4">
                                        <div>
                                            {t('calculator fed text')}
                                            <Link to="/calculator-fed">{t('start')}</Link>
                                        </div>
                                    </Panel>
                                    <Panel header={(
                                        <div className={cn('item')}>
                                            <div className={cn('icon-wrap')}>
                                                <Icon component={Calculator} />
                                            </div>
                                            <span className={cn('text-wrap')}>
                                                {t('calculator agro')}
                                            </span>
                                        </div>
                                    )}
                                    key="5">
                                        <div>
                                            {t('calculator agro text')}
                                            <Link to="/calculator/7">{t('start')}</Link>
                                        </div>
                                    </Panel>
                                    <Panel header={(
                                        <div className={cn('item')}>
                                            <div className={cn('icon-wrap')}>
                                                <Icon component={Calculator} />
                                            </div>
                                            <span className={cn('text-wrap')}>
                                                {t('calculator myco')}
                                            </span>
                                        </div>
                                    )}
                                    key="6">
                                        <div>
                                            {t('calculator myco text')}
                                            <Link to="/calculator-myco">{t('start')}</Link>
                                        </div>
                                    </Panel>
                                    <Panel header={(
                                        <div className={cn('item')}>
                                            <div className={cn('icon-wrap')}>
                                                <Icon component={Calculator} />
                                            </div>
                                            <span className={cn('text-wrap')}>
                                                {t('calculator nutrition')}
                                            </span>
                                        </div>
                                    )}
                                           key="7">
                                        <div>
                                            {t('calculator nutrition text')}
                                            <Link to="/calculator/11">{t('start')}</Link>
                                        </div>
                                    </Panel>
                                    <Panel
                                        header={(
                                            <div className={cn('item')}>
                                                <div className={cn('icon-wrap')}>
                                                    <Icon component={Calculator} />
                                                </div>
                                                <span className={cn('text-wrap')}>
                                                    {t('calculator phyto')}
                                                </span>
                                            </div>
                                        )}
                                        key="16">
                                        <div onClick={() => setTimeout(() => window.location.reload(), 1500)}>
                                            {/* {t('calculator phyto text')} */}
                                            <Link to="/calculator-phyto">{t('start')}</Link>
                                        </div>
                                    </Panel>
                                </>
                            )}
                        </Collapse>
                    </PanelContent>
                </PanelLayout>
            </Layout>
        );
    }
}
import {
    linkMapToUser
} from '../constants';

const initialState = {};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case linkMapToUser.success: {
            return { ...action.payload, creationSuccessful: true };
        }

        case linkMapToUser.error: {
            return { error: 'cannot link' };
        }
        default: return state;
    }
}

import React, { Component, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { selectors } from '../../../models';
import { Spin } from 'antd';
import { getProfile } from '../../../models/account/actions';
import { getCalculatorsMordoviaShape } from '../../../models/mordovia/actions';
import proj4 from 'proj4';
import 'ol/ol.css';
import VectorSource from 'ol/source/Vector.js';
import Point from 'ol/geom/Point.js'
import MultiPoint from 'ol/geom/MultiPoint.js'
import LineString from 'ol/geom/LineString.js';
import Polygon from 'ol/geom/Polygon.js';
import MultiLineString from 'ol/geom/MultiLineString.js';
import MultiPolygon from 'ol/geom/MultiPolygon.js';
import Feature from 'ol/Feature.js';
import { GEOCODE_MAP_URL_BASE } from '../../../environment';

const {
    getCurrentCalculatorMordovia,
    getCurrentShape,
    isShapeLoading,
    getUserId,
    getUser,
    curPoints
} = selectors;

export default ({ curPoint }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation('map');
    const fieldShape = useSelector(state => getCurrentShape(state));
    const calculator = useSelector(state => getCurrentCalculatorMordovia(state));
    const shape = useSelector(state => getCurrentShape(state));
    const points = useSelector(state => curPoints(state));
    const isShape = useSelector(state => isShapeLoading(state));
    const userId = useSelector(state => getUserId(state));
    const user = useSelector(state => getUser(state));
    const [geoJson, setGeoJson] = useState({});
    const [filteredPoints, setFilteredPoints] = useState([]);
    const [drawMap, setDraw] = useState({});
    const [center, setCenter] = useState([54.26, 44.27]);
    
    useEffect(() => {
        dispatch(getProfile());
    }, []);

    useEffect(() => {
        if (curPoint) {
            const curPointInfo = points.find(item =>
                Number(item.id) === Number(curPoint));
            if (curPointInfo?.coordinates) {
                setCenter([curPointInfo.coordinates.coordinates[1], curPointInfo.coordinates.coordinates[0]]);
            }
        }
    }, [curPoint]);

    useEffect(() => {
        const filteredPoints = points.filter(point => point.coordinates);
        filteredPoints.forEach((point) => {
            point.coordinates.coordinates = [
                point.coordinates.coordinates[1], point.coordinates.coordinates[0]
            ];
        });
        setFilteredPoints(filteredPoints ?? []);
    }, [points]);

    useEffect(() => {
        if (shape.length && shape[0].field_shape) {
            const coord = shape[0].field_shape.coordinates[0];
            const coordinates = getShapeCoordinates(coord);
            const centroid = getCentroid(coordinates);
            const center = [centroid[1], centroid[0]];
            setCenter([...center]);
            const geoJson = {
                type: 'FeatureCollection',
                features: [
                    {
                        type: 'Feature',
                        geometry: { ...shape[0].field_shape }
                    }
                ]
            };
            setGeoJson({ ...geoJson });
        }
        setDraw(isShape);
    }, [shape, isShape]);

    const getShapeCoordinates = (data) => (data.length > 0 ? data.flat(1) : []);

    const getCentroid = (coordinates) => {
        const coord = { lat: 0, lan: 0 };
        coordinates.forEach((item) => {
            coord.lat += item[0];
            coord.lan += item[1];
        });
        return [coord.lat / coordinates.length, coord.lan / coordinates.length];
    };

    const isFasie = user?.groups?.includes('fasie');

    const [objectId, setObjectId] = useState(null);
    const [mapId, setMapId] = useState(null);
    const [layerId, setLayerId] = useState(null);
    const [mapExtent, setMapExtent] = useState([]);
    const [mapSourceString, setMapSourceString] = useState(null);

    useEffect(() => {
        if (layerId && objectId) {
            const urlString = `${GEOCODE_MAP_URL_BASE}/api/layers/${layerId}/features/${objectId}`;
            fetch(urlString, {
                mode: 'cors',
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
            }).then((res) => res.json())
            .then((data) => {
                const { properties, geometry } = data;
                const { tsentroid } = properties;
                const { type, coordinates } = geometry;

                const vectorSrc = new VectorSource();

                let featureInstance;

                if (type === 'Point') {
                    featureInstance = new LineString([coordinates, [parseFloat(coordinates[0]) + 0.0001, parseFloat(coordinates[1]) + 0.0001]]);
                }
                if (type === 'LineString') {
                    featureInstance = new LineString(coordinates);
                }
                if (type === 'MultiLineString') {
                    featureInstance = new MultiLineString(coordinates);
                }
                if (type === 'Polygon') {
                    featureInstance = new Polygon(coordinates);
                }
                if (type === 'MultiPoint') {
                    featureInstance = new Polygon(coordinates?.length > 1 ? [coordinates] : [coordinates[0], [parseFloat(coordinates[0][0]) + 0.0001, parseFloat(coordinates[0][1]) + 0.0001]]);
                }
                if (type === 'MultiPolygon') {
                    featureInstance = new MultiPolygon(coordinates);
                }

                const resFeat = new Feature({ geometry: featureInstance });

                vectorSrc.addFeature(resFeat);

                setMapExtent(vectorSrc.getExtent());
            })
        }
    }, [layerId, objectId]);
    

    useEffect(() => {
        if (user?.geohub_map_id) {
            setMapId(user?.geohub_map_id);
        } else {
            const lastId = localStorage.getItem('teleagronom-last-opened-map');
            if (lastId) {
                setMapId(lastId)
            }
        }
    }, [user]);

    useEffect(() => {
        const { feature_id, layer_id } = calculator?.registry_object;
        if (feature_id) {
            setObjectId(feature_id);
        }
        if (calculator?.registry_object?.feature_id && calculator?.registry_object?.feature_id > 0) {
            dispatch(getCalculatorsMordoviaShape({
                objectId: calculator?.registry_object?.feature_id
            }));
        }

        if (calculator?.registry_object?.layer_id && calculator?.registry_object?.layer_id > 0) {
            setLayerId(calculator?.registry_object?.layer_id);
        }
    }, [calculator]);

    useEffect(() => {
        console.log('vars', layerId, mapId, mapExtent);
        if (!layerId) {
            const lastId = localStorage.getItem('teleagronom-last-opened-map');

            if (mapExtent?.length > 0) {
                setMapId(lastId);
                setMapSourceString(
                    `${GEOCODE_MAP_URL_BASE}/maps/${lastId}?extent=[${mapExtent?.join(',')}]`
                );
            } else {
                setMapSourceString(
                    `${GEOCODE_MAP_URL_BASE}/maps/${lastId}`
                );
            }
        }
        // if (mapId && layerId && !mapExtent?.length > 0) {
        //     setMapSourceString(
        //         `${GEOCODE_MAP_URL_BASE}/maps/${mapId}?visibleLayers=%7B%22layerId%22:${layerId},%22filters%22:%5B%5D%7D`
        //     );
        // }
        
        // if (mapId && layerId && mapExtent?.length > 0) {
        //     setMapSourceString(
        //         `${GEOCODE_MAP_URL_BASE}/maps/${mapId}?extent=[${mapExtent?.join(',')}]&visibleLayers=%7B%22layerId%22:${layerId},%22filters%22:%5B%5D%7D`
        //     );
        // }
    }, [layerId, mapId, mapExtent]);

    return (
        <div style={{ width: '100%', height: '100%', position: 'relative' }}>
            <Spin style={{ position: 'absolute', top: '250px', left: '350px', zIndex: '10' }} />
            {mapSourceString?.length > 0 && (
                <iframe
                    style={{ width: '100%', height: '100%', position: 'absolute', top: '0px', left: '0px', zIndex: '15' }}
                    src={mapSourceString}
                    width="100%"
                    height="100%">
                </iframe>
            )}
        </div>
    );
};
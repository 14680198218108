import * as constants from './constants';

export const getCalculatorsMordovia = payload => ({
    type: constants.getCalculatorsMordovia.toString(),
    payload
});

export const getCalculatorMordovia = payload => ({
    type: constants.getCalculatorMordovia.toString(),
    payload
});

export const getCalculatorsMordoviaShape = payload => ({
    type: constants.getCalculatorsMordoviaShape.toString(),
    payload
});

export const getCalculatorsMordoviaForecast = payload => ({
    type: constants.getCalculatorsMordoviaForecast.toString(),
    payload
});

export const updateCalculatorsMordoviaForecast = payload => ({
    type: constants.updateCalculatorsMordoviaForecast.toString(),
    payload
});

export const getPestProbabilityData = payload => ({
    type: constants.getPestProbabilityData.toString(),
    payload
});

export const getPestProbabilityDataGroup = payload => ({
    type: constants.getPestProbabilityDataGroup.toString(),
    payload
});

export const calculatePestProbability = payload => ({
    type: constants.calculatePestProbability.toString(),
    payload
});

export const calculatePestProbabilityGroup = payload => ({
    type: constants.calculatePestProbabilityGroup.toString(),
    payload
});

export const clearCalculatorMordovia = payload => ({
    type: constants.clearCalculatorMordovia.toString()
});

export const addGroupCalculate = payload => ({
    type: constants.addGroupCalculate.toString(),
    payload
});

export const setActiveCalculate = payload => ({
    type: constants.setActiveCalculate.toString(),
    payload
});

export const removeGroupCalculate = payload => ({
    type: constants.removeGroupCalculate.toString(),
    payload
});

export const updateFieldDistribution = payload => ({
    type: constants.updateFieldDistribution.toString(),
    payload
});

export const getFieldPoints = payload => ({
    type: constants.getFieldPoints.toString(),
    payload
});

export const updateDistributionGroupCalculate = payload => ({
    type: constants.updateDistributionGroupCalculate.toString(),
    payload
});

export const getRegisrtyObject = payload => ({
    type: constants.getRegisrtyObject.toString(),
    payload
});

export const getGeocodeFeature = payload => ({
    type: constants.getGeocodeFeature.toString(),
    payload
});

export const getObjectsRegistryList = payload => ({
    type: constants.getObjectsRegistryList.toString(),
    payload
});

export const syncField = payload => ({
    type: constants.syncField.toString(),
    payload
});

export const getObjectByLayer = payload => ({
    type: constants.getObjectByLayer.toString(),
    payload
});

export const getSyncedLayers = payload => ({
    type: constants.getSyncedLayers.toString(),
    payload
});

export const updateObjectsPage = payload => ({
    type: constants.updateObjectsPage.toString(),
    payload
});

export const getLayersByMap = payload => ({
    type: constants.getLayersByMap.toString(),
    payload
});

export const getDistricts = payload => ({
    type: constants.getDistricts.toString(),
    payload
});

export const getSoils = payload => ({
    type: constants.getSoils.toString(),
    payload
});
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Layout, Row, Col } from 'antd';

import { withTranslation } from 'react-i18next';

import { Chart } from '@antv/g2';
import moment from 'moment';
import * as Scroll from 'react-scroll';
import cn from '../../utils/cn';

import {
    PanelLayout, SideBar, Table, Title
} from '../../components';

import './style.less';
import { actions, selectors } from '../../models';

import Map from './map';
import Map37 from './map37';
import Map38 from './map38';
import Map41 from './map41';
import Monitoring from './monitoring';
import { getUser } from '../../models/account/selectors';

const { PanelHeader, PanelContent } = PanelLayout;
const mstp = (state) => ({
    user: getUser(state)
});

const mdtp = ({
});

@connect(mstp, mdtp)
@withTranslation('map')
@cn('page')
export default class Profile extends Component {
    render() {
        const { cn } = this;
        const { t, user } = this.props;
        const { id } = user;

        return (
            <Layout key="page-map" className={cn()}>
                <PanelLayout>
                    <PanelHeader>
                        <Title title={t('title')} />
                    </PanelHeader>
                    <PanelContent>
                        <div id="map">
                            {(id !== 37 && id !== 38 && id !== 41) && (<Map />)}
                            {id === 37 && (<Map37 />)}
                            {id === 38 && (<Map38 />)}
                            {id === 41 && (<Map41 />)}
                        </div>
                        <Monitoring />
                    </PanelContent>
                </PanelLayout>
            </Layout>
        );
    }
}

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Col, DatePicker, Form, Input, InputNumber, Modal, Row, Select
} from 'antd';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../components';

import '../style.less';

import { useClassName } from '../../../utils/cn';
import { getCardDefaults, updateCardDefaults } from '../../../models/sample-log/actions';
import { getUser } from '../../../models/account/selectors';
import { curCardDefaults } from '../../../models/sample-log/selectors';

const SampleModal = ({
    setIsOpened,
    isOpened,
    form
}) => {
    const dispatch = useDispatch();
    const cn = useClassName('calculator-detail-params');
    const { t } = useTranslation('sample log');

    const currentUser = useSelector(state => getUser(state));
    const currentDefaults = useSelector(state => curCardDefaults(state));

    const [currentConfig, setCurrentConfig] = useState({});

    useEffect(() => {
        form?.setFieldsValue({
            ...currentConfig
        });
    }, [currentConfig]);

    useEffect(() => {
        if (currentDefaults?.results && currentDefaults?.results[0]) {
            setCurrentConfig(currentDefaults?.results[0]);
        }
    }, [currentDefaults]);

    useEffect(() => {
        currentUser?.id && dispatch(getCardDefaults({ laborantId: currentUser?.id }));
    }, [currentUser]);

    useEffect(() => {
        dispatch(getCardDefaults({ laborantId: currentUser?.id }));
    }, []);

    const handleCancel = () => {
        setIsOpened(false);
    };

    const handleOk = () => {
        const values = form?.getFieldsValue();
        dispatch(updateCardDefaults({ configId: currentConfig?.id, values }));
        setTimeout(() => {
            setIsOpened(false);
        }, 300);
    };

    return (
        <Modal
            width={864}
            onOk={handleOk}
            onCancel={handleCancel}
            visible={isOpened}
            footer={[
                <Button onClick={handleCancel} type="secondary">
                    {t('modal cancel button')}
                </Button>,
                <Button onClick={handleOk}>
                    {t('modal ok button')}
                </Button>
            ]}
            title={t('modal title')}>
            <Row>
                <Col span={11}>
                    <Form.Item
                        labelAlign="left"
                        labelCol={{ span: 24 }}
                        name="method"
                        label={t('row card method')}
                        rules={[{ required: true }]}>
                        <Select
                            options={[
                                {
                                    label: 'Ч',
                                    value: 'chirikov'
                                },
                                {
                                    label: 'K',
                                    value: 'kirsanov'
                                },
                                {
                                    label: 'М',
                                    value: 'machigin'
                                }
                            ]}
                            className="w-100" />
                    </Form.Item>
                </Col>
                <Col span={1} />
                <Col span={12}>
                    <Form.Item
                        labelAlign="left"
                        labelCol={{ span: 24 }}
                        name="dist_water_volume"
                        label={t('row card dilution_factor')}
                        rules={[{ required: true }]}>
                        <InputNumber
                            decimalSeparator=","
                            className="w-100" />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={11}>
                    <Form.Item
                        labelAlign="left"
                        labelCol={{ span: 24 }}
                        name="air_weigth"
                        label={t('row card dist_water_volume')}
                        rules={[{ required: true }]}>
                        <InputNumber
                            decimalSeparator=","
                            className="w-100" />
                    </Form.Item>
                </Col>
                <Col span={1} />
                <Col span={12}>
                    <Form.Item
                        labelAlign="left"
                        labelCol={{ span: 24 }}
                        name="dilution_factor"
                        label={t('row card air_weigth')}
                        rules={[{ required: true }]}>
                        <InputNumber
                            decimalSeparator=","
                            className="w-100" />
                    </Form.Item>
                </Col>
            </Row>
            {/* <Row>
                <Col span={11}>
                    <Row>
                        <Col span={10} />
                        <Col span={6}>
                            <Form.Item name="K" label={t('row card K')} rules={[{ required: true }]}>
                                <Select
                                    options={[
                                        {
                                            label: '0',
                                            value: 0
                                        },
                                        {
                                            label: '1',
                                            value: 1
                                        }
                                    ]}
                                    className="w-100" />
                            </Form.Item>
                        </Col>
                        <Col span={2} />
                        <Col span={6}>
                            <Form.Item name="A" label={t('row card A')} rules={[{ required: true }]}>
                                <Select
                                    options={[
                                        {
                                            label: '0',
                                            value: 0
                                        },
                                        {
                                            label: '1',
                                            value: 1
                                        }
                                    ]}
                                    className="w-100" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
                <Col span={1} />
                <Col span={12} />
            </Row> */}
        </Modal>
    );
};

export default SampleModal;
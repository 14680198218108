import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useClassName } from '../../../utils/cn';

import '../style.less';
import {
    Button, Col, Collapse, DatePicker, Form, Input, InputNumber, Row, Select, Table, Upload, Image, Popover
} from 'antd';
import { toFixed } from '../../../utils/utils';
import { DeleteOutlined, DownloadOutlined, ExclamationCircleOutlined, UploadOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from '../../../models/account/selectors';
import { updateCalculatorPhyto, uploadRegistryFile, updateRegistryPhyto } from '../../../models/calculators-phyto/actions';
import moment from 'moment';
import { useRouteMatch } from 'react-router-dom';
import { makeRequest } from './utils';
import {
    createRegistryPhyto, updatePhotoDescription
} from '../../../models/calculators-phyto/actions';
import {
    uploadedFilePhyto, updatedPhotoPhyto
} from '../../../models/calculators-phyto/selectors';

const { Panel } = Collapse;

const LaborantQualityIndicator = ({
    protocolCulture, protocolSort,
    calculatorId, qualityRepetitions, setQualityRepetitions,
    seedsMassOne, seedsNumOne, seedsMassTwo, seedsNumTwo,
    setSeedsMassOne, setSeedsMassTwo,
    form, culturesList, varietiesList,
    seedsMass, setPreparedProtocol,
    activeTabs, setActiveTabs, images,
    showRecalculateButtons
}) => {
    const dispatch = useDispatch();
    const cn = useClassName('all-calculators');
    const { t } = useTranslation('phyto calculator');
    const match = useRouteMatch();

    const uploadedRegistryFile = useSelector(state => uploadedFilePhyto(state));

    const [cultureName, setCultureName] = useState('-');
    const [sortName, setSortName] = useState('-');
    const [allFieldsDisabled, setAllFieldsDisabled] = useState(false);

    useEffect(() => {
        protocolCulture !== '-' && setCultureName(protocolCulture);
    }, [protocolCulture]);

    useEffect(() => {
        protocolSort !== '-' && setSortName(protocolSort);
    }, [protocolSort]);
    
    

    useEffect(() => {
        const { image_type, uploaded_file } = uploadedRegistryFile;
        if (uploaded_file?.id && image_type === 'seed_images') {
            let newImg = [];
            if (images?.length > 0) {
                newImg = images?.map(x => x?.id);
            }

            dispatch(updateRegistryPhyto({
                calcId: match?.params?.id,
                values: {
                    seed_images: [...newImg, uploaded_file?.id]
                }
            }));

            setTimeout(() => {
                window.location.reload();
            }, 500);
        }
    }, [uploadedRegistryFile]);
    
    const handleUpdatePhotoDescription = (currentPhoto, newDescription) => {
        dispatch(updatePhotoDescription({
            imageId: currentPhoto?.id,
            values: {
                picture_desc: newDescription
            }
        }));
    };

    const editableDescriptionContent = (photoInstance, oldVals) => {
        const [photoDescription, setPhotoDescription] = useState('');

        useEffect(() => {
            setPhotoDescription(oldVals)
        }, [oldVals])

        return (
            <div style={{ width: '300px', height: '200px' }}>
                <Row style={{ height: '150px' }}>
                    <Input.TextArea
                        style={{ maxHeight: '150px' }}
                        value={photoDescription}
                        defaultValue={oldVals}
                        onChange={evt => setPhotoDescription(evt?.target?.value)} />
                </Row>
                <Row align="middle" gutter={12} style={{ height: '50px' }}>
                    <Col span={24}>
                        <Button onClick={() => handleUpdatePhotoDescription(photoInstance, photoDescription)} type="primary" style={{ width: '100%' }}>
                            {t('save photo changes')}
                        </Button>
                    </Col>
                </Row>
            </div>
        );
    };
    
    const uploadProps = {
        name: 'file',
        customRequest: (reqParams) => {
            if (match?.params?.id) {
                const { file } = reqParams;
                return dispatch(uploadRegistryFile({
                    protocolId: match?.params?.id,
                    imageType: 'seed_images',
                    values: {
                        uploaded: moment().format('YYYY-MM-DDThh:mm'),
                        picture_file: file,
                        picture_desc: `Seed quality indicator ${moment().format('YYYY-MM-DDThh:mm')}`
                    },
                    type: 'formdata'
                }));
            }
        }
    };

    const handleCalculationFinish = () => {
        const formVals = form?.getFieldsValue();

        const {
            owner, vega_key, ent_helm_analysis,
            name, germination, germination_comment, purity, seeding_rate_comment, stowing_date,
            lab_germination, germination_power, year, culture, address, others, pickling, plant_protection,
            region, reproduction, sample_weight, seed_collection_date, seed_purpose, weight_500_1, weight_500_2,
            sort, weight, weight_1000_comment
        } = formVals;

        const formValsKeys = Object.keys(formVals);

        const germinationPowerPercentageKeys = formValsKeys.filter(key => key.includes('germination_power/percentage'));
        const labGerminationPercentageKeys = formValsKeys.filter(key => key.includes('lab_germination/percentage'));

        const labPercentages = [];
        const powerPercentages = [];

        germinationPowerPercentageKeys.forEach(key => {
            // console.log('GERM test key', key);
            powerPercentages.push(formVals[key]);
        });
        labGerminationPercentageKeys.forEach(key => {
            // console.log('LAB test key', key);
            labPercentages.push(formVals[key]);
        });

        const calcBody = {
            address: address,
            culture: culture,
            germination: germination,
            germination_comment: germination_comment,
            analysis_date: seed_collection_date?.format('YYYY-MM-DD'),
            hundred_seeds_weight: weight_500_1 + weight_500_2,
            sample_date: seed_collection_date?.format('YYYY-MM-DD'),
            name: name,
            others: others,
            owner: owner,
            pickling: pickling,
            plant_protection: pickling && plant_protection ? plant_protection : undefined,
            purity: purity,
            region: region,
            reproduction: reproduction,
            sample_weight: sample_weight,
            seed_collection_date: seed_collection_date?.format('YYYY-MM-DD'),
            seed_purpose: seed_purpose,
            seeding_rate_comment: seeding_rate_comment,
            weight_1000_comment: weight_1000_comment,
            seeds_mass_1: weight_500_1,
            seeds_mass_2: weight_500_2,
            hundred_seeds_weight: weight_500_1 + weight_500_2,
            sort: sort,
            stowing_date: stowing_date?.format('YYYY-MM-DD'),
            vega_key: vega_key,
            weight: weight,
            year: year
        };

        setPreparedProtocol(calcBody);

        if (!match?.params?.id) {
            dispatch(createRegistryPhyto({
                owner: owner,
                vega_key: vega_key,
                diseases: false,
                weight_1000_comment: weight_1000_comment,
                weight_500_1: weight_500_1,
                weight_500_2: weight_500_2,
                germination_comment: germination_comment,
                seeding_rate_comment: seeding_rate_comment,
                stowing_date: stowing_date?.format('YYYY-MM-DD'),
                germination_power: {
                    calculation_date: germination_power?.calculation_date?.format('YYYY-MM-DD'),
                    germination_to_calculation_days: germination_power?.germination_to_calculation_days,
                    percentages: powerPercentages
                },
                lab_germination: {
                    calculation_date: lab_germination?.calculation_date?.format('YYYY-MM-DD'),
                    germination_to_calculation_days: lab_germination?.germination_to_calculation_days,
                    percentages: labPercentages
                }
            }));
        } else {
            dispatch(updateCalculatorPhyto({
                calcId: calculatorId,
                values: calcBody
            }));

            dispatch(updateRegistryPhyto({
                calcId: match?.params?.id,
                values: {
                    ent_helm_analysis: ent_helm_analysis,
                    owner: owner,
                    vega_key: vega_key,
                    diseases: false,
                    stowing_date: stowing_date?.format('YYYY-MM-DD'),
                    weight_1000_comment: weight_1000_comment,
                    weight_500_1: weight_500_1,
                    weight_500_2: weight_500_2,
                    germination_comment: germination_comment,
                    seeding_rate_comment: seeding_rate_comment,
                    germination_power: {
                        calculation_date: germination_power?.calculation_date?.format('YYYY-MM-DD'),
                        germination_to_calculation_days: germination_power?.germination_to_calculation_days,
                        percentages: powerPercentages
                    },
                    lab_germination: {
                        calculation_date: lab_germination?.calculation_date?.format('YYYY-MM-DD'),
                        germination_to_calculation_days: lab_germination?.germination_to_calculation_days,
                        percentages: labPercentages
                    }
                }
            }));
        }
    };

    const imagesColumns = [
        {
            key: "picture_file",
            dataIndex: "picture_file",
            render: value => value ? (
                <Image width={100} src={value} />
            ) : "-"
        },
        {
            key: "picture_name",
            dataIndex: "picture_name"
        },
        {
            key: "picture_desc",
            dataIndex: "picture_desc",
            render: (value, record) => (
                <Popover content={() => editableDescriptionContent(record, value)} trigger="click">
                    {value}
                </Popover>
            )
        },
        {
            key: "uploaded",
            dataIndex: "uploaded",
            render: value => value ? moment(value).format('DD.MM.YYYY') : "-"
        },
        {
            key: "actions",
            dataIndex: '',
            render: (value, record) => (
                <DeleteOutlined
                    onClick={() => {
                        const newImgs = [...images].map(x => x.id).filter(y => y !== record?.id);
                        dispatch(updateRegistryPhyto({
                            calcId: match?.params?.id,
                            values: {
                                seed_images: newImgs
                            }
                        }));
                    }} />
            )
        }
    ];

    useEffect(() => {
        const formSort = form?.getFieldValue('sort');
        const formCulture = form?.getFieldValue('culture');

        if (formSort && formSort > 0 && varietiesList?.length > 0) {
            const selectedSort = varietiesList?.filter(srt => srt?.id === parseInt(formSort, 10))[0];
            setSortName(selectedSort?.sort_name);
        }

        if (formCulture && formCulture > 0 && culturesList?.length > 0) {
            const selectedCulture = culturesList?.filter(clt => clt.id === parseInt(formCulture, 10))[0];
            setCultureName(selectedCulture?.name);
        }
    }, [form, culturesList, varietiesList]);

    const recalculateValues = (valueType) => {
        const formValues = form?.getFieldsValue();
        const formKeys = Object.keys(formValues);

        if (valueType === 'germination') {
            const germinationRepetitions = [];
            formKeys.filter(key => key.includes('germination_power/percentage/')).forEach(gKey => germinationRepetitions.push(formValues[gKey]));

            const labRepetitions = [];
            formKeys.filter(key => key.includes('lab_germination/percentage/')).forEach(lKey => labRepetitions.push(formValues[lKey]));

            const { germination_power, lab_germination, stowing_date } = formValues;

            const newGermPower = {
                calculation_date: germination_power?.calculation_date?.format('YYYY-MM-DD'),
                germination_to_calculation_days: germination_power?.germination_to_calculation_days,
                percentages: germinationRepetitions
            };

            const newLabGerm = {
                calculation_date: lab_germination?.calculation_date?.format('YYYY-MM-DD'),
                germination_to_calculation_days: lab_germination?.germination_to_calculation_days,
                percentages: labRepetitions
            };

            dispatch(updateRegistryPhyto({
                calcId: match?.params?.id,
                values: {
                    lab_germination: newLabGerm,
                    germination_power: newGermPower,
                    stowing_date: stowing_date?.format('YYYY-MM-DD')
                }
            }));
        }

        if (valueType === 'mass') {
            const { weight_500_1, weight_500_2 } = formValues;
            dispatch(updateRegistryPhyto({
                calcId: match?.params?.id,
                values: { weight_500_1, weight_500_2 }
            }));
        }
    };

    return (
        <>
            <Collapse
                style={{ backgroundColor: 'white' }}
                bordered={false}
                defaultActiveKey={['norm', 'mass', 'energy']}
                className={cn('collapse')}>
                <Panel
                    showArrow={false}
                    disabled
                    header={<div className="mt-25"><b>{t('calculator quality collapse header energy')}</b></div>}
                    key="energy">

                    <div className="section-table">
                        <Row style={{ marginTop: '10px', marginBottom: '10px' }}>
                            <Col span={6}>
                                <div>
                                    {t('repetitions num selector label')}
                                </div>
                                <Select
                                    defaultValue={qualityRepetitions}
                                    options={[
                                        {
                                            label: 1,
                                            value: 1
                                        },
                                        {
                                            label: 2,
                                            value: 2
                                        },
                                        {
                                            label: 3,
                                            value: 3
                                        },
                                        {
                                            label: 4,
                                            value: 4
                                        },
                                        {
                                            label: 5,
                                            value: 5
                                        },
                                        {
                                            label: 6,
                                            value: 6
                                        }
                                    ]}
                                    style={{ width: '100%' }}
                                    onSelect={setQualityRepetitions} />
                            </Col>
                        </Row>
                        <Row className="section-table__row__header padded-row">
                            <Col span={3}>
                                {t('energy table header 1')}
                            </Col>
                            <Col span={4}>
                                {t('energy table header 2')}
                            </Col>
                            <Col span={4}>
                                {t('energy table header 3')}
                            </Col>
                            <Col span={3}>
                                {t('energy table header 4')}
                            </Col>

                            <Col span={8}>
                                <Row>
                                    <Col span={24}>
                                        {t('energy table header 5')}
                                    </Col>
                                </Row>
                                <Row>
                                    {new Array(qualityRepetitions).fill(0).map((x, idx) => (
                                        <Col key={idx} span={qualityRepetitions === 5 ? 4 : 24 / qualityRepetitions}>
                                            {idx + 1}
                                        </Col>
                                    ))}
                                </Row>
                            </Col>

                            <Col span={2}>
                                {t('energy table header 6')}
                            </Col>
                        </Row>

                        <Row className="section-table__row">
                            <Col span={3}>
                                <Row className="section-table__row padded-row" style={{ height: '50%' }}>
                                    {t('growth energy')}
                                </Row>
                                <Row className="section-table__row padded-row" style={{ height: '50%' }}>
                                    {t('lab germination')}
                                </Row>
                            </Col>

                            <Col align="middle" span={4}>
                                <Row justify="middle" className="bordered-left h-100 padded-row">
                                    <Form.Item
                                        name="stowing_date">
                                        <DatePicker
                                            format="DD.MM.YYYY"
                                            style={{ width: '100%' }} />
                                    </Form.Item>
                                </Row>
                            </Col>

                            <Col span={17}>
                                <Row justify="middle" className="section-table__row bordered-left h-50 padded-row">
                                    <Col span={5}>
                                        <Form.Item
                                            name={[
                                                'germination_power',
                                                'calculation_date'
                                            ]}>
                                            <DatePicker format="DD.MM.YYYY" />
                                        </Form.Item>
                                    </Col>

                                    <Col span={4}>
                                        <Form.Item
                                            rules={[{ pattern: /^[\d]{0,9}$/ }]}
                                            name={[
                                                'germination_power',
                                                'germination_to_calculation_days'
                                            ]}>
                                            <InputNumber decimalSeparator="," />
                                        </Form.Item>
                                    </Col>

                                    <Col span={11}>
                                        <Row>
                                            {new Array(qualityRepetitions).fill(0).map((x, idx) => (
                                                <Col key={idx} span={qualityRepetitions === 5 ? 4 : 24 / qualityRepetitions}>
                                                    <Form.Item
                                                        name={`germination_power/percentage/${idx + 1}`}>
                                                        <InputNumber decimalSeparator="," style={{ width: '80%', transform: 'translateX(10%)' }} />
                                                    </Form.Item>
                                                </Col>
                                            ))}
                                        </Row>
                                    </Col>

                                    <Col span={4}>
                                        {/* {form?.getFieldsValue()?.germination_power?.mean_percentage || '-'} */}
                                        <Form.Item name={['germination_power', 'mean_percentage']}>
                                            <InputNumber
                                                style={{ transform: 'translateX(25%)' }}
                                                decimalSeparator=","
                                                border={false}
                                                disabled />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row justify="middle" className="section-table__row bordered-left h-50 padded-row">
                                    <Col className="h-100" span={5}>
                                        <div className="m-auto">
                                            <Form.Item
                                                name={[
                                                    'lab_germination',
                                                    'calculation_date'
                                                ]}>
                                                <DatePicker format="DD.MM.YYYY" />
                                            </Form.Item>
                                        </div>
                                    </Col>
                                    <Col className="h-100" span={4}>
                                        <div className="m-auto">
                                            <Form.Item
                                                name={[
                                                    'lab_germination',
                                                    'germination_to_calculation_days'
                                                ]}>
                                                <InputNumber
                                                    decimalSeparator=","
                                                    className="m-auto" />
                                            </Form.Item>
                                        </div>
                                    </Col>
                                    <Col span={11}>
                                        <Row>
                                            {new Array(qualityRepetitions).fill(0).map((x, idx) => (
                                                <Col span={qualityRepetitions === 5 ? 4 : 24 / qualityRepetitions}>
                                                    <Form.Item name={`lab_germination/percentage/${idx + 1}`}>
                                                        <InputNumber decimalSeparator="," style={{ width: '80%', transform: 'translateX(10%)' }} />
                                                    </Form.Item>
                                                </Col>
                                            ))}
                                        </Row>
                                    </Col>
                                    <Col span={4}>
                                        <Form.Item name={['lab_germination', 'mean_percentage']}>
                                            <InputNumber
                                                style={{ transform: 'translateX(25%)' }}
                                                decimalSeparator=","
                                                border={false}
                                                disabled />
                                        </Form.Item>
                                        {/* {form?.getFieldsValue()?.lab_germination?.mean_percentage || '-'} */}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '10px' }}>
                            {showRecalculateButtons ? (
                                <>
                                    <Col span={21} />
                                    <Col span={3} align="end">
                                        <a style={{ color: 'grey' }} onClick={() => recalculateValues('germination')}>{t('recalculate values')}</a>
                                    </Col>
                                </>
                            ) : (
                                <>
                                    <Col span={18} />
                                    <Col span={6} align="end">
                                        <div className="notification-text-container">
                                            {t('recalculate warning')}
                                        </div>
                                    </Col>
                                </>
                            )}
                        </Row>
                    </div>

                    <Row className="mt-25">
                        <Col span={24}>
                            <Form.Item
                                label={t('form label comment')}
                                name="germination_comment">
                                <Input.TextArea
                                    maxLength={5000}
                                    placeholder={t('form placeholder enter comment')}
                                    className="w-100"
                                    disabled={allFieldsDisabled} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={24}>
                            <Form.Item
                                label={t('form label study')}
                                name="ent_helm_analysis">
                                <Input.TextArea
                                    maxLength={5000}
                                    placeholder={t('form placeholder enter comment')}
                                    className="w-100" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Panel>

                <Panel
                    showArrow={false}
                    disabled
                    header={<div className="mt-25"><b>{t('calculator quality collapse header mass')}</b></div>}
                    key="mass">
                    <div className="section-table">
                        <Row className="section-table__row__header padded-row">
                            <Col span={6}>
                                {t('mass table header 1')}
                            </Col>
                            <Col span={6}>
                                {t('mass table header 2')}
                            </Col>
                            <Col span={6}>
                                {t('mass table header 3')}
                            </Col>
                            <Col span={6}>
                                {t('mass table header 4')}
                            </Col>
                        </Row>
                        <Row>
                            <Col span={18}>
                                <Row className="section-table__row w-50 padded-row">
                                    <Col span={8}>
                                        1
                                    </Col>
                                    <Col span={8}>
                                        {seedsNumOne}
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item name="weight_500_1">
                                            <InputNumber
                                                decimalSeparator=","
                                                value={seedsMassOne}
                                                onChange={setSeedsMassOne} />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row className="section-table__row w-50 padded-row">
                                    <Col span={8}>
                                        2
                                    </Col>
                                    <Col span={8}>
                                        {seedsNumTwo}
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item name="weight_500_2">
                                            <InputNumber
                                                decimalSeparator=","
                                                value={seedsMassTwo}
                                                onChange={setSeedsMassTwo} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                            <Col align="center" span={6}>
                                <Row align="middle" className="section-table__row h-100 bordered-left">
                                    <Form.Item name="weight_1000">
                                        <Input style={{ width: '50%', color: 'black' }} disabled border={false} />
                                    </Form.Item>
                                </Row>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '10px' }}>
                            {showRecalculateButtons ? (
                                <>
                                    <Col span={21} />
                                    <Col span={3} align="end">
                                        <a style={{ color: 'grey' }} onClick={() => recalculateValues('mass')}>{t('recalculate values')}</a>
                                    </Col>
                                </>
                            ) : (
                                <>
                                    <Col span={14} />
                                    <Col span={10} align="start">
                                        <div className="notification-text-container">
                                            {t('recalculate warning')}
                                        </div>
                                    </Col>
                                </>
                            )}
                        </Row>
                    </div>

                    <Row className="mt-25">
                        <Col span={24}>
                            <Form.Item
                                label={t('form label comment')}
                                name="weight_1000_comment">
                                <Input.TextArea
                                    maxLength={5000}
                                    placeholder={t('form placeholder enter comment')}
                                    className="w-100"
                                    disabled={allFieldsDisabled} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Panel>

                <Panel
                    showArrow={false}
                    disabled
                    header={<div className="mt-25"><b>{t('calculator quality collapse header')}</b></div>}
                    key="norm">
                    <Row className="seed-quality-table">
                        <Col span={11}>
                            <Row align="middle" className="seed-quality-table__row__dark">
                                <Col span={14} className="seed-quality-table__row__text">
                                    {t('seed quality table header agriculture')}
                                </Col>
                                <Col span={2} />
                                <Col span={8} className="seed-quality-table__row__text__bold">
                                    {cultureName}
                                </Col>
                            </Row>
                            <Row align="middle" className="seed-quality-table__row">
                                <Col span={14} className="seed-quality-table__row__text">
                                    {t('seed quality table header variety')}
                                </Col>
                                <Col span={2} />
                                <Col span={8} className="seed-quality-table__row__text__bold">
                                    {sortName}
                                </Col>
                            </Row>
                            <Row align="middle" className="seed-quality-table__row__dark" justify="middle">
                                <Col span={14} className="seed-quality-table__row__text">
                                    {t('seed quality table header purity')}
                                </Col>
                                <Col span={2} />
                                <Col span={8} className="seed-quality-table__row__text__bold">
                                    <Form.Item style={{ height: '100%' }} name="purity">
                                        <InputNumber decimalSeparator="," style={{ color: 'black' }} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row align="middle" className="seed-quality-table__row">
                                <Col span={14} className="seed-quality-table__row__text">
                                    {t('seed quality table header germination')}
                                </Col>
                                <Col span={2} />
                                <Col span={8} className="seed-quality-table__row__text__bold">
                                    <Form.Item style={{ height: 'fit-content' }} name="germination">
                                        <InputNumber formatter={value => toFixed(value, 1)} disabled bordered={false} style={{ color: 'black' }} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row align="middle" className="seed-quality-table__row__dark">
                                <Col span={14} className="seed-quality-table__row__text">
                                    {t('seed quality table header goodness')}
                                </Col>
                                <Col span={2} />
                                <Col span={8} className="seed-quality-table__row__text__bold">
                                    <Form.Item name="sowing_suitability">
                                        <InputNumber formatter={value => toFixed(value, 1)} disabled bordered={false} style={{ color: 'black' }} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>

                        <Col span={2} />

                        <Col span={11}>
                            <Row align="middle" className="seed-quality-table__row__dark">
                                <Col span={14} className="seed-quality-table__row__text">
                                    {t('seed quality table header mass')}
                                </Col>
                                <Col span={2} />
                                <Col align="center" span={8} className="seed-quality-table__row__text__bold">
                                    <Form.Item name="hundred_seeds_weight">
                                        <InputNumber formatter={value => toFixed(value, 1)} bordered={false} style={{ color: 'black' }} />
                                    </Form.Item>
                                    {/* {form?.getFieldValue('hundred_seeds_weight') ? toFixed(form?.getFieldValue('hundred_seeds_weight'), 1) : '-'} */}
                                </Col>
                            </Row>
                            <Row align="middle" className="seed-quality-table__row">
                                <Col span={14} className="seed-quality-table__row__text">
                                    {t('seed quality table header planning')}
                                </Col>
                                <Col span={2} />
                                <Col align="center" span={8} className="seed-quality-table__row__text__bold">
                                    <Form.Item name="germination">
                                        <InputNumber decimalSeparator="," style={{ color: 'black' }} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row align="middle" className="seed-quality-table__row__dark">
                                <Col span={14} className="seed-quality-table__row__text">
                                    {t('seed quality table header seeding norm')}
                                </Col>
                                <Col span={2} />
                                <Col align="center" span={8} className="seed-quality-table__row__text__bold">
                                    <Form.Item name="calc_seeding_rate">
                                        <InputNumber formatter={value => toFixed(value, 1)} disabled bordered={false} style={{ color: 'black' }} />
                                    </Form.Item>
                                    {/* {form?.getFieldValue('calc_seeding_rate') ? toFixed(form?.getFieldValue('calc_seeding_rate'), 1) : '-'} */}
                                </Col>
                            </Row>
                            <Row align="middle" className="seed-quality-table__row">
                                <Col span={14} className="seed-quality-table__row__text">
                                    {t('seed quality table header delta')}
                                </Col>
                                <Col span={2} />
                                <Col align="center" span={8} className="seed-quality-table__row__text__bold">
                                    <Form.Item name="field_germination">
                                        <InputNumber formatter={value => toFixed(value, 1)} disabled bordered={false} style={{ color: 'black' }} />
                                    </Form.Item>
                                    {/* {form?.getFieldValue('field_germination') ? toFixed(form?.getFieldValue('field_germination'), 1) : '-'} */}
                                </Col>
                            </Row>
                            <Row align="middle" className="seed-quality-table__row__dark">
                                <Col span={14} className="seed-quality-table__row__text">
                                    {t('seed quality table header delta norm')}
                                </Col>
                                <Col span={2} />
                                <Col align="center" span={8} className="seed-quality-table__row__text__bold">
                                    <Form.Item name="seeding_rate_germination">
                                        <InputNumber formatter={value => toFixed(value, 1)} disabled bordered={false} style={{ color: 'black' }} />
                                    </Form.Item>
                                    {/* {form?.getFieldValue('seeding_rate_germination') ? toFixed(form?.getFieldValue('seeding_rate_germination'), 1) : '-'} */}
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row className="mt-25">
                        <Col span={24}>
                            <Form.Item
                                label={t('form label comment')}
                                name="seeding_rate_comment">
                                <Input.TextArea
                                    maxLength={5000}
                                    placeholder={t('form placeholder enter comment')}
                                    className="w-100"
                                    disabled={allFieldsDisabled} />
                            </Form.Item>
                        </Col>
                    </Row>


                    {match?.params?.id && (
                        <Row className="upload-file">
                            <Col>
                                <div className="upload-file__header">
                                    {t('upload file header')}
                                </div>
                                <Upload {...uploadProps} className="upload-file__upload">
                                    <Button icon={<UploadOutlined />} type="secondary">
                                        {t('upload file button')}
                                    </Button>
                                </Upload>
                                <div className="upload-file__subtext">
                                    {t('upload file subtext 1')}
                                </div>
                                <div className="upload-file__subtext">
                                    {t('upload file subtext 2')}
                                </div>
                            </Col>
                        </Row>
                    )}

                    {images?.length > 0 && (
                        <Table dataSource={images} columns={imagesColumns} />
                    )}

                    <Row>
                        {allFieldsDisabled ? (
                            <>
                                <Col span={4} />
                                <Col span={5}>
                                    <Button
                                        onClick={() => setAllFieldsDisabled(false)}
                                        type="primary"
                                        className="mt-25">
                                        {t('edit button')}
                                    </Button>
                                </Col>
                                <Col span={6}>
                                    <Button ghost type="primary" className="mt-25">
                                        <DownloadOutlined />
                                        {t('download protocol button')}
                                    </Button>
                                </Col>
                                <Col span={8}>
                                    <Button ghost type="primary" className="mt-25">
                                        <DownloadOutlined />
                                        {t('download general protocol button')}
                                    </Button>
                                </Col>
                            </>
                        ) : (
                            <>
                                <Col span={10} />
                                <Col span={4}>
                                    <Button onClick={handleCalculationFinish} type="primary" className="mt-25">
                                        {t('finish calculation')}
                                    </Button>
                                </Col>
                                <Col span={10} />
                            </>
                        )}
                    </Row>
                </Panel>
            </Collapse>
        </>
    );
};

export default LaborantQualityIndicator;

import React, { Component, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Row, Col } from 'antd';

import '../style.less';

export default ({
    controlsDisabled = false,
    onSave = () => {},
    onCancel = () => {}
}) => {
    const { t } = useTranslation('layer objects');

    return (
        <Row style={{ marginBottom: '25px' }} gutter={24}>
            <Col span={18} />
            <Col span={3}>
                <Button style={{ width: '100%' }} onClick={onCancel} disabled={controlsDisabled}>
                    {t('controls cancel')}
                </Button>
            </Col>
            <Col span={3}>
                <Button style={{ width: '100%' }} onClick={onSave} type="primary" disabled={controlsDisabled}>
                    {t('controls save')}
                </Button>
            </Col>
        </Row>
    );
};
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { withRouter } from 'react-router-dom';
import Icon from '@ant-design/icons';
import {
    Row, Col, Select, InputNumber, Form, Input
} from 'antd';

import cn from '../../../utils/cn';
import {
    Tabs, CatalogTitle
} from '../../../components';

import { object2Form } from '../../../utils/form';
import { selectors, actions } from '../../../models';

import PesticidesTable from './pesticides-table';

const {
    getPesticides,
    getPesticideProductApplication,
    getPesticideApplicationArea,
    getPesticideChemicalClasses,
    getPesticideHarmfulObject,
    getPesticideHazardClass,
    getPesticidePlaceOfProduction,
    getPesticideGroup,
    getPesticideMainDrug,
    getPesticidePreparativeForm,
    getPesticideTreatmentType,
    getCultures
} = actions;
const {
    getPesticideApplicationList,
    getPesticideApplicationCount,
    getPesticideApplicationAreaList,
    getPesticideChemicalClassesList,
    getPesticideHarmfulObjectList,
    getPesticideHazardClassList,
    getPesticidePlaceOfProductionList,
    getPesticideGroupList,
    getPesticideMainDrugList,
    getPesticidePreparativeFormList,
    getPesticideTreatmentTypeList,
    getCulturesCatalog
} = selectors;

const mstp = (state) => ({
    pesticidesList: getPesticideApplicationList(state),
    pesticidesCount: getPesticideApplicationCount(state),
    applicationArea: getPesticideApplicationAreaList(state),
    chemicalClasses: getPesticideChemicalClassesList(state),
    harmfulObjects: getPesticideHarmfulObjectList(state),
    hazardClasses: getPesticideHazardClassList(state),
    placeOfProduction: getPesticidePlaceOfProductionList(state),
    groups: getPesticideGroupList(state),
    cultures: getCulturesCatalog(state),
    mainDrug: getPesticideMainDrugList(state),
    preparativeForms: getPesticidePreparativeFormList(state),
    treatmentTypes: getPesticideTreatmentTypeList(state)
});
const mdtp = ({
    getPesticides,
    getPesticideProductApplication,
    getPesticideApplicationArea,
    getPesticideChemicalClasses,
    getPesticideHarmfulObject,
    getPesticideHazardClass,
    getPesticidePlaceOfProduction,
    getPesticideGroup,
    getPesticideMainDrug,
    getPesticidePreparativeForm,
    getPesticideTreatmentType,
    getCultures
});

const { TabPane } = Tabs;
const { Option } = Select;

const ROW_DIVIDER = 10;
const MAX_RECORDS_IN_PAGINATOR = 40;
const DEFAULT_FERTILIZER = { 1: 'N', 2: 'P', 3: 'K' };

@withRouter
@connect(mstp, mdtp)
@withTranslation('pesticides')
@cn('pesticides')
export default class PesticidesDetail extends Component {
    formRef = React.createRef();

    state = {
        visible: false,
        form: {},
        formInit: () => {},
        formClear: () => {},
        formFieldsClear: () => {},
        priceTo: undefined,
        priceFrom: undefined,
        page_size: 10,
        page: 1,
        showFilter: true,
        pesticidesListState: [],
        setFilter: false,
        showInfo: false,
        showPesticide: undefined
    };

    componentDidMount() {
        const {
            getPesticideApplicationArea,
            getPesticideChemicalClasses,
            getPesticideHarmfulObject,
            getPesticideHazardClass,
            getPesticidePlaceOfProduction,
            getPesticideGroup,
            getPesticideMainDrug,
            getPesticidePreparativeForm,
            getPesticideTreatmentType,
            getCultures
        } = this.props;
        getPesticideApplicationArea('');
        getPesticideChemicalClasses('');
        getPesticideHarmfulObject('');
        getPesticideHazardClass('');
        getPesticidePlaceOfProduction('');
        getPesticideGroup('');
        getPesticideMainDrug('');
        getPesticidePreparativeForm('');
        getPesticideTreatmentType('');
        getCultures('?page_size=1000');
        this.getFilteredPesticide();
    }

    getFilteredPesticide = () => {
        const { getPesticideProductApplication } = this.props;
        const {
            page_size,
            page,
            group,
            hazard_class,
            name,
            main_drug,
            culture,
            preparativeForm,
            treatment_type,
            harmful_object,
            chemical_class,
            price_from,
            price_to
        } = this.state;
        const urlArr = [];
        urlArr.push('"plant_protection_product.is_in_state_registry":true');
        name ? urlArr.push(`"plant_protection_product.name.icontains":"${name}"`) : '';
        price_from ? urlArr.push(`"plant_protection_product.related_sellers.price.gte":${price_from}`) : '';
        price_to ? urlArr.push(`"plant_protection_product.related_sellers.price.lte":"${price_to}"`) : '';
        main_drug ? urlArr.push(`"plant_protection_product.related_main_drugs.main_drug.name.icontains":"${main_drug}"`) : '';
        preparativeForm ? urlArr.push(`"plant_protection_product.preparative_form.id":"${preparativeForm}"`) : '';
        treatment_type ? urlArr.push(`"treatment_type.id":${treatment_type}`) : '';
        culture ? urlArr.push(`"related_cultures.culture.id":${culture}`) : '';
        harmful_object ? urlArr.push(`"related_harmful_objects.harmful_object.name.icontains":"${harmful_object}"`) : '';
        group ? urlArr.push(`"plant_protection_product.group.id":${group}`) : '';
        hazard_class ? urlArr.push(`"plant_protection_product.hazard_class.id":${hazard_class}`) : '';
        chemical_class ? urlArr.push(`"plant_protection_product.related_chemical_classes.chemical_class.id":${chemical_class}`) : '';
        const page_size_url = `page_size=${page_size}`;
        const page_url = `page=${page}`;
        getPesticideProductApplication(`?${page_size_url}&${page_url}&filter={${urlArr.join(',')}}`);
    };


    onChange = (value, values) => {
        this.setState(value, () => {
            this.setState({ setFilter: true, page: 1 }, () => {
                this.getFilteredPesticide();
            });
        });
    };

    onMount = (form, { formInit, formFieldsClear, formClear }) => {
        this.setState({
            form, formInit, formFieldsClear, formClear
        });
    };

    hideInfo = () => {
        this.setState({ showInfo: false });
    };

    onCurrentPageChange = (page) => {
        this.setState({
            page
        }, () => {
            this.getFilteredPesticide();
        });
    };

    onPageSizeChange = (page_size) => {
        this.setState({
            page_size, page: 1
        }, () => this.getFilteredPesticide());
    };

    get filterItems() {
        const { pesticidesCount: dataSize } = this.props;
        const items = [];
        for (let i = 1; i < dataSize; i += 1) {
            if (i % ROW_DIVIDER === 0) {
                items.push({ value: `${i}`, title: `${i}` });
            }

            if (i > MAX_RECORDS_IN_PAGINATOR) break;
        }
        return items;
    }

    goBack = () => {
        const { history } = this.props;
        history.push('/catalog-info');
    };


    showFilter = () => {
        this.setState({ showFilter: true });
    };

    showInfo = (pesticide) => {
        this.setState({ showInfo: true, showPesticide: pesticide });
    };

    filterOption = (inputName, option) => {
        const optionName = option.props.children.toLowerCase();

        inputName = inputName.toLowerCase();

        return optionName.indexOf(inputName) !== -1;
    };

    render() {
        const { cn } = this;
        const {
            t,
            groups,
            hazardClasses,
            name,
            mainDrug,
            cultures,
            preparativeForms,
            treatmentTypes,
            harmfulObjects,
            chemicalClasses,
            pesticidesList,
            pesticidesCount = 1,
            isCompleted
        } = this.props;
        const {
            showFilter,
            page_size,
            page
        } = this.state;

        return (
            <div>
                <Form
                    ref={this.formRef}
                    onValuesChange={this.onChange}
                    name="pesticide">
                    <Row gutter={20}>
                        <Col span={12}>
                            <CatalogTitle title={t('pesticides')} />
                        </Col>
                        <Col span={12} className={cn('field')}>
                            <Form.Item name="name">
                                <Input
                                    disabled={isCompleted}
                                    placeholder={t('search')}
                                    suffix={<Icon type="search" />} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: '30px' }}>
                        <Col>
                            <span className={cn('back')} onClick={this.goBack}>
                                {t('back to catalog')}
                            </span>
                        </Col>
                    </Row>
                    <Row gutter={20} style={{ marginBottom: '20px' }}>
                        <Col span={12} className="field">
                            <Form.Item name="name" label={t('search')}>
                                <Input
                                    disabled={isCompleted}
                                    placeholder={t('search name')}
                                    suffix={<Icon type="search" />} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Row gutter={20}>
                                <Col span={6} className="field">
                                    <Form.Item name="price_from" label={t('price from')}>
                                        <InputNumber
                                            min={0}
                                            max={1000000}
                                            disabled={isCompleted}
                                            placeholder={t('price from placeholder')} />
                                    </Form.Item>
                                </Col>
                                <Col span={6} className="field">
                                    <Form.Item name="price_to" label={t('price to')}>
                                        <InputNumber
                                            min={0}
                                            max={1000000}
                                            disabled={isCompleted}
                                            placeholder={t('price to placeholder')} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row gutter={20} style={{ marginBottom: '20px' }}>
                        <Col span={6} className="field">
                            <Form.Item label={t('group pesticides')}
                                name="group">
                                <Select
                                    allowClear
                                    showSearch
                                    filterOption={this.filterOption}>
                                    {groups.map(item => (
                                        <Select.Option value={item.id} key={item.id}>
                                            {item.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6} className="field">
                            <Form.Item label={t('treatment type pesticide')}
                                name="treatment_type">
                                <Select
                                    allowClear
                                    showSearch
                                    filterOption={this.filterOption}>
                                    {treatmentTypes.map(item => (
                                        <Select.Option value={item.id} key={item.id}>
                                            {item.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6} className="field">
                            <Form.Item label={t('hazard class pesticide')}
                                name="hazard_class">
                                <Select
                                    allowClear
                                    showSearch
                                    filterOption={this.filterOption}>
                                    {hazardClasses.map(item => (
                                        <Select.Option value={item.id} key={item.id}>
                                            {item.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6} className="field">
                            <Form.Item label={t('preparative form pesticide')}
                                name="preparativeForm">
                                <Select
                                    allowClear
                                    showSearch
                                    filterOption={this.filterOption}>
                                    {preparativeForms.map(item => (
                                        <Select.Option value={item.id} key={item.id}>
                                            {item.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20} style={{ marginBottom: '20px' }}>
                        <Col span={6} className="field">
                            <Form.Item label={t('culture pesticide')}
                                name="culture">
                                <Select
                                    allowClear
                                    showSearch
                                    filterOption={this.filterOption}>
                                    {cultures.map(item => (
                                        <Select.Option value={item.id} key={item.id}>
                                            {item.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6} className="field">
                            <Form.Item label={t('chemical class pesticide')}
                                name="chemical_class">
                                <Select
                                    allowClear
                                    showSearch
                                    filterOption={this.filterOption}>
                                    {chemicalClasses.map(item => (
                                        <Select.Option value={item.id} key={item.id}>
                                            {item.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={6} className="field">
                            <Form.Item label={t('main drug pesticide')}
                                name="main_drug">
                                <Input placeholder={t('search main drug')}
                                    suffix={<Icon type="search" />} />
                            </Form.Item>
                        </Col>
                        <Col span={6} className="field">
                            <Form.Item label={t('harmful object pesticide')}
                                name="harmful_object">
                                <Input placeholder={t('search harmful object')}
                                    suffix={<Icon type="search" />} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <Row style={{ marginBottom: '20px' }}>
                    <Col span={24}>
                        <PesticidesTable
                            filterItems={this.filterItems}
                            dataSource={pesticidesList}
                            dataSize={pesticidesCount}
                            pageSize={page_size}
                            pageNum={page}
                            resetDataSource={showFilter}
                            showInfo={this.showInfo}
                            onPageSizeChange={this.onPageSizeChange}
                            onCurrentPageChange={this.onCurrentPageChange} />
                    </Col>
                </Row>
            </div>

        );
    }
}
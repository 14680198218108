import * as constants from '../constants';

const initialState = [];

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case constants.getProtocolList.success: {
            return action.payload;
        }


        case constants.getProtocolList.try:
        case constants.getProtocolList.error: {
            return [];
        }

        default:
            return state;
    }
}
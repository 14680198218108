/* eslint-env browser */
import 'core-js/stable';

import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
// import { Router } from 'react-router';
import { ConfigProvider } from 'antd';
import ruRU from 'antd/lib/locale-provider/ru_RU';
import { I18nextProvider } from 'react-i18next';
import { createBrowserHistory } from 'history';
import AuthLayout from './containers/auth-layout';

import { ReactKeycloakProvider } from '@react-keycloak/web';
import
    keycloak,
    {
        initOptions,
        keycloakEventHandler,
        keycloakTokenHandler
    }
from './utils/keycloak';

import i18n from './i18n';
import store from './store';
import routes from './routes';

import './style/style.less';

export const history = createBrowserHistory();

render(
    <ReactKeycloakProvider
        authClient={keycloak} keycloak={keycloak}
        initOptions={initOptions}
        onTokens={keycloakTokenHandler}
        onEvent={keycloakEventHandler}>
        <Provider store={store}>
            <ConfigProvider locale={ruRU}>
                <I18nextProvider i18n={i18n}>
                    <Router history={history}>
                        <AuthLayout>
                            { routes }
                        </AuthLayout>
                    </Router>
                </I18nextProvider>
            </ConfigProvider>
        </Provider>
    </ReactKeycloakProvider>,
    document.getElementById('root'),
);
import React, { Component } from 'react';
import { Layout } from 'antd';
import Icon from '@ant-design/icons';
import cn from '../../utils/cn';

import './style.less';
import ResetPassword from './components/component';
import { PanelLayout, Title } from '../../components';
import Logo from '../../../public/images/logo_main.svg';

const { Content } = Layout;
const { PanelHeader, PanelContent } = PanelLayout;

@cn('reset-page')
export default class Home extends Component {
    render() {
        const { cn } = this;
        return (
            <Layout key="page-reset-password" className={cn()}>
                <Content className={cn('content')}>
                    <Icon component={Logo} className={cn('logo')} />
                    <ResetPassword />
                </Content>
            </Layout>
        );
    }
}

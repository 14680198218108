import { createSelector } from 'reselect';

import options from './options';

const { name } = options;

export const newCalculatorPhyto = state => state.models[name].calculatorCreated;
export const curCalculatorsPhyto = state => state.models[name].calculatorsList.results;
export const curCalculatorPhyto = state => state.models[name].calculator;
export const isPhytoLoading = state => state.models[name].loading;
export const isCalculatorCreated = state => state.models[name].calculatorCreated;
export const currentReproductionsPhyto = state => state.models[name].reproductions;
export const currentVarietiesPhyto = state => state.models[name].varieties;
export const currentCulturesPhyto = state => state.models[name].cultures;
export const currentFertilizersPhyto = state => state.models[name].fertilizers;
export const deletionResult = state => state.models[name].deletedItem;
export const currentRegionsPhyto = state => state.models[name].regions;
export const currentPurposesPhyto = state => state.models[name].purposes;
export const currentCausativeAgentsPhyto = state => state.models[name].causativeAgents;
export const currentCausativeAgentItemsPhyto = state => state.models[name].causativeAgentItems;
export const currentContaminationCreatedPhyto = state => state.models[name].contaminationCreated;
export const currentProtocolsList = state => state.models[name].protocolList;
export const currentProtocolPhyto = state => state.models[name].protocol;
export const currentUsersPhyto = state => state.models[name].userList;
export const newlyCreatedProtocolPhyto = state => state.models[name].protocolCreated;
export const updatedProtocolPhyto = state => state.models[name].protocolUpdated;
export const uploadedFilePhyto = state => state.models[name].uploadedFile;
export const updatedPhotoPhyto = state => state.models[name].updatedPhoto;
export const sympPhyto = state => state.models[name].symptoms?.results;
export const subPhyto = state => state.models[name].subgroups?.results;
export const vegPhyto = state => state.models[name].vegPhases?.results;
export const drugsPhyto = state => state.models[name].drugs;
export const curDiseasesPhyto = state => state.models[name].diseases;


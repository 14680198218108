import React, { useEffect, useState, useRef } from 'react';
import {
    Col, Collapse, Form, InputNumber, Row
} from 'antd';

import { useTranslation } from 'react-i18next';

import { useClassName } from 'utils/cn';

import '../style.less';
import Icon from '@ant-design/icons';
import ListIcon from '../../../../public/images/calculator/list2_stroke.svg';
import CalculatorData from './data';
import CalculatorIcon from '../../../../public/images/calculator/calculator2_stroke.svg';
import CalculatorResults from './results';
import CalculatorFertilizers from './fertilizer';
import { Button } from '../../../components';

const { Panel } = Collapse;

const CalculatorParameters = ({ isCompleted, updateInternalCalc }) => {
    const cn = useClassName('calculator-detail-fed-params');
    const { t } = useTranslation('calculator params fed');

    return (
        <div>
            <div>
                <div className={cn('subtitle')}>{t('subtitle')}</div>
                <Row className={`${cn('title-line')} ${cn('border')}`}>
                    <Col span={4} />
                    <Col span={2} className={cn('gray-col')}>
                        {t('K2')}
                    </Col>
                    <Col span={2}>
                        {t('N')}
                    </Col>
                    <Col span={2}>
                        {t('P')}
                    </Col>
                    <Col span={2}>
                        {t('K')}
                    </Col>
                    <Col span={2}>
                        {t('S')}
                    </Col>
                    <Col span={2} className={cn('gray-col')}>
                        {t('K3')}
                    </Col>
                    <Col span={2}>
                        {t('Ca')}
                    </Col>
                    <Col span={2}>
                        {t('Mg')}
                    </Col>
                    <Col span={2}>
                        {t('B')}
                    </Col>
                    <Col span={2}>
                        {t('Cu')}
                    </Col>
                </Row>
                <Row className={cn('border')}>
                    <Col span={4} className={cn('title-col')}>
                        {t('results before')}
                    </Col>
                    <Col span={2} className={cn('gray-col')}>
                        <Form.Item name="c_k2">
                            <InputNumber
                                disabled={isCompleted}
                                decimalSeparator=","
                                placeholder="" />
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item name="c_n">
                            <InputNumber
                                disabled={isCompleted}
                                decimalSeparator=","
                                placeholder="" />
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item name="c_p">
                            <InputNumber
                                disabled={isCompleted}
                                decimalSeparator=","
                                placeholder="" />
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item name="c_ks">
                            <InputNumber
                                disabled={isCompleted}
                                decimalSeparator=","
                                validator="any"
                                placeholder="" />
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item name="c_kcl">
                            <InputNumber
                                disabled={isCompleted}
                                decimalSeparator=","
                                placeholder="" />
                        </Form.Item>
                    </Col>
                    <Col span={2} className={cn('gray-col')}>
                        <Form.Item name="c_k3">
                            <InputNumber
                                disabled={isCompleted}
                                decimalSeparator=","
                                placeholder="" />
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item name="c_ca">
                            <InputNumber
                                disabled={isCompleted}
                                decimalSeparator=","
                                placeholder="" />
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item name="c_mg">
                            <InputNumber
                                disabled={isCompleted}
                                decimalSeparator=","
                                placeholder="" />
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item name="c_b">
                            <InputNumber
                                disabled={isCompleted}
                                decimalSeparator=","
                                placeholder="" />
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item name="c_cu">
                            <InputNumber
                                disabled={isCompleted}
                                decimalSeparator=","
                                placeholder="" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row className={cn('border')}>
                    <Col span={4} className={cn('title-col')}>
                        {t('results after')}
                    </Col>
                    <Col span={2} className={cn('gray-col')}>
                        <Form.Item name="d_k2">
                            <InputNumber
                                disabled={isCompleted}
                                decimalSeparator=","
                                placeholder="" />
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item name="d_n">
                            <InputNumber
                                disabled={isCompleted}
                                decimalSeparator=","
                                placeholder="" />
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item name="d_p">
                            <InputNumber
                                disabled={isCompleted}
                                decimalSeparator=","
                                validator="any"
                                placeholder="" />
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item name="d_ks">
                            <InputNumber
                                disabled={isCompleted}
                                decimalSeparator=","
                                placeholder="" />
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item name="d_kcl">
                            <InputNumber
                                disabled={isCompleted}
                                decimalSeparator=","
                                placeholder="" />
                        </Form.Item>
                    </Col>
                    <Col span={2} className={cn('gray-col')}>
                        <Form.Item name="d_k3">
                            <InputNumber
                                disabled={isCompleted}
                                decimalSeparator=","
                                placeholder="" />
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item name="d_ca">
                            <InputNumber
                                disabled={isCompleted}
                                decimalSeparator=","
                                placeholder="" />
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item name="d_mg">
                            <InputNumber
                                disabled={isCompleted}
                                decimalSeparator=","
                                placeholder="" />
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item name="d_b">
                            <InputNumber
                                disabled={isCompleted}
                                decimalSeparator=","
                                placeholder="" />
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item name="d_cu">
                            <InputNumber
                                disabled={isCompleted}
                                decimalSeparator=","
                                placeholder="" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row className={`${cn('title-line')} ${cn('border')}`}>
                    <Col span={4} />
                    <Col span={2} className={cn('gray-col')}>
                        {t('K4')}
                    </Col>
                    <Col span={2}>
                        {t('Zn')}
                    </Col>
                    <Col span={2}>
                        {t('Mn')}
                    </Col>
                    <Col span={2}>
                        {t('Fe')}
                    </Col>
                    <Col span={2} className={cn('gray-col')}>
                        {t('K5')}
                    </Col>
                    <Col span={2}>
                        {t('Mo')}
                    </Col>
                    <Col span={2}>
                        {t('Co')}
                    </Col>
                    <Col span={2}>
                        {t('I')}
                    </Col>
                    <Col span={2} className={cn('gray-col')}>
                        {t('K6')}
                    </Col>
                    <Col span={2} />
                </Row>
                <Row className={cn('border')}>
                    <Col span={4} className={cn('title-col')}>
                        {t('results before')}
                    </Col>
                    <Col span={2} className={cn('gray-col')}>
                        <Form.Item name="c_k4">
                            <InputNumber
                                disabled={isCompleted}
                                decimalSeparator=","
                                placeholder="" />
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item name="c_zn">
                            <InputNumber
                                disabled={isCompleted}
                                decimalSeparator=","
                                placeholder="" />
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item name="c_mn">
                            <InputNumber
                                disabled={isCompleted}
                                decimalSeparator=","
                                placeholder="" />
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item name="c_fe">
                            <InputNumber
                                disabled={isCompleted}
                                decimalSeparator=","
                                placeholder="" />
                        </Form.Item>
                    </Col>
                    <Col span={2} className={cn('gray-col')}>
                        <Form.Item name="c_k5">
                            <InputNumber
                                disabled={isCompleted}
                                decimalSeparator=","
                                placeholder="" />
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item name="c_mo">
                            <InputNumber
                                disabled={isCompleted}
                                decimalSeparator=","
                                placeholder="" />
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item name="c_co">
                            <InputNumber
                                disabled={isCompleted}
                                decimalSeparator=","
                                placeholder="" />
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item name="c_i">
                            <InputNumber
                                disabled={isCompleted}
                                decimalSeparator=","
                                placeholder="" />
                        </Form.Item>
                    </Col>
                    <Col span={2} className={cn('gray-col')}>
                        <Form.Item name="c_k6">
                            <InputNumber
                                disabled={isCompleted}
                                decimalSeparator=","
                                placeholder="" />
                        </Form.Item>
                    </Col>
                    <Col span={2} />
                </Row>
                <Row className={cn('border')}>
                    <Col span={4} className={cn('title-col')}>
                        {t('results after')}
                    </Col>
                    <Col span={2} className={cn('gray-col')}>
                        <Form.Item name="d_k4">
                            <InputNumber
                                disabled={isCompleted}
                                decimalSeparator=","
                                placeholder="" />
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item name="d_zn">
                            <InputNumber
                                disabled={isCompleted}
                                decimalSeparator=","
                                placeholder="" />
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item name="d_mn">
                            <InputNumber
                                disabled={isCompleted}
                                decimalSeparator=","
                                placeholder="" />
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item name="d_fe">
                            <InputNumber
                                disabled={isCompleted}
                                decimalSeparator=","
                                placeholder="" />
                        </Form.Item>
                    </Col>
                    <Col span={2} className={cn('gray-col')}>
                        <Form.Item name="d_k5">
                            <InputNumber
                                disabled={isCompleted}
                                decimalSeparator=","
                                placeholder="" />
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item name="d_mo">
                            <InputNumber
                                disabled={isCompleted}
                                decimalSeparator=","
                                placeholder="" />
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item name="d_co">
                            <InputNumber
                                disabled={isCompleted}
                                decimalSeparator=","
                                placeholder="" />
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item name="d_i">
                            <InputNumber
                                disabled={isCompleted}
                                decimalSeparator=","
                                placeholder="" />
                        </Form.Item>
                    </Col>
                    <Col span={2} className={cn('gray-col')}>
                        <Form.Item name="d_k6">
                            <InputNumber
                                disabled={isCompleted}
                                decimalSeparator=","
                                placeholder="" />
                        </Form.Item>
                    </Col>
                    <Col span={2} />
                </Row>

            </div>
            <Row className={cn('button')}>
                <Col>
                    <Button
                        type="primary"
                        disabled={isCompleted}
                        onClick={updateInternalCalc}
                        size="large">
                        {t('calculate')}
                    </Button>
                </Col>
            </Row>
        </div>
    );
};

export default CalculatorParameters;
import React, { Component, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Space, Spin, Popover, Checkbox, Row, Col, Button } from 'antd';
import '../style.less';
import { DownOutlined } from '@ant-design/icons';

export default ({
    columnsList = [], activeColumns = [], setActiveColumns = () => {}, defaultColumns = [], importantColumns = []
}) => {
    const { t } = useTranslation('column select');

    const onChange = (newValues) => {
        setActiveColumns(newValues);
    };

    const popoverContent = (
        <Col>
            <Checkbox.Group
                value={activeColumns}
                defaultValue={defaultColumns}
                onChange={onChange}>
                {columnsList?.map(colOpt => (
                    <Row>
                        <Checkbox style={{ marginLeft: '25px' }} disabled={ importantColumns?.includes(colOpt?.key) } value={colOpt?.key} key={colOpt?.key}>{colOpt?.title}</Checkbox>
                    </Row>
                ))}
            </Checkbox.Group>
            <Button onClick={() => setActiveColumns(defaultColumns)} style={{ width: '100%', marginBottom: '15px', marginTop: '15px' }}>{t('by default')}</Button>
        </Col>
    );

    const popoverTitle = (
        <Row>
            <Checkbox checked={activeColumns?.length === columnsList?.length} onChange={val => val && setActiveColumns(columnsList?.map(x => x?.key))}>{t('check all')}</Checkbox>
        </Row>
    );

    return (
        <Popover placement="bottom" title={popoverTitle} content={popoverContent} trigger="click">
            <Space style={{ cursor: 'pointer' }} direction="horizontal">
                <div>{t('show columns')}</div>
                <Space direction="horizontal">
                    <div style={{ borderRadius: '25px', backgroundColor: 'rgba(240, 240, 240, 1)', padding: '5px 10px' }}>
                        {activeColumns?.length}/{columnsList?.length}
                    </div>
                    <DownOutlined />
                </Space>
            </Space>
        </Popover>
    );
};
import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
    Col, Row, Layout, Space, DatePicker, Input, Slider, Dropdown, Popover
} from 'antd';
import {
    CopyOutlined,
    DeleteOutlined,
    DownloadOutlined,
    PlusCircleOutlined,
    SearchOutlined
} from '@ant-design/icons';

import { useTranslation } from 'react-i18next';

import { useClassName } from 'utils/cn';

import moment from 'moment';
import {
    Button,
    PanelLayout, Table, Title
} from '../../components';
import './style.less';
import { actions, selectors } from '../../models';
import {
    addMycologicalRegistry, publishMycologicalRegistry,
    updateMycologicalRegistry
} from '../../models/mycological-registry/actions';
import { getUsersList } from '../../models/sample-log/actions';
import { deleteMycologicalRegistry } from '../../models/mycological-registry/api';
import { usrListResponse } from '../../models/sample-log/selectors';

const { PanelFooter, PanelHeader, PanelContent } = PanelLayout;

const {
    getMycologicalRegistries,
    getCultures
} = actions;

const { curMycoRegistries, curMycoRegistriesCount, getCulturesCatalog } = selectors;

const { RangePicker } = DatePicker;

const Registries = () => {
    const cn = useClassName('calculators');
    const match = useRouteMatch();
    const { params: { type, id } } = match;
    const users = useSelector(state => usrListResponse(state));
    const registries = useSelector(state => curMycoRegistries(state));
    const dataSize = useSelector(state => curMycoRegistriesCount(state));
    const cultures = useSelector(state => getCulturesCatalog(state));
    const dispatch = useDispatch();
    const { t } = useTranslation('mycological registry');
    const history = useHistory();

    const tableRef = useRef(null);

    const [usersSearchString, setUsersSearchString] = useState('');
    const [fullUsersList, setFullUsersList] = useState([]);
    const [usersPage, setUsersPage] = useState(1);

    useEffect(() => {
        if (usersSearchString?.length > 0 && usersPage !== 1) {
            dispatch(getUsersList({
                page: 1,
                searchString: ''
            }));
        } else {
            dispatch(getUsersList({
                page: usersPage,
                searchString: ''
            }));
        }
    }, [usersPage, usersSearchString]);

    useEffect(() => {
        const listPortion = users?.results;
        if (listPortion?.length > 0) {
            setFullUsersList([...fullUsersList, ...listPortion]);
        }
        const nextPage = users?.next;
        if (nextPage?.length > 0) {
            setUsersPage(usersPage + 1);
        }
    }, [users]);

    const [searchRange, setSearchRange] = useState([]);
    const [selectedEntries, setSelectedEntries] = useState([]);
    const [selectedObjEntity, setSelectedObjEntity] = useState([]);
    const [filteredInfo, setFilteredInfo] = useState({});
    const [sortedInfo, setSortedInfo] = useState({});

    const rowSelection = {
        selectedRowKeys: selectedEntries,
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedObjEntity(selectedRows);
            setSelectedEntries(selectedRows.map(selRow => selRow.id));
        }
    };

    useEffect(() => {
        dispatch(getMycologicalRegistries());
        dispatch(getCultures());
    }, []);

    const handleFiltersChange = (pagination, filters, sorter) => {
        setFilteredInfo(filters);
        setSortedInfo(sorter);
    };

    const clearAll = () => {
        setFilteredInfo({});
        setSortedInfo({});
    };

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        if (dataIndex === 'creation_date' || dataIndex === 'test_date' || dataIndex === 'id') {
            setSearchRange(selectedKeys);
        }
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchRange([]);
        clearAll();
    };

    const getColumnSearchProps = (dataIndex, sliderBorders = [0, 100]) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters
        }) => (
            <div style={{ padding: 8 }}>
                <div>
                    {(
                        dataIndex === 'owner_user' || dataIndex === 'num' || dataIndex === 'field_num' || dataIndex === 'culture' || dataIndex === 'owner'
                    ) ? (
                            <Input onChange={(evt) => {
                                setSelectedKeys([evt?.target?.value]);
                            }} />
                        ) : dataIndex === 'id' ? (
                            <Slider
                                max={sliderBorders[1]}
                                min={sliderBorders[0]}
                                range
                                defaultValue={sliderBorders}
                                onChange={(vals) => {
                                    setSearchRange(vals);
                                    setSelectedKeys(vals);
                                }} />
                        ) : (
                            <RangePicker
                                onChange={(e) => {
                                    setSearchRange(e);
                                    setSelectedKeys(e);
                                }} />
                        )}
                </div>
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}>
                        {t('search')}
                    </Button>
                    <Button
                        onClick={() => handleReset(clearFilters)}
                        size="small"
                        style={{ width: 90 }}>
                        {t('reset')}
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined style={{ color: filtered ? '#03B575' : undefined }} />
        ),
        onFilter: (value, record) => {
            if (dataIndex === 'creation_date' || dataIndex === 'test_date') {
                return moment(record[dataIndex]) >= moment(searchRange[0]) && moment(record[dataIndex]) <= moment(searchRange[1]);
            }

            if (dataIndex === 'id') {
                return parseInt(record[dataIndex], 10) >= parseInt(searchRange[0], 10) && parseInt(record[dataIndex], 10) <= parseInt(searchRange[1], 10);
            }

            if (dataIndex === 'owner') {
                const userName = fullUsersList?.filter(usr => parseInt(usr.id, 10) === parseInt(record.owner, 10))[0].name;
                return userName?.toLowerCase().includes(value?.toLowerCase());
            }

            return (
                record[dataIndex]
                    ? record[dataIndex]
                        .toString()
                        .toLowerCase()
                        .includes(value.toLowerCase())
                    : ''

            );
        }
    });

    const requestsColumns = [
        {
            title: t('id'),
            dataIndex: 'id',
            key: 'id',
            filteredValue: filteredInfo.id || null,
            sortOrder: sortedInfo.columnKey === 'id' ? sortedInfo.order : null,
            sorter: (a, b) => a.id - b.id,
            sortDirections: ['ascend', 'descend'],
            ...getColumnSearchProps('id', [Math.min(...registries?.map(reg => parseInt(reg.id, 10))), Math.max(...registries?.map(reg => parseInt(reg.id, 10)))])
        },
        {
            title: t('test date'),
            dataIndex: 'creation_date',
            key: 'creation_date',
            filteredValue: filteredInfo.creation_date || null,
            sortOrder: sortedInfo.columnKey === 'creation_date' ? sortedInfo.order : null,
            sorter: (a, b) => moment(a.creation_date).unix() - moment(b.creation_date).unix(),
            sortDirections: ['descend', 'ascend'],
            render: (text) => moment(text).format('DD.MM.YYYY'),
            ...getColumnSearchProps('creation_date')
        },
        {
            title: t('owner'),
            dataIndex: 'owner',
            key: 'owner',
            filteredValue: filteredInfo.owner || null,
            sortOrder: sortedInfo.columnKey === 'owner' ? sortedInfo.order : null,
            sorter: (a, b) => a.owner.localeCompare(b.owner),
            render: (val) => {
                return fullUsersList?.length > 0 ? fullUsersList.filter(usr => usr.id === parseInt(val, 10))[0]?.name : val
            },
            ...getColumnSearchProps('owner')
        },
        // {
        //     title: t('owner user'),
        //     dataIndex: 'owner_user',
        //     key: 'owner_user',
        //     ...getColumnSearchProps('owner_user')
        // },
        {
            title: t('number'),
            dataIndex: 'num',
            key: 'num',
            filteredValue: filteredInfo.num || null,
            sortOrder: sortedInfo.columnKey === 'num' ? sortedInfo.order : null,
            sorter: (a, b) => a.num.localeCompare(b.num),
            sortDirections: ['ascend', 'descend'],
            ...getColumnSearchProps('num')
        },
        {
            title: t('date of test'),
            dataIndex: 'test_date',
            key: 'test_date',
            filteredValue: filteredInfo.test_date || null,
            sortOrder: sortedInfo.columnKey === 'test_date' ? sortedInfo.order : null,
            sorter: (a, b) => moment(a.test_date).unix() - moment(b.test_date).unix(),
            sortDirections: ['descend', 'ascend'],
            render: (text) => moment(text).format('DD.MM.YYYY'),
            ...getColumnSearchProps('test_date')
        },
        {
            title: t('field num'),
            dataIndex: 'field_num',
            key: 'field_num',
            filteredValue: filteredInfo.field_num || null,
            sortOrder: sortedInfo.columnKey === 'field_num' ? sortedInfo.order : null,
            sorter: (a, b) => a.field_num.localeCompare(b.field_num),
            sortDirections: ['descend', 'ascend'],
            ...getColumnSearchProps('field_num')
        },
        {
            title: t('culture'),
            dataIndex: 'culture',
            key: 'culture',
            filteredValue: filteredInfo.culture || null,
            sortOrder: sortedInfo.columnKey === 'culture' ? sortedInfo.order : null,
            sorter: (a, b) => cultures.find(item => item.id === a)?.name.localeCompare(cultures.find(item => item.id === b)?.name),
            sortDirections: ['descend', 'ascend'],
            ...getColumnSearchProps('culture'),
            render: (val) => cultures.find(item => item.id === val)?.name
        },
        {
            title: t('is published'),
            dataIndex: 'public',
            key: 'public',
            filters: [
                {
                    text: t('archived'),
                    value: 'archived'
                },
                {
                    text: t('published'),
                    value: 'published'
                },
                {
                    text: t('edition'),
                    value: 'edition'
                }
            ],
            filteredValue: filteredInfo.public || null,
            sortOrder: sortedInfo.columnKey === 'public' ? sortedInfo.order : null,
            onFilter: (value, record) => {
                if (value === 'archived') {
                    return record?.deleted;
                }
                if (value === 'published') {
                    return !record?.deleted && record?.public;
                }
                if (value === 'edition') {
                    return !record?.deleted && !record?.public;
                }
            },
            render: (val, record) => record?.deleted ? (
                <div className="publication-status__archived">
                    {t('archived')}
                </div>
            ) : val ? (
                <div className="publication-status__published">
                    {t('published')}
                </div>
            ) : (
                <div className="publication-status__edition">
                    {t('edition')}
                </div>
            )
        }
    ];

    const handleDownloadWord = () => {
        selectedEntries?.forEach(selEn => {
            fetch(`/api/v1/mycological_calculator/mycological_calculator_reestr/${selEn}/download_protocol/`, {
                headers: {
                    Authorization: `JWT ${localStorage.getItem('token')}`
                }
            }).then((resp) => {
                resp.blob().then((blb) => {
                    const url = window.URL.createObjectURL(blb);
                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = url;
                    a.download = `mycological-registry[id-${selEn}]`;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    window.URL.revokeObjectURL(url);
                });
            });
        });
    };

    const handleStatusUpdate = () => {
        selectedObjEntity.forEach(selectedRow => {
            const rowId = selectedRow?.id;
            dispatch(updateMycologicalRegistry({ values: { id: selectedRow?.id, public: selectedRow?.public ? !selectedRow.public : true }, id: rowId }));
        });
    };

    const makeRowCopy = (prototypeObject) => {
        const clonedObject = {
            ...prototypeObject
        };
        clonedObject.pictures1 = clonedObject?.pictures1?.map(pic => pic?.id);
        clonedObject.pictures2 = clonedObject?.pictures2?.map(pic => pic?.id);
        clonedObject.pictures3 = clonedObject?.pictures3?.map(pic => pic?.id);
        clonedObject.alternaria = { value: clonedObject.alternaria?.value };
        clonedObject.bipolaris = { value: clonedObject.bipolaris?.value };
        clonedObject.blumeria_graminis = { value: clonedObject.blumeria_graminis?.value };
        clonedObject.cladosporium = { value: clonedObject.cladosporium?.value };
        clonedObject.fusarium = { value: clonedObject.fusarium?.value };
        clonedObject.murogenella = { value: clonedObject.murogenella?.value };
        clonedObject.phoma = { value: clonedObject.phoma?.value };
        clonedObject.pythium = { value: clonedObject.pythium?.value };
        return clonedObject;
    };

    const handleCopy = () => {
        const toCopy = selectedObjEntity[0];
        delete toCopy.id;
        delete toCopy.creation_date;
        const clonedObject = makeRowCopy(toCopy);
        clonedObject.microorganisms = clonedObject?.microorganisms?.map(x => {
            if (!x?.mycobiota_type) {
                delete x.mycobiota_type
            }
            x.genius = x?.genius?.id
            return x
        })
        dispatch(addMycologicalRegistry({
            history,
            values: clonedObject
        }));
    };

    const handleDelete = () => {
        selectedObjEntity.forEach(selectedRow => {
            setTimeout(() => dispatch(deleteMycologicalRegistry(selectedRow?.id)), 100);
        });
        selectedObjEntity?.length > 0 && setTimeout(() => dispatch(getMycologicalRegistries()), 2000);
    };

    const discardFilters = () => {
        setSelectedEntries([]);
        setSelectedObjEntity([]);
    };

    const handleRowsAction = (actionType) => {
        switch (actionType) {
            case 'publish':
                selectedObjEntity.forEach(objEntity => {
                    dispatch(publishMycologicalRegistry({
                        protocolId: objEntity?.id,
                        values: {
                            public: true
                        }
                    }));
                });
                setTimeout(() => dispatch(getMycologicalRegistries()), 1500);
                break;
            case 'edit':
                selectedObjEntity.forEach(objEntity => {
                    dispatch(publishMycologicalRegistry({
                        protocolId: objEntity?.id,
                        values: {
                            public: false
                        }
                    }));
                });
                setTimeout(() => dispatch(getMycologicalRegistries()), 1500);
                break;
            case 'archive':
                selectedObjEntity.forEach(objEntity => {
                    dispatch(updateMycologicalRegistry({
                        id: objEntity?.id,
                        values: {
                            deleted: true
                        }
                    }));
                });
                setTimeout(() => dispatch(getMycologicalRegistries()), 1500);
                break;
            default:
                break;
        }
    };

    const [buttonOpen, setButtonOpen] = useState(false);

    return (
        <Layout key="page-mycological-registries" className={cn()}>
            <PanelLayout>
                <PanelHeader>
                    <Title title={t('title')} />
                </PanelHeader>
                <PanelContent>
                    <Row gutter={24} style={{ marginBottom: '30px' }}>
                        <Row>
                            <div className="myco-top-text">
                                {t('top text')}
                            </div>
                        </Row>
                        <Row>
                            <Col span={13}>
                                {selectedObjEntity?.length > 0 ? (
                                    <Popover
                                        placement="bottom"
                                        content={(
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    width: '200px'
                                                }}>
                                                <Button
                                                    onClick={() => handleRowsAction('publish')}
                                                    style={{ margin: '10px 5px' }}>
                                                    {t('publish')}
                                                </Button>
                                                <Button
                                                    onClick={() => handleRowsAction('edit')}
                                                    style={{ margin: '0px 5px' }}>
                                                    {t('edit')}
                                                </Button>
                                                <Button
                                                    onClick={() => handleRowsAction('archive')}
                                                    style={{ margin: '10px 5px' }}>
                                                    {t('archive')}
                                                </Button>
                                            </div>
                                        )}
                                        trigger="click"
                                        visible={buttonOpen}
                                        onVisibleChange={setButtonOpen}>
                                        <Button>
                                            {t('manage status')}
                                        </Button>
                                    </Popover>
                                ) : (
                                    <Button
                                        onClick={() => history.push('/mycological-registry')}>
                                        <PlusCircleOutlined />
                                        {t('add new registry')}
                                    </Button>
                                )}
                            </Col>
                            <Col span={1} />
                            <Col span={5}>
                                <Button
                                    type="secondary"
                                    disabled={selectedEntries.length < 1}
                                    onClick={handleDownloadWord}>
                                    <DownloadOutlined />
                                    {`${t('download')} Word`}
                                </Button>
                            </Col>
                        </Row>
                        <Col span={1} />
                        <Row>
                            <Col span={11}>
                                <Button
                                    onClick={handleCopy}
                                    disabled={selectedEntries.length !== 1}>
                                    <CopyOutlined />
                                </Button>
                            </Col>
                            <Col span={2} />
                            <Col span={11}>
                                <Button
                                    onClick={handleDelete}
                                    disabled={selectedEntries.length < 1}>
                                    <DeleteOutlined />
                                </Button>
                            </Col>
                        </Row>
                        <Col span={9} />
                        <Col span={3} style={{ marginBottom: '6px' }}>
                            {/* <Button onClick={discardFilters}>
                                {t('reset filter')}
                            </Button> */}
                        </Col>
                    </Row>
                    <div className={cn()}>
                        <Table
                            ref={tableRef}
                            style={{
                                overflowX: 'auto'
                            }}
                            onChange={handleFiltersChange}
                            rowKey={(record) => record?.id}
                            rowSelection={rowSelection}
                            dataSource={registries?.length > 0 ? [...registries] : []}
                            columns={requestsColumns}
                            onRow={(record, rowIndex) => ({
                                onClick: () => !record.public && !record?.deleted && history.push(`/mycological-registry/${record.id}`) // click row
                            })}
                            pagination={false} />

                    </div>
                </PanelContent>
            </PanelLayout>
        </Layout>
    );
};

export default Registries;

import React, { Component } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Row, Col } from 'antd';

import './../style.less';

function NoLayers ({
    setModalOpen
}) {
    const { t } = useTranslation('sampling points');

    return (
        <>
            <div className="sampling-points__empty-info-box">
                <div>
                    {t('empty text first line')}
                </div>
                <div>
                    {t('empty text second line')}
                </div>
            </div>
            <Row className="sampling-points__button-row">
                <Col span={9} />
                <Col span={5}>
                    <Button onClick={() => setModalOpen(true)} type="primary">
                        {t('empty button text')}
                    </Button>
                </Col>
                <Col span={10} />
            </Row>
        </>
    );
};

export default NoLayers

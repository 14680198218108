import React, { useEffect, useMemo, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    Table,
    Select,
    Layout,
    Space
} from 'antd';
import { selectors } from '../../../../models';
import { getFieldPoints } from '../../../../models/mordovia/actions';
import { toFixed } from '../../../../utils/utils';

const { curPoints, getCurrentCalculatorMordovia } = selectors;

const zonesSorter = { low: 1, high: 3, middle: 2 };

const AgroResults = ({ setCurPoint }) => {
    const points = useSelector(state => curPoints(state));
    const calculator = useSelector(state => getCurrentCalculatorMordovia(state));
    const { Option } = Select;
    const { t } = useTranslation('interpretation');
    const dispatch = useDispatch();
    const match = useRouteMatch();
    const [zoneFilterValue, setZoneFilter] = useState(null);
    const [pointFilterValue, setPointFilter] = useState(null);
    const [zoneFilterOptions, setZoneFilterOptions] = useState({});
    const [pointFilterOptions, setPointFilterOptions] = useState([]);

    useEffect(() => {
        const zoneOptionsCopy = {};
        const pointOptionsCopy = [];
        points?.forEach((item) => {
            if (!pointOptionsCopy.includes(item.point_number)) {
                pointOptionsCopy.push(item.point_number);
            }
            if (!zoneOptionsCopy[item.productivity_zone]) {
                zoneOptionsCopy[item.productivity_zone] = [item.point_number];
            } else {
                if (!zoneOptionsCopy[item.productivity_zone].includes(item.point_number)) {
                    zoneOptionsCopy[item.productivity_zone].push(item.point_number);
                }
            }
        });
        setZoneFilterOptions({ ...zoneOptionsCopy });
        setPointFilterOptions([...pointOptionsCopy]);
    }, [points]);

    const { id, year } = match.params;

    const yearMemo = useMemo(() => {
        return calculator.year_group.years.filter(x => x.year.toString() === year.toString())[0];
    }, [calculator]);

    const getFilterResult = (zoneFilter, pointFilter) => {
        let filterString = '';
        if (zoneFilter?.length && (zoneFilter?.length > 0)) {
            filterString += `, "productivity_zone": "${zoneFilter}"`;
        }
        if (pointFilter?.length && (pointFilter?.length > 0)) {
            filterString += `, "point_number": ${pointFilter}`;
        }
        if (filterString.length < 0) {
            return;
        }
        dispatch(getFieldPoints({
            id: yearMemo?.id,
            filter: filterString
        }));
    };

    const handleZoneFilterChange = (value) => {
        setZoneFilter(value);
        getFilterResult(value, pointFilterValue);
    };

    const handlePointFilterChange = (value) => {
        setPointFilter(value);
        getFilterResult(zoneFilterValue, value);
    };

    const handleClick = (pointId) => {
        setCurPoint(pointId);
    };

    const columns = [
        // {
        //     title: t('field number'),
        //     dataIndex: 'field',
        //     key: 'field_number',
        //     width: 50,
        //     render: (value) => value?.field_num || value?.vega_key
        // },
        // {
        //     title: t('crop'),
        //     dataIndex: 'crop',
        //     width: 200,
        //     render: (value) => (
        //         value?.name
        //     )
        // },
        {
            title: t('date analysis'),
            dataIndex: 'date',
            width: 150,
            render: (value) => {
                const dateStr = value?.split('T')[0];
                const dateArray = dateStr ? dateStr.split('-') : [];
                return dateArray?.length > 0 ? `${dateArray[2]}.${dateArray[1]}.${dateArray[0]}` : value;
            }
        },
        {
            title: t('point number'),
            dataIndex: 'point_number',
            width: 100,
            sorter: (a, b) => a.point_number - b.point_number
        },
        {
            title: t('productivity zone'),
            dataIndex: 'productivity_zone',
            width: 100,
            render: value => t(value),
            sorter: (a, b) => zonesSorter[a.productivity_zone] - zonesSorter[b.productivity_zone]
        },
        {
            title: t('ph water'),
            dataIndex: 'ph_water',
            width: 100,
            render: value => toFixed(value, 1)
        },
        {
            title: t('ph salt'),
            dataIndex: 'ph_salt',
            width: 100,
            render: value => toFixed(value, 1)
        },
        {
            title: t('salt_conductivity'),
            dataIndex: 'electrical_conductivity',
            width: 100,
            render: value => toFixed(value, 1)
        },
        {
            title: t('organic_substances'),
            dataIndex: 'organic_matter',
            width: 100,
            render: value => toFixed(value, 1)
        },
        {
            title: t('no'),
            dataIndex: 'no3',
            width: 100,
            render: value => toFixed(value, 1)
        },
        {
            title: t('nh'),
            dataIndex: 'nh4',
            width: 100,
            render: value => toFixed(value, 1)
        },
        {
            title: t('po'),
            dataIndex: 'p2o5',
            width: 100,
            render: value => toFixed(value, 1)
        },
        {
            title: t('ko'),
            dataIndex: 'k2o',
            width: 100,
            render: value => toFixed(value, 1)
        },
        {
            title: t('s'),
            dataIndex: 's',
            width: 100,
            render: value => toFixed(value, 1)
        },
        {
            title: t('na'),
            dataIndex: 'na',
            width: 100,
            render: value => toFixed(value, 1)
        },
        {
            title: t('ca'),
            dataIndex: 'ca',
            width: 100,
            render: value => toFixed(value, 1)
        },
        {
            title: t('mg'),
            dataIndex: 'mg',
            width: 100,
            render: value => toFixed(value, 1)
        },
        {
            title: t('so'),
            dataIndex: 'so4',
            width: 100,
            render: value => toFixed(value, 1)
        },
        {
            title: t('cl'),
            dataIndex: 'cl',
            width: 100,
            render: value => toFixed(value, 1)
        },
        {
            title: t('method po'),
            dataIndex: 'method',
            width: 100,
            render: value => t(`${value} sm`)
        }
    ];
    return (
        <Layout>
            <Space>
                <Select
                    placeholder={t('zone_type')}
                    onChange={handleZoneFilterChange}
                    allowClear>
                    {
                        Object.keys(zoneFilterOptions).map((option) =>
                            <Option key={option}>{ t(option) }</Option>
                        )
                    }
                </Select>
                <Select
                    placeholder={t('point_number')}
                    onChange={handlePointFilterChange}
                    allowClear>
                    {[...pointFilterOptions].map((option) =>
                        <Option key={option}>{ option }</Option>)}
                </Select>
            </Space>
            <Table dataSource={points}
                onRow={(record, rowIndex) => ({
                    onClick: () => handleClick(record.id)
                })}
                columns={columns}
                pagination={false}
                scroll={{
                    x: 1300
                }} />
        </Layout>
    );
};

export default AgroResults;
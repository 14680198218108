import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useClassName } from 'utils/cn';
import { Button, Modal, Popover, Space } from 'antd';
import './style.less';
import { useLocation, useHistory, Link } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';

const { confirm } = Modal;

export default ({
    backRoute = {}, pathArray = [],
    isIteractive = true,
    isConfirmable = false,
    confirmParameters = {}
}) => {
    const history = useHistory();
    // const location = useLocation();
    // const { pathname } = location;

    const handleConfirmableClick = async () => {
        const confirmResult = await confirm({
            title: confirmParameters?.title,
            icon: confirmParameters?.icon,
            content: confirmParameters?.content,
            onOk: confirmParameters?.onOk,
            onCancel: confirmParameters?.onCancel
        });

        // console.log('CONF RES', confirmResult);

        // if (confirmResult) {
        //     history.push(backRoute?.path);
        // }
        // confirm(confirmParameters);
    }

    return (
        <Space className="section-header" direction="horizontal">
            <Popover
                content={(
                    <div className="go-back-popover">
                        {backRoute?.name}
                    </div>
                )}>
                <Button size="middle" onClick={isConfirmable ? () => handleConfirmableClick() : () => history.push(backRoute?.path)}><ArrowLeftOutlined /></Button>
            </Popover>

            <Space className="section-header-path" direction="horizontal">
                {pathArray?.map((pathOption, idx) => {
                    if (idx === (pathArray?.length - 1)) {
                        return isIteractive ? (
                            <Link
                                to={pathOption?.path}
                                className="active-stage">
                                {pathOption?.name}
                            </Link>
                        ) : (
                            <div
                                className="active-stage-fixed">
                                {pathOption?.name}
                            </div>
                        );
                    }

                    return isIteractive ? (
                        // <div>test / </div>
                        <Link
                            to={pathOption?.path}
                            className="inactive-stage">
                                {`${pathOption?.name} / `}
                        </Link>
                    ) : (
                        <div
                            className="inactive-stage-fixed">
                                {`${pathOption?.name} / `}
                        </div>
                    );
                })}
            </Space>
        </Space>
    );
};

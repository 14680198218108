import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Pagination from '../pagination';
import TableSizeFilter from '../custom-filter';

import cn from '../../utils/cn';

import './style.less';


@withTranslation('table footer')
@cn('table-footer')
export default class TableFooter extends Component {
    render() {
        const { cn } = this;
        const {
            t,
            filterItems: items,
            rowDivider,
            dataSize,
            pageSize,
            onSelect,
            isSized = true,
            ...rest
        } = this.props;

        return (
            <div className={cn()}>
                {isSized && (
                    <TableSizeFilter
                    className={cn('table-pages-filter')}
                    name="table-pages-filter"
                    label={`${t('show records')}:`}
                    placeholder={pageSize?.toString() || rowDivider?.toString()}
                    items={items}
                    onSelect={onSelect} />
                )}
                <Pagination
                    pageSize={pageSize}
                    total={dataSize}
                    {...rest} />
            </div>
        );
    }
}
import {
    all, call, put, takeLatest
} from 'redux-saga/effects';
import {
    defaultSaga, getActions
} from '../utils';

import * as constants from './constants';
import * as api from './api';
import {
    getCardDefaults,
    getUsersList,
    manageSampleRowPublication,
    updateCardDefaults
} from './api';
import { addOwnerSampleLog } from './actions';

function* createSampleRowSaga({ payload }) {
    const [start, success, failure] = getActions(
        constants.createSampleRow.try,
        constants.createSampleRow.success,
        constants.createSampleRow.error
    );
    try {
        yield put(start());
        const data = yield call(api.createSampleRow, payload);
        yield put(success(data));
    } catch (error) {
        yield put(failure(error));
    }
}

function* addNewUserSaga({ payload }) {
    const [start, success, failure] = getActions(
        constants.addOwnerSampleLog.try,
        constants.addOwnerSampleLog.success,
        constants.addOwnerSampleLog.error,
    );
    try {
        yield put(start());
        const data = yield call(api.addOwnerSampleLog, payload);
        yield put(success(data));
    } catch (error) {
        yield put(failure(error));
    }
}

function* customFileUploadSaga({ payload }) {
    const [start, success, failure] = getActions(
        constants.uploadSampleFile.try,
        constants.uploadSampleFile.success,
        constants.uploadSampleFile.error,
    );

    try {
        yield put(start());
        const data = yield call(api.uploadSampleFile, payload);
        yield put(success(data));
    } catch (error) {
        yield put(failure(error.text()));
    }
}

export default function* rootSaga() {
    yield all([
        yield takeLatest(
            constants.getSamplesList.toString(),
            defaultSaga({
                constants: constants.getSamplesList,
                api: api.getSamplesList
            })
        ),
        yield takeLatest(
            constants.deleteSampleRow.toString(),
            defaultSaga({
                constants: constants.deleteSampleRow,
                api: api.deleteSampleRow
            })
        ),
        yield takeLatest(
            constants.createSampleRow.toString(),
            createSampleRowSaga
        ),
        yield takeLatest(
            constants.uploadSampleFile.toString(),
            customFileUploadSaga
        ),
        yield takeLatest(
            constants.editSampleRow.toString(),
            defaultSaga({
                constants: constants.editSampleRow,
                api: api.editSampleRow
            })
        ),
        yield takeLatest(
            constants.getSampleById.toString(),
            defaultSaga({
                constants: constants.getSampleById,
                api: api.getSampleById
            })
        ),
        yield takeLatest(
            constants.manageSampleRowPublication.toString(),
            defaultSaga({
                constants: constants.manageSampleRowPublication,
                api: api.manageSampleRowPublication
            })
        ),
        yield takeLatest(
            constants.addOwnerSampleLog.toString(),
            addNewUserSaga
        ),
        yield takeLatest(
            constants.getUsersList.toString(),
            defaultSaga({
                constants: constants.getUsersList,
                api: api.getUsersList
            })
        ),
        //
        yield takeLatest(
            constants.getCardDefaults.toString(),
            defaultSaga({
                constants: constants.getCardDefaults,
                api: api.getCardDefaults
            })
        ),
        yield takeLatest(
            constants.updateCardDefaults.toString(),
            defaultSaga({
                constants: constants.updateCardDefaults,
                api: api.updateCardDefaults
            })
        )
    ]);
}
import * as constants from '../constants';
import converter from '../converter';

const initialState = { isResult: 'default' };

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case constants.syncField.success: {
            return { results: action.payload, isResult: true } ;
        }

        case constants.syncField.error: {
            return { isResult: false, error: 'backend error' };
        }

        default:
            return state;
    }
}

import React, { useEffect, useState, useRef } from 'react';
import {
    Button,
    Col, DatePicker, Form, Input, InputNumber, Row, Select
} from 'antd';

import { useTranslation } from 'react-i18next';

import { useClassName } from 'utils/cn';

import '../style.less';
import { useRouteMatch } from 'react-router-dom';
import moment from 'moment';

const CalculatorData = ({
    createProtocol, usersList, culturesList, fieldsList, form, showButton
}) => {
    const cn = useClassName('calculators');
    const { t } = useTranslation('fed registry');
    const match = useRouteMatch();

    const compareOpts = (a, b) => {
        if (a.label < b.label) {
            return -1;
        }
        if (a.label > b.label) {
            return 1;
        }
        return 0;
    };

    return (
        <div>
            <Row>
                <Col span={11}>
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                label={t('data label protocol num')}
                                name="protocol_number"
                                rules={[
                                    { required: true }
                                ]}>
                                <InputNumber
                                    style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                label={t('data label owner')}
                                name="owner"
                                rules={[
                                    { required: true }
                                ]}>
                                <Select
                                    showSearch
                                    filterOption={(input, option) => {
                                        return (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
                                    }}
                                    filterSort={(optionA, optionB) => {
                                        return (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase());
                                    }}
                                    options={usersList?.length > 0 ? usersList?.map(usr => {
                                        return {
                                            label: usr?.name,
                                            value: usr?.id
                                        };
                                    }).sort(compareOpts) : []}
                                    style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                label={t('data label acceptance date')}
                                name="acceptation_date"
                                rules={[
                                    { required: true }
                                ]}>
                                <DatePicker
                                    onChange={(evt) => {
                                        form.setFieldsValue({
                                            acceptation_date: moment(evt?.format('YYYY-MM-DD'))
                                        });
                                    }}
                                    format="DD.MM.YYYY"
                                    style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                label={t('data label sample date')}
                                name="examination_date"
                                rules={[
                                    { required: true }
                                ]}>
                                <DatePicker
                                    onChange={(evt) => {
                                        form.setFieldsValue({
                                            examination_date: moment(evt?.format('YYYY-MM-DD'))
                                        });
                                    }}
                                    format="DD.MM.YYYY"
                                    style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={24}>
                            <Form.Item
                                label={t('data label productivity zone')}
                                name="productivity_zone"
                                rules={[
                                    // { required: true }
                                ]}>
                                <Select
                                    options={[
                                        {
                                            label: t('productivity zone high'),
                                            value: 'high'
                                        },
                                        {
                                            label: t('productivity zone middle'),
                                            value: 'middle'
                                        },
                                        {
                                            label: t('productivity zone low'),
                                            value: 'low'
                                        }
                                    ]} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
                <Col span={2} />
                <Col span={11}>
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                label={t('data label field num')}
                                name="field_num"
                                rules={[
                                    { required: true }
                                ]}>
                                <Select
                                    options={fieldsList?.length > 0 ? fieldsList?.map(fieldOption => {
                                        return {
                                            label: fieldOption?.field_num,
                                            value: fieldOption?.field_num
                                        };
                                    }) : []}
                                    style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                label={t('data label point')}
                                name="field_points"
                                rules={[
                                    { required: true }
                                ]}>
                                <Select
                                    mode="tags"
                                    style={{ width: '100%' }}
                                    placeholder="Введите точки через запятую"
                                    tokenSeparators={[',']}
                                    options={[]} />
                                {/* <InputNumber style={{ width: '100%' }} /> */}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                label={t('data label culture')}
                                name="culture"
                                rules={[
                                    { required: true }
                                ]}>
                                <Select
                                    showSearch
                                    filterOption={(input, option) => {
                                        return option?.label?.toLowerCase().includes(input?.toLowerCase());
                                    }}
                                    options={culturesList?.length > 0 ? [...culturesList].map(cultureItem => {
                                        return {
                                            label: cultureItem?.name,
                                            value: cultureItem?.id
                                        };
                                    }).sort((a, b) => {
                                        return a.label?.localeCompare(b.label);
                                    }) : []}
                                    style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                label={t('data label sample')}
                                name="sample"
                                rules={[
                                    { required: true }
                                ]}>
                                <Select
                                    disabled
                                    options={[
                                        {
                                            value: 'Leaf',
                                            label: t('culture select leaf')
                                        }
                                    ]}
                                    style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={24}>
                            <Form.Item
                                label={t('data label productivity zone area')}
                                name="zone_area"
                                rules={[
                                    // { required: true }
                                ]}>
                                <InputNumber decimalSeparator="," style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
            </Row>
            {(!match?.params?.id || showButton) && (
                <Row>
                    <Col span={4}>
                        <Button type="primary" onClick={createProtocol}>
                            {t('confirm create')}
                        </Button>
                    </Col>
                </Row>
            )}
        </div>
    );
};

export default CalculatorData;
import { getConstant } from '../utils';

export const getCalculatorPhyto = getConstant('GET_CALCULATOR_PHYTO');
export const getCalculatorsPhyto = getConstant('GET_CALCULATORS_PHYTO');
export const createCalculatorPhyto = getConstant('CREATE_CALCULATOR_PHYTO');
export const createRegistryPhyto = getConstant('CREATE_REGISTRY_PHYTO');
export const updateRegistryPhyto = getConstant('UPDATE_REGISTRY_PHYTO');
export const getReproductionsPhyto = getConstant('GET_REPRODUCTIONS_PHYTO');
export const getVarietiesPhyto = getConstant('GET_VARIATIONS_PHYTO');
export const removeCalculatorPhyto = getConstant('REMOVE_CALCULATOR_PHYTO');
export const getCulturesPhyto = getConstant('GET_CULTURES_PHYTO');
export const getFertilizersPhyto = getConstant('GET_FERTILIZERS_PHYTO');
export const getPurposesPhyto = getConstant('GET_PURPOSES_PHYTO');
export const getRegionsPhyto = getConstant('GET_REGIONS_PHYTO');
export const updateCalculatorPhyto = getConstant('UPDATE_CALCULATOR_PHYTO');
export const getCausativeAgents = getConstant('GET_CAUSATIVE_AGENTS_PHYTO');
export const addExternalContamination = getConstant('ADD_EXTERNAL_CONTAMINATION_PHYTO');
export const addInternalContamination = getConstant('ADD_INTERNAL_CONTAMINATION_PHYTO');
export const addCausativeAgent = getConstant('ADD_CAUSATIVE_AGENT_PHYTO');
export const addDisease = getConstant('ADD_DISEASE_PHYTO');
export const uploadRegistryFile = getConstant('UPLOAD_REGISTRY_FILE_PHYTO');
export const updateRegistryFiles = getConstant('UPDATE_REGISTRY_FILES_PHYTO');
export const getProtocolsPhyto = getConstant('GET_PROTOCOLS_PHYTO');
export const getProtocolPhyto = getConstant('GET_PROTOCOL_PHYTO');
export const getUsersPhyto = getConstant('GET_USERS_PHYTO');
export const updateInternalContamination = getConstant('UPDATE_INTERNAL_CONTAMINATION_PHYTO');
export const updateExternalContamination = getConstant('UPDATE_EXTERNAL_CONTAMINATION_PHYTO');
export const updatePhotoDescription = getConstant('UPDATE_PHOTO_DESCRIPTION');
export const newAgentPhyto = getConstant('NEW_AGENT_PHYTO');
export const newKindPhyto = getConstant('NEW_KIND_PHYTO');
export const newDiseasePhyto = getConstant('NEW_DISEASE_PHYTO');
export const newConnectionPhyto = getConstant('NEW_CONNECTION_PHYTO');
export const getVegPhasesPhyto = getConstant('GET_VEG_PHYTO');
export const getSubgroupsPhyto = getConstant('GET_SUB_PHYTO');
export const getSymptomsPhyto = getConstant('GET_SYMP_PHYTO');
export const getDrugsPhyto = getConstant('GET_DRUGS_PHYTO');
export const deleteProtocolPhyto = getConstant('DELETE_PROTOCOL_PHYTO');
export const getCausativeAgentItems = getConstant('GET_CAUSATIVE_AGENT_ITEMS_PHYTO');
export const getDiseasesPhyto = getConstant('GET_DISEASES_PHYTO');

import React, {
    useEffect, useState, useMemo
} from 'react';
import './style.less';
import { useTranslation } from 'react-i18next';
import { useClassName } from '../../../utils/cn';
import { Button, Checkbox, Col, Modal, Row, Switch, Tabs } from 'antd';
import { toFixed } from '../../../utils/utils';
import mockStations from './example-response-stations.json';
import mockResponse from './example-response-charts.json';
import ChartRepresentation from './chart';

const { TabPane } = Tabs;

const FieldInfo = ({
    calculator,
    selectedSources
}) => {
    const { t } = useTranslation('meteodata registry');
    const cn = useClassName('field-info');

    const [isModal, setIsModal] = useState(false);
    const [selectedStations, setSelectedStations] = useState([]);
    const [selectedFields, setSelectedFields] = useState([]);

    const [filteredLegend, setFilteredLegend] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [filteredGtkData, setFilteredGtkData] = useState([]);

    useEffect(() => {
        console.log('SELECTED FIELDS', selectedFields);
    }, [selectedFields]);

    useEffect(() => {
        console.log('filteredData filteredData', filteredData);
    }, [selectedFields]);
    

    useEffect(() => {
        console.log('mockResponse', mockResponse);
        const { telemetry, gtk, legend } = mockResponse;
        setFilteredLegend(legend);
        setFilteredData(telemetry?.filter(x => selectedStations?.includes(x?.id)));
        setFilteredGtkData(gtk?.filter(x => selectedStations?.includes(x?.id)));
    }, [mockResponse, selectedStations, selectedFields]);

    useEffect(() => {
        console.log('Data', filteredData);
        console.log('Gtk', filteredGtkData);
    }, [filteredData, filteredGtkData])
    
    useEffect(() => {
        console.log('Stats', selectedStations)
        console.log('Fields', selectedFields)
    }, [selectedStations, selectedFields])
    

    const toggleOption = (type, state, id) => {
        if (type === 'station') {
            if (state && !selectedStations?.includes(id)) {
                setSelectedStations([...selectedStations, id]);
            }
            if (state === false && selectedStations?.includes(id)) {
                setSelectedStations(selectedStations?.filter(x => x?.id !== id));
            }
        }

        if (type === 'field') {
            if (state && !selectedFields?.includes(id)) {
                setSelectedFields([...selectedFields, id]);
            }
            if (state === false && selectedFields?.includes(id)) {
                setSelectedFields(selectedFields?.filter(x => x?.id !== id));
            }
        }
    }

    const handleOk = () => {

    };

    const handleCancel = () => {
        setIsModal(false);
    };

    const getMeteostationName = (meteoId) => {
        const findingMeteoStation = mockStations?.[0]?.meteoStations?.filter(x => x?.id === meteoId)?.[0];
        return findingMeteoStation.name;
    }

    return (
        <div style={{ padding: '10px' }} id="field-info">
            <Row style={{ paddingBottom: '10px', color: '#000000D9', fontWeight: '600', fontSize: '16px' }}>
                {t('params')}
            </Row>
            <hr style={{ marginTop: 0 }} />
            {calculator?.map(x => x !== undefined && (
                <Row>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <Switch onChange={(isChecked) => toggleOption('field', isChecked, x)} checked={selectedFields?.filter(y => y?.id === x?.id)?.[0]} />
                        {x?.vega_key}
                    </div>
                </Row>
            ))}
            <hr style={{ marginBottom: 0 }} />
            <Row style={{ paddingBottom: '10px', paddingTop: '10px', color: '#000000D9', fontWeight: '600', fontSize: '16px' }}>
                {t('priority')}
            </Row>
            {selectedSources?.map(x => (
                <Row>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <Switch onChange={(isChecked) => toggleOption('station', isChecked, x)} value={selectedStations?.filter(y => y?.id === x?.id)?.[0]} />
                        {getMeteostationName(x)}
                    </div>
                </Row>
            ))}
            <hr />
            <Button type='primary' style={{ width: '100%' }} onClick={() => { setIsModal(true) }} >Cравнить</Button>
            <Modal
                width="100%"
                onOk={handleOk}
                onCancel={handleCancel}
                visible={isModal}
                footer={undefined}
                title={t('modal title')}
            >
                {selectedFields?.map((selectedField, fieldIdx) => (
                    <div style={{ marginBottom: '25px' }}>
                        <Row style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <Col style={{ marginLeft: '5px' }}>
                                <h3>{selectedField?.vega_key}</h3>
                            </Col>
                        </Row>

                        <Row>
                            {/* <Col span={16}> */}
                            <ChartRepresentation isModal={true} selectedFields={selectedFields} currFieldName={selectedField?.vega_key} chartName={t('chart air')} chartLegend={filteredLegend} chartType="air" chartData={filteredData[fieldIdx]?.data} style={{ width: '100%' }} />
                            {/* </Col> */}
                        </Row>
                        <Row style={{ marginTop: '45px' }}>
                            {/* <Col span={16}> */}
                            <ChartRepresentation isModal={true} selectedFields={selectedFields} currFieldName={selectedField?.vega_key} chartName={t('chart soil')} chartLegend={filteredLegend} chartType="soil" chartData={filteredData[fieldIdx]?.data} style={{ width: '100%' }} />
                            {/* </Col> */}
                        </Row>
                        <Row style={{ marginTop: '45px' }}>
                            {/* <Col span={16}> */}
                            <ChartRepresentation isModal={true} selectedFields={selectedFields} currFieldName={selectedField?.vega_key} chartName={t('chart precipitation')} chartLegend={filteredLegend} chartType="precipitation" chartData={filteredData[fieldIdx]?.data} style={{ width: '100%' }} />
                            {/* </Col> */}
                        </Row>
                        <Row style={{ marginTop: '45px' }}>
                            {/* <Col span={16}> */}
                            <ChartRepresentation isModal={true} selectedFields={selectedFields} currFieldName={selectedField?.vega_key} chartName={t('chart wind')} chartLegend={filteredLegend} chartType="wind" chartData={filteredData[fieldIdx]?.data} style={{ width: '100%' }} />
                            {/* </Col> */}
                        </Row>
                        <Row style={{ marginTop: '45px' }}>
                            {/* <Col span={16}> */}
                            {/* <ChartRepresentation isModal={true} selectedFields={selectedFields} currFieldName={selectedField?.vega_key} chartName={t('chart harmful')} chartLegend={filteredLegend} chartType="harmful" chartData={filteredData[fieldIdx]?.data} style={{ width: '100%' }} /> */}
                            {/* </Col> */}
                        </Row>
                        <Row style={{ marginTop: '45px' }}>
                            {/* <Col span={16}> */}
                            <ChartRepresentation isModal={true} selectedFields={selectedFields} currFieldName={selectedField?.vega_key} chartName={t('chart gtk')} chartLegend={filteredLegend} chartType="gtk" chartData={filteredGtkData[fieldIdx]?.data} style={{ width: '100%' }} />
                            {/* </Col> */}
                        </Row>
                    </div>
                ))}
                {/* Modal haha */}
            </Modal>
        </div>
    );
};

export default FieldInfo;
import React, { Component, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Modal, Button, Alert, message
} from 'antd';

import request from '../../../utils/request';

function DeleteModalWindow({ deleteId, closeModal, reset }) {
    const { t } = useTranslation('sampling points')

    const [isWaiting, setIsWaiting] = useState(false);

    const deleteLayer = async (id) => {
        try {
            setIsWaiting(true);
            const res = await request.patch({
                url: `/api/v1/mordovia/layer/${id}/`,
                body: {
                    is_deleted: true
                }
            });
            reset();
        } catch (e) {
            message.error(`Status: ${e.status}. ${e.statusText}`);
            console.log('error: ', e);
        } finally {
            setIsWaiting(false);
            closeModal();
        }
    };

    return (
        <Modal
            visible={deleteId !== null}
            title={t('delete modal.title')}
            footer={[
                <Button disabled={isWaiting} key="back" onClick={() => closeModal()}>
                  {t('delete modal.cancel button')}
                </Button>,
                <Button
                    loading={isWaiting}
                    key="delete"
                    type="primary"
                    danger
                    onClick={() => deleteLayer(deleteId)}>
                    {t('delete modal.delete button')}
                </Button>
            ]}
            onCancel={() => {
                if (!isWaiting) {
                    closeModal();
                }
            }}>
            <div style={{ marginBottom: '20px' }}>
      {t('delete modal.line1')}
                <br />
      {t('delete modal.line2')}
            </div>
            <Alert
                message={t('delete modal.warning message')}
                type="warning"
                showIcon />
        </Modal>
    );
}

export default DeleteModalWindow;

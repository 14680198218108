// import React, { useEffect, useState } from 'react';
// import { useTranslation } from 'react-i18next';

// import { useClassName } from 'utils/cn';

// import '../style.less';
// import { Button, Col, Collapse, Form, Input, InputNumber, Row, Select } from 'antd';
// import InfoModal from './info-modal';

// const { Panel } = Collapse;

// const InternalContamination = ({ form }) => {
//     const cn = useClassName('all-calculators');
//     const { t } = useTranslation('phyto calculator');

//     const [isModalOpen, setIsModalOpen] = useState(false);
//     const [selectedItems, setSelectedItems] = useState([{
//         pathogen: 'Puccinia graminis f. sp. tritici',
//         external_pathogens: ['ext_pat1', 'ext_pat2'],
//         phase: [
//             'Кущение',
//             'Выход в трубку',
//             'Колошение',
//             'Цветение',
//             'Молочная спелость',
//             'Восковая спелость',
//             'Полная спелость'
//         ]
//     }]);
//     const [protectorOptions, setProtectorOptions] = useState([
//         {
//             object: 'Пыльная головня',
//             drugs: ['препарат 1', 'Длинное название препарата', 'препарат 3']
//         },
//         {
//             object: 'Стеблевая ржавчина',
//             drugs: ['Длинное название препарата', 'препарат 4']
//         },
//         {
//             object: 'Стеблевая головня/Индийская головня',
//             drugs: ['Длинное название препарата 5', 'Длинное название препарата 6', 'Длинное название препарата 7']
//         }
//     ]);

//     return (
//         <>
//             <Row className="mt-25">
//                 <Col span={12}>
//                     <Form.Item
//                         rules={[{ required: true }]}
//                         label={t('internal input label')}
//                         name="internal_1">
//                         <InputNumber
//                             className="w-100"
//                             placeholder={t('internal input placeholder')} />
//                     </Form.Item>
//                 </Col>
//             </Row>
//             <Row>
//                 <Col span={12}>
//                     <Form.Item
//                         label={t('internal select label')}
//                         name="internal_2">
//                         <Select
//                             allowClear
//                             placeholder={t('internal select placeholder')}
//                             className="w-100"
//                             onChange={setSelectedItems}
//                             mode="multiple" />
//                     </Form.Item>
//                 </Col>
//             </Row>
//             <Row>
//                 <Col span={2}>
//                     <Button type="primary" className="mt-25 mb-25">
//                         {t('button text select')}
//                     </Button>
//                 </Col>
//             </Row>

//             {selectedItems?.length > 0 && (
//                 <Collapse
//                     expandIconPosition="right"
//                     style={{ backgroundColor: 'white', marginBottom: '0' }}
//                     bordered={false}
//                     defaultActiveKey={['external_probability']}
//                     className={`${cn('collapse')} remove-padding`}>
//                     <Panel
//                         header={<b>{t('calculator external collapse header')}</b>}
//                         key="external_probability">
//                         <div className="pathogens-table">

//                             <Row className="pathogens-table__row__header">
//                                 <Col className="pathogens-table__row__text__header" span={4}>
//                                     {t('internal probability header reason')}
//                                 </Col>
//                                 <Col className="pathogens-table__row__text__header" span={5}>
//                                     {t('internal probability header harmful')}
//                                 </Col>
//                                 <Col className="pathogens-table__row__text__header" span={5}>
//                                     {t('internal probability header probability')}
//                                 </Col>
//                                 <Col className="pathogens-table__row__text__header" span={5}>
//                                     {t('internal probability header amount')}
//                                 </Col>
//                                 <Col className="pathogens-table__row__text__header" span={5}>
//                                     {t('internal probability header percent')}
//                                 </Col>
//                             </Row>

//                             {selectedItems.map(selectedPathogen => (
//                                 <>
//                                     <Row className="pathogens-table__row">
//                                         <Col className="pathogens-table__row__text" span={4}>
//                                             {selectedPathogen?.pathogen}
//                                         </Col>
//                                         <Col className="pathogens-table__row__text__highlighted" span={5}>
//                                             {selectedPathogen?.external_pathogens?.join(', ')}
//                                         </Col>
//                                         <Col className="pathogens-table__row__text__columned" span={5}>
//                                             {selectedPathogen?.phase?.map(phaseName => (
//                                                 <div>
//                                                     {phaseName}
//                                                 </div>
//                                             ))}
//                                         </Col>
//                                         <Col span={5}>
//                                             <InputNumber />
//                                         </Col>
//                                         <Col span={5}>
//                                             <InputNumber />
//                                         </Col>
//                                     </Row>
//                                     <Row>
//                                         <Col span={4} />
//                                         <Col span={5} />
//                                         <Col span={5} />
//                                         <Col span={5}>
//                                             <b>
//                                                 {t('general percent')}
//                                             </b>
//                                         </Col>
//                                         <Col span={5} style={{ height: '100%' }}>
//                                             <div style={{ margin: 'auto' }}>
//                                                 <b>2.28%</b>
//                                             </div>
//                                         </Col>
//                                     </Row>
//                                 </>
//                             ))}
//                         </div>
//                     </Panel>
//                 </Collapse>
//             )}

//             {protectorOptions?.length > 0 && (
//                 <Collapse
//                     expandIconPosition="right"
//                     style={{ backgroundColor: 'white', padding: '0' }}
//                     bordered={false}
//                     defaultActiveKey={['drug_selection']}
//                     className={`${cn('collapse')} remove-padding`}>
//                     <Panel
//                         header={<b>{t('calculator external collapse header drug_selection')}</b>}
//                         key="drug_selection">
//                         <div className="drug-cards">
//                             {protectorOptions?.map(drugOption => (
//                                 <div className="drug-card">
//                                     <div className="drug-card__header">
//                                         {drugOption?.object}
//                                     </div>
//                                     <div className="drug-card__list">
//                                         <ul>
//                                             {drugOption?.drugs.map(dr => (
//                                                 <li>
//                                                     {dr}
//                                                 </li>
//                                             ))}
//                                         </ul>
//                                     </div>
//                                 </div>
//                             ))}
//                         </div>
//                     </Panel>
//                 </Collapse>
//             )}

//             <Row className="mt-25">
//                 <Col span={2} />
//                 <Col span={6}>
//                     <Button type="primary" className="w-100">{t('external bottom button continue')}</Button>
//                 </Col>
//                 <Col span={1} />
//                 <Col span={6}>
//                     <Button className="w-100">{t('external bottom button goto')}</Button>
//                 </Col>
//                 <Col span={1} />
//                 <Col span={4}>
//                     <Button className="w-100">{t('external bottom button finish')}</Button>
//                 </Col>
//                 <Col span={2} />
//             </Row>

//             <InfoModal
//                 inspectableItem={{}}
//                 downloadPDF={() => console.log('Download')}
//                 closeModal={() => setIsModalOpen(false)}
//                 isOpened={isModalOpen} />
//         </>
//     );
// };

// export default InternalContamination;

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useClassName } from 'utils/cn';

import '../style.less';
import { Button, Col, Collapse, Form, Image, Input, InputNumber, Modal, Popover, Row, Select, Table, Tabs, Upload } from 'antd';
import InfoModal from './info-modal';
import { useDispatch, useSelector } from 'react-redux';
import { currentCausativeAgentsPhyto, currentFertilizersPhyto, drugsPhyto, uploadedFilePhyto } from '../../../models/calculators-phyto/selectors';
import {
    addInternalContamination,
    getCausativeAgents,
    updateCalculatorPhyto,
    updateRegistryPhyto,
    uploadRegistryFile,
    updateInternalContamination,
    updatePhotoDescription,
    getDrugsPhyto,
    getProtocolPhyto,
    getCalculatorPhyto
} from '../../../models/calculators-phyto/actions';
import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import { useRouteMatch, useHistory } from 'react-router-dom';
import moment from 'moment';
import { jsPDF } from 'jspdf';
import * as constants from '../../../constants';

const { Panel } = Collapse;

const InternalContamination = ({
    internalPathogenStage, setInternalPathogenStage,
    activeTabs, setActiveTabs,
    form, images, calculatorId,
    pathogens, fertilizersFullList
}) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const match = useRouteMatch();
    const cn = useClassName('all-calculators');
    const { t } = useTranslation('phyto calculator');
    const { t: pdfT } = useTranslation('phyto calculator');

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [currentDisease, setCurrentDisease] = useState({});
    const [agentPage, setAgentPage] = useState(1);
    const [agentsList, setAgentsList] = useState([]);
    const [showDrugModal, setShowDrugModal] = useState(false);
    const [drugDisease, setDrugDisease] = useState({});
    const [activeDrugTab, setActiveDrugTab] = useState(null);
    const [selectedDrugList, setSelectedDrugList] = useState([]);

    const agents = useSelector(state => currentCausativeAgentsPhyto(state));
    const uploadedRegistryFile = useSelector(state => uploadedFilePhyto(state));

    useEffect(() => {
        pathogens?.length > 0 && setActiveDrugTab(pathogens[0].id);
    }, [pathogens]);

    useEffect(() => {
        pathogens?.forEach(pathogenVal => {
            if (pathogenVal?.pathogens_number || pathogenVal?.pathogens_percentage) {
                setInternalPathogenStage(2);
            }
        })
    }, [pathogens])
    

    const showDiseaseModal = (disease) => {
        setCurrentDisease(disease);
        setIsModalOpen(true);
    };

    const nextContaminationStep = () => {
        if (internalPathogenStage === 2) {
            const formVals = form?.getFieldsValue();
            // console.log('FORMVAL', formVals);
            setActiveTabs(activeTabs?.filter(x => x !== 'calculator_quality'));
            dispatch(updateRegistryPhyto({
                calcId: match?.params?.id,
                values: {
                    pathogens_number: 10,
                    pathogens_percentage: 1.1,
                    drugs: [1, 2]
                }
            }));
        }
        setInternalPathogenStage(internalPathogenStage + 1);
    }; 

    useEffect(() => {
        dispatch(getCausativeAgents({ page: agentPage }));
    }, [agentPage]);

    useEffect(() => {
        const formVals = form?.getFieldsValue();
        const { fito_calculator } = form?.getFieldsValue();
        if (fito_calculator) {
            const { total_pathogens_percentage } = fito_calculator;
            if (total_pathogens_percentage) {
                setInternalPathogenStage(3);
            }
        }
    }, [form])
    

    useEffect(() => {
        const { next, results } = agents;
        if (results?.length > 0) {
            const toInsert = [];
            results?.forEach(resVal => {
                const findingSame = agentsList?.filter(agnt => agnt?.id === resVal?.id);
                if (!findingSame[0]) {
                    toInsert.push(resVal);
                }
            })
            setAgentsList([
                ...agentsList,
                ...toInsert?.map(x => {
                    return {
                        label: x?.name,
                        value: x?.id
                    };
                })]);
        }
        if (next?.length > 0) {
            setAgentPage(agentPage + 1);
        }
    }, [agents]);
    
    useEffect(() => {
        const { image_type, uploaded_file } = uploadedRegistryFile;
        if (uploaded_file?.id && image_type === 'internal_contamination') {
            let newImg = [];
            if (images?.length > 0) {
                newImg = images?.map(x => x?.id);
            }
            setActiveTabs(activeTabs?.filter(x => x !== 'calculator_quality'));
            dispatch(updateRegistryPhyto({
                calcId: match?.params?.id,
                values: {
                    internal_pathogen_images: [...newImg, uploaded_file?.id]
                }
            }));

            setTimeout(() => {
                window.location.reload();
            }, 500);
        }
    }, [uploadedRegistryFile]);

    const handleUpdatePhotoDescription = (currentPhoto, newDescription) => {
        dispatch(updatePhotoDescription({
            imageId: currentPhoto?.id,
            values: {
                picture_desc: newDescription
            }
        }));
    };

    const editableDescriptionContent = (photoInstance, oldVals) => {
        const [photoDescription, setPhotoDescription] = useState('');

        useEffect(() => {
            setPhotoDescription(oldVals)
        }, [oldVals])

        return (
            <div style={{ width: '300px', height: '200px' }}>
                <Row style={{ height: '150px' }}>
                    <Input.TextArea
                        style={{ maxHeight: '150px' }}
                        value={photoDescription}
                        defaultValue={oldVals}
                        onChange={evt => setPhotoDescription(evt?.target?.value)} />
                </Row>
                <Row align="middle" gutter={12} style={{ height: '50px' }}>
                    <Col span={24}>
                        <Button onClick={() => handleUpdatePhotoDescription(photoInstance, photoDescription)} type="primary" style={{ width: '100%' }}>
                            {t('save photo changes')}
                        </Button>
                    </Col>
                </Row>
            </div>
        );
    };

    const imagesColumns = [
        {
            key: "picture_file",
            dataIndex: "picture_file",
            render: value => value ? (
                <Image width={100} src={value} />
            ) : "-"
        },
        {
            key: "picture_name",
            dataIndex: "picture_name"
        },
        {
            key: "picture_desc",
            dataIndex: "picture_desc",
            render: (value, record) => (
                <Popover content={() => editableDescriptionContent(record, value)} trigger="click">
                    {value}
                </Popover>
            )
        },
        {
            key: "uploaded",
            dataIndex: "uploaded",
            render: value => value ? moment(value).format('DD.MM.YYYY') : "-"
        },
        {
            key: "actions",
            dataIndex: '',
            render: (value, record) => (
                <DeleteOutlined
                    onClick={() => {
                        const newImgs = [...images].map(x => x.id).filter(y => y !== record?.id);
                        setActiveTabs(activeTabs?.filter(x => x !== 'calculator_quality'));
                        dispatch(updateRegistryPhyto({
                            calcId: match?.params?.id,
                            values: {
                                internal_pathogen_images: newImgs
                            }
                        }));
                    }} />
            )
        }
    ];

    const uploadProps = {
        name: 'file',
        customRequest: (reqParams) => {
            if (match?.params?.id) {
                const { file } = reqParams;
                return dispatch(uploadRegistryFile({
                    protocolId: match?.params?.id,
                    imageType: 'internal_contamination',
                    values: {
                        uploaded: moment().format('YYYY-MM-DDThh:mm'),
                        picture_file: file,
                        picture_desc: `Internal pathogen ${moment().format('YYYY-MM-DDThh:mm')}`
                    },
                    type: 'formdata'
                }));
            }
        }
    };

    const downloadDiseasePDF = () => {
        const doc = new jsPDF('p', 'pt', 'a4', true);
        doc.addFileToVFS('Arial-normal.ttf', constants.FONT);
        doc.addFont('Arial-normal.ttf', 'Arial', 'normal');

        doc.setFont('Arial');
        doc.setFontSize(14);

        const { pageSize } = doc.internal;
        const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
        const pageWidthTable = pageSize.width ? pageSize.width - 80 : pageSize.getWidth() - 80;
        const text = doc.splitTextToSize(currentDisease?.name, pageWidth - 35, {});
        const finalY = text.length * 30;
        doc.text(text, 40, 40);
        doc.setFontSize(12);
        doc.setFontSize(12);

        const rowOne = pdfT('modal row 1');
        const rowTwo = pdfT('modal row 2');
        const rowThree = pdfT('modal row 3');
        const rowFour = pdfT('modal row 4');
        const rowFive = pdfT('modal row 5');
        const rowSix = pdfT('modal row 6');
        
        const body = [
            [rowOne, currentDisease?.causative_agent?.name],
            [rowTwo, currentDisease?.causative_agent?.causes_of_occurrence],
            [rowThree, currentDisease?.pathogen_morphology],
            [rowFour, currentDisease?.biology_spread_pathogen],
            [rowFive, currentDisease?.ecology_of_disease],
            [rowSix, currentDisease?.disease_harmfulness],
        ];

        doc.autoTable({
            startY: finalY,
            styles: {
                fontSize: 11,
                cellWidth: 'wrap'
            },
            columnStyles: {
                0: { font: 'Arial', cellWidth: pageWidthTable / 2, cellPadding: 10 },
                1: { font: 'Arial', cellWidth: pageWidthTable / 2, cellPadding: 10 }
            },
            body
        });
        const fileName = `${currentDisease?.name} ${pdfT('summary')}`;
        doc.save(`${fileName}.pdf`);
    };

    const handleCreateInternal = () => {
        // const seedsNum = form?.getFieldsValue()?.seeds_number;
        dispatch(addInternalContamination({
            calculatorId: calculatorId,
            values: {
                causative_agents: selectedItems?.length > 0 ? selectedItems : form?.getFieldsValue()?.internal_pathogen_ids
            }
        }));

        setTimeout(() => {
            dispatch(updateCalculatorPhyto({
                calcId: calculatorId,
                values: {
                    seeds_number: form?.getFieldsValue()?.seeds_number
                }
            }));

            setTimeout(() => {
                dispatch(getCalculatorPhyto(match?.params?.id));
            }, 500);
        }, 300);
    };

    const handleCalcFinish = () => {
        const formVals = form?.getFieldsValue();
        setActiveTabs(activeTabs?.filter(x => x !== 'calculator_quality'));
        dispatch(updateRegistryPhyto({
            calcId: match?.params?.id,
            values: {
                internal_pathogen_comment: formVals?.internal_pathogen_comment
            }
        }));
        const formPercentKeys = Object.keys(formVals)?.filter(x => x?.startsWith('internal/percentage/'));
        const formPieceKeys = Object.keys(formVals)?.filter(x => x?.startsWith('internal/pcs/'));

        formPercentKeys?.forEach(pathIdString => {
            setTimeout(() => {
                dispatch(updateInternalContamination({
                    pathogenId: pathIdString?.split('/')[2],
                    values: {
                        pathogens_percentage: formVals[pathIdString]
                    }
                }));
            }, 200);
        });

        formPieceKeys?.forEach(pathIdString => {
            setTimeout(() => {
                dispatch(updateInternalContamination({
                    pathogenId: pathIdString?.split('/')[2],
                    values: {
                        pathogens_number: formVals[pathIdString]
                    }
                }));
            }, 200);
        });
    };

    const handleAddDrugs = () => {
        dispatch(updateInternalContamination({
            pathogenId: parseInt(activeDrugTab, 10),
            values: {
                drugs: selectedDrugList
            }
        }));

        dispatch(getProtocolPhyto(match?.params?.id));

        setTimeout(() => setShowDrugModal(false), 300);
    };

    const openDrugSelector = () => {
        setShowDrugModal(true);
    };

    return (
        <>
            <Row className="mt-25">
                <Col span={12}>
                    <Form.Item
                        rules={[{ required: true }]}
                        label={t('internal input label')}
                        name={['seeds_number']}>
                        <InputNumber
                            className="w-100"
                            placeholder={t('internal input placeholder')} />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    <Form.Item
                        label={t('internal select label')}
                        name={['internal_pathogen_ids']}>
                        <Select
                            filterOption={(input, option) => (option?.label ?? '')?.toLowerCase().includes(input?.toLowerCase())}
                            showSearch
                            options={agentsList}
                            allowClear
                            placeholder={t('internal select placeholder')}
                            className="w-100"
                            onChange={setSelectedItems}
                            mode="multiple" />
                    </Form.Item>
                </Col>
            </Row>
            
            <Row>
                <Col span={2}>
                    <Button
                        type="primary"
                        className="mt-25 mb-25"
                        onClick={() => handleCreateInternal()}>
                        {t('button text select')}
                    </Button>
                </Col>
            </Row>

            {pathogens?.length > 0 && (
                <Collapse
                    expandIconPosition="right"
                    style={{ backgroundColor: 'white', marginBottom: '0' }}
                    bordered={false}
                    defaultActiveKey={['external_probability']}
                    className={`${cn('collapse')} remove-padding`}>
                    <Panel
                        header={<b>{t('calculator external collapse header')}</b>}
                        key="external_probability">
                        <div className="pathogens-table">

                            <Row className="pathogens-table__row__header">
                                <Col className="pathogens-table__row__text__header" span={internalPathogenStage > 1 ? 4 : 8}>
                                    {t('internal probability header reason')}
                                </Col>
                                <Col className="pathogens-table__row__text__header" span={internalPathogenStage > 1 ? 5 : 8}>
                                    {t('internal probability header harmful')}
                                </Col>
                                <Col className="pathogens-table__row__text__header" span={internalPathogenStage > 1 ? 5 : 8}>
                                    {t('internal probability header probability')}
                                </Col>
                                {internalPathogenStage > 1 && (
                                    <>
                                        <Col className="pathogens-table__row__text__header" span={5}>
                                            {t('internal probability header amount')}
                                        </Col>
                                        <Col className="pathogens-table__row__text__header" span={5}>
                                            {t('internal probability header percent')}
                                        </Col>
                                    </>
                                )}
                            </Row>

                            {pathogens.map(selectedPathogen => (
                                <>
                                    <Row className="pathogens-table__row">
                                        <Col className="pathogens-table__row__text" span={internalPathogenStage > 1 ? 4 : 8}>
                                            {selectedPathogen?.causative_agent?.name}
                                        </Col>
                                        <Col className="pathogens-table__row__text__highlighted" span={internalPathogenStage > 1 ? 5 : 8}>
                                            <div onClick={() => showDiseaseModal(selectedPathogen?.disease)}>
                                                {selectedPathogen?.disease?.name}
                                            </div>
                                        </Col>
                                        <Col className="pathogens-table__row__text__columned" span={internalPathogenStage > 1 ? 5 : 8}>
                                            {selectedPathogen?.disease?.cultures?.map(x => x?.vegetation_phases?.map(y => y?.name)).join(',')}
                                        </Col>
                                        {internalPathogenStage > 1 && (
                                            <>
                                                <Col span={5}>
                                                    <Form.Item name={`internal/pcs/${selectedPathogen?.id}`}>
                                                        <InputNumber />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={5}>
                                                    <Form.Item name={`internal/percentage/${selectedPathogen?.id}`}>
                                                        <InputNumber />
                                                    </Form.Item>
                                                </Col>
                                            </>
                                        )}
                                    </Row>
                                </>
                            ))}

                            {internalPathogenStage === 3 && (
                                <Row align="middle">
                                    <Col span={14} />
                                    <Col span={5}>
                                        <b>
                                            {t('general percent')}
                                        </b>
                                    </Col>
                                    <Col span={5} style={{ height: '100%' }}>
                                        <div style={{ margin: 'auto' }}>
                                            <b>
                                                <Form.Item
                                                    name={['fito_calculator', 'total_pathogens_percentage']}>
                                                    <Input disabled bordered={false} />
                                                </Form.Item>
                                            </b>
                                        </div>
                                    </Col>
                                </Row>
                            )}
                        </div>
                    </Panel>
                </Collapse>
            )}

            {/* <Row style={{ marginTop: '15px' }}>
                <Form.Item name="internal_pathogen_comment">
                    <Input.TextArea disabled />
                </Form.Item>
            </Row> */}

            {images?.length > 0 && (
                <Table dataSource={images} columns={imagesColumns} />
            )}

            <Row className="mt-25" gutter={12}>
                <Col span={internalPathogenStage !== 3 ? 3 : 5} />
                {internalPathogenStage !== 3 && (
                    <Col span={6}>
                        <Button type="primary" className="w-100" onClick={nextContaminationStep}>{t('external bottom button continue')}</Button>
                    </Col>
                )}
                <Col span={6}>
                    <Button
                        className="w-100"
                        // onClick={openDrugSelector}
                        onClick={() => {}}>{t('external bottom button goto')}</Button>
                </Col>
                <Col span={6}>
                    <Button className="w-100" onClick={handleCalcFinish}>{t('external bottom button finish')}</Button>
                </Col>
                <Col span={internalPathogenStage !== 3 ? 3 : 5} />
            </Row>

            <InfoModal
                inspectableItem={currentDisease}
                downloadPDF={downloadDiseasePDF}
                closeModal={() => setIsModalOpen(false)}
                isOpened={isModalOpen} />
        </>
    );
};

export default InternalContamination;
import {
    Button, Form, Input, Select, Spin, Popconfirm, Table
} from 'antd';
import React, {
    useContext, useEffect, useRef, useState
} from 'react';
import { useTranslation } from 'react-i18next';

const EditableContext = React.createContext(null);

export const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

export const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    selectOptions,
    loadingId,
    ...restProps
}) => {
    const { t } = useTranslation('sampling points');
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);
    const form = useContext(EditableContext);

    useEffect(() => {
        if (editing) {
            inputRef.current.focus();
        }
    }, [editing]);

    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({
            [dataIndex]: record[dataIndex]
        });
    };

    const save = async () => {
        try {
            const values = await form.validateFields();
            toggleEdit();
            handleSave({
                ...record,
                ...values
            });
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    };

    let childNode = children;
    if (loadingId && loadingId === record?.key) {
        childNode = (
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <Spin />
            </div>
        );
    } else if (editable) {
        childNode = editing ? (
            <Form.Item
                style={{
                    margin: 0
                }}
                name={dataIndex}>
                <Select
                    showSearch
                    filterOption={(input, option) =>
            (option?.label ?? '')?.toLowerCase().includes(input?.toLowerCase())}
                    filterSort={(optionA, optionB) => (optionA?.label ?? '')
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? '').toLowerCase())}
                    ref={inputRef}
                    placeholder={t('objects.owner cell placeholder')}
                    options={selectOptions}
                    onChange={save}
                    onBlur={toggleEdit} />
            </Form.Item>
        ) : (
            <div
                className="editable-cell-value-wrap"
                style={{
                    paddingRight: '24px',
                    minHeight: '32px'
                }}
                onClick={toggleEdit}>
                {children}
            </div>
        );
    }
    return <td {...restProps}>{childNode}</td>;
};

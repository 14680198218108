import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Icon from '@ant-design/icons';
import { Layout } from 'antd';
import LoginForm from './components/login-form';
import cn from '../../utils/cn';
import './style.less';

import Logo from '../../../public/images/logo_main.svg';
const { Content } = Layout;

const mstp = state => ({});
const mdtp = ({ });

@withRouter
@connect(mstp, mdtp)
@withTranslation('auth-page')
@cn('auth-page')
export default class Auth extends Component {
    render() {
        const { cn } = this;
        return (
            <Layout key="page-auth" className={cn()}>
                <Content className={cn('content')}>
                    <Icon component={Logo} className={cn('logo')} />
                    <LoginForm />
                </Content>
            </Layout>
        );
    }
}

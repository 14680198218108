import * as constants from '../constants';

const initialState = {};

export default function reducer(state = initialState, action) {
    switch (action.type) {

        case constants.uploadSampleFile.error:
        case constants.uploadSampleFile.success: {
            return action.payload;
        }

        case constants.uploadSampleFile.try: {
            return initialState;
        }

        default:
            return state;
    }
}
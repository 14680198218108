import moment from 'moment';

export const makeRequest = (inputVals) => {
    const collectionDate = inputVals?.seed_collection_date?.format('YYYY-MM-DD');
    const returnValues = {
        name: inputVals?.name,
        year: inputVals?.year,
        weight: inputVals?.weight,
        address: inputVals?.address,
        pickling: inputVals?.pickling,
        seed_collection_date: collectionDate,
        sample_weight: inputVals?.sample_weight,
        germination: inputVals?.germination,
        purity: inputVals?.purity,
        humidity: inputVals?.humidity,
        hundred_seeds_weight: inputVals?.hundred_seeds_weight,
        culture: inputVals?.culture,
        sort: inputVals?.sort,
        reproduction: inputVals?.reproduction,
        region: inputVals?.region,
        others: inputVals?.others,
        protocol_number: inputVals?.protocol_number || 1,
        sample_date: inputVals?.sample_date || collectionDate,
        analysis_date: inputVals?.analysis_date || collectionDate,
        sowing_suitability: inputVals?.sowing_suitability,
        germination_desc: inputVals?.germination_desc,
        purity_desc: inputVals?.purity_desc,
        humidity_desc: inputVals?.humidity_desc,
        germinating_seeds_plan: inputVals?.germinating_seeds_plan,
        calc_seeding_rate: inputVals?.calc_seeding_rate,
        field_germination: inputVals?.field_germination,
        seeding_rate_germination: inputVals?.seeding_rate_germination,
        seed_purpose: inputVals?.seed_purpose
    };

    const isPickling = inputVals?.pickling;
    if (isPickling) {
        returnValues.plant_protection = inputVals?.plant_protection;
    }

    return returnValues;
};

export const makeResponse = (inputVals) => {
    const germPower = {
        calculation_date: inputVals?.germination_power?.calculation_date ? moment(inputVals?.germination_power?.calculation_date) : moment(null),
        germination_to_calculation_days: inputVals?.germination_power?.germination_to_calculation_days,
        mean_percentage: inputVals?.germination_power?.mean_percentage,
    };

    const labGerm = {
        calculation_date: inputVals?.lab_germination?.calculation_date ? moment(inputVals?.lab_germination?.calculation_date) : moment(null),
        germination_to_calculation_days: inputVals?.lab_germination?.germination_to_calculation_days,
        mean_percentage: inputVals?.lab_germination?.mean_percentage,
    };

    const percentagesObj = {};

    inputVals?.lab_germination?.percentages?.forEach((perc, idx) => {
        percentagesObj[`lab_germination/percentage/${idx + 1}`] = perc;
    });

    inputVals?.germination_power?.percentages?.forEach((perc, idx) => {
        percentagesObj[`germination_power/percentage/${idx + 1}`] = perc;
    });

    const returnValues = {
        ...percentagesObj,
        qualityRepetitionsNumber: inputVals?.lab_germination?.percentages?.length || inputVals?.germination_power?.percentages?.length || 1,
        conclusion: inputVals?.conclusion,
        diseases: inputVals?.diseases,
        analysis_date: inputVals?.analysis_date ? moment(inputVals?.analysis_date) : moment(null),
        sample_date: inputVals?.sample_date ? moment(inputVals?.sample_date) : moment(null),
        seed_collection_date: inputVals?.seed_collection_date ? moment(inputVals?.seed_collection_date) : moment(null),
        ent_helm_analysis: inputVals?.ent_helm_analysis,
        external_pathogen_comment: inputVals?.external_pathogen_comment,
        external_pathogen_images: inputVals?.external_pathogen_images,
        fito_calculator: inputVals?.fito_calculator,
        germination_comment: inputVals?.germination_comment,
        germination_power: germPower,
        id: inputVals?.id,
        internal_pathogen_comment: inputVals?.internal_pathogen_comment,
        internal_pathogen_images: inputVals?.internal_pathogen_images,
        lab_germination: labGerm,
        owner: inputVals?.owner,
        protocol_date: inputVals?.protocol_date ? moment(inputVals?.protocol_date) : moment(null),
        result_comment: inputVals?.result_comment,
        seed_images: inputVals?.seed_images,
        seeding_rate_comment: inputVals?.seeding_rate_comment,
        stowing_date: inputVals?.stowing_date ? moment(inputVals?.stowing_date) : moment(null),
        vega_key: inputVals?.vega_key,
        weight_500_1: inputVals?.weight_500_1,
        weight_500_2: inputVals?.weight_500_2,
        weight_1000: inputVals?.weight_1000,
        weight_1000_comment: inputVals?.weight_1000_comment
    };

    returnValues.fito_calculator.internal_pathogen_ids = returnValues.fito_calculator?.internal_pathogens?.map(x => {
        returnValues[`internal/percentage/${x.id}`] = x?.pathogens_percentage;
        returnValues[`internal/pcs/${x.id}`] = x?.pathogens_number;
        return x.id;
    });

    returnValues.fito_calculator.external_pathogen_ids = returnValues.fito_calculator?.external_pathogens?.map(x => {
        returnValues[`external/percentage/${x.id}`] = x?.pathogens_percentage;
        return x.id
    });

    if (returnValues?.germination_power) {
        returnValues.germination_power.calculation_date = returnValues?.germination_power?.calculation_date ? moment(returnValues.germination_power.calculation_date) : moment(null);
    }

    return returnValues;
};
import {
    all, call, put, takeLatest
} from 'redux-saga/effects';
import {
    defaultSaga, getActions
} from '../utils';

import * as constants from './constants';
import * as api from './api';

// function* updateInternalCalcSaga({ payload }) {
//     const [start, success, failure] = getActions(
//         constants.updateInternalCalculator.try,
//         constants.updateInternalCalculator.success,
//         constants.updateInternalCalculator.error
//     );
//     try {
//         yield put(start(payload));
//         const data = yield call(api.updateInternalCalculator, payload);
//         yield put(success(data));
//     } catch (error) {
//         yield put(failure({ error: error }));
//     }
// }
export default function* rootSaga() {
    yield all([
        yield takeLatest(
            constants.getMeteodataStations.toString(),
            defaultSaga({
                constants: constants.getMeteodataStations,
                api: api.getMeteodataStations
            })
        ),
        yield takeLatest(
            constants.getMeteodataRegistry.toString(),
            defaultSaga({
                constants: constants.getMeteodataRegistry,
                api: api.getMeteodataRegistry
            })
        ),
    ]);
}
import request from '../../utils/request';
import options from './options';

const url = options.apiUrl;
const urlPath = path => `${url}${path}`;

export const getDistribution = id => request.get({
    url: urlPath(id),
    id
});

export const getDistributions = () => request.get({
    url
});

export const addDistribution = body => request.post({
    url: urlPath('create_distribution/'),
    body
});

export const updateDistribution = body => request.post({
    url: urlPath(`${body.id}/update_distribution/`),
    body: body.values
});

export const updateFeedingDistribution = body => request.patch({
    url: `${options.feedingApiUrl}${body.id}/`,
    body: body.values
});

import React, { useState, useRef, useEffect, useMemo, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
    Checkbox,
    Col,
    DatePicker,
    Form,
    Input,
    Row, Select,
    Slider,
    Space,
    Spin,
    Table,
    Tooltip
} from 'antd';
import { PlusCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../components';

import '../style.less';
import { createSampleRow, editSampleRow, getUsersList } from '../../../models/sample-log/actions';

import StatusLowCircle from '../../../../public/images/circles/low.svg';
import StatusMediumCircle from '../../../../public/images/circles/medium.svg';
import StatusHighCircle from '../../../../public/images/circles/high.svg';
import { getUser } from '../../../models/account/selectors';
import { getCalculatorsMordoviaCatalog, getCalculatorsMordoviaObject } from '../../../models/mordovia/selectors';
import { getCalculatorsMordovia } from '../../../models/mordovia/actions';
import { usrCreationResult, usrListResponse } from '../../../models/sample-log/selectors';
import { toFixed } from '../../../utils/utils';
import { curMycoFields } from '../../../models/mycological-registry/selectors';

const methodNames = [
    {
        label: 'Ч',
        value: 'chirikov'
    },
    {
        label: 'K',
        value: 'kirsanov'
    },
    {
        label: 'М',
        value: 'machigin'
    }
];

const { RangePicker } = DatePicker;

const zonesSorter = { low: 1, high: 3, middle: 2 };

const SampleTable = ({
    filteredDataSet, setFilteredDataSet,
    tableData, addRow, clickRow,
    selectedRows, selectRows, columnOptions,
    setColumnOptions, activeColumnOptions, setIsLoading,
    isFullScreen, exitFullScreen, setIsNewUserModalOpen,
    setUserList
}) => {
    const { t } = useTranslation('sample log');
    const dispatch = useDispatch();

    const usersNew = useSelector(state => usrListResponse(state));
    const userCreated = useSelector(state => usrCreationResult(state));
    const currentUser = useSelector(state => getUser(state));

    const searchInput = useRef(null);
    const searchInputDate = useRef(null);

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [searchedOwnerName, setSearchedOwnerName] = useState('');

    const [columnsArrayReserved, setColumnsArrayReserved] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(25);
    const [searchedDates, setSearchedDates] = useState([]);
    const [searchedRange, setSearchedRange] = useState([]);
    const [isCheckboxSearch, setIsCheckboxSearch] = useState(false);
    const [searchedOwnerIds, setSearchedOwnerIds] = useState([]);
    const [isDynamicallyColumned, setIsDynamicallyColumned] = useState(false);

    const [newPNum, setNewPNum] = useState('');
    const [newPOwner, setNewPOwner] = useState(null);
    const [newPField, setNewPField] = useState('');
    const [newPFieldName, setNewPFieldName] = useState('');
    const [selectedK, setSelectedK] = useState(null);
    const [selectedA, setSelectedA] = useState(null);
    const [selectedMethod, setSelectedMethod] = useState(null);
    const [selectedPhWater, setSelectedPhWater] = useState(null);
    const [selectedPhSalt, setSelectedPhSalt] = useState(null);
    const [selectedElectricalConductivity, setSelectedElectricalConductivity] = useState(null);
    const [selectedOrganicSubstances, setSelectedOrganicSubstances] = useState(null);
    const [selectedProductivityZone, setSelectedProductivityZone] = useState(null);
    const [selectedProductivityZoneArea, setSelectedProductivityZoneArea] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedKapelDate, setSelectedKapelDate] = useState(null);
    const [selectedFNum, setSelectedFNum] = useState(null);
    const [selectedNH4, setSelectedNH4] = useState(null);
    const [selectedK2O, setSelectedK2O] = useState(null);
    const [selectedNa, setSelectedNa] = useState(null);
    const [selectedMg, setSelectedMg] = useState(null);
    const [selectedCa, setSelectedCa] = useState(null);
    const [selectedCl, setSelectedCl] = useState(null);
    const [selectedSO4, setSelectedSO4] = useState(null);
    const [selectedN, setSelectedN] = useState(null);
    const [selectedPO4, setSelectedPO4] = useState(null);
    const [usersPage, setUsersPage] = useState(1);
    const [fullUsersList, setFullUsersList] = useState([]);
    const [newRecordEntity, setNewRecordEntity] = useState([]);
    const [tableDataState, setTableDataState] = useState([]);
    const [isRange, setIsRange] = useState(false);
    const [usersDictionary, setUsersDictionary] = useState({});

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
        setSearchedOwnerName('');
        setSearchedOwnerIds([]);
    };

    const handleSearch = (
        selectedKeys, confirm, dataIndex
    ) => {
        confirm();
        if (dataIndex === 'date' || dataIndex === 'analysis_date') {
            setSearchedDates(selectedKeys);
        } else if (dataIndex === 'num') {
            setSearchedRange(selectedKeys);
        } else if (dataIndex === 'owner') {
            isCheckboxSearch && setSearchedOwnerIds(selectedKeys);
            !isCheckboxSearch && setSearchedOwnerName(selectedKeys);
        } else {
            setSearchText(selectedKeys[0]);
        }
        setSearchedColumn(dataIndex);
    };

    const handleSave = (record, fieldName, newRow = false) => {
        if (newRow) {
            setValueToNew(fieldName, record?.valueToUpdateNow);
            if (newPField && newPField > 0 && newPOwner && newPOwner > 0 && newPNum && newPNum > 0 && newPFieldName && newPFieldName?.length > 0) {
                dispatch(editSampleRow({
                    sampleRowId: record.id,
                    body: {
                        [fieldName]: record.valueToUpdateNow
                    }
                }));
            }
            fieldName === 'owner' && setNewPOwner(record?.valueToUpdateNow);
            fieldName === 'num' && setNewPNum(record?.valueToUpdateNow);
            fieldName === 'point' && setNewPField(record?.valueToUpdateNow);
            fieldName === 'vega_key' && setNewPFieldName(record?.valueToUpdateNow);
        } else if ((record?.valueToUpdateNow?.length > 0 || record?.valueToUpdateNow || ((fieldName === 'K' || fieldName === 'A') && record?.valueToUpdateNow === 0)) && fieldName !== 'num') {
            const reqBody = {
                sampleRowId: record.id,
                body: {
                    [fieldName]: record.valueToUpdateNow
                }
            };

            if (fieldName === 'NH4_result' && parseFloat(record.valueToUpdateNow) !== record.NH4_result) {
                reqBody.body = {
                    NH4: record.valueToUpdateNow
                };
            }
            if (fieldName === 'K20_result' && parseFloat(record.valueToUpdateNow) !== record.K20_result) {
                reqBody.body = {
                    K20: record.valueToUpdateNow
                };
            }
            if (fieldName === 'Na_result' && parseFloat(record.valueToUpdateNow) !== record.Na_result) {
                reqBody.body = {
                    Na: record.valueToUpdateNow
                };
            }
            if (fieldName === 'Mg_result' && parseFloat(record.valueToUpdateNow) !== record.Mg_result) {
                reqBody.body = {
                    Mg: record.valueToUpdateNow
                };
            }
            if (fieldName === 'Ca_result' && parseFloat(record.valueToUpdateNow) !== record.Ca_result) {
                reqBody.body = {
                    Ca: record.valueToUpdateNow
                };
            }
            if (fieldName === 'Cl_result' && parseFloat(record.valueToUpdateNow) !== record.Cl_result) {
                reqBody.body = {
                    Cl: record.valueToUpdateNow
                };
            }
            if (fieldName === 'SO4_result' && parseFloat(record.valueToUpdateNow) !== record.SO4_result) {
                reqBody.body = {
                    SO4: record.valueToUpdateNow
                };
            }
            if (fieldName === 'N_result' && parseFloat(record.valueToUpdateNow) !== record.N_result) {
                reqBody.body = {
                    N: record.valueToUpdateNow
                };
            }
            if (fieldName === 'PO4_result' && parseFloat(record.valueToUpdateNow) !== record.PO4_result) {
                reqBody.body = {
                    PO4: record.valueToUpdateNow
                };
            }
            if (fieldName === 'owner') {
                setNewPOwner(record?.valueToUpdateNow);
                reqBody.body = {
                    [fieldName]: record.valueToUpdateNow,
                    vega_key: null
                };
            }

            if (fieldName === 'productivity_zone' && record?.valueToUpdateNow === 'undefined') {
                reqBody.body = {
                    [fieldName]: null
                };
            }

            dispatch(editSampleRow(reqBody));
        }
    };

    const CheckboxOptionsList = ({ setSelectedKeys, ...props }) => {
        return (
            <div className="filter-options-list">
                <Checkbox.Group
                    {...props}
                    options={fullUsersList?.map(usr => {
                        return {
                            label: usr.name,
                            value: usr.id
                        };
                    })}
                    onChange={(evt) => {
                        setSearchedOwnerName('');
                        setIsCheckboxSearch(true);
                        setSelectedKeys(evt?.length > 0 ? evt : []);
                    }} />
            </div>
        );
    };

    const getColumnSearchProps = (dataIndex, optionsData = []) => ({
        filterDropdown: ({
            setSelectedKeys, selectedKeys, confirm, clearFilters
        }) => {
            if (dataIndex === 'date' || dataIndex === 'analysis_date') {
                return (
                    <div
                        style={{
                            padding: 8,
                            display: 'flex',
                            flexDirection: 'column',
                            position: 'relative',
                            zIndex: '999999999999999999999999999999999'
                        }}
                        onKeyDown={(e) => e.stopPropagation()}>
                        <RangePicker
                            defaultValue={[0, 100]}
                            value={selectedKeys}
                            onChange={(values) => {
                                setSearchedDates(values);
                                setTimeout(() => setSelectedKeys(values ? values : []), 200);
                            }}
                            ref={searchInputDate} />
                        <Space style={{ marginTop: '8px' }}>
                            <Button
                                type="primary"
                                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                                icon={<SearchOutlined />}
                                size="small"
                                style={{ width: 90 }}>
                                {t('search dropdown button search')}
                            </Button>
                            <Button
                                onClick={() => {
                                    clearFilters()
                                    handleReset(clearFilters)
                                }}
                                size="small"
                                style={{ width: 90 }}>
                                {t('search dropdown button reset')}
                            </Button>
                        </Space>
                    </div>
                );
            }

            if (dataIndex === 'owner') {
                return (
                    <div
                        style={{
                            padding: 8,
                            position: 'relative',
                            zIndex: '999999999999999999999999999999999'
                        }}
                        onKeyDown={(e) => e.stopPropagation()}>
                        <Input
                            ref={searchInput}
                            placeholder={`Search ${dataIndex}`}
                            value={!selectedKeys[1] ? selectedKeys[0] : ''}
                            onChange={(e) => {
                                setSearchedOwnerIds([]);
                                setIsCheckboxSearch(false);
                                setSelectedKeys(e.target.value ? [e.target.value] : []);
                            }}
                            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            style={{ marginBottom: 8, display: 'block' }} />
                        <CheckboxOptionsList setSelectedKeys={setSelectedKeys} />
                        <Space>
                            <Button
                                type="primary"
                                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                                icon={<SearchOutlined />}
                                size="small"
                                style={{ width: 90 }}>
                                {t('search dropdown button search')}
                            </Button>
                            <Button
                                onClick={() => {
                                    clearFilters()
                                    handleReset(clearFilters)
                                }}
                                size="small"
                                style={{ width: 90 }}>
                                {t('search dropdown button reset')}
                            </Button>
                        </Space>
                    </div>
                );
            }

            return (
                <div style={{ padding: 8, position: 'relative', zIndex: '999999999999999999999999999999999' }} onKeyDown={(e) => e.stopPropagation()}>
                    <Input
                        ref={searchInput}
                        placeholder={`Search ${dataIndex}`}
                        value={selectedKeys[0]}
                        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{ marginBottom: 8, display: 'block' }} />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}>
                            {t('search dropdown button search')}
                        </Button>
                        <Button
                            onClick={() => {
                                clearFilters()
                                handleReset(clearFilters)
                            }}
                            size="small"
                            style={{ width: 90 }}>
                            {t('search dropdown button reset')}
                        </Button>
                    </Space>
                </div>
            );
        },
        filterIcon: (filtered) => (
            <SearchOutlined style={{ color: filtered ? '#97D1AF' : undefined }} />
        ),
        onFilter: (value, record) => {
            return record[dataIndex] ? record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()) : false;
        },
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
                setTimeout(() => searchInputDate.current?.select(), 100);
            }
        }
    });

    const [columnsArray, setColumnsArray] = useState([
        {
            title: t('table header sample num'),
            dataIndex: 'num',
            key: 'num',
            textWrap: 'word-break',
            width: 75,
            ellipsis: true,
            onCell: (record) => ({
                dataIndex: 'num',
                inputType: 'text',
                record,
                editable: !record?.id,
                handleSave: (record) => handleSave(record, 'num', !record?.id)
            }),
            render: (val, record) => {
                return {
                    props: {
                        style: record?.deleted ? {
                            background: '#dcdcdc'
                        } : {
                            color: 'rgba(3, 181, 117, 1)',
                            background: 'rgba(31, 205, 117, 0.10)'
                        }
                    },
                    children: (<div onClick={() => clickRow(record?.id)}>{tooltipedValue(val)}</div>)
                };
            },
            sorter: (a, b) => a.num - b.num,
            ...getColumnSearchProps('num', searchedDates)
        },
        {
            title: t('table header owner'),
            width: 140,
            ellipsis: true,
            dataIndex: 'owner',
            key: 'owner',
            onCell: (record) => ({
                inputType: 'select',
                dataIndex: 'owner',
                selectOptions: [],
                record,
                editable: true,
                handleSave: (record) => handleSave(record, 'owner', !record?.id)
            }),
            ...getColumnSearchProps('owner', searchedDates),
            render: (val) => {
                return tooltipedValue(fullUsersList?.filter(x => parseInt(x?.id, 10) === parseInt(val, 10))[0]?.name || val);
            }
        },
        {
            title: t('table header field name'),
            width: 150,
            ellipsis: true,
            dataIndex: 'vega_key',
            key: 'vega_key',
            onCell: (record) => ({
                // inputType: 'text',
                inputType: 'select',
                dataIndex: 'vega_key',
                selectOptions: [],
                record,
                editable: true,
                handleSave: (record) => handleSave(record, 'vega_key', !record?.id)
            }),
            render: (val) => tooltipedValue(val),
            ...getColumnSearchProps('vega_key', searchedDates)
        },
        {
            title: t('table header field num'),
            width: 75,
            ellipsis: true,
            dataIndex: 'field_num',
            key: 'field_num',
            onCell: (record) => ({
                inputType: 'text',
                dataIndex: 'field_num',
                record,
                editable: true,
                handleSave: (record) => handleSave(record, 'field_num', !record?.id)
            }),
            render: (val) => tooltipedValue(val),
            ...getColumnSearchProps('field_num', searchedDates)
        },
        {
            title: t('table header point num'),
            width: 100,
            ellipsis: true,
            dataIndex: 'point',
            key: 'point',
            onCell: (record) => ({
                dataIndex: 'point',
                inputType: 'text',
                record,
                editable: true,
                handleSave: (record) => handleSave(record, 'point', !record?.id)
            }),
            render: (val) => tooltipedValue(val),
            ...getColumnSearchProps('point', searchedDates)
        },
        {
            title: t('table header status'),
            width: 100,
            ellipsis: true,
            dataIndex: 'public',
            key: 'public',
            render: (val) => val ? (
                <div className="status-message__public">
                    {t('status text public')}
                </div>
            ) : (
                <div className="status-message__notpublic">
                    {t('status text not public')}
                </div>
            )
        },
        {
            title: t('table header sample date'),
            width: 125,
            ellipsis: true,
            dataIndex: 'date',
            key: 'date',
            onCell: (record) => ({
                dataIndex: 'date',
                inputType: 'text',
                record,
                editable: true,
                handleSave: (record) => handleSave(record, 'date', !record?.id)
            }),
            render: (val) => tooltipedValue(val, true),
            ...getColumnSearchProps('date', searchedDates),
            sorter: (a, b) => moment(a.date) - moment(b.date)
        },
        {
            title: t('table header analysis date'),
            width: 125,
            ellipsis: true,
            dataIndex: 'analysis_date',
            key: 'analysis_date',
            onCell: (record) => ({
                dataIndex: 'analysis_date',
                inputType: 'text',
                record,
                editable: true,
                handleSave: (record) => handleSave(record, 'analysis_date', !record?.id)
            }),
            render: (val) => tooltipedValue(val, true),
            ...getColumnSearchProps('analysis_date', searchedDates),
            sorter: (a, b) => moment(a.analysis_date) - moment(b.analysis_date)
        },
        {
            title: t('table header K'),
            width: 75,
            ellipsis: true,
            dataIndex: 'K',
            key: 'K',
            onCell: (record) => ({
                dataIndex: 'K',
                inputType: 'select',
                selectOptions: [
                    {
                        label: 0,
                        value: 0
                    },
                    {
                        label: 1,
                        value: 1
                    }],
                record,
                editable: true,
                handleSave: (record) => handleSave(record, 'K', !record?.id)
            }),
            filters: [
                {
                    text: 0,
                    value: 0
                },
                {
                    text: 1,
                    value: 1
                }
            ],
            onFilter: (value, record) => {
                return record?.K === value;
            }
        },
        {
            title: t('table header A'),
            width: 75,
            ellipsis: true,
            dataIndex: 'A',
            key: 'A',
            onCell: (record) => ({
                dataIndex: 'A',
                inputType: 'select',
                selectOptions: [
                    {
                        label: 0,
                        value: 0
                    },
                    {
                        label: 1,
                        value: 1
                    }],
                record,
                editable: true,
                handleSave: (record) => handleSave(record, 'A', !record?.id)
            }),
            filters: [
                {
                    text: 0,
                    value: 0
                },
                {
                    text: 1,
                    value: 1
                }
            ],
            onFilter: (value, record) => {
                return record?.A === value;
            }
        },
        {
            title: t('table header method'),
            width: 75,
            ellipsis: true,
            dataIndex: 'method',
            key: 'method',
            onCell: (record) => ({
                dataIndex: 'method',
                inputType: 'select',
                selectOptions: [
                    {
                        label: 'Ч',
                        value: 'chirikov'
                    },
                    {
                        label: 'К',
                        value: 'kirsanov'
                    },
                    {
                        label: 'М',
                        value: 'machigin'
                    }],
                record,
                editable: true,
                handleSave: (record) => handleSave(record, 'method', !record?.id)
            }),
            filters: [
                {
                    text: 'Ч',
                    value: 'chirikov'
                },
                {
                    text: 'К',
                    value: 'kirsanov'
                },
                {
                    text: 'М',
                    value: 'machigin'
                }
            ],
            onFilter: (value, record) => {
                return record?.method === value;
            },
            render: (val) => methodNames?.filter(methodName => methodName.value === val)[0]?.label
        },
        {
            title: t('table header ph_water'),
            width: 100,
            ellipsis: true,
            onCell: (record) => ({
                dataIndex: 'ph_water',
                inputType: 'text',
                record,
                editable: true,
                handleSave: (record) => handleSave(record, 'ph_water', !record?.id)
            }),
            dataIndex: 'ph_water',
            key: 'ph_water',
            render: (val) => tooltipedValue(val)
        },
        {
            title: t('table header ph_salt'),
            width: 100,
            ellipsis: true,
            onCell: (record) => ({
                dataIndex: 'ph_salt',
                inputType: 'text',
                record,
                editable: true,
                handleSave: (record) => handleSave(record, 'ph_salt', !record?.id)
            }),
            dataIndex: 'ph_salt',
            key: 'ph_salt',
            render: (val) => tooltipedValue(val)
        },
        {
            title: t('table header E'),
            width: 75,
            ellipsis: true,
            onCell: (record) => ({
                dataIndex: 'electrical_conductivity',
                inputType: 'text',
                record,
                editable: true,
                handleSave: (record) => handleSave(record, 'electrical_conductivity', !record?.id)
            }),
            dataIndex: 'electrical_conductivity',
            key: 'electrical_conductivity',
            render: (val) => tooltipedValue(val)
        },
        {
            title: t('table header organic_substances'),
            width: 75,
            ellipsis: true,
            onCell: (record) => ({
                dataIndex: 'organic_substances',
                inputType: 'text',
                record,
                editable: true,
                handleSave: (record) => handleSave(record, 'organic_substances', !record?.id)
            }),
            dataIndex: 'organic_substances',
            key: 'organic_substances',
            render: (val) => tooltipedValue(val)
        },
        {
            title: t('table header productivity zone'),
            width: 100,
            ellipsis: true,
            onCell: (record) => ({
                dataIndex: 'productivity_zone',
                inputType: 'select',
                selectOptions: [
                    {
                        label: t('productivity zone low'),
                        value: 'low'
                    },
                    {
                        label: t('productivity zone middle'),
                        value: 'middle'
                    },
                    {
                        label: t('productivity zone high'),
                        value: 'high'
                    },
                    {
                        label: t('productivity zone not defined'),
                        value: 'undefined'
                    }],
                record,
                editable: true,
                handleSave: (record) => handleSave(record, 'productivity_zone', !record?.id)
            }),
            dataIndex: 'productivity_zone',
            key: 'productivity_zone',
            render: (val, record) => {
                let childrenVal;
                if (val) {
                    childrenVal = (
                        <>
                            <div className="productivity_indicator">
                                {val === 'low' && (
                                    <div className={`productivity_indicator__${val}`}>
                                        <StatusLowCircle style={{ width: '6px', height: '6px' }} />
                                    </div>
                                )}
                                {val === 'middle' && (
                                    <div className={`productivity_indicator__${val}`}>
                                        <StatusMediumCircle style={{ width: '6px', height: '6px' }} />
                                    </div>
                                )}
                                {val === 'high' && (
                                    <div className={`productivity_indicator__${val}`}>
                                        <StatusHighCircle style={{ width: '6px', height: '6px' }} />
                                    </div>
                                )}
                                <div>
                                    { t(`productivity zone ${val}`) }
                                </div>
                            </div>
                        </>
                    );
                } else {
                    childrenVal = (
                        <></>
                    );
                }

                return {
                    props: {
                        style: record?.deleted ? {} : { background: 'rgba(31, 205, 117, 0.15)' }
                    },
                    children: childrenVal
                };
            },
            sorter: (a, b) => zonesSorter[a.productivity_zone] - zonesSorter[b.productivity_zone]
        },
        {
            title: t('table header productivity zone area'),
            width: 100,
            ellipsis: true,
            onCell: (record) => ({
                dataIndex: 'zone_area',
                inputType: 'text',
                record,
                editable: true,
                handleSave: (record) => handleSave(record, 'zone_area', !record?.id)
            }),
            dataIndex: 'zone_area',
            key: 'zone_area',
            render: (val, record) => {
                return {
                    props: {
                        style: record?.deleted ? {} : { background: 'rgba(31, 205, 117, 0.15)' }
                    },
                    children: tooltipedValue(val)
                };
            },
            sorter: (a, b) => a.zone_area - b.zone_area
        },
        {
            title: () => (
                <Col>
                    <Row>
                        {t('table header NH4')}
                    </Row>
                    <Row>
                        мг/кг
                    </Row>
                </Col>
            ),
            // title: t('table header NH4'),
            width: 75,
            ellipsis: true,
            dataIndex: 'NH4_result',
            key: 'NH4',
            className: "filed-column",
            onCell: (record) => ({
                dataIndex: 'NH4_result',
                inputType: 'text',
                record,
                editable: true,
                handleSave: (record) => handleSave(record, 'NH4_result', !record?.id)
            }),
            render: (val) => tooltipedValue(val)
        },
        {
            title: () => (
                <Col>
                    <Row>
                        {t('table header K20')}
                    </Row>
                    <Row>
                        мг/кг
                    </Row>
                </Col>
            ),
            // title: t('table header K20'),
            width: 75,
            ellipsis: true,
            dataIndex: 'K20_result',
            key: 'K20',
            className: "filed-column",
            onCell: (record) => ({
                dataIndex: 'K20_result',
                inputType: 'text',
                record,
                editable: true,
                handleSave: (record) => handleSave(record, 'K20_result', !record?.id)
            }),
            render: (val) => tooltipedValue(val)
        },
        {
            title: () => (
                <Col>
                    <Row>
                        {t('table header Na')}
                    </Row>
                    <Row>
                        ммоль/кг
                    </Row>
                </Col>
            ),
            // title: t('table header Na'),
            width: 75,
            ellipsis: true,
            dataIndex: 'Na_result',
            key: 'Na',
            className: "filed-column",
            onCell: (record) => ({
                dataIndex: 'Na_result',
                inputType: 'text',
                record,
                editable: true,
                handleSave: (record) => handleSave(record, 'Na_result', !record?.id)
            }),
            render: (val) => tooltipedValue(val)
        },
        {
            title: () => (
                <Col>
                    <Row>
                        {t('table header Mg')}
                    </Row>
                    <Row>
                        мг-экв/кг
                    </Row>
                </Col>
            ),
            // title: t('table header Mg'),
            width: 75,
            ellipsis: true,
            dataIndex: 'Mg_result',
            key: 'Mg',
            className: "filed-column",
            onCell: (record) => ({
                dataIndex: 'Mg_result',
                inputType: 'text',
                record,
                editable: true,
                handleSave: (record) => handleSave(record, 'Mg_result', !record?.id)
            }),
            render: (val) => tooltipedValue(val)
        },
        {
            title: () => (
                <Col>
                    <Row>
                        {t('table header Ca')}
                    </Row>
                    <Row>
                        мг-экв/кг
                    </Row>
                </Col>
            ),
            // title: t('table header Ca'),
            width: 75,
            ellipsis: true,
            dataIndex: 'Ca_result',
            key: 'Ca',
            className: "filed-column",
            onCell: (record) => ({
                dataIndex: 'Ca_result',
                inputType: 'text',
                record,
                editable: true,
                handleSave: (record) => handleSave(record, 'Ca_result', !record?.id)
            }),
            render: (val) => tooltipedValue(val)
        },
        {
            title: () => (
                <Col>
                    <Row>
                        {t('table header Cl')}
                    </Row>
                    <Row>
                        мг/кг
                    </Row>
                </Col>
            ),
            // title: t('table header Cl'),
            width: 75,
            ellipsis: true,
            dataIndex: 'Cl_result',
            key: 'Cl',
            className: "filed-column",
            onCell: (record) => ({
                dataIndex: 'Cl_result',
                inputType: 'text',
                record,
                editable: true,
                handleSave: (record) => handleSave(record, 'Cl_result', !record?.id)
            }),
            render: (val) => tooltipedValue(val)
        },
        {
            title: () => (
                <Col>
                    <Row>
                        {t('table header SO4')}
                    </Row>
                    <Row>
                        мг/кг
                    </Row>
                </Col>
            ),
            // title: t('table header SO4'),
            width: 75,
            ellipsis: true,
            dataIndex: 'SO4_result',
            key: 'SO4',
            className: "filed-column",
            onCell: (record) => ({
                dataIndex: 'SO4_result',
                inputType: 'text',
                record,
                editable: true,
                handleSave: (record) => handleSave(record, 'SO4_result', !record?.id)
            }),
            render: (val) => tooltipedValue(val)
        },
        {
            title: () => (
                <Col>
                    <Row>
                        {t('table header S')}
                    </Row>
                    <Row>
                        мг/кг
                    </Row>
                </Col>
            ),
            // title: t('table header S'),
            width: 80,
            ellipsis: true,
            // dataIndex: 'S_result',
            dataIndex: 'S',
            key: 'S',
            className: "filed-column",
            render: (val) => tooltipedValue(toFixed(val, 3))
        },
        {
            title: () => (
                <Col>
                    <Row>
                        {t('table header N')}
                    </Row>
                    <Row>
                        мг/кг
                    </Row>
                </Col>
            ),
            // title: t('table header N'),
            width: 75,
            ellipsis: true,
            dataIndex: 'N_result',
            key: 'N',
            className: "filed-column",
            onCell: (record) => ({
                dataIndex: 'N_result',
                inputType: 'text',
                record,
                editable: true,
                handleSave: (record) => handleSave(record, 'N_result', !record?.id)
            }),
            render: (val) => tooltipedValue(val)
        },
        {
            title: () => (
                <Col>
                    <Row>
                        {t('table header NO3')}
                    </Row>
                    <Row>
                        мг/кг
                    </Row>
                </Col>
            ),
            // title: t('table header NO3'),
            width: 80,
            ellipsis: true,
            // dataIndex: 'NO3_result',
            dataIndex: 'NO3',
            key: 'NO3',
            className: "filed-column",
            render: (val) => tooltipedValue(toFixed(val, 3))
        },
        {
            title: () => (
                <Col>
                    <Row>
                        {t('table header PO4')}
                    </Row>
                    <Row>
                        мг/кг
                    </Row>
                </Col>
            ),
            // title: t('table header PO4'),
            width: 75,
            ellipsis: true,
            dataIndex: 'PO4_result',
            key: 'PO4',
            className: "filed-column",
            onCell: (record) => ({
                dataIndex: 'PO4_result',
                inputType: 'text',
                record,
                editable: true,
                handleSave: (record) => handleSave(record, 'PO4_result', !record?.id)
            }),
            render: (val) => tooltipedValue(val)
        },
        {
            title: () => (
                <Col>
                    <Row>
                        {t('table header P2O5')}
                    </Row>
                    <Row>
                        мг/кг
                    </Row>
                </Col>
            ),
            // title: `${t('table header P2O5')} \n мг/кг`,
            width: 80,
            ellipsis: true,
            // dataIndex: 'P2O5_result',
            dataIndex: 'P2O5',
            key: 'P2O5',
            className: "filed-column",
            render: (val) => tooltipedValue(toFixed(val, 3))
        },
        {
            title: t('table header comment'),
            width: 100,
            ellipsis: true,
            onCell: (record) => ({
                dataIndex: 'comment',
                inputType: 'text',
                record,
                editable: true,
                handleSave: (record) => handleSave(record, 'comment', !record?.id)
            }),
            dataIndex: 'comment',
            key: 'comment',
            render: (val) => tooltipedValue(val)
        }
    ]);

    const EditableContext = React.createContext(null);

    const EditableRow = ({ index, ...props }) => {
        const [form] = Form.useForm();
        return (
            <Form form={form} component={false}>
                <EditableContext.Provider value={form}>
                    <tr {...props} />
                </EditableContext.Provider>
            </Form>
        );
    };

    const handleVegaSelect = (val1, val2) => {
        console.log('handleVegaSelect', val1, val2);
    }

    const EditableCell = ({
        title,
        editable,
        children,
        dataIndex,
        record,
        handleSave,
        inputType,
        selectOptions,
        ...restProps
    }) => {
        let childNode = children;

        const isHighlightedCell = record?.type === 'new record' && (dataIndex === 'num' || dataIndex === 'owner' || dataIndex === 'vega_key' || dataIndex === 'point');

        const [fieldNameMapping, setFieldNameMapping] = useState({});
        const [fieldedUser, setFieldedUser] = useState(null);
        const [fieldOptns, setFieldOptns] = useState([]);
        const [fieldOptionPage, setFieldOptionPage] = useState(1);
        const [inputWidth, setInputWidth] = useState(50);
        const [editing, setEditing] = useState(false);
        const inputRef = useRef(null);
        const form = useContext(EditableContext);
        const [reserveOptions, setReserveOptions] = useState([]);
        const [nextString, setNextString] = useState(null);
        const [additionalFields, setAdditionalFields] = useState([]);
        const [fieldsLoading, setFieldsLoading] = useState(false);

        const usrFields = useSelector(state => getCalculatorsMordoviaObject(state));

        useEffect(() => {
            if (fieldOptns?.length > 0 && additionalFields?.length > 0) {
                const varCpy = [...fieldOptns];
                additionalFields?.forEach(addFld => {
                    const srcRes = fieldOptns?.filter(x => x.label === addFld?.label);

                    if (srcRes?.length < 1) {
                        varCpy.push(addFld);
                    }
                });
                setAdditionalFields([]);
                setFieldOptns(varCpy);
            }
        }, [fieldOptns, additionalFields]);
        
        useEffect(() => {
            if (fieldOptns?.length > 0 && nextString?.length > 0) {
                setFieldOptionPage(fieldOptionPage + 1);
            }
        }, [fieldOptns, nextString]);
        
        useEffect(() => {
            if (fieldOptionPage > 1) {
                const ownerId = record?.owner;

                if (ownerId === fieldedUser) {
                    const urlArr = [`"owner_user":${ownerId}`];
                    dispatch(getCalculatorsMordovia(`?filter={${urlArr.join(',')}}&page=${fieldOptionPage}`));
                }

                // setFieldedUser(ownerId);
            }
        }, [fieldOptionPage]);

        useEffect(() => {
            const ownerId = record?.owner;

            if (ownerId === fieldedUser) {
                const { results, next } = usrFields;

                setNextString(next);

                if (next?.length < 1 || !next) {
                    setFieldsLoading(false);
                }

                if (results?.length > 0) {
                    const fieldsCopy = fieldOptns;

                    results?.forEach(fieldEntity => {
                        const resObj = {
                            label: fieldEntity?.vega_key,
                            value: fieldEntity?.vega_key
                        };

                        const srcRes = fieldOptns?.filter(x => x?.value === resObj?.value);

                        if (srcRes?.length < 1) {
                            fieldsCopy.push(resObj);
                        }
                    });

                    if (fieldOptionPage === 1) {
                        setFieldOptns(fieldsCopy);
                    } else {
                        setAdditionalFields(fieldsCopy);
                    }
                }
            }
        }, [usrFields]);

        useEffect(() => {
            if (editing) {
                inputRef?.current?.focus();
            }
        }, [editing]);

        const getFilteredFieldsList = (userId, pageNum) => {
            const urlArr = [`"owner_user":${userId}`];
            dispatch(getCalculatorsMordovia(`?filter={${urlArr.join(',')}}&page=${pageNum}`));
        }

        const toggleEdit = (isOpening = false) => {
            const ownerId = record?.owner;

            if (dataIndex === 'vega_key' && ownerId && ownerId > 0) {
                if (ownerId !== fieldedUser) {
                    setFieldsLoading(true);
                    getFilteredFieldsList(ownerId, 1);
                }

                setFieldedUser(ownerId);
            }

            if (dataIndex === 'vega_key') {
                if (!editing) {
                    setFieldOptionPage(1);
                } else {
                    setFieldOptionPage(0);
                }
            }

            if (!editing && dataIndex === 'owner') {
                setEditing(!editing);
                setReserveOptions(fullUsersList?.map(usr => {
                    return {
                        label: usr?.name,
                        value: usr?.id
                    };
                }));
                form.setFieldsValue({
                    [dataIndex]: fullUsersList?.filter(x => x.id === record[dataIndex]) ? fullUsersList?.filter(x => x.id === record[dataIndex])[0]?.name : ''
                });
            } else {
                setEditing(!editing);
                if (!((dataIndex === 'date' || dataIndex === 'analysis_date') && isOpening)) {
                    form.setFieldsValue({
                        [dataIndex]: record[dataIndex]
                    });
                }
            }
        };

        const save = (newVals, isDate = false) => {
            const dateSplit = isDate ? newVals?.split('.') : [];
            const numberSplit = (typeof newVals === 'string' && !isNaN(newVals?.replace(/,/g, '.'))) ? newVals?.split(',') : [];
            toggleEdit();
            const recordToSave = { ...record };
            // recordToSave.valueToUpdateNow = isDate ? `${dateSplit[2]}-${dateSplit[1]}-${dateSplit[0]}` : newVals;
            recordToSave.valueToUpdateNow = isDate ? `${dateSplit[2]}-${dateSplit[1]}-${dateSplit[0]}` : numberSplit?.length > 1 ? newVals.replace(/,/g, '.') : newVals;
            handleSave(recordToSave);
        };

        const handleInputChange = (e) => {
            if (e?.target?.value?.length === 0) {
                setInputWidth(50);
            }
            if (inputWidth <= 100) {
                setInputWidth(75 + e?.target?.value?.length * 5);
            }
        };

        if (editable) {
            childNode = editing ? (
                <Form.Item
                    style={{
                        margin: 0
                    }}
                    name={dataIndex}
                    rules={[
                        {
                            required: true,
                            message: `${title} is required.`
                        }
                    ]}>
                    {inputType === 'text' ? (
                        <Input
                            onChange={handleInputChange}
                            style={{ width: inputWidth }}
                            ref={inputRef}
                            onPressEnter={(evt) => save(evt.target.value, (dataIndex === 'date' || dataIndex === 'analysis_date'))}
                            onBlur={(evt) => save(evt.target.value, (dataIndex === 'date' || dataIndex === 'analysis_date'))} />
                    ) : dataIndex === 'vega_key' ? (
                        <Select
                            onChange={(evt) => handleVegaSelect(evt, fieldOptns)}
                            showSearch
                            filterOption={(input, option) => (option?.label ?? '')?.toLowerCase().includes(input?.toLowerCase())}
                            dropdownStyle={{
                                position: 'relative',
                                zIndex: '999999999999999999999'
                            }}
                            options={fieldOptns?.length > 0 ? [...fieldOptns] : [...selectOptions]}
                            ref={inputRef}
                            onBlur={toggleEdit}
                            onSelect={(val) => save(val)} />
                    ) : dataIndex === 'owner' ? (
                        <Select
                            showSearch
                            filterOption={(input, option) => (option?.label ?? '')?.toLowerCase().includes(input?.toLowerCase())}
                            dropdownStyle={{
                                position: 'relative',
                                zIndex: '999999999999999999999'
                            }}
                            options={[...selectOptions]}
                            ref={inputRef}
                            onBlur={toggleEdit}
                            onSelect={(val) => save(val)} />
                    ) : (
                        <Select
                            dropdownStyle={{
                                position: 'relative',
                                zIndex: '999999999999999999999'
                            }}
                            options={selectOptions?.length > 0 ? [...selectOptions] : [...reserveOptions]}
                            ref={inputRef}
                            onBlur={toggleEdit}
                            onSelect={(val) => save(val)} />
                    )}
                </Form.Item>
            ) : (
                <div
                    style={{
                        cursor: 'pointer',
                        padding: '16px 16px'
                    }}
                    onClick={() => {
                        if (dataIndex === 'date' || dataIndex === 'analysis_date') {
                            toggleEdit(true);
                        } else {
                            toggleEdit();
                        }
                    }}>
                    <div
                        className={isHighlightedCell ? 'highlighted-cell' : 'editable-cell-value-wrap'}
                        style={{
                            paddingRight: 24
                        }}>
                        {children}
                    </div>
                </div>
            );
        }

        return (
            <td {...restProps} style={editable ? { padding: '0' } : {}}>
                {childNode}
                {/* {dataIndex === 'vega_key' ? fieldsLoading ? (<Spin />) : childNode  : childNode} */}
            </td>
        );
    };

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell
        }
    };

    const handleRowSelectionChange = (ids) => {
        const deletedIds = tableData?.filter(x => x.deleted === true)?.map(y => y.id);
        selectRows(ids.filter((rowId) => !deletedIds.includes(rowId)));
    };

    const getCheckProp = (record) => {
        return {
            disabled: record?.deleted
        };
    };

    const getSelectedRows = () => [...selectedRows];

    const tooltipedValue = (val, isDate = false) => {
        let returnValue;
        if (val === null) {
            returnValue = (<div>{ val }</div>);
        }

        let answerRes = val;

        if (isDate) {
            const dateStr = val?.split('T')[0];
            const dateArray = dateStr ? dateStr.split('-') : [];
            answerRes = dateArray?.length > 0 ? `${dateArray[2]}.${dateArray[1]}.${dateArray[0]}` : val;
        }

        returnValue = (
            <Tooltip title={answerRes}>
                <span>{ answerRes }</span>
            </Tooltip>
        );
        return [returnValue];
    };

    useEffect(() => {
        if (userCreated?.id > 0 && userCreated?.name?.length > 0) {
            setFullUsersList([...fullUsersList, userCreated]);
        }
    }, [userCreated]);

    useEffect(() => {
        const reqBody = {
            page: usersPage
        };
        
        dispatch(getUsersList(reqBody));
    }, [usersPage]);

    useEffect(() => {
        const newUsersPortion = usersNew?.results;
        if (newUsersPortion?.length > 0) {
            setFullUsersList([...fullUsersList, ...newUsersPortion]);
        }
        const isNext = usersNew?.next?.length > 0;
        if (isNext) {
            setUsersPage(usersPage + 1);
        }
    }, [usersNew]);

    const rowSelection = useMemo(() => {
        const undeletedRows = tableData?.filter(x => x.deleted !== true);
        if (tableData?.length <= 0 || undeletedRows?.length <= 0) {
            return {
                getCheckboxProps: getCheckProp,
                getSelectedRows,
                hideSelectAll: true,
                onChange: handleRowSelectionChange
            };
        }

        return {
            getCheckboxProps: getCheckProp,
            getSelectedRows,
            onChange: handleRowSelectionChange
        };
    }, [tableData]);

    useEffect(() => {
        if (activeColumnOptions?.length > 0) {
            setIsDynamicallyColumned(true);
            if (activeColumnOptions?.length && columnsArray?.length && activeColumnOptions?.length < columnsArray?.length) {
                setColumnsArray(columnsArray.filter(x => activeColumnOptions.filter(y => y.value === x.key)?.length > 0));
            } else if (isDynamicallyColumned) {
                setColumnsArray(columnsArrayReserved.filter(x => activeColumnOptions.filter(y => y.value === x.key)?.length > 0));
            }
        }
    }, []);
    
    useEffect(() => {
        if (activeColumnOptions?.length > 0) {
            setIsDynamicallyColumned(true);
            if (activeColumnOptions?.length && columnsArray?.length && activeColumnOptions?.length < columnsArray?.length) {
                setColumnsArray(columnsArray.filter(x => activeColumnOptions.filter(y => y.value === x.key)?.length > 0));
            } else if (isDynamicallyColumned) {
                setColumnsArray(columnsArrayReserved.filter(x => activeColumnOptions.filter(y => y.value === x.key)?.length > 0));
            }
        }
    }, [activeColumnOptions]);

    useEffect(() => {
        searchedDates?.length > 1 && setColumnsArray([...columnsArray].map(x => {
            if (x.key === 'date' || x.key === 'analysis_date') {
                const dataIndex = x.key;
                x.onFilter = (value, record) => {
                    const currentMoment = moment(record[dataIndex]);
                    return searchedDates[1] >= currentMoment && searchedDates[0] <= currentMoment;
                };
            }
            return x;
        }));
    }, [searchedDates]);

    useEffect(() => {
        searchedRange?.length > 1 && setColumnsArray([...columnsArray].map(x => {
            if (x.key === 'field_num' || x.key === 'point' || x.key === 'num') {
                const dataIndex = x.key;
                x.onFilter = (value, record) => {
                    const entryNum = parseInt(record[dataIndex], 10);
                    if (isRange) {
                        return entryNum >= parseInt(searchedRange[0], 10) && entryNum <= parseInt(searchedRange[1], 10);
                    }
                    return record[dataIndex]?.toString().toLowerCase() === (value.toLowerCase ? value.toLowerCase() : value[0]);
                };
            }

            return x;
        }));
    }, [searchedRange]);

    useEffect(() => {
        tableData?.length > 0 && setColumnsArray([...columnsArray].map(x => {
            if (x.key === 'field_num' || x.key === 'point' || x.key === 'num') {
                const dataIndex = x.key;
                const minVal = Math.min(
                    ...tableData.map((tableEntry) => parseInt(tableEntry[dataIndex], 10))
                );
                const maxVal = Math.max(
                    ...tableData.map((tableEntry) => parseInt(tableEntry[dataIndex], 10))
                );

                x.onFilter = (value, record) => {
                    const entryNum = parseInt(record[dataIndex], 10);
                    if (isRange) {
                        return entryNum >= parseInt(searchedRange[0], 10) && entryNum <= parseInt(searchedRange[1], 10);
                    }

                    return record[dataIndex]?.toString().toLowerCase() === (value.toLowerCase ? value.toLowerCase() : value[0]);
                };
                x.filterDropdown = ({
                    setSelectedKeys, selectedKeys, confirm, clearFilters
                }) => {
                    return (
                        <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                            {selectedKeys[0] && selectedKeys[1] && (
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                                    {!selectedKeys[1] ? selectedKeys[0] : `${selectedKeys[0]}${selectedKeys[1] && (' - ' + selectedKeys[1])}`}
                                </div>
                            )}
                            {!selectedKeys[1] && (
                                <Input
                                    ref={searchInput}
                                    placeholder={t('search word')}
                                    value={selectedKeys[0]}
                                    onChange={(e) => setSelectedKeys(
                                        e.target.value ? [e.target.value] : []
                                    )}
                                    onClick={() => {
                                        setSelectedKeys(['']);
                                        setIsRange(false);
                                    }}
                                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                                    style={{ marginBottom: 8, display: 'block' }} />
                            )}
                            <Slider
                                onChange={(rangeValues) => {
                                    setIsRange(true);
                                    setSearchedRange(rangeValues);
                                    setSelectedKeys(rangeValues);
                                }}
                                onAfterChange={() => {
                                    handleSearch(selectedKeys, confirm, dataIndex, true);
                                }}
                                range
                                min={minVal}
                                max={maxVal}
                                defaultValue={[minVal, maxVal]}
                                disabled={
                                    dataIndex !== 'num' && dataIndex !== 'point' && dataIndex !== 'field_num'
                                } />
                            <Space>
                                <Button
                                    type="primary"
                                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                                    icon={<SearchOutlined />}
                                    size="small"
                                    style={{ width: 90 }}>
                                    {t('search dropdown button search')}
                                </Button>
                                <Button
                                    onClick={() => clearFilters && handleReset(clearFilters)}
                                    size="small"
                                    style={{ width: 90 }}>
                                    {t('search dropdown button reset')}
                                </Button>
                            </Space>
                        </div>
                    );
                };
            }

            return x;
        }));
    }, [tableData]);

    useEffect(() => {
        setUserList && setUserList(fullUsersList);
        const userOptions = fullUsersList.map(usr => {
            return {
                label: usr.name,
                value: usr.id
            };
        });
        fullUsersList?.length > 0 && fullUsersList.map(user => {
            const userDictCopy = usersDictionary;
            userDictCopy[user.id] = user.name;
            setUsersDictionary(userDictCopy);
        }) && setColumnsArray([...columnsArray].map(x => {
            if (x.key === 'owner') {
                x = {
                    ...x,
                    ...getColumnSearchProps('owner', fullUsersList.map(usr => {
                        return {
                            label: usr.name,
                            value: usr.id
                        };
                    }))
                };
            }
            if (x.key === 'owner') {
                x.onCell = (record) => ({
                    inputType: 'select',
                    dataIndex: 'owner',
                    selectOptions: [...userOptions],
                    record,
                    editable: true,
                    handleSave: (record) => handleSave(record, 'owner', !record?.id)
                });
                x.render = (val) => tooltipedValue(fullUsersList?.filter(z => parseInt(z?.id, 10) === parseInt(val, 10))[0]?.name || val);
                x.onFilter = (value, record) => {
                    if (isCheckboxSearch === true) {
                        return searchedOwnerIds.includes(value);
                    } else {
                        return usersDictionary[record.owner]?.toLowerCase().includes(value?.toLowerCase());
                    }
                };
            }
            return x;
        }));
    }, [fullUsersList]);

    useEffect(() => {
        setColumnsArray([...columnsArray].map(x => {
            if (x.key === 'owner') {
                if (isCheckboxSearch === true) {
                    const uIdValues = [];
                    x.onFilter = (value, record) => {
                        !uIdValues.includes(value) && uIdValues.push(value);
                        return uIdValues.includes(record.owner);
                    };
                } else {
                    x.onFilter = (value, record) => {
                        return usersDictionary[record.owner]?.toLowerCase().includes(value?.toLowerCase());
                    };
                }
            }
            return x;
        }));
    }, [isCheckboxSearch, searchedOwnerIds]);

    const setValueToNew = (dataIndex, newValue) => {
        if (dataIndex === 'K') {
            setSelectedK(newValue);
        }
        if (dataIndex === 'A') {
            setSelectedA(newValue);
        }
        if (dataIndex === 'method') {
            setSelectedMethod(newValue);
        }
        if (dataIndex === 'ph_water') {
            setSelectedPhWater(newValue);
        }
        if (dataIndex === 'ph_salt') {
            setSelectedPhSalt(newValue);
        }
        if (dataIndex === 'electrical_conductivity') {
            setSelectedElectricalConductivity(newValue);
        }
        if (dataIndex === 'organic_substances') {
            setSelectedOrganicSubstances(newValue);
        }
        if (dataIndex === 'productivity_zone') {
            setSelectedProductivityZone(newValue);
        }
        if (dataIndex === 'zone_area') {
            setSelectedProductivityZoneArea(newValue);
        }
        if (dataIndex === 'date') {
            setSelectedDate(newValue);
        }
        if (dataIndex === 'analysis_date') {
            setSelectedKapelDate(newValue);
        }
        if (dataIndex === 'field_num') {
            setSelectedFNum(newValue);
        }
        if (dataIndex === 'NH4') {
            setSelectedNH4(newValue);
        }
        if (dataIndex === 'K20') {
            setSelectedK2O(newValue);
        }
        if (dataIndex === 'Na') {
            setSelectedNa(newValue);
        }
        if (dataIndex === 'Mg') {
            setSelectedMg(newValue);
        }
        if (dataIndex === 'Ca') {
            setSelectedCa(newValue);
        }
        if (dataIndex === 'Cl') {
            setSelectedCl(newValue);
        }
        if (dataIndex === 'SO4') {
            setSelectedSO4(newValue);
        }
        if (dataIndex === 'N') {
            setSelectedN(newValue);
        }
        if (dataIndex === 'PO4') {
            setSelectedPO4(newValue);
        }
    };

    useEffect(() => {
        if (
            selectedNH4 || selectedK2O || selectedNa || selectedMg || selectedCa || selectedCl || selectedSO4 || selectedN || selectedPO4 || selectedA || selectedK || selectedMethod || selectedPhSalt || selectedPhWater || selectedElectricalConductivity || selectedOrganicSubstances || selectedProductivityZone || selectedProductivityZoneArea || selectedDate || selectedKapelDate || selectedFNum
        ) {
            setNewRecordEntity([{
                type: 'new record',
                num: newPNum,
                owner: newPOwner,
                point: newPField,
                vega_key: newPFieldName,
                zone_area: selectedProductivityZoneArea,
                productivity_zone: selectedProductivityZone,
                organic_substances: selectedOrganicSubstances,
                electrical_conductivity: selectedElectricalConductivity,
                ph_salt: selectedPhSalt,
                ph_water: selectedPhWater,
                method: selectedMethod,
                A: selectedA,
                K: selectedK,
                date: selectedDate,
                analysis_date: selectedKapelDate,
                field_num: selectedFNum,
                //
                NH4: selectedNH4,
                K20: selectedK2O,
                Na: selectedNa,
                Mg: selectedMg,
                Ca: selectedCa,
                Cl: selectedCl,
                SO4: selectedSO4,
                N: selectedN,
                PO4: selectedPO4
            }]);
        }
    }, [
        selectedA, selectedK, selectedMethod, selectedPhSalt, selectedPhWater,
        selectedElectricalConductivity, selectedOrganicSubstances,
        selectedProductivityZone, selectedProductivityZoneArea,
        selectedDate, selectedKapelDate, selectedFNum,
        selectedNH4, selectedK2O, selectedNa, selectedMg, selectedCa,
        selectedCl, selectedSO4, selectedN, selectedPO4
    ]);

    useEffect(() => {
        if (newPOwner > 0 || newPField?.length > 0  || newPNum?.length > 0 || newPFieldName?.length > 0) {
            setNewRecordEntity([{
                type: 'new record',
                //
                NH4_result: selectedNH4,
                K20_result: selectedK2O,
                Na_result: selectedNa,
                Mg_result: selectedMg,
                Ca_result: selectedCa,
                Cl_result: selectedCl,
                SO4_result: selectedSO4,
                N_result: selectedN,
                PO4_result: selectedPO4,
                //
                num: newPNum,
                owner: newPOwner,
                point: newPField,
                vega_key: newPFieldName,
                zone_area: selectedProductivityZoneArea,
                productivity_zone: selectedProductivityZone,
                organic_substances: selectedOrganicSubstances,
                electrical_conductivity: selectedElectricalConductivity,
                ph_salt: selectedPhSalt,
                ph_water: selectedPhWater,
                method: selectedMethod,
                A: selectedA,
                K: selectedK,
                date: moment(selectedDate ? selectedDate : null).format('YYYY-MM-DD').split('T')[0],
                analysis_date: moment(selectedKapelDate ? selectedKapelDate : null).format('YYYY-MM-DD').split('T')[0],
                field_num: selectedFNum
            }]);
        }

        if (newRecordEntity && newRecordEntity[0] && newRecordEntity?.length > 0 && Object.keys(newRecordEntity[0])?.length > 0 && newPField?.length > 0 && newPOwner > 0 && newPNum?.length > 0 && newPFieldName?.length > 0) {
            dispatch(createSampleRow({
                num: newPNum,
                owner: parseInt(newPOwner, 10),
                point: newPField,
                vega_key: newPFieldName,
                zone_area: selectedProductivityZoneArea,
                productivity_zone: selectedProductivityZone,
                organic_substances: selectedOrganicSubstances,
                electrical_conductivity: selectedElectricalConductivity,
                ph_salt: selectedPhSalt,
                ph_water: selectedPhWater,
                method: selectedMethod,
                A: selectedA,
                K: selectedK,
                date: selectedDate,
                analysis_date: selectedKapelDate,
                field_num: selectedFNum,
                //
                NH4: selectedNH4,
                K20: selectedK2O,
                Na: selectedNa,
                Mg: selectedMg,
                Ca: selectedCa,
                Cl: selectedCl,
                SO4: selectedSO4,
                N: selectedN,
                PO4: selectedPO4,
                //
                from_file: false
            }));
            setNewRecordEntity([]);
            setNewPOwner('');
            setNewPField('');
            setNewPNum('');
            setNewPFieldName('');
            setSelectedK(null);
            setSelectedA(null);
            setSelectedMethod(null);
            setSelectedPhWater(null);
            setSelectedPhSalt(null);
            setSelectedElectricalConductivity(null);
            setSelectedOrganicSubstances(null);
            setSelectedProductivityZone(null);
            setSelectedProductivityZoneArea(null);
            setSelectedDate(null);
            setSelectedKapelDate(null);
            setSelectedFNum(null);
            //
            setSelectedNH4(null);
            setSelectedK2O(null);
            setSelectedNa(null);
            setSelectedMg(null);
            setSelectedCa(null);
            setSelectedCl(null);
            setSelectedSO4(null);
            setSelectedN(null);
            setSelectedPO4(null);
            //
        }
    }, [newPNum, newPOwner, newPField, newPFieldName]);

    useEffect(() => {
        columnsArray?.length >= columnsArrayReserved?.length && setColumnsArrayReserved(columnsArray);
        if (!columnOptions || !columnOptions?.length || (columnsArray?.length > columnOptions?.length)) {
            !isDynamicallyColumned && setColumnOptions(columnsArray?.map(x => {
                return {
                    label: x.title,
                    value: x.key
                };
            }));
        }
    }, [columnsArray]);

    useEffect(() => {
        setTableDataState([...tableData, ...newRecordEntity]);
    }, [tableData]);

    useEffect(() => {
        setTableDataState([...tableData, ...newRecordEntity]);
    }, [newRecordEntity]);

    const addNewRecord = () => {
        setCurrentPage(Math.ceil((tableDataState?.length + 1) / pageSize));
        setNewRecordEntity([{
            type: 'new record',
            key: 'test123'
        }]);
    };

    const addNewOwner = () => {
        if (isFullScreen) {
            exitFullScreen();
        }
        setIsNewUserModalOpen(true);
    };

    const onPaginationChange = (page, pageSize) => {
        setCurrentPage(page);
        setPageSize(pageSize);
    };

    return (
        <>
            <Table
                onChange={(pagination, filters, sorter, extra) => {
                    setFilteredDataSet(extra.currentDataSource);
                }} 
                bordered
                rowKey="id"
                rowSelection={rowSelection}
                columns={columnsArray}
                style={{ whiteSpace: 'pre'}}
                // dataSource={tableDataState?.filter(x => !x.deleted)}
                dataSource={tableDataState}
                components={components}
                pagination={{
                    onChange: onPaginationChange,
                    current: currentPage,
                    pageSize: pageSize,
                    defaultPageSize: pageSize,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '25', '50', '100']
                }}
                rowClassName={record => {
                    return (!record?.from_file || !Object.keys(record).includes('from_file')) ? 'editable-row': 'editable-row disabled-row'
                }}
                scroll={{ y: 550, x: 'max-content' }}
                footer={() => (
                    <Row>
                        <Col span={3}>
                            <Button
                                onClick={addNewRecord}
                                type="secondary"
                                className="buttons-row__bottom__button">
                                <PlusCircleOutlined />
                                {t('add row')}
                            </Button>
                        </Col>
                        <Col span={1} />
                        <Col style={{ height: '100%' }} span={4}>
                            <Button
                                style={{ height: '100%' }}
                                onClick={addNewOwner}
                                type="secondary"
                                className="buttons-row__bottom__button">
                                <PlusCircleOutlined />
                                {t('add owner user')}
                            </Button>
                        </Col>
                    </Row>
                )} />
        </>
    );
};

export default SampleTable;
import * as constants from '../constants';

const initialState = {};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case constants.calculatePestProbability.success: {
            return action.payload;
        }

        default:
            return state;
    }
}

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { useClassName } from 'utils/cn';

import {
    Button, Col, Collapse, DatePicker, Form, Input, InputNumber, Row, Select
} from 'antd';
import {
    curCalculatorPhyto,
    currentCulturesPhyto,
    currentFertilizersPhyto, currentPurposesPhyto, currentRegionsPhyto,
    currentReproductionsPhyto,
    currentVarietiesPhyto, newCalculatorPhyto
} from '../../../models/calculators-phyto/selectors';

import '../style.less';
import {
    createCalculatorPhyto,
    getCulturesPhyto,
    getFertilizersPhyto, getPurposesPhyto, getRegionsPhyto, getReproductionsPhyto,
    getVarietiesPhyto, updateCalculatorPhyto
} from '../../../models/calculators-phyto/actions';
import { makeRequest } from './utils';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { getUser } from '../../../models/account/selectors';

const { Panel } = Collapse;

const CalculatorData = ({
    setCultures, setSorts, form, buttonDisabled, seedsMass, fertilizersFullList
}) => {
    const cn = useClassName('all-calculators');
    const { t } = useTranslation('phyto calculator');

    const dispatch = useDispatch();
    const history = useHistory();
    const match = useRouteMatch();

    const regionsList = useSelector(state => currentRegionsPhyto(state));
    const purposesList = useSelector(state => currentPurposesPhyto(state));
    const culturesList = useSelector(state => currentCulturesPhyto(state));
    const varietiesList = useSelector(state => currentVarietiesPhyto(state));
    const reproductionsList = useSelector(state => currentReproductionsPhyto(state));

    const newCalculatorCreated = useSelector(state => curCalculatorPhyto(state));
    const currentUser = useSelector(state => getUser(state));

    useEffect(() => {
        if (newCalculatorCreated?.culture?.id > 0) {
            handleCultureChange(newCalculatorCreated?.culture?.id);
        }
        if (newCalculatorCreated?.id > 0) {
            setTimeout(() => history.push(`/calculator-phyto/${newCalculatorCreated?.id}`), 1500);
        }
    }, [newCalculatorCreated]);

    const [picklingAgentDisabled, setPicklingAgentDisabled] = useState(true);
    const [selectedCulture, setSelectedCulture] = useState([]);
    const [regionsFullList, setRegionsFullList] = useState([]);
    const [purposesFullList, setPurposesFullList] = useState([]);
    const [culturesFullList, setCulturesFullList] = useState([]);
    const [varietiesFullList, setVarietiesFullList] = useState([]);
    const [reproductionsFullList, setReproductionsFullList] = useState([]);
    const [regionsPage, setRegionsPage] = useState(1);
    const [purposesPage, setPurposesPage] = useState(1);
    const [reproductionsPage, setReproductionsPage] = useState(1);
    const [varietiesPage, setVarietiesPage] = useState(1);
    const [culturesPage, setCulturesPage] = useState(1);
    const [fertilizersPage, setFertilizersPage] = useState(1);

    const [allFieldsDisabled, setAllFieldsDisabled] = useState(false);

    useEffect(() => {
        varietiesFullList?.length > 0 && setSorts([...varietiesFullList]);
    }, [varietiesFullList]);
    useEffect(() => {
        culturesFullList?.length > 0 && setCultures([...culturesFullList]);
    }, [culturesFullList]);

    useEffect(() => {
        regionsPage && regionsPage > 0 && dispatch(getRegionsPhyto({ page: regionsPage }));
    }, [regionsPage]);
    useEffect(() => {
        const dataPortion = regionsList?.results?.length > 0 ? regionsList?.results : [];
        dataPortion?.length > 0 && setRegionsFullList([...regionsFullList, ...dataPortion]);
        regionsList?.next?.length > 0 && setRegionsPage(regionsPage + 1);
    }, [regionsList]);

    useEffect(() => {
        purposesPage && purposesPage > 0 && dispatch(getPurposesPhyto({ page: purposesPage }));
    }, [purposesPage]);
    useEffect(() => {
        const dataPortion = purposesList?.results?.length > 0 ? purposesList?.results : [];
        dataPortion?.length > 0 && setPurposesFullList([...purposesFullList, ...dataPortion]);
        purposesList?.next?.length > 0 && setPurposesPage(purposesPage + 1);
    }, [purposesList]);

    useEffect(() => {
        culturesPage && culturesPage > 0 && dispatch(getCulturesPhyto({ page: culturesPage }));
    }, [culturesPage]);
    useEffect(() => {
        const dataPortion = culturesList?.results?.length > 0 ? culturesList?.results : [];
        dataPortion?.length > 0 && setCulturesFullList([...culturesFullList, ...dataPortion]);
        culturesList?.next?.length > 0 && setCulturesPage(culturesPage + 1);
    }, [culturesList]);

    useEffect(() => {
        varietiesPage && varietiesPage > 0 && dispatch(getVarietiesPhyto({ page: varietiesPage, selectedCulture: (selectedCulture && selectedCulture > 0) ? [selectedCulture] : null }));
    }, [varietiesPage]);
    useEffect(() => {
        const dataPortion = varietiesList?.results?.length > 0 ? varietiesList?.results : [];
        if (dataPortion?.length > 0) {
            const addableVarieties = [];
            dataPortion?.forEach((varietyData) => {
                const founderEl = varietiesFullList?.filter(x => x?.id === varietyData?.id);
                if (!founderEl[0]) {
                    addableVarieties.push(varietyData);
                }
            });
            setVarietiesFullList(addableVarieties);
        }
        varietiesList?.next?.length > 0 && setVarietiesPage(varietiesPage + 1);
    }, [varietiesList]);

    useEffect(() => {
        reproductionsPage && reproductionsPage > 0 && dispatch(getReproductionsPhyto({ page: reproductionsPage }));
    }, [reproductionsPage]);
    useEffect(() => {
        const dataPortion = reproductionsList?.results?.length > 0 ? reproductionsList?.results : [];
        dataPortion?.length > 0 && setReproductionsFullList([...reproductionsFullList, ...dataPortion]);
        reproductionsList?.next?.length > 0 && setReproductionsPage(reproductionsPage + 1);
    }, [reproductionsList]);

    const handleContinue = () => {
        const formValues = form.getFieldsValue();
        const bodyVals = makeRequest(formValues);
        match?.params?.id ? dispatch(updateCalculatorPhyto({
            calcId: match?.params?.id,
            values: bodyVals
        })) : dispatch(createCalculatorPhyto(bodyVals));
        setAllFieldsDisabled(true);
    };

    const getYearOptions = () => {
        const years = [];
        const yearDifference = 7;
        const currentYear = new Date().getFullYear();
        let lastYear = currentYear - yearDifference;

        while (lastYear <= currentYear) {
            years.push(lastYear);
            lastYear++;
        }

        return years.map(yearNum => {
            return {
                label: yearNum,
                value: yearNum
            };
        }).reverse();
    };

    const handleCultureChange = (newVal) => {
        setSelectedCulture(newVal);
        setVarietiesFullList([]);
        dispatch(getVarietiesPhyto({
            page: varietiesPage,
            culturesList: [newVal]
        }));
        setVarietiesPage(1);
    };

    return (
        <>
            <Collapse
                style={{ backgroundColor: 'white' }}
                bordered={false}
                defaultActiveKey={['1', '2']}
                expandIconPosition="right"
                className={cn('collapse')}>
                <Panel
                    showArrow={false}
                    disabled
                    header={<b>{t('calculator data collapse header 1')}</b>}
                    key="1">
                    <Row className="mt-25">
                        <Col span={11}>
                            <Row>
                                <Col span={12}>
                                    <Form.Item
                                        label={t('form label agriculture_id')}
                                        name="culture"
                                        rules={[{ required: true }]}>
                                        <Select
                                            onChange={handleCultureChange}
                                            showSearch
                                            filterOption={(input, option) => option?.props?.label?.toLowerCase().indexOf(input?.toLowerCase()) >= 0}
                                            disabled={allFieldsDisabled}
                                            options={culturesFullList?.length > 0 ? culturesFullList.map(itm => {
                                                return {
                                                    label: itm?.name,
                                                    value: itm?.id
                                                };
                                            }).sort((a,b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0)) : []}
                                            placeholder={t('form placeholder agriculture_id')}
                                            className="w-100" />
                                    </Form.Item>
                                </Col>
                                <Col span={1} />
                                <Col span={11}>
                                    <Form.Item
                                        label={t('form label reproduction_id')}
                                        name="reproduction"
                                        rules={[{ required: true }]}>
                                        <Select
                                            disabled={allFieldsDisabled}
                                            options={reproductionsFullList?.length > 0 ? reproductionsFullList?.map(itm => {
                                                return {
                                                    label: itm?.name,
                                                    value: itm?.id
                                                };
                                            }) : []}
                                            placeholder={t('form placeholder reproduction_id')}
                                            className="w-100" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        label={t('form label variety')}
                                        name="sort">
                                        <Select
                                            showSearch
                                            filterOption={(input, option) => option?.props?.label?.toLowerCase().indexOf(input?.toLowerCase()) >= 0}
                                            disabled={allFieldsDisabled}
                                            placeholder={t('form placeholder variety')}
                                            className="w-100"
                                            options={varietiesFullList?.length > 0 ? varietiesFullList.map(itm => {
                                                return {
                                                    label: itm?.sort_name,
                                                    value: itm?.id
                                                };
                                            }).sort((a,b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0)) : []} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={12}>
                                    <Form.Item
                                        label={t('form label year')}
                                        name="year"
                                        rules={[{ required: true }]}>
                                        <Select
                                            disabled={allFieldsDisabled}
                                            options={getYearOptions()}
                                            placeholder={t('form placeholder year')}
                                            className="w-100" />
                                    </Form.Item>
                                </Col>
                                <Col span={1} />
                                <Col span={11}>
                                    <Form.Item
                                        label={t('form label lot_mass')}
                                        name="weight">
                                        <InputNumber
                                            disabled={allFieldsDisabled}
                                            placeholder={t('form placeholder lot_mass')}
                                            className="w-100" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        label={t('form label purpose_id')}
                                        name="seed_purpose">
                                        <Select
                                            disabled={allFieldsDisabled}
                                            options={purposesFullList?.length > 0 ? purposesFullList.map(itm => {
                                                return {
                                                    label: itm?.name,
                                                    value: itm?.id
                                                };
                                            }) : []}
                                            placeholder={t('form placeholder purpose_id')}
                                            className="w-100" />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={1} />
                        <Col span={12}>
                            <Row>
                                <Col span={11}>
                                    <Form.Item
                                        label={t('form label zone_id')}
                                        name="region"
                                        rules={[{ required: true }]}>
                                        <Select
                                            showSearch
                                            filterOption={(input, option) => option?.props?.label?.toLowerCase().indexOf(input?.toLowerCase()) >= 0}
                                            disabled={allFieldsDisabled}
                                            options={regionsFullList?.length > 0 ? regionsFullList.map(itm => {
                                                return {
                                                    label: itm?.name,
                                                    value: itm?.id
                                                };
                                            }) : []}
                                            placeholder={t('form placeholder zone_id')}
                                            className="w-100" />
                                    </Form.Item>
                                </Col>
                                <Col span={1} />
                                <Col span={12}>
                                    <Form.Item
                                        label={t('form label pickling')}
                                        name="pickling">
                                        <Select
                                            disabled={allFieldsDisabled}
                                            options={[
                                                {
                                                    label: t('select option yes'),
                                                    value: true
                                                },
                                                {
                                                    label: t('select option no'),
                                                    value: false
                                                }
                                            ]}
                                            onChange={(newVal) => setPicklingAgentDisabled(!newVal)}
                                            placeholder={t('form placeholder pickling')}
                                            className="w-100" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        label={t('form label address')}
                                        name="address"
                                        rules={[{ required: true }]}>
                                        <Input.TextArea
                                            disabled={allFieldsDisabled}
                                            placeholder={t('form placeholder address')}
                                            className="w-100" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        label={t('form label plant_protection')}
                                        name="plant_protection">
                                        <Select
                                            options={fertilizersFullList.map(x => {
                                                return {
                                                    label: x?.name || '',
                                                    value: x?.id
                                                };
                                            })}
                                            disabled={picklingAgentDisabled || allFieldsDisabled}
                                            placeholder={t('form placeholder plant_protection')}
                                            className="w-100" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={11}>
                                    <Form.Item
                                        label={t('form label date')}
                                        name="seed_collection_date"
                                        rules={[{ required: true }]}>
                                        <DatePicker
                                            format="DD.MM.YYYY"
                                            disabled={allFieldsDisabled}
                                            placeholder={t('form placeholder date')}
                                            className="w-100" />
                                    </Form.Item>
                                </Col>
                                <Col span={1} />
                                <Col span={12}>
                                    <Form.Item
                                        label={t('form label sample_weight')}
                                        name="sample_weight">
                                        <InputNumber
                                            disabled={allFieldsDisabled}
                                            placeholder={t('form placeholder sample_weight')}
                                            className="w-100" />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        label={t('laborant data misc label')}
                                        name="others">
                                        <Input.TextArea
                                            disabled={allFieldsDisabled}
                                            placeholder={t('laborant data misc placeholder')}
                                            className="w-100" />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Panel>

                <Panel
                    showArrow={false}
                    disabled
                    header={<b>{t('calculator data collapse header 2')}</b>}
                    key="2">
                    <Row className="mt-25">
                        <Col span={11}>
                            <Row>
                                <Col span={12}>
                                    <Form.Item
                                        label={t('form label germination')}
                                        name="germination"
                                        rules={[{ required: true }]}>
                                        <InputNumber
                                            decimalSeparator={','}
                                            parser={value => value.replace(/,/g, '.')}
                                            placeholder={t('form placeholder germination')}
                                            className="w-100" />
                                    </Form.Item>
                                </Col>
                                <Col span={1} />
                                <Col span={11}>
                                    <Form.Item
                                        label={t('form label purity')}
                                        name="purity"
                                        rules={[{ required: true }]}>
                                        <InputNumber
                                            decimalSeparator={','}
                                            parser={value => value.replace(/,/g, '.')}
                                            placeholder={t('form placeholder purity')}
                                            className="w-100" />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={1} />
                        <Col span={12}>
                            <Row>
                                <Col span={11}>
                                    <Form.Item
                                        label={t('form label humidity')}
                                        name="humidity">
                                        <InputNumber
                                            decimalSeparator={','}
                                            parser={value => value.replace(/,/g, '.')}
                                            placeholder={t('form placeholder humidity')}
                                            className="w-100" />
                                    </Form.Item>
                                </Col>
                                <Col span={1} />
                                <Col span={12}>
                                    <Form.Item
                                        label={t('form label weight_1000_seeds')}
                                        name="hundred_seeds_weight"
                                        rules={[{ required: true }]}>
                                        <InputNumber
                                            decimalSeparator={','}
                                            parser={value => value.replace(/,/g, '.')}
                                            placeholder={t('form placeholder weight_1000_seeds')}
                                            className="w-100" />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Panel>
            </Collapse>

            <Row>
                <Col span={24}>
                    <div className="notification-text-container">
                        {t('notification text')}
                    </div>
                </Col>
            </Row>

            <Row>
                <Col span={10} />
                <Col span={2}>
                    {allFieldsDisabled ? (
                        <Button
                            onClick={() => setAllFieldsDisabled(false)}
                            className="mt-25"
                            type="secondary">
                            {t('edit button')}
                        </Button>
                    ) : (
                        <Button
                            disabled={buttonDisabled}
                            type="primary"
                            className="mt-25"
                            onClick={handleContinue}>
                            {t('save calculation data')}
                        </Button>
                    )
                    // (
                    //     <Button
                    //         // disabled={buttonDisabled}
                    //         type="primary"
                    //         className="mt-25"
                    //         onClick={handleContinue}>
                    //         {t('continue calculation')}
                    //     </Button>
                    // )
                    }
                </Col>
                <Col span={10} />
            </Row>
        </>
    );
};

export default CalculatorData;
import React from 'react';
import Private from '../../containers/private-route';
import Page from './component';

const Routes = [
    <Private
        exact
        key="ReportRegistry"
        path="/report-registry"
        component={Page} />
];

export default Routes;
import React, {
    useEffect, useState, useMemo
} from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {
    Button, Modal, Row, Col, Form, Input, Select, DatePicker, InputNumber, Spin
} from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import '../../style.less';
import { useDispatch, useSelector } from 'react-redux';
import { getCalculatorMordovia } from '../../../../models/mordovia/actions';
import {
    getCalculatorsMordoviaCatalog,
    getCurrentCalculatorMordovia,
    isFieldMordoviaLoading
} from '../../../../models/mordovia/selectors';
import { toFixed } from '../../../../utils/utils';
import {
    getAgrotechnicalEventsList,
    getVegetationPhases,
    updateAgrotechnicalEvents
} from '../../../../models/agrotechnical-events/actions';
import PlantProtections from './plant-protections/component';
import { curAgroEventsList } from '../../../../models/agrotechnical-events/selectors';
import { getUser } from '../../../../models/account/selectors';
import { DATEPICKER_FORMAT } from '../../../../constants';

const AccountingModal = ({
    isOpened, setIsOpened,
    vegPhases, editableEvent, setEditableEvent, fixedField
}) => {
    const dispatch = useDispatch();
    const { t } = useTranslation('accounting documents');
    const [form] = Form.useForm();

    const [confirmLoading, setConfirmLoading] = useState(false);
    const [formValues, setFormValues] = useState({});
    const [year, setYear] = useState(0);
    const [dateString, setDateString] = useState(undefined);
    const [manualYear, setManualYear] = useState(0);

    const currentUser = useSelector(state => getUser(state));
    const information = useSelector(state => getCurrentCalculatorMordovia(state));
    const fields = useSelector(state => getCalculatorsMordoviaCatalog(state));
    const isLoading = useSelector(state => isFieldMordoviaLoading(state));
    const agroEventOptions = useSelector(state => curAgroEventsList(state));

    const [listPageSize, setListPageSize] = useState(15);
    const [listPage, setListPage] = useState(1);
    const [isAppendableList, setIsAppendableList] = useState(false);
    const [listOptions, setListOptions] = useState([]);

    const [selectedField, setSelectedField] = useState([]);
    const [selectedCulture, setSelectedCulture] = useState(undefined);
    const [selectedVegPhase, setSelectedVegPhase] = useState(undefined);
    const [selectedDate, setSelectedDate] = useState(undefined);
    const [selectedAgroEvent, setSelectedAgroEvent] = useState(undefined);

    const [fieldNames, setFieldNames] = useState([]);
    const [fieldCultures, setFieldCultures] = useState([]);
    const [fieldsDisabled, setFieldsDisabled] = useState(true);
    const [fieldPhases, setFieldPhases] = useState([]);
    const [formError, setFormError] = useState(false);
    const [selectAllFields, setSelectAllFields] = useState(false);
    const [disableFieldArea, setDisableFieldArea] = useState(false);

    const [allowProtection, setAllowProtection] = useState(false);
    const [protectionMode, setProtectionMode] = useState(undefined);
    const [protectionList, setProtectionList] = useState([]);

    useEffect(() => {
        dispatch(getAgrotechnicalEventsList({
            page: listPage,
            pageSize: listPageSize
        }));
    }, [listPage]);

    useEffect(() => {
        const resultsArray = agroEventOptions?.results;

        if (isAppendableList) {
            resultsArray?.length > 0 && setListOptions([...listOptions, ...resultsArray.map(x => {
                return {
                    label: x.name,
                    value: x.name
                };
            })]);
        } else {
            resultsArray?.length > 0 && setListOptions([...resultsArray.map(x => {
                return {
                    label: x.name,
                    value: x.name
                };
            })]);
        }

        if (agroEventOptions?.next?.length > 0) {
            setIsAppendableList(true);
            setListPage(listPage + 1);
        }
    }, [agroEventOptions]);

    const clearModal = () => {
        setSelectedField([]);
        setSelectedCulture(undefined);
        setSelectedVegPhase(undefined);
        setSelectedDate(undefined);
        setSelectedAgroEvent(undefined);
        setProtectionMode(undefined);
        setProtectionList([]);
        setFormValues({});
        form.resetFields();
        setFormError(false);
        setAllowProtection(false);
        setSelectAllFields(false);
        setEditableEvent(undefined);
        setDisableFieldArea(false);
    };

    useEffect(() => {
        if (editableEvent?.id) {
            setSelectedField([editableEvent.vega_key]);
            setSelectedCulture(editableEvent?.culture);
            setSelectedVegPhase(editableEvent.event_plan?.vegetation_phase?.name);
            setSelectedDate(editableEvent.plan_date ? editableEvent.plan_date : '2000-01-01');
            setSelectedAgroEvent(editableEvent.event_plan?.event?.name);
            setProtectionMode(editableEvent.fertilizers?.length > 0 ? 'fertilizer' : editableEvent.plant_protections?.length > 0 ? 'protect' : undefined);
            setProtectionList(editableEvent.fertilizers?.length > 0 ? editableEvent.fertilizers : editableEvent.plant_protections?.length > 0 ? editableEvent.plant_protections : undefined);

            setYear(editableEvent.year);

            if (editableEvent.fertilizers?.length > 0 || editableEvent.plant_protections?.length > 0) {
                setAllowProtection(true);
            }

            if (editableEvent.plan_date) {
                form.setFieldsValue({
                    plan_date: moment(editableEvent.plan_date)
                });
            }

            form.setFieldsValue({
                vega_keys: [editableEvent.vega_key],
                vegetation_phase: editableEvent.event_plan?.vegetation_phase?.name,
                culture: editableEvent?.culture,
                fact_date: moment(editableEvent.fact_date),
                field_area: editableEvent.field_area,
                performer: editableEvent.performer,
                event: editableEvent.event_plan?.event?.name
            });
        }
    }, [editableEvent]);

    useEffect(() => {
        if (selectedDate) {
            form.setFieldsValue({
                fact_date: moment(selectedDate)
            });
        }
    }, [selectedDate]);

    useEffect(() => {
        const vegInd = vegPhases.indexOf(selectedVegPhase);
        if (fieldPhases[vegInd]?.occurrence_date) {
            setSelectedDate(fieldPhases[vegInd]?.occurrence_date);
        }
    }, [selectedVegPhase]);

    const createCultureList = (fieldsArray) => {
        const culturesList = [];
        fieldsArray.forEach(fieldEntity => {
            const finderArray = culturesList.filter(x => x.value === fieldEntity?.culture?.id);
            if (finderArray.length === 0) {
                culturesList.push({
                    label: fieldEntity?.culture?.name,
                    value: fieldEntity?.culture?.id
                });
            }
        });
        return culturesList;
    };

    const createFieldsList = (fieldsArray) => {
        const fieldsList = [];
        fieldsArray.forEach(fieldEntity => {
            fieldsList.push({
                label: fieldEntity.vega_key,
                value: fieldEntity.vega_key
            });
        });
        return fieldsList;
    };

    useEffect(() => {
        if (information?.forecast?.items?.length > 0) {
            setFieldPhases(information.forecast.items);
        }
        const fieldValues = {
            field_area: information.area
        };
        if (selectedField?.length > 1) {
            fieldValues.field_area = null;
        }
        if (selectedField.length > 0) {
            fieldValues.culture = null;
            fieldValues.culture = [...fields].filter(x => x.vega_key === selectedField[0])[0]?.culture?.id;
        }
        form.setFieldsValue(fieldValues);
    }, [information]);

    useEffect(() => {
        if (selectedField.length === 0) {
            setDisableFieldArea(false);
        }
        if (selectedField?.length > 0) {
            form.setFieldsValue({
                vega_keys: selectedField
            });

            if (selectedField?.length > 1) {
                setDisableFieldArea(true);
                form.setFieldsValue({
                    field_area: null
                });
            }

            if (!fixedField && selectedField?.length === 1) {
                dispatch(getCalculatorMordovia({
                    id: selectedField[0],
                    year: year
                }));
            }
            if (!selectedCulture) {
                const fieldEntry = [...fields].filter(fieldEntry => selectedField.includes(fieldEntry.vega_key));
                setSelectedCulture(fieldEntry[0]?.culture?.id);
                setFieldNames(createFieldsList([...fields].filter(x => x?.culture?.id === fieldEntry[0]?.culture?.id)));
                const newCultures = createCultureList(fieldEntry);
                setFieldCultures(newCultures);
            }
            setFieldsDisabled(false);
        } else if (selectedField.length < 1 && !selectedCulture) {
            setFieldCultures(createCultureList([...fields]));
            setFieldNames(createFieldsList([...fields]));
            setFieldsDisabled(true);
        }
    }, [selectedField]);

    useEffect(() => {
        setFieldCultures([...fieldCultures].filter(x => x.value === selectedCulture));
        if (selectedCulture) {
            if (selectedField.length < 1) {
                setSelectAllFields(true);
            }
            dispatch(getVegetationPhases({
                culture: selectedCulture
            }));
        }
        if (selectedCulture && selectedField.length < 1) {
            const newFields = createFieldsList([...fields].filter(fieldEntry => selectedCulture === fieldEntry?.culture?.id));
            setFieldNames(newFields);
            setFieldsDisabled(false);
        } else if (selectedField.length < 1 && !selectedCulture) {
            setFieldCultures(createCultureList([...fields]));
            setFieldNames(createFieldsList([...fields]));
            setFieldsDisabled(true);
        } else if (selectedField.length >= 1 && !selectedCulture) {
            const newCultures = createCultureList([...fields].filter(fieldEntry => selectedField.includes(fieldEntry.vega_key)));
            setFieldCultures(newCultures);
            setFieldsDisabled(false);
        }
    }, [selectedCulture]);

    useEffect(() => {
        if (fields && fields.length > 0) {
            const fieldNames = createFieldsList(fields);
            setFieldNames(fieldNames);
            const fieldCultures = createCultureList(fields);
            setFieldCultures(fieldCultures);
        }
    }, [fields]);

    const checkFields = () => {
        return selectedField?.length > 0
            && selectedAgroEvent?.length > 0
            && form.getFieldValue('performer')?.length > 0
            && selectedDate?.length > 0;
    };

    const onSubmit = () => {
        form.submit();
        if (checkFields()) {
            setFormError(false);
            setConfirmLoading(true);

            const reqBody = {
                vega_keys: selectedField,
                year: information.year,
                plan_date: form?.getFieldValue('plan_date')?.format('YYYY-MM-DD'),
                fact_date: selectedDate?.length > 0 ? moment(selectedDate)?.format('YYYY-MM-DD') : '2021-11-11',
                performer: form.getFieldValue('performer'),
                event: selectedAgroEvent
            };

            if (!reqBody?.year && manualYear && manualYear > 0) {
                reqBody.year = manualYear;
            }

            if (editableEvent?.id) {
                reqBody.id = editableEvent.id;
            }

            if (protectionMode && protectionMode === 'protect') {
                reqBody.plant_protections = [...protectionList].map(x => {
                    return {
                        fertilizer: x.fertilizer.id ? x.fertilizer.id : x.fertilizer,
                        cultivated_area: x.cultivated_area,
                        // ??
                        recommended_amount: x.fact_amount,
                        fact_amount: x.fact_amount
                        //
                    };
                });
            }
            if (protectionMode && protectionMode === 'fertilizer') {
                reqBody.fertilizers = [...protectionList].map(x => {
                    return {
                        fertilizer: x.fertilizer.id ? x.fertilizer.id : x.fertilizer,
                        cultivated_area: x.cultivated_area,
                        // ??
                        recommended_amount: x.fact_amount,
                        fact_amount: x.fact_amount,
                        //
                        stage: x.stage
                    };
                });
            }

            dispatch(updateAgrotechnicalEvents({
                reqType: 'accounting',
                accountingParams: {
                    userId: currentUser?.id || null
                },
                body: reqBody
            }));

            setConfirmLoading(false);
            handleCancel();
        } else {
            setFormError(true);
        }
    };

    const handleCancel = () => {
        clearModal();
        setIsOpened(false);
    };

    const onFormChange = (changedValue, resultForm) => {
        setFormValues(resultForm);
    };

    const onFormFieldsChange = (val1, val2) => {
        if (!val1[0].errors) {
            setSelectedField(val1);
        }
    };

    const onDateChange = (a, b) => {
        if (b && b?.split('-')[0]?.length > 0) {
            setManualYear(parseInt(b?.split('-')[0], 10));
        }

        if (information?.forecast?.items?.length > 0) {
            information.forecast.items.forEach((item, indx) => {
                const startDateString = item.occurrence_date;
                const startDate = new Date(startDateString);
                const nextDateString = information?.forecast?.items[indx + 1]?.occurrence_date;
                const nextDate = new Date(nextDateString);
                const endDate = new Date();
                endDate.setDate(nextDate.getDate() - 1);

                if (a <= endDate && a >= startDate) {
                    const cultName = fieldCultures.filter(x => x.value === selectedCulture)[0].label;
                    const newVegPhaseName = item.vegetation_phase_culture_name.replace(cultName, '').trim();
                    form.setFieldsValue({
                        vegetation_phase: newVegPhaseName
                    });
                }
            });
        }

        setYear(a.year());
        setDateString(b);
        setSelectedDate(b);
    };

    const onFieldSelect = (selectedFields) => {
        setSelectedField(selectedFields);
    };

    const onCultureSelect = (selectedCulture) => {
        setSelectedCulture(selectedCulture);
    };

    const onAgroEventSelect = (selectedEvent) => {
        setSelectedAgroEvent(selectedEvent);
        if (selectedEvent === 'Обработка гербицидами' || selectedEvent === 'Обработка средствами защиты растений') {
            setAllowProtection(true);
            setProtectionMode('protect');
        } else if (selectedEvent === 'Внесение удобрений') {
            setAllowProtection(true);
            setProtectionMode('fertilizer');
        } else {
            setAllowProtection(false);
            setProtectionMode('');
        }
        setProtectionList([]);
    };

    const fieldOptionSwitch = () => {
        return fixedField ? [
            {
                label: fixedField,
                value: fixedField
            }
        ] : fieldNames;
    };

    return (
        <Modal
            className="modal"
            visible={isOpened}
            confirmLoading={confirmLoading}
            width={1136}
            onCancel={handleCancel}
            footer={[
                <div
                    key="footer">
                    <Button
                        key="cancel"
                        onClick={handleCancel}>
                        {t('modal button cancel')}
                    </Button>
                    <Button
                        key="submit"
                        type="primary"
                        onClick={onSubmit}
                        htmlType="submit">
                        {t('modal button ok')}
                    </Button>
                </div>
            ]}
            title={t('modal add title')}>

            <Spin spinning={isLoading}>

                <Form
                    form={form}
                    name="add_event_form"
                    labelCol={{ flex: '50%' }}
                    labelAlign="left"
                    onValuesChange={onFormChange}
                    onFieldsChange={onFormFieldsChange}>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                label={t('modal add form name')}
                                rules={[{ required: true }]}
                                name="vega_keys">
                                <div>
                                    <Select
                                        mode="multiple"
                                        allowClear
                                        value={selectedField}
                                        placeholder={t('modal add form name placeholder')}
                                        onChange={onFieldSelect}
                                        options={fieldOptionSwitch()} />
                                    {selectAllFields && (
                                        <a
                                            onClick={() => setSelectedField(fieldNames.map(x => x.value))}>
                                            {t('modal fields selectall')}
                                        </a>
                                    )}
                                </div>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={t('modal add form vegetation phase')}
                                name="vegetation_phase">
                                <Select
                                    allowClear
                                    disabled
                                    placeholder={t('modal add form vegetation phase placeholder')} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                label={t('modal add form culture')}
                                name="culture">
                                <Select
                                    allowClear
                                    placeholder={t('modal add form culture placeholder')}
                                    onChange={onCultureSelect}
                                    options={fieldCultures} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={t('modal add form fact date')}
                                rules={[{ required: true }]}
                                name="fact_date">
                                <DatePicker
                                    format={DATEPICKER_FORMAT}
                                    style={{ width: '100%' }}
                                    disabled={fieldsDisabled}
                                    placeholder={t('modal add form fact date placeholder')}
                                    onChange={onDateChange} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                label={t('modal add form culture area')}
                                name="field_area">
                                <InputNumber
                                    style={{ width: '100%' }}
                                    formatter={(val) => toFixed(val, 2)}
                                    disabled={fieldsDisabled || disableFieldArea}
                                    placeholder={t('modal add form culture area placeholder')} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={t('modal add form plan date')}
                                name="plan_date">
                                <DatePicker
                                    format={DATEPICKER_FORMAT}
                                    style={{ width: '100%' }}
                                    disabled={fieldsDisabled}
                                    placeholder={t('modal add form fact date placeholder')} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                label={t('modal add form agrotechnical event')}
                                rules={[{ required: true }]}
                                name="event">
                                <Select
                                    style={{ width: '100%' }}
                                    allowClear
                                    disabled={fieldsDisabled}
                                    placeholder={t('modal add form agrotechnical event placeholder')}
                                    onChange={onAgroEventSelect}
                                    // options={agroEventOptionsMemo}
                                    options={listOptions} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={t('modal add form performer')}
                                rules={[{ required: true }]}
                                name="performer">
                                <Input
                                    disabled={fieldsDisabled}
                                    placeholder={t('modal add form performer placeholder')}
                                    allowClear />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>

                {allowProtection && (
                    <PlantProtections
                        relatedCulture={selectedCulture}
                        setProtectionList={setProtectionList}
                        protectionList={protectionList}
                        protectionMode={protectionMode} />
                )}

                {formError && (
                    <div className="accounting-documents__error_message">
                        <CloseCircleOutlined />
                        <div>{t('modal error message')}</div>
                    </div>
                )}
            </Spin>

        </Modal>
    );
};

export default AccountingModal;
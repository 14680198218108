import React, { useEffect, useState } from 'react';
import moment from 'moment';

import {
    Col, Row, Collapse, Form, Select, DatePicker, Button, Input, InputNumber, Table
} from 'antd';
import { useTranslation } from 'react-i18next';

import { useClassName } from 'utils/cn';

import './style.less';

import { useDispatch, useSelector } from 'react-redux';

import { actions, selectors } from '../../models';

import { PHASES, PROBABILITY } from './constants';
import { toFixed } from '../../utils/utils';
import { DATEPICKER_FORMAT } from '../../constants';
import { history } from '../../index';
import { useHistory } from 'react-router-dom';
import { currentUserProbability } from '../../models/mordovia/selectors';

const {
    getCurrentForecastCalculatorMordovia,
    getCurrentForecastErrorCalculatorMordovia,
    getRegionNormCultureCatalog,
    curNormative,
    getProbabilityData,
    getCurrentVegaKey,
    getCurCalculatorField
} = selectors;

const {
    updateCalculatorsMordoviaForecast,
    calculatePestProbability,
    getPestProbabilityData
} = actions;

const { Panel } = Collapse;

export default ({ isCompleted, year, currentCulture }) => {
    const history = useHistory();
    const cn = useClassName('phases');
    const forecast = useSelector(state => getCurrentForecastCalculatorMordovia(state));
    const calculator = useSelector(state => getCurCalculatorField(state));
    const normative = useSelector(state => curNormative(state));
    const cultures = useSelector(state => getRegionNormCultureCatalog(state));
    const forecastError = useSelector(state => getCurrentForecastErrorCalculatorMordovia(state));
    const probabilityData = useSelector(state => getProbabilityData(state));
    const vegaKey = useSelector(state => getCurrentVegaKey(state));

    const todayIs = new Date();
    const isColored = todayIs?.getFullYear()?.toString() === year?.toString();

    const [hardcodedCulture, setHardcodedCulture] = useState(0);

    const { t } = useTranslation('phases');
    const dispatch = useDispatch();

    const [form] = Form.useForm();

    useEffect(() => {
        if (forecast?.culture?.id) {
            form.setFieldsValue({
                culture: forecast?.culture?.id
            });
        }
    }, [forecast]);

    const [phase, setPhase] = useState(null);
    const [showSoil, setShowSoil] = useState(false);
    const [isFullFields, setFullFields] = useState(false);
    const [isCalculate, setCalculate] = useState(false);

    useEffect(() => {
        if (vegaKey) {
            const date = moment(Date.now()).format('YYYY-MM-DD');
            if (forecast?.culture?.id) {
                setHardcodedCulture(parseInt(forecast.culture.id, 10));
            }
            dispatch(getPestProbabilityData({ id: vegaKey, body: { date: date, culture: forecast?.culture?.id } }));
        }
    }, [vegaKey]);

    useEffect(() => {
        form.setFieldsValue({
            culture: normative?.culture?.name,
            sowing_date: moment(forecast.sowing_date, 'YYYY-MM-DD'),
            t_air: toFixed(probabilityData?.t_air, 0),
            h_air: toFixed(probabilityData?.h_air, 0),
            date: moment(Date.now())
        });

        if (forecast.sowing_date && (normative?.culture?.id || currentCulture || forecast?.culture?.id) && year && forecast?.sowing_date) {
            setFullFields(true);
        }

        if (normative?.culture?.id && forecast.sowing_date && probabilityData?.t_air && probabilityData?.h_air) {
            if (normative?.culture?.id) {
                setHardcodedCulture(parseInt(normative.culture.id, 10));
            }
            
            dispatch(calculatePestProbability(
                {
                    id: vegaKey,
                    body: {
                        t_air: toFixed(probabilityData?.t_air, 0),
                        h_air: toFixed(probabilityData?.h_air, 0),
                        culture: normative?.culture?.id ?? currentCulture,
                        sowing_date: moment(forecast.sowing_date).format('YYYY-MM-DD'),
                        date: moment(Date.now()).format('YYYY-MM-DD'),
                        year: parseInt(year, 10)
                    }
                }
            ));
            setCalculate(true);
            setFullFields(true);
        }
    }, [probabilityData]);

    useEffect(() => {
        const curPhase = forecast?.items?.find(
            (item, index) =>
                checkDateInPeriod(item.occurrence_date, forecast.items[index + 1]?.occurrence_date)
        );
        setPhase({ ...curPhase });
    }, [forecast]);

    const checkDateInPeriod = (dateBefore, dateAfter) => {
        const curDate = new Date().getTime();
        const prevDate = new Date(dateBefore).getTime();
        const nextDate = dateAfter ? new Date(dateAfter).getTime() : new Date().getTime();
        if (curDate >= prevDate && !dateAfter) {
            return true;
        }
        if (curDate < prevDate) {
            return false;
        }
        if (curDate >= prevDate && curDate < nextDate && dateAfter) {
            return true;
        }
        return false;
    };

    const onValuesChange = (value, values) => {
        console.log(year,
            calculator?.culture?.id,);
        if (Object.keys(value)[0] === 'sowing_date') {
            dispatch(updateCalculatorsMordoviaForecast(
                {
                    id: vegaKey,
                    body: {
                        year,
                        culture: forecast?.culture?.id || normative?.culture?.id || hardcodedCulture,
                        sowing_date: moment(values.sowing_date).format('YYYY-MM-DD')
                    }
                }
            ));
        }
        if ((values?.culture || forecast?.culture?.id || normative?.culture?.id || hardcodedCulture) && values?.sowing_date && values?.date && year) {
            setFullFields(true);
        } else {
            setFullFields(false);
        }
    };

    const onFinish = (values) => {
        dispatch(calculatePestProbability(
            {
                id: vegaKey,
                body: {
                    ...values,
                    culture: forecast?.culture?.id ?? currentCulture ?? normative?.culture?.id,
                    sowing_date: moment(values?.sowing_date).format('YYYY-MM-DD'),
                    date: moment(values?.date).format('YYYY-MM-DD'),
                    year: parseInt(year, 10)
                    // culture_id: normative?.culture?.id,
                    // sowing_date: moment(values.sowing_date).format('YYYY-MM-DD'),
                    // date: moment(values.date).format('YYYY-MM-DD')
                }
            }
        ));
        setCalculate(true);
    };

    const toggleSoil = () => {
        setShowSoil(!showSoil);
    };

    const usrProb = useSelector(state => currentUserProbability(state));

    const setToDefault = () => {
        setShowSoil(!showSoil);
        form.setFieldsValue({
            culture: normative?.culture?.name,
            sowing_date: moment(forecast.sowing_date, 'YYYY-MM-DD'),
            t_air: toFixed(probabilityData?.t_air, 0),
            h_air: toFixed(probabilityData?.h_air, 0),
            date: moment(Date.now())
        });

        if (normative?.culture?.id && forecast?.sowing_date && year) {
            setFullFields(true);
        }
    };

    const prognosisColumns = [
        {
            title: t('table occurrence date'),
            dataIndex: 'occurrence_date',
            render: value => value ? moment(value)?.format('DD.MM.YYYY') : '-'
        },
        {
            title: t('table vegetation phase'),
            dataIndex: 'vegetation_phase',
        },
        {
            title: t('table name'),
            // width: 140,
            dataIndex: 'name',
            render: (value, record) => record?.id && (<a className="highlighted-hyperlink" onClick={() => {
                if (record?.type === 'pest') {
                    history.push(`/harmful-object/${record?.id}/`);
                } else {
                    history.push(`/disease/${record?.id}/`);
                }
            }}>{value}</a>)
        },
        {
            title: t('table probability'),
            dataIndex: 'probability',
        },
    ];

    const prognosisColumnsUser = [
        // {
        //     title: t('table occurrence date'),
        //     dataIndex: 'occurrence_date',
        //     render: value => value ? moment(value)?.format('DD.MM.YYYY') : null
        // },
        // {
        //     title: t('table vegetation phase'),
        //     dataIndex: 'vegetation_phase',
        // },
        {
            title: t('table name'),
            // width: 140,
            dataIndex: 'name',
            // render: (value, record) => record?.id && (<a className="highlighted-hyperlink" onClick={() => {
            //     if (record?.type === 'pest') {
            //         history.push(`/harmful-object/${record?.id}/`);
            //     } else {
            //         history.push(`/disease/${record?.id}/`);
            //     }
            // }}>{value}</a>)
        },
        {
            title: t('table probability'),
            dataIndex: 'probability',
        },
    ];
    
    const legendColors = [
        {
            title: t('legend past'),
            styles: { margin: 'auto', marginLeft: '10px', marginRight: '5px', height: '10px', width: '20px', backgroundColor: 'rgba(3, 181, 117, 0.1)' }
        },
        {
            title: t('legend current'),
            styles: { margin: 'auto', marginLeft: '10px', marginRight: '5px', height: '10px', width: '20px', backgroundColor: 'rgba(242, 201, 76, 0.1)' }
        },
        {
            title: t('legend future'),
            styles: { margin: 'auto', marginLeft: '10px', marginRight: '5px', height: '10px', width: '20px', backgroundColor: 'rgba(9, 109, 217, 0.05)' }
        },
    ];

    const [activeDate, setActiveDate] = useState(null);
    
    useEffect(() => {
        if (forecast?.pests?.length > 0) {
            forecast?.pests?.forEach((x, idx) => {
                const nowDate = new Date();
                const firstDate = new Date(x?.occurrence_date);
                const secondDate = new Date(forecast?.pests?.[idx+1]?.occurrence_date);
                if (firstDate >= nowDate && nowDate <= secondDate) {
                    setActiveDate(x?.occurrence_date);
                }
            })
            // setCategorizedObjects(categorizeDates(forecast?.pests));
        }
    }, [forecast?.pests]);

    const [currentPhaseName, setCurrentPhaseName] = useState(null);

    return (
        <div>
            {(forecast.errors?.length > 0 || forecastError) && (
                <Row className={cn('info')}>
                    <Col>
                        <div className={cn('info__inner')}>
                            {t('no info for culture')}
                        </div>
                    </Col>
                </Row>
            )}
            {forecast?.items?.length > 0 && !forecastError && (
                <Collapse
                    defaultActiveKey={isColored ? ['calculate_phases_user'] : ['calculate_phases_meteo']}
                    expandIconPosition="right"
                    ghost
                    className={`${cn('collapse')} ${cn('inner-collapse')}`}>
                    {isColored && (
                        <>
                            <Panel
                                header={(
                                    <b>
                                        {t('user data')}
                                    </b>
                                )}
                                key="user data">
                                <div className={cn('sowing-block')}>
                                    <Form form={form} onValuesChange={onValuesChange} onFinish={onFinish}>
                                        <Row gutter={20}>
                                            <Col span={5} className="field">
                                                <Form.Item name="date" label={t('date forecast')}>
                                                    <DatePicker format={DATEPICKER_FORMAT} disabled />
                                                </Form.Item>
                                            </Col>
                                            <Col span={5} className="field">
                                                <Form.Item name="culture" label={t('culture')}>
                                                    <Select
                                                        disabled
                                                        options={[
                                                            {
                                                                value: forecast?.culture?.id,
                                                                label: forecast?.culture?.name
                                                            }
                                                        ]}
                                                        defaultValue={forecast?.culture?.id}
                                                        showSearch />
                                                </Form.Item>
                                            </Col>
                                            <Col span={5} className="field">
                                                <Form.Item name="sowing_date" label={t('sowing date')}>
                                                    <DatePicker format={DATEPICKER_FORMAT} allowClear={false} />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={20}>
                                            <Col span={5} className="field">
                                                <Form.Item name="t_air" label={t('t air')}>
                                                    <InputNumber allowClear={false} step={1} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={5} className="field">
                                                <Form.Item name="h_air" label={t('h air')}>
                                                    <InputNumber allowClear={false} step={1} />
                                                </Form.Item>
                                            </Col>
                                            {!showSoil && (
                                                <Col span={14} className={`field ${cn('button')}`}>
                                                    <Button type="link" onClick={toggleSoil}>
                                                        {t('add soil info')}
                                                    </Button>
                                                </Col>
                                            )}
                                            {showSoil && (
                                                <>
                                                    <Col span={5} className="field">
                                                        <Form.Item name="t_soil" label={t('t soil')}>
                                                            <InputNumber allowClear={false} step={1} />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={5} className="field">
                                                        <Form.Item name="h_soil" label={t('h soil')}>
                                                            <InputNumber allowClear={false} step={1} />
                                                        </Form.Item>
                                                    </Col>
                                                </>
                                            )}
                                        </Row>
                                        <Row gutter={20}>
                                            <Col span={5} className="field">
                                                <Button type="primary"
                                                    disabled={!isFullFields}
                                                    htmlType="submit">
                                                    {showSoil ? t('recalculate') : t('calculate')}
                                                </Button>
                                            </Col>
                                            {showSoil && (
                                                <Col span={5} className="field">
                                                    <Button type="default"
                                                        onClick={setToDefault}>
                                                        {t('to default')}
                                                    </Button>
                                                </Col>
                                            )}
                                        </Row>
                                        { phase?.pests?.length <= 0 && (
                                            <Row className={cn('info')}>
                                                <Col>
                                                    <div className={cn('info__inner')}>
                                                        {t('no info for phase')}
                                                    </div>
                                                </Col>
                                            </Row>
                                        )}
                                        {phase?.pests?.length > 0 && isCalculate && (
                                            <div className="row-table" style={{ marginTop: '30px' }}>
                                                <Row className="row-head">
                                                    <Col span={4} className="row-table__label">{t('name')}</Col>
                                                    <Col span={4} className="row-table__description">{t('pathogen')}</Col>
                                                    <Col span={5} className="row-table__description">{t('epv')}</Col>
                                                    <Col span={5} className="row-table__description">{t('probability')}</Col>
                                                    <Col span={6} className="row-table__description">{t('active substance')}</Col>
                                                </Row>
                                                {
                                                    phase.pests.map((pest, i) => (
                                                        <Row className="row-table__withborder" key={pest.name + i}>
                                                            <Col span={4} className="row-table__label">
                                                                {pest.name}
                                                            </Col>
                                                            <Col span={4} className="row-table__description">
                                                                {pest.pathogen}
                                                            </Col>
                                                            <Col span={5} className="row-table__description">
                                                                {pest.economic_harmfulness}
                                                            </Col>
                                                            <Col span={5} className={`row-table__description ${pest.pest_probability && cn(`phase-color-${PROBABILITY[pest.pest_probability]}`)}`}>
                                                                {pest.pest_probability ? t(PROBABILITY[pest.pest_probability]) : pest.occurrence_probability}
                                                            </Col>
                                                            <Col span={6} className="row-table__description" style={{ whiteSpace: 'pre-wrap' }}>
                                                                {pest.main_drugs?.join('\n')}
                                                            </Col>
                                                        </Row>
                                                    ))
                                                }
                                            </div>
                                        )}
                                    </Form>
                                </div>
                            </Panel>
                            <Panel
                                header={(
                                    <b>
                                        {/* {t('calculate phases')} */}
                                        {t('calculate phases user')}
                                    </b>
                                )}
                                key="calculate_phases_user">
                                    <Table
                                        scroll={{ y: 500 }}
                                        dataSource={usrProb?.pests}
                                        columns={prognosisColumnsUser}
                                        pagination={false} />
                            </Panel>
                        </>
                    )}
                    <Panel
                        header={(
                            <Row>
                                <b>{t('calculate phases meteo')}</b>
                                {/* <Col> */}
                                    {legendColors?.map(x => (
                                        <Row style={{ marginLeft: '25px' }}>
                                            <div style={x?.styles} />
                                            <div>
                                                {x?.title}
                                            </div>
                                        </Row>
                                    ))}
                                {/* </Col> */}
                            </Row>
                        )}
                        key="calculate_phases_meteo">
                            <Table
                                rowClassName={(record, index) => {
                                    // if (!isColored) {
                                    //     return 'row-past';
                                    // }
                                    if (currentPhaseName?.length > 0 && currentPhaseName === record?.vegetation_phase) {
                                        return 'row-current';
                                    }
                                    if (isColored) {
                                        let currentDate, nextDate;

                                        currentDate = new Date(record?.occurrence_date);

                                        if ((index + 1) <= forecast?.pests?.length) {
                                            nextDate = new Date(forecast?.pests?.[index + 1]?.occurrence_date);
                                        }

                                        if (nextDate) {
                                            if (
                                                (todayIs >= currentDate && todayIs <= nextDate)
                                                || todayIs == currentDate
                                            ) {
                                                setCurrentPhaseName(record?.vegetation_phase);
                                                return 'row-current';
                                            }

                                            if (todayIs >= currentDate && todayIs >= nextDate) {
                                                return 'row-past';
                                            }

                                            if (todayIs < currentDate) {
                                                return 'row-future';
                                            }
                                        } else {
                                            return 'row-past';
                                        }
                                    } else {
                                        return 'row-past';
                                    }
                                }}
                                scroll={{ y: 500 }}
                                dataSource={forecast?.pests}
                                columns={prognosisColumns}
                                pagination={false} />
                        {/* <Collapse defaultActiveKey={['1']} ghost className={cn('collapse')}>
                            {
                                forecast.items.map((item, index) => index > 0
                                        && (
                                            <Panel
                                                className={`${checkDateInPeriod(item.occurrence_date, forecast.items[index + 1]?.occurrence_date) && 'panel-grey'}`}
                                                header={(
                                                    <b>
                                                        {moment(item.occurrence_date).format('DD.MM.YYYY')}&nbsp;
                                                        {t(PHASES[item.vegetation_phase_culture_name.replace(forecast?.culture?.name, '').trim('')])}
                                                    </b>
                                                )}
                                                key={item.vegetation_phase_culture_name}>
                                                {item.pests?.length > 0 && (
                                                    <div className="row-table">
                                                        <Row className="row-head">
                                                            <Col span={5} className="row-table__label">{t('name')}</Col>
                                                            <Col span={5} className="row-table__description">{t('pathogen')}</Col>
                                                            <Col span={6} className="row-table__description">{t('epv')}</Col>
                                                            <Col span={1} className="row-table__description">{t('percent')}<sup>*</sup></Col>
                                                            <Col span={7} className="row-table__description">{t('active substance')}</Col>
                                                        </Row>
                                                        {
                                                            item.pests.map((pest, i) => (
                                                                <Row className="row-table__withborder" key={pest.name + i}>
                                                                    <Col span={5} className="row-table__label">
                                                                        {pest.name}
                                                                    </Col>
                                                                    <Col span={5} className="row-table__description">
                                                                        {pest.pathogen}
                                                                    </Col>
                                                                    <Col span={6} className="row-table__description">
                                                                        {pest.economic_harmfulness}
                                                                    </Col>
                                                                    <Col span={1} className="row-table__description">
                                                                        {pest.occurrence_probability}
                                                                    </Col>
                                                                    <Col span={7} className="row-table__description" style={{ whiteSpace: 'pre-wrap' }}>
                                                                        {pest.main_drugs?.join('\n')}
                                                                    </Col>
                                                                </Row>
                                                            ))
                                                        }
                                                    </div>
                                                )}
                                            </Panel>
                                        ))
                            }
                        </Collapse> */}
                    </Panel>
                </Collapse>
            )}
        </div>
    );
};

import React, { useEffect, useState, useRef } from 'react';

import { useTranslation } from 'react-i18next';

// import { useClassName } from 'utils/cn';

import '../style.less';
import { Table } from 'antd';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { curGeneralProtocol } from '../../../models/general-protocol/selectors';

const Imap = ({
    calculatorData, selectedImaps, setSelectedImaps, ownerName
}) => {
    // const cn = useClassName('calculators');
    const { t } = useTranslation('general protocol');

    const generalProto = useSelector(state => curGeneralProtocol(state));

    const columns = [
        {
            title: t('header num'),
            dataIndex: 'num',
            key: 'num'
        },
        {
            title: t('header vega_key'),
            dataIndex: 'vega_key',
            key: 'vega_key'
        },
        {
            title: t('header owner'),
            dataIndex: 'owner',
            key: 'owner',
            render: () => ownerName
        },
        {
            title: t('header acceptation_date'),
            dataIndex: 'sample_date',
            key: 'sample_date',
            render: value => moment(value).format('DD.MM.YYYY')
        },
        {
            title: t('header examination_date'),
            dataIndex: 'test_date',
            key: 'test_date',
            render: value => moment(value).format('DD.MM.YYYY')
        }
    ];

    const onSelectChange = (newSelectedRowKeys, selectedRows) => {
        setSelectedImaps(selectedRows);
    };

    const rowSelection = {
        selectedRowKeys: selectedImaps?.map(item => item.id),
        onChange: onSelectChange
    };

    return (
        <div>
            {(generalProto?.imaps && generalProto?.imaps?.length > 0) ? (
                <Table
                    rowSelection={rowSelection}
                    rowKey="id"
                    columns={columns}
                    dataSource={calculatorData} />
            ) : (
                <div>{t('no protocols')}</div>
            )}
        </div>
    );
};

export default Imap;
import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { withRouter } from 'react-router';
import * as Scroll from 'react-scroll';
import {
    Collapse, Icon, Layout, Rate, Spin
} from 'antd';
import { Chart } from '@antv/g2';

import { withTranslation } from 'react-i18next';
import Geocode from '../../libs/geocode-map-viewer.min';

import cn from '../../utils/cn';

import {
    PanelLayout, SideBar, Table, TableFooter, Title
} from '../../components';

import './style.less';
import { actions, selectors } from '../../models';

import { regions } from '../../regions';
import ListLine from '../../../public/images/calculator/list_line.svg';

const { PanelHeader, PanelContent } = PanelLayout;

const {
    getProfileInfo,
    getNDMI,
    getNDVI,
    getNDVIandNDMI,
    getSWI
} = actions;
const {
    getUser,
    getCurNDMI,
    getCurNDVI,
    getCurSWI,
    getCurNDMIandNDVI
} = selectors;

const mstp = (state) => ({
    user: getUser(state),
    ndmi: getCurNDMI(state),
    ndvi: getCurNDVI(state),
    swi: getCurSWI(state),
    ndMiVi: getCurNDMIandNDVI(state)
});
const mdtp = ({
    getProfileInfo,
    getNDMI,
    getNDVI,
    getNDVIandNDMI,
    getSWI
});

let chart;

@withRouter
@connect(mstp, mdtp)
@withTranslation('map')
@cn('page')
export default class Profile extends Component {
    state = {
        data: [],
        columns: []
    }

    componentDidMount() {
        const { user } = this.props;
        const { id } = user;
        const geocode = new Geocode();
        const mapId = id === 14 ? '4e381d96-ed21-4e43-a846-6f0f5c6a69c9' : 'd0824cc7-fb0e-438c-ae59-5247ba5ee3a4';
        const map = geocode.map('map', mapId);
        map.then(body => body.map.on('vectorLayer:click', (e) => {
            const id = e.event.data.uuid;
            this.showGraph(id);
            console.log('event', e.event);
            console.log('layer', e.layer);
        }));
        if (id !== 14) {
            chart = new Chart({
                container: 'container',
                autoFit: true,
                height: 500
            });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { ndMiVi, swi } = this.props;
        if (prevProps.ndMiVi !== ndMiVi) {
            this.drawPlot();
        }
        if (prevProps.swi !== swi) {
            this.drawTableSwi();
        }
    }

    showGraph = (id) => {
        const { getNDVIandNDMI, getSWI } = this.props;
        getNDVIandNDMI(id);
        getSWI(id);
    }

    get agregateNDMIandNDVI() {
        const { ndMiVi } = this.props;
        const { dataNDMI, dataNDVI } = ndMiVi;
        const dataArr = [];
        const data = [];
        dataNDVI.forEach(item => {
            dataArr.push(moment(item.date).format('DD-MM-YYYY hh:mm'));
        });
        dataNDMI.forEach(item => {
            if (!dataArr.includes(moment(item.date).format('DD-MM-YYYY hh:mm'))) {
                dataArr.push(moment(item.date).format('DD-MM-YYYY hh:mm'));
            }
        });
        dataArr.sort();
        dataArr.forEach(item => {
            const ndvi = dataNDVI.find(i => moment(i.date).format('DD-MM-YYYY hh:mm') === item);
            const ndmi = dataNDMI.find(i => moment(i.date).format('DD-MM-YYYY hh:mm') === item);
            if (ndmi) data.push({ date: item, type: 'Индекс NDMI', value: ndmi.value.toFixed(6) });
            if (ndvi) data.push({ date: item, type: 'Индекс NDVI', value: ndvi.value.toFixed(6) });
        });
        return data;
    }

    get agregateSWI() {
        const { cn } = this;
        const { swi, t } = this.props;
        const dates = [];
        const obj = {};
        const data = [];
        const columns = [];
        swi.forEach((item) => {
            dates.push(item.date);
            obj[item.date] = {
                swi_001: item.swi_001,
                swi_005: item.swi_005,
                swi_010: item.swi_010,
                swi_015: item.swi_015,
                swi_020: item.swi_020,
                swi_040: item.swi_040,
                swi_060: item.swi_060,
                swi_100: item.swi_100
            };
        });
        columns.push({
            dataIndex: 'title',
            key: 'title',
            fixed: 'left',
            width: 80,
            render: (item) => (<>{t(item)}</>)

        });
        dates.forEach((item, i) => {
            const itemData = {
                title: item,
                dataIndex: item,
                key: `${item} ${i}`,
                className: cn('item'),
                render: (item) => (
                    <div className={cn('color', this.MyRound10(item))}>{item}</div>
                )
            };
            columns.push(itemData);
        });
        const items = [
            'swi_001',
            'swi_005',
            'swi_010',
            'swi_015',
            'swi_020',
            'swi_040',
            'swi_060',
            'swi_100'
        ];
        items.forEach((item) => {
            const itemData = {};
            itemData.title = item;
            dates.forEach(date => {
                itemData[date] = obj[date][item];
            });
            data.push(itemData);
        });
        return { data, columns };
    }


    drawTableSwi = () => {
        const { data, columns } = this.agregateSWI;
        this.setState({ data, columns });
    }

    drawPlot = () => {
        const data = this.agregateNDMIandNDVI;
        Scroll.animateScroll.scrollToBottom();
        chart.data(data);
        chart.scale({
            month: {
                range: [0, 1]
            },
            temperature: {
                nice: true
            }
        });

        chart.tooltip({
            showCrosshairs: true,
            shared: true
        });

        chart.axis('value', {
            label: {
                formatter: (val) => val
            }
        });

        chart
            .line()
            .position('date*value')
            .color('type')
            .shape('smooth');

        chart
            .point()
            .position('date*value')
            .color('type')
            .shape('circle')
            .style({
                stroke: '#fff',
                lineWidth: 1
            });

        chart.render();
    }

    MyRound10 = (val) => {
        const round = Math.floor(val / 10) * 10 < 150 ? Math.floor(val / 10) * 10 : 150;
        return round;
    }

    render() {
        const { cn } = this;
        const {
            t, user, ndMiVi
        } = this.props;
        const { data, columns } = this.state;
        const { id } = user;
        const opacity = Object.keys(ndMiVi).length ? 1 : 0;
        const height = Object.keys(ndMiVi).length ? 'auto' : 0;

        return (
            <Layout key="page-map-old" className={cn()}>
                {/* <SideBar /> */}
                <PanelLayout>
                    <PanelHeader>
                        <Title title={id === 14 ? t('title 14') : t('title')} />
                    </PanelHeader>
                    <PanelContent>
                        <div id="map" />
                        {id !== 14 && (
                            <div className={cn('monitoring')}>
                                <div className={cn('monitoring', 'title')}>{t('monitoring')}</div>
                                <div id="container" style={{ opacity, height }} />
                                {data.length > 0 && (
                                    <>
                                        <div className={cn('monitoring', 'title')}>
                                            {t('deep')}
                                        </div>
                                        <Table
                                            className={cn('table')}
                                            scroll={{ x: 1300 }}
                                            dataSource={data}
                                            columns={columns}
                                            pagination={false} />
                                    </>
                                )}
                            </div>
                        )}
                    </PanelContent>
                </PanelLayout>
            </Layout>
        );
    }
}

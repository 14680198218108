import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { Layout, Spin } from 'antd';

import { useClassName } from 'utils/cn';
import { SideBar } from '../../components';


import './style.less';
import Calculator from './components/wrapper';
import { actions } from '../../models';
import { getUser, isAuth } from '../../models/account/selectors';

import keycloak from '../../utils/keycloak';

const {
    getCalculatorMordovia, getFieldPoints
} = actions;

export default () => {
    const cn = useClassName('all-calculators');
    const match = useRouteMatch();
    const dispatch = useDispatch();
    const auth = useSelector(state => isAuth(state));
    const user = useSelector(state => getUser(state));

    useEffect(() => {
        const { id, year } = match.params;
        if (id && year) {
            dispatch(getCalculatorMordovia({ id, year }));
        }
    }, []);


    return (
        <div key="page-representation-route">
            <SideBar isMordovia username={keycloak?.idTokenParsed?.preferred_username ?? 'loading...'} />
            {/* <SideBar isMordovia username={match.params.id} /> */}
            <Layout className={cn()}>
                <Calculator vegaKey={match.params.id} />
            </Layout>
        </div>
    );
};

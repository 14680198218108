import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { withRouter } from 'react-router-dom';
import {
    Row, Col, Form, Input, Select
} from 'antd';
import Icon from '@ant-design/icons';

import cn from '../../../utils/cn';
import {
    Tabs, CatalogTitle
} from '../../../components';


import { selectors, actions } from '../../../models';

import DiseasesTable from './diseases-table';

const {
    getDiseases,
    getDiseasesGroup,
    getDiseasesSubGroup,
    getDiseasesDamageArea,
    getDiseasesCausativeAgent,
    getDiseasesCulture
} = actions;
const {
    getDiseasesList,
    getDiseasesCount,
    getDiseasesGroupList,
    getDiseasesSubGroupList,
    getDiseasesDamageAreaList,
    getDiseasesCausativeAgentList,
    getDiseasesCultureList
} = selectors;

const mstp = (state) => ({
    diseases: getDiseasesList(state),
    diseasesCount: getDiseasesCount(state),
    groups: getDiseasesGroupList(state),
    subgroups: getDiseasesSubGroupList(state),
    damageAreas: getDiseasesDamageAreaList(state),
    causativeAgents: getDiseasesCausativeAgentList(state),
    cultures: getDiseasesCultureList(state)
});
const mdtp = ({
    getDiseases,
    getDiseasesGroup,
    getDiseasesSubGroup,
    getDiseasesDamageArea,
    getDiseasesCausativeAgent,
    getDiseasesCulture
});


const ROW_DIVIDER = 10;
const MAX_RECORDS_IN_PAGINATOR = 40;

@withRouter
@connect(mstp, mdtp)
@withTranslation('diseases')
@cn('diseases')
export default class DiseasesDetail extends Component {
    formRef = React.createRef();

    state = {
        subgroupDisabled: true,
        visible: false,
        form: {},
        formInit: () => {},
        formClear: () => {},
        formFieldsClear: () => {},
        page: 1,
        page_size: ROW_DIVIDER,
        showFilter: true,
        setFilter: false
    };

    componentDidMount() {
        const {
            getDiseasesGroup,
            getDiseasesDamageArea,
            getDiseasesCausativeAgent,
            getDiseasesCulture
        } = this.props;
        const { pageNum, pageSize, filterOptions } = this.props?.location;
        getDiseasesCulture('&sort=["name","DESC"]');
        getDiseasesGroup('?sort=["name","DESC"]');
        getDiseasesDamageArea('?sort=["name","DESC"]');
        getDiseasesCausativeAgent('?sort=["name","DESC"]');
        this.formRef.current.setFieldsValue(filterOptions);
        this.setState({
            ...filterOptions,
            page: pageNum || 1,
            page_size: pageSize || 10
        }, () => {
            this.getFilteredDiseases();
        });
    }

    getFilteredDiseases = () => {
        const { getDiseases } = this.props;
        const {
            page_size,
            page,
            damageAreas,
            subgroup,
            group,
            causative_agent,
            name,
            culture
        } = this.state;

        const urlArr = [];
        name ? urlArr.push(`"name.icontains":"${name}"`) : '';
        causative_agent ? urlArr.push(`"causative_agent.name.icontains":"${causative_agent}"`) : '';
        damageAreas ? urlArr.push(`"related_damage_areas.id":${damageAreas}`) : '';
        culture && culture?.length > 0 ? urlArr.push(`"related_cultures.culture.id":[${culture}]`) : '';
        subgroup ? urlArr.push(`"subgroup.id":${subgroup}`) : '';
        group ? urlArr.push(`"subgroup.group.id":${group}`) : '';
        const page_size_url = `page_size=${page_size}`;
        const page_url = `page=${page}`;
        getDiseases(`?${page_size_url}&${page_url}&filter={${urlArr.join(',')}}`);
    };

    onChange = (value, values) => {
        const { getDiseasesSubGroup } = this.props;
        if (Object.keys(value)[0] === 'group') {
            this.formRef.current.setFieldsValue({ subgroup: '' });
            this.setState({ subgroup: '' });
            getDiseasesSubGroup(`?sort=["name","DESC"]&filter={"group.id":${value.group}}`);
        }
        this.setState(value, () => {
            this.setState({ page: 1 }, () => {
                this.getFilteredDiseases();
            });
        });
    };

    hideInfo = () => {
        this.setState({ showInfo: false });
    };

    onCurrentPageChange = (page) => {
        this.setState({
            page
        }, () => {
            this.getFilteredDiseases();
        });
    };

    onPageSizeChange = (page_size) => {
        this.setState({
            page_size, page: 1
        }, () => this.getFilteredDiseases());
    };

    get filterItems() {
        const { diseasesCount: dataSize } = this.props;
        const items = [];
        for (let i = 1; i < dataSize; i += 1) {
            if (i % ROW_DIVIDER === 0) {
                items.push({ value: `${i}`, title: `${i}` });
            }

            if (i > MAX_RECORDS_IN_PAGINATOR) break;
        }
        return items;
    }

    get filterOptions() {
        const {
            damageAreas,
            subgroup,
            group,
            causative_agent,
            name
        } = this.state;
        return {
            damageAreas,
            subgroup,
            group,
            causative_agent,
            name
        };
    }

    goBack = () => {
        const { history } = this.props;
        history.push('/catalog-info');
    };


    showFilter = () => {
        this.setState({ showFilter: true });
    };

    showInfo = (disease) => {
        this.setState({ showInfo: true, showDisease: disease });
    };

    filterOption = (inputName, option) => {
        const optionName = option.props.children.toLowerCase();

        inputName = inputName.toLowerCase();

        return optionName.indexOf(inputName) !== -1;
    };

    render() {
        const { cn } = this;
        const {
            t,
            diseases,
            diseasesCount,
            isCompleted,
            groups,
            subgroups,
            damageAreas,
            cultures
        } = this.props;
        const {
            subgroupDisabled,
            showFilter,
            page_size,
            page
        } = this.state;

        return (
            <div>
                <Form
                    ref={this.formRef}
                    onValuesChange={this.onChange}
                    name="diseases">
                    <Row gutter={20}>
                        <Col span={12}>
                            <CatalogTitle title={t('diseases')} />
                        </Col>
                        <Col span={12} className={cn('label')}>
                            {/* <Form.Item name="name">
                                <Input
                                    allowClear
                                    disabled={isCompleted}
                                    placeholder={t('search placeholder main')}
                                    validator="anyReg"
                                    suffix={<Icon type="search" />} />
                            </Form.Item> */}
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: '30px' }}>
                        <Col>
                            <span className={cn('back')} onClick={this.goBack}>
                                {t('back to catalog')}
                            </span>
                        </Col>
                    </Row>
                    <Row gutter={20} style={{ marginBottom: '20px' }}>
                        <Col span={5} className="field">
                            <Form.Item name="name" label={t('name diseases')}>
                                <Input
                                    allowClear
                                    disabled={isCompleted}
                                    placeholder={t('search placeholder main')}
                                    validator="anyReg"
                                    suffix={<Icon type="search" />} />
                            </Form.Item>
                        </Col>
                        <Col span={5} className="field">
                            <Form.Item name="group" label={t('group diseases')}>
                                <Select
                                    onChange={(value) => {
                                        if (value) {
                                            this.setState({
                                                subgroupDisabled: false
                                            }, () => {
                                                // this.getFilteredDiseases();
                                            });
                                        } else {
                                            this.setState({
                                                subgroupDisabled: true
                                            }, () => {
                                                // this.getFilteredDiseases();
                                            });
                                        }
                                        
                                    }}
                                    allowClear
                                    disabled={isCompleted}
                                    filterOption={this.filterOption}>
                                    {groups.map(item => (
                                        <Select.Option value={item.id} key={item.id}>{item.name}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={5} className="field">
                            <Form.Item name="subgroup" label={t('subgroup diseases')}>
                                <Select
                                    allowClear
                                    disabled={subgroupDisabled || isCompleted}
                                    filterOption={this.filterOption}>
                                    {subgroups.map(item => (
                                        <Select.Option value={item.id} key={item.id}>{item.name}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={5} className="field">
                            <Form.Item name="culture" label={t('cultures diseases')}>
                                <Select
                                    maxTagCount={1}
                                    allowClear
                                    mode="multiple"
                                    disabled={isCompleted}
                                    filterOption={this.filterOption}>
                                    {cultures?.map(item => (
                                        <Select.Option value={item.id} key={item.id}>{item.name}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        {/* <Col span={5} className="field">
                            <Form.Item name="damageAreas" label={t('damage areas diseases')}>
                                <Select
                                    disabled={isCompleted}
                                    allowClear
                                    filterOption={this.filterOption}>
                                    {damageAreas.map(item => (
                                        <Select.Option value={item.id} key={item.id}>{item.name}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col> */}
                        <Col span={4} className="field">
                            {/* <Form.Item name="causative_agent" label={t('causative agents')}>
                                <Input
                                    disabled={isCompleted}
                                    placeholder={t('search placeholder sub')}
                                    validator="anyReg"
                                    suffix={<Icon type="search" />} />
                            </Form.Item> */}
                        </Col>
                    </Row>
                </Form>
                <Row style={{ marginBottom: '20px' }}>
                    <Col span={24}>
                        <DiseasesTable
                            filterItems={this.filterItems}
                            filterOptions={this.filterOptions}
                            dataSource={diseases}
                            dataSize={diseasesCount}
                            pageSize={page_size}
                            pageNum={page}
                            resetDataSource={showFilter}
                            showInfo={this.showInfo}
                            onPageSizeChange={this.onPageSizeChange}
                            onCurrentPageChange={this.onCurrentPageChange} />
                    </Col>
                </Row>
            </div>

        );
    }
}
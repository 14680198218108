import React from 'react';
import Private from '../../containers/private-route';
// import Page from './component';
import Page from './component2';

const Routes = [
    <Private exact
        key="profile"
        path="/profile/:isNewUser?"
        component={Page} />
];

export default Routes;
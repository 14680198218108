import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';

import {
    Col, Form, InputNumber, Row, Select
} from 'antd';
import { useClassName } from 'utils/cn';

import { actions, selectors } from '../../models';

import './style.less';
import {
    Button, InfoMessage
} from '../../components';

import { generatePdf } from '../pdf-generator/interpretation';
import { toFixed } from '../../utils/utils';

const {
    curCalculate,
    curInterpretation
} = selectors;

const {
    addInterpretation,
    updateInterpretation
} = actions;

export default (props) => {
    const { type, isCompleted: isCompletedState = false } = props;
    const calculator = useSelector(state => curCalculate(state));
    const interpretation = useSelector(state => curInterpretation(state));
    const dispatch = useDispatch();
    const { t } = useTranslation('interpretation');
    const cn = useClassName('calculator');
    const match = useRouteMatch();
    const { params: { id } } = match;
    const history = useHistory();
    const [form] = Form.useForm();
    const [isCompleted, setCompleted] = useState(false);
    const [visible, setVisible] = useState(false);
    const [isSetMacroElements, setIsMacroElements] = useState(false);
    const [isSetNo3, setIsSetNo3] = useState('');

    useEffect(() => {
        setCompleted(isCompletedState);
    }, [isCompletedState]);

    useEffect(() => {
        form.setFieldsValue(interpretation);
        setIsMacroElements(interpretation.no3 || interpretation.p2o5 || interpretation.k2o);
    }, [interpretation]);

    const onFinishCalcNO3orOrganic = () => {
        const values = form.getFieldsValue(true);
        if (isSetNo3 === 'organic') {
            values.calc_no3 = true;
            values.calc_organic_substances = false;
        } else {
            values.calc_no3 = false;
            values.calc_organic_substances = true;
        }
        onFinish(values);
        hideModal();
        setIsSetNo3('');
    };

    const onFinishNotCalcNO3orOrganic = () => {
        const values = form.getFieldsValue(true);
        values.calc_no3 = false;
        values.calc_organic_substances = false;
        onFinish(values);
        hideModal();
        setIsSetNo3('');
    };

    const onFinish = (values) => {
        Object.keys(values).forEach((key) => (values[key] == null) && delete values[key]);
        if (isSetNo3 === 'no3' || isSetNo3 === 'organic' || (values.no3 && values.organic_substances)
            || (!values.no3 && !values.organic_substances)) {
            interpretation.id
                ? dispatch(updateInterpretation({ id: interpretation.id, values }))
                : dispatch(addInterpretation({
                    calculate: {
                        id,
                        name: calculator.name,
                        calculator_type: calculator.calculator_type
                    },
                    values
                }));
        } else {
            if (values.no3 && !values.organic_substances) {
                setIsSetNo3('no3');
                showModal();
            }
            if (!values.no3 && values.organic_substances) {
                setIsSetNo3('organic');
                showModal();
            }
        }
    };

    const onValuesChange = (value, values) => {
        setIsMacroElements(values.no3 || values.p2o5 || values.k2o);
    };

    const onFieldsChange = (field, fields) => {

    };

    const filterOption = (inputName, option) => {
        const optionName = option.props.children.toLowerCase();
        inputName = inputName.toLowerCase();
        return optionName.indexOf(inputName) !== -1;
    };

    const showModal = () => {
        setVisible(true);
    };

    const hideModal = () => {
        setVisible(false);
    };

    const closeCalculator = () => {
        history.push('/calculators');
    };

    const downloadPDF = () => {
        generatePdf(t, calculator);
    };

    const valueFormatter = (value) => {
        if (!value) {
            return 0;
        }
        const numberVal = parseFloat(value);
        if (numberVal < 0.1) {
            let offsetIterator = 1;
            let numberCopy = numberVal;
            while (numberCopy < 0) {
                offsetIterator += 1;
                numberCopy *= 10;
            }
            return toFixed(numberVal, 1);
        } else {
            return toFixed(numberVal, 1);
        }
    };

    return (
        <Form form={form}
            onValuesChange={onValuesChange}
            onFieldsChange={onFieldsChange}
            onFinish={onFinish}
            className={cn()}
            name="calculator-data-agro">
            <div className={cn('row-table')}>
                <Row className={cn('row-title')}>
                    <Col span={24}>{t('by the content')}</Col>
                </Row>
                <Row className={cn('row-head')}>
                    <Col span={6} className={cn('row-table__label')}>
                        {t('indicator name')}
                    </Col>
                    <Col span={3}>
                        {t('result')}
                    </Col>
                    <Col span={15} className={cn('row-table__description')}>
                        {t('by elements')}
                        <div className={cn('gradient-line')} />
                        <Row className={cn('gradient-line__description')}>
                            <Col span={4}>{t('VERY_LOW')}</Col>
                            <Col span={3}>{t('LOW')}</Col>
                            <Col span={3}>{t('AVERAGE')}</Col>
                            <Col span={4}>{t('INCREASED')}</Col>
                            <Col span={3}>{t('HIGH')}</Col>
                            <Col span={4}>{t('VERY_HIGH')}</Col>
                            <Col span={3}>{t('TOXIC')}</Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col span={6} className={cn('row-table__label')}>
                        <span>{t('nh')}NH<sub>4</sub>{t('val')}</span>
                    </Col>
                    <Col span={3} className={cn('row-table__input')}>
                        <Form.Item name="nh4">
                            <InputNumber
                                min={0}
                                max={1000}
                                step={0.1}
                                decimalSeparator=","
                                disabled={isCompleted}
                                formatter={valueFormatter}
                                placeholder="" />
                        </Form.Item>
                    </Col>
                    <Col span={15} className={cn('row-table__description')}>
                        {interpretation.nh4_interval && (
                            <div className={cn(`gradient-line__${interpretation.nh4_interval}`)} />
                        )}
                    </Col>
                </Row>
                <Row>
                    <Col span={6} className={cn('row-table__label')}>
                        <span>{t('no')}NO<sub>3</sub>{t('val')}</span>
                    </Col>
                    <Col span={3} className={cn('row-table__input')}>
                        <Form.Item name="no3">
                            <InputNumber
                                min={0}
                                max={1000}
                                step={0.1}
                                decimalSeparator=","
                                disabled={isCompleted}
                                formatter={valueFormatter}
                                placeholder="" />
                        </Form.Item>
                    </Col>
                    <Col span={15} className={cn('row-table__description')}>
                        {interpretation.no3_interval && (
                            <div className={cn(`gradient-line__${interpretation.no3_interval}`)} />
                        )}
                    </Col>
                    {interpretation.compare_no3_with_nh4_interval && (
                        <Col span={24} className={cn('info')} style={{ marginTop: '20px' }}>
                            <div className={cn('info__inner')}>
                                <Trans i18nKey={`interpretation:${interpretation.compare_no3_with_nh4_interval}`}>
                                    .<sub>.</sub>.<sub>.</sub>.<i>.</i>.<i>.</i>
                                </Trans>
                            </div>
                        </Col>
                    )}
                </Row>
                <Row>
                    <Col span={6} className={cn('row-table__label')}>
                        <span>{t('organic_substances')}</span>
                    </Col>
                    <Col span={3} className={cn('row-table__input')}>
                        <Form.Item name="organic_substances">
                            <InputNumber
                                min={0}
                                max={1000}
                                step={0.1}
                                decimalSeparator=","
                                disabled={isCompleted}
                                formatter={valueFormatter}
                                placeholder="" />
                        </Form.Item>
                    </Col>
                    <Col span={15} className={cn('row-table__description')}>
                        {interpretation.organic_substances_interval && (
                            <div className={cn(`gradient-line__${interpretation.organic_substances_interval}`)} />
                        )}
                    </Col>
                </Row>
                <Row className={cn('row-table__noborder')}>
                    <Col span={6} className={cn('row-table__label')}>
                        <span>{t('po')}P<sub>2</sub>O<sub>5</sub>{t('val')}</span>
                    </Col>
                    <Col span={3} className={cn('row-table__input')}>
                        <Form.Item name="p2o5">
                            <InputNumber
                                min={0}
                                max={1000}
                                step={0.1}
                                decimalSeparator=","
                                disabled={isCompleted}
                                formatter={valueFormatter}
                                placeholder="" />
                        </Form.Item>
                    </Col>
                    <Col span={15} className={cn('row-table__description')}>
                        {interpretation.p2o5_interval && (
                            <div className={cn(`gradient-line__${interpretation.p2o5_interval}`)} />
                        )}
                    </Col>
                </Row>
                <Row>
                    <Col span={6} className={cn('row-table__label')}>
                        <Form.Item name="method" rules={[{ required: true, message: 'Выберите метод исследования' }]}>
                            <Select
                                disabled={isCompleted}
                                filterOption={filterOption}>
                                <Select.Option value="kirsanov">{t('kirsanov')}</Select.Option>
                                <Select.Option value="chirikov">{t('chirikov')}</Select.Option>
                                <Select.Option value="machigin">{t('machigin')}</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row className={cn('row-table__noborder')}>
                    <Col span={6} className={cn('row-table__label')}>
                        <span>{t('ko')}K<sub>2</sub>O{t('val')}</span>
                    </Col>
                    <Col span={3} className={cn('row-table__input')}>
                        <Form.Item name="k2o">
                            <InputNumber
                                min={0}
                                max={1000}
                                step={0.1}
                                decimalSeparator=","
                                disabled={isCompleted}
                                formatter={valueFormatter}
                                placeholder="" />
                        </Form.Item>
                    </Col>
                    <Col span={15} className={cn('row-table__description')}>
                        {interpretation.k2o_interval && (
                            <div className={cn(`gradient-line__${interpretation.k2o_interval}`)} />
                        )}
                    </Col>
                </Row>
                <Row>
                    <Col span={6} className={cn('row-table__label')}>
                        <Form.Item name="method" rules={[{ required: true, message: 'Выберите метод исследования' }]}>
                            <Select
                                disabled={isCompleted}
                                filterOption={filterOption}>
                                <Select.Option value="kirsanov">{t('kirsanov')}</Select.Option>
                                <Select.Option value="chirikov">{t('chirikov')}</Select.Option>
                                <Select.Option value="machigin">{t('machigin')}</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={6} className={cn('row-table__label')}>
                        <span>{t('s')}</span>
                    </Col>
                    <Col span={3} className={cn('row-table__input')}>
                        <Form.Item name="s">
                            <InputNumber
                                min={0}
                                max={1000}
                                step={0.1}
                                decimalSeparator=","
                                disabled={isCompleted}
                                formatter={valueFormatter}
                                placeholder="" />
                        </Form.Item>
                    </Col>
                    <Col span={15} className={cn('row-table__description')}>
                        {interpretation.s_interval && (
                            <div className={cn(`gradient-line__${interpretation.s_interval}`)} />
                        )}
                    </Col>
                </Row>
                <Row>
                    <Col span={6} className={cn('row-table__label')}>
                        <span>{t('ca')}</span>
                    </Col>
                    <Col span={3} className={cn('row-table__input')}>
                        <Form.Item name="ca">
                            <InputNumber
                                min={0}
                                max={1000}
                                step={0.1}
                                decimalSeparator=","
                                disabled={isCompleted}
                                formatter={valueFormatter}
                                placeholder="" />
                        </Form.Item>
                    </Col>
                    <Col span={15} className={cn('row-table__description')}>
                        {interpretation.ca_interval && (
                            <div className={cn(`gradient-line__${interpretation.ca_interval}`)} />
                        )}
                    </Col>
                </Row>
                <Row>
                    <Col span={6} className={cn('row-table__label')}>
                        <span>{t('mg')}</span>
                    </Col>
                    <Col span={3} className={cn('row-table__input')}>
                        <Form.Item name="mg">
                            <InputNumber
                                min={0}
                                max={1000}
                                step={0.1}
                                decimalSeparator=","
                                disabled={isCompleted}
                                formatter={valueFormatter}
                                placeholder="" />
                        </Form.Item>
                    </Col>
                    <Col span={15} className={cn('row-table__description')}>
                        {interpretation.mg_interval && (
                            <div className={cn(`gradient-line__${interpretation.mg_interval}`)} />
                        )}
                    </Col>
                </Row>
                <Row>
                    <Col span={6} className={cn('row-table__label')}>
                        <span>{t('na')}</span>
                    </Col>
                    <Col span={3} className={cn('row-table__input')}>
                        <Form.Item name="na">
                            <InputNumber
                                min={0}
                                max={1000}
                                step={0.1}
                                decimalSeparator=","
                                disabled={isCompleted}
                                formatter={valueFormatter}
                                placeholder="" />
                        </Form.Item>
                    </Col>
                    <Col span={15} className={cn('row-table__description')}>
                        {interpretation.na_interval && (
                            <div className={cn(`gradient-line__${interpretation.na_interval}`)} />
                        )}
                    </Col>
                </Row>
                <Row>
                    <Col span={6} className={cn('row-table__label')}>
                        <span>{t('so')}SO<sub>4</sub>{t('val')}</span>
                    </Col>
                    <Col span={3} className={cn('row-table__input')}>
                        <Form.Item name="so4">
                            <InputNumber
                                min={0}
                                max={1000}
                                step={0.1}
                                decimalSeparator=","
                                disabled={isCompleted}
                                formatter={valueFormatter}
                                placeholder="" />
                        </Form.Item>
                    </Col>
                    <Col span={15} className={cn('row-table__description')}>
                        {interpretation.so4_interval && (
                            <div className={cn(`gradient-line__${interpretation.so4_interval}`)} />
                        )}
                    </Col>
                </Row>
                <Row>
                    <Col span={6} className={cn('row-table__label')}>
                        <span>{t('cl')}</span>
                    </Col>
                    <Col span={3} className={cn('row-table__input')}>
                        <Form.Item name="cl">
                            <InputNumber
                                min={0}
                                max={1000}
                                step={0.1}
                                decimalSeparator=","
                                disabled={isCompleted}
                                formatter={valueFormatter}
                                placeholder="" />
                        </Form.Item>
                    </Col>
                    <Col span={15} className={cn('row-table__description')}>
                        {interpretation.cl_interval && (
                            <div className={cn(`gradient-line__${interpretation.cl_interval}`)} />
                        )}
                    </Col>
                </Row>
                <Row>
                    <Col span={6} className={cn('row-table__label')}>
                        <span>{t('salt_conductivity')}</span>
                    </Col>
                    <Col span={3} className={cn('row-table__input')}>
                        <Form.Item
                            name="salt_conductivity">
                            <InputNumber
                                min={0}
                                max={1000}
                                step={0.1}
                                decimalSeparator=","
                                disabled={isCompleted}
                                formatter={valueFormatter}
                                placeholder="" />
                        </Form.Item>
                    </Col>
                    <Col span={15} className={cn('row-table__description')}>
                        {interpretation.salt_conductivity_interval && (
                            <div className={cn(`gradient-line__${interpretation.salt_conductivity_interval}`)} />
                        )}
                    </Col>
                    {interpretation.salt_conductivity_interval && (
                        <Col span={24} className={cn('info')} style={{ marginTop: '20px' }}>
                            <div className={cn('info__inner')}>
                                {t(`CONDUCTIVITY_${interpretation.salt_conductivity_interval}`)}
                            </div>
                        </Col>
                    )}
                </Row>

            </div>
            <div className={cn('row-table')}>
                <Row className={cn('row-title')}>
                    <Col span={24}>{t('by the content')}</Col>
                </Row>
                <Row className={cn('row-head')}>
                    <Col span={6} className={cn('row-table__label')}>
                        {t('indicator name')}
                    </Col>
                    <Col span={3}>
                        {t('result')}
                    </Col>
                    <Col span={15} className={cn('row-table__description')}>
                        {t('by elements')}
                    </Col>
                </Row>
                <Row>
                    <Col span={6} className={cn('row-table__label')}>
                        <span>{t('ph water')}</span>
                    </Col>
                    <Col span={3} className={cn('row-table__input')}>
                        <Form.Item
                            name="ph_water">
                            <InputNumber
                                min={0}
                                max={10}
                                step={0.1}
                                decimalSeparator=","
                                disabled={isCompleted}
                                formatter={valueFormatter}
                                placeholder="" />
                        </Form.Item>
                    </Col>
                    <Col span={15} className={cn('row-table__description')}>
                        <span>
                            {t('ph water description')}: &nbsp;
                            <b>{interpretation.ph_water_interval && t(interpretation.ph_water_interval)}</b>
                        </span>
                    </Col>
                </Row>
                <Row>
                    <Col span={6} className={cn('row-table__label')}>
                        <span>{t('ph salt')}</span>
                    </Col>
                    <Col span={3} className={cn('row-table__input')}>
                        <Form.Item
                            name="ph_salt">
                            <InputNumber
                                min={0}
                                max={8}
                                step={0.1}
                                decimalSeparator=","
                                disabled={isCompleted}
                                formatter={valueFormatter}
                                placeholder="" />
                        </Form.Item>
                    </Col>
                    <Col span={15} className={cn('row-table__description')}>
                        <span>
                            {t('ph salt description')}: &nbsp;
                            <b>{interpretation.ph_salt_interval && t(interpretation.ph_salt_interval)}</b>
                        </span>
                    </Col>
                    <Col span={24} className={cn('info')} style={{ marginTop: '20px' }}>
                        <div className={cn('info__inner')}>
                            {t('ph water info')}<br />
                            {t('ph salt info')}
                        </div>
                    </Col>
                    {interpretation.ph_salt_interval && (
                        <Row className={cn('info')}>
                            <Col span={24}>
                                <div className={cn('info__inner')}>
                                    {t(`SALT_${interpretation.ph_salt_interval}`)}
                                </div>
                            </Col>
                        </Row>
                    )}
                    {/* (calculator.ph_water < 4 || calculator.ph_water > 8.9) && calculator.ph_water_interval && (
                        <Row className={cn('info')}>
                            <Col span={24}>
                                <div className={cn('info__inner')}>
                                    {t(`SOIL_${calculator.ph_water_interval}`)}
                                </div>
                            </Col>
                        </Row>
                    ) */}
                    {interpretation.compare_ph_water_with_ph_salt_interval && (
                        <Row className={cn('info')}>
                            <Col span={24}>
                                <div className={cn('info__inner')}>
                                    {interpretation.compare_ph_water_with_ph_salt_interval !== 'EXCESS_SALT'
                                    && (t(`SALT_${interpretation.compare_ph_water_with_ph_salt_interval}`))}
                                    {interpretation.compare_ph_water_with_ph_salt_interval === 'EXCESS_SALT'
                                    && (
                                        <>
                                            {t('SALT_EXCESS_SALT_1')}
                                            Na<sup>+</sup>,
                                            K<sup>+</sup>,
                                            Ca<sup>+2</sup>,
                                            Mg<sup>+2</sup>
                                            {t('SALT_EXCESS_SALT_2')}
                                            Cl<sup>-</sup>,
                                            SO<sub>4</sub><sup>-2</sup>,
                                            HCO<sub>3</sub><sup>-</sup>,
                                            CO<sub>3</sub><sup>-2</sup>
                                            {t('SALT_EXCESS_SALT_3')}
                                        </>
                                    )}
                                </div>
                            </Col>
                        </Row>
                    )}
                </Row>
            </div>
            { !isSetMacroElements && !isCompleted && (
                <Row className={cn('info')}>
                    <Col>
                        <div className={cn('info__inner')}>{t('info')}</div>
                    </Col>
                </Row>
            )}
            {!isCompleted && (
                <Row className={cn('button')}>
                    <Col>
                        <Button
                            type="primary"
                            htmlType="submit"
                            disabled={isCompleted}
                            size="large">
                            {t('save')}
                        </Button>
                        <Button
                            type="primary"
                            onClick={downloadPDF}
                            size="large">
                            {t('generate')}
                        </Button>
                    </Col>
                </Row>
            )}
            <InfoMessage
                visible={visible}
                text={isSetNo3 === 'organic' ? 'calc_no3' : 'calc_organic_substances'}
                okBtn="calc"
                cancelBtn="cancel calc"
                onOk={onFinishCalcNO3orOrganic}
                onCancel={onFinishNotCalcNO3orOrganic} />
        </Form>
    );
};
import React, { useEffect, useState, useRef } from 'react';
import {
    Layout, Collapse, Form, Row, Col, Button, Spin, Input, message
} from 'antd';
import Icon, { DownloadOutlined } from '@ant-design/icons';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';

import { useClassName } from 'utils/cn';

import {
    PanelLayout, Title
} from '../../components';

import ListIcon from '../../../public/images/calculator/list2_stroke.svg';
import CalculatorIcon from '../../../public/images/calculator/calculator2_stroke.svg';

import CalculatorData from './components/data';
import CalculatorParameters from './components/parameters';
import CalculatorResults from './components/results';
import CalculatorFertilizers from './components/fertilizer';

import './style.less';

import {
    createInitialProtocol,
    createInternalCalculator,
    getProtocol,
    updateInternalCalculator, updateProtocol
} from '../../models/fed-registry/actions';
import { getUsersList } from '../../models/sample-log/actions';
import { getFilteredFields } from '../../models/mycological-registry/actions';
import { usrListResponse } from '../../models/sample-log/selectors';
import { getCulturesCatalog } from '../../models/cultures/selectors';
import { getCultures } from '../../models/cultures/actions';
import { curMycoFields } from '../../models/mycological-registry/selectors';
import {
    curNewInitialProtocol,
    curNewInternalCalculator,
    curProtocol, curUpdatedCalculator, curUpdatedProtocol
} from '../../models/fed-registry/selectors';
import moment from 'moment';
import { getFieldPoints } from '../../models/mordovia/actions';
import { getCalculatorMordovia } from '../../models/mordovia/api';
import { getUserId } from '../../models/account/selectors';

const { Panel } = Collapse;
const { PanelHeader, PanelContent } = PanelLayout;

const parameterFieldsBefore = [
    'c_k2', 'c_n', 'c_p', 'c_ks', 'c_kcl', 'c_k3', 'c_ca',
    'c_mg', 'c_b', 'c_cu', 'c_k4', 'c_zn', 'c_mn', 'c_fe',
    'c_k5', 'c_mo', 'c_co', 'c_i', 'c_k6'
];

const parameterFieldsAfter = [
    'd_k2', 'd_n', 'd_p', 'd_kcl', 'd_ks', 'd_k3', 'd_ca',
    'd_mg', 'd_b', 'd_cu', 'd_k4', 'd_zn', 'd_mn', 'd_fe',
    'd_k5', 'd_mo', 'd_co', 'd_i', 'd_k6'
];

const FedRegistry = () => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const cn = useClassName('calculators');
    const { t } = useTranslation('fed registry');
    const match = useRouteMatch();
    const history = useHistory();

    const users = useSelector(state => usrListResponse(state));
    const cultures = useSelector(state => getCulturesCatalog(state));
    const fields = useSelector(state => curMycoFields(state));
    const currentProtocol = useSelector(state => curProtocol(state));
    const createdProtocol = useSelector(state => curNewInitialProtocol(state));
    const createdInternalCalculator = useSelector(state => curNewInternalCalculator(state));
    const updatedInternalCalculator = useSelector(state => curUpdatedCalculator(state));
    const updatedCalc = useSelector(state => curUpdatedProtocol(state));

    useEffect(() => {
        if (updatedInternalCalculator?.error) {
            message.error(t('parameters error'));
        }
        // console.log('createdInternalCalculator', createdInternalCalculator);
        // console.log('createdProtocol', createdProtocol);
        // console.log('updatedCalc', updatedCalc);
        // console.log('currentProtocol', currentProtocol);
    }, [updatedInternalCalculator, createdInternalCalculator, createdProtocol, updatedCalc, currentProtocol])
    

    useEffect(() => {
        if (currentProtocol?.fed_calculator) {
            const currentKeys = Object.keys(currentProtocol?.fed_calculator);
            const deficitKeys = currentKeys.filter(x => x.includes('deficit'));
            let flagValue = true;
            deficitKeys.forEach(dKey => {
                if (currentProtocol?.fed_calculator[dKey]) {
                    if (activeTabs.includes('fertilizer')) {
                        setActiveTabs([...activeTabs, 'fertilizer']);
                    }
                    flagValue = false;
                }
            });
            setFertilizersLocked(flagValue);
        }
    }, [createdProtocol, currentProtocol]);

    useEffect(() => {
        const currentKeys = Object.keys(updatedInternalCalculator);

        if (currentKeys?.length > 0) {
            const deficitKeys = currentKeys.filter(x => x.includes('deficit'));
            let flagValue = true;
            deficitKeys.forEach(dKey => {
                if (updatedInternalCalculator[dKey]) {
                    if (activeTabs.includes('fertilizer')) {
                        setActiveTabs([...activeTabs, 'fertilizer']);
                    }
                    flagValue = false;
                }
            });
            setFertilizersLocked(flagValue);
        }
    }, [updatedInternalCalculator]);
    
    
    const [formInitialValues, setFormInitialValues] = useState({
        sample: 'Leaf'
    });
    const [fertilizersLocked, setFertilizersLocked] = useState(false);
    const [currentFertilizers, setCurrentFertilizers] = useState([]);
    const [currentFedCalc, setCurrentFedCalc] = useState({});
    const [fieldsList, setFieldsList] = useState([]);
    const [activeTabs, setActiveTabs] = useState([]);
    const [isCompleted, setIsCompleted] = useState(false);
    const [fullUserList, setFullUserList] = useState([]);
    const [userPage, setUserPage] = useState(1);
    const [userSearchString, setUserSearchString] = useState('');
    const [resultsDisabled, setResultsDisabled] = useState(false);
    const [isReportGenerating, setIsReportGenerating] = useState(false);
    const [isProtocolSaving, setIsProtocolSaving] = useState(false);
    const [selectedField, setSelectedField] = useState({});

    useEffect(() => {
        selectedField?.vega_key && dispatch(getCalculatorMordovia({
            id: selectedField?.vega_key
        }));
        selectedField?.year_group && dispatch(getFieldPoints({
            id: selectedField?.year_group,
            filter: ''
        }));
    }, []);

    useEffect(() => {
        const fedKeys = currentFedCalc ? Object.keys(currentFedCalc) : [];
        if (fedKeys?.length > 0) {
            form?.setFieldsValue(currentFedCalc);
            // if (currentFedCalc?.fed_calculator?.id) {
            //     form?.setFieldsValue(currentFedCalc?.fed_calculator);
            // }
            setActiveTabs([
                ...activeTabs, 'results'
            ]);
            setResultsDisabled(false);
        } else {
            setActiveTabs(activeTabs.filter(x => x !== 'results'));
            setResultsDisabled(true);
        }
    }, [currentFedCalc]);

    useEffect(() => {
        if (updatedCalc?.id && updatedCalc?.id > 0) {
            dispatch(getProtocol({ id: updatedCalc?.id }));
        }
    }, [updatedCalc]);

    useEffect(() => {
        if (updatedInternalCalculator?.id && updatedInternalCalculator?.id > 0) {
            setCurrentFedCalc(updatedInternalCalculator);
        }
    }, [updatedInternalCalculator]);

    useEffect(() => {
        const formVals = form?.getFieldsValue();
        if (createdProtocol?.id && createdProtocol?.id > 0 && !createdProtocol?.fed_calculator?.id) {
            dispatch(createInternalCalculator({
                id: createdProtocol?.id,
                values: formVals
            }));
            setTimeout(() => {
                history.push(`/fed-registry/${createdProtocol?.id}`);
            }, 1000);
        }
    }, [createdProtocol]);

    useEffect(() => {
        const fieldsListArr = [];
        const fldKeysArr = Object.keys(fields);

        fldKeysArr.forEach(key => {
            if (key !== 'next') {
                fieldsListArr.push(fields[key]);
            }
        });

        setFieldsList(fieldsListArr);

        if (currentProtocol?.field_num) {
            const fieldsList = [];
            const fldKeys = Object.keys(fields);

            fldKeys.forEach(key => {
                if (key !== 'next') {
                    fieldsList.push(fields[key]);
                }
            });


            const foundField = fieldsList?.filter(x => x.field_num === currentProtocol?.field_num)[0];

            if (foundField) {
                setSelectedField(foundField);
            }
        }
    }, [fields]);

    useEffect(() => {
        if (userSearchString?.length > 0 && userPage !== 1) {
            dispatch(getUsersList({
                page: 1,
                searchString: userSearchString
            }));
        } else {
            dispatch(getUsersList({
                page: userPage,
                searchString: userSearchString
            }));
        }
    }, [userPage, userSearchString]);

    const updateInternalCalc = () => {
        if (!currentProtocol?.fed_calculator?.id) {
            const formVals = form?.getFieldsValue();
            dispatch(createInternalCalculator({
                id: match?.params?.id,
                values: formVals
            }));
            setTimeout(() => {
                const fieldNames = [...parameterFieldsBefore, ...parameterFieldsAfter];
                const requestBody = {};
                fieldNames?.forEach(fieldName => {
                    requestBody[fieldName] = formVals[fieldName];
                });
                dispatch(updateInternalCalculator({
                    id: createdInternalCalculator?.id,
                    values: requestBody
                }));
            }, 2500);
        } else {
            const formVals = form?.getFieldsValue();
            const fieldNames = [...parameterFieldsBefore, ...parameterFieldsAfter];
            const requestBody = {};
            fieldNames?.forEach(fieldName => {
                requestBody[fieldName] = formVals[fieldName];
            });
            try {
                dispatch(updateInternalCalculator({
                    id: currentFedCalc?.id,
                    values: requestBody
                }));
            } catch (e) {
                console.log('Error', e);
            }
            // dispatch(updateInternalCalculator({
            //     id: currentFedCalc?.id,
            //     values: requestBody
            // }));
        }
    };

    const onTabsChange = (newValues) => {
        setActiveTabs(newValues);
    };

    const onFormFinish = () => {

    };

    const onFormChange = (updatedValue, updatedFormValues) => {
        const updatedField = Object.keys(updatedValue)[0];
        const updatedContent = updatedValue[updatedField];

        if (updatedField === 'owner') {
            dispatch(getFilteredFields({
                userId: updatedContent,
                searchString: '',
                pageSize: 1000
            }));
        }

        if (updatedField === 'field_num') {
            const fieldsListArr = [];
            const fldKeysArr = Object.keys(fields);

            fldKeysArr.forEach(key => {
                if (key !== 'next') {
                    fieldsListArr.push(fields[key]);
                }
            });

            const foundField = fieldsListArr?.filter(x => x.field_num === updatedContent)[0];

            if (foundField) {
                setSelectedField(foundField);
            }
        }
    };

    const prepareRequest = () => {
        const formVals = form?.getFieldsValue();

        return {
            zone_area: formVals?.zone_area,
            productivity_zone: formVals?.productivity_zone,
            acceptation_date: formVals?.acceptation_date?.format('YYYY-MM-DD'),
            comment: formVals?.comment || '',
            culture: formVals?.culture,
            examination_date: formVals?.examination_date.format('YYYY-MM-DD'),
            fed_fertilizers: currentFertilizers?.length > 0 ? currentFertilizers.map(x => x.id) : [],
            field_num: formVals?.field_num,
            field_points: formVals?.field_points,
            id: match?.params?.id,
            owner: formVals?.owner,
            protocol_number: formVals?.protocol_number,
            sample: formVals?.sample,
            vega_key: fieldsList?.filter(x => x?.field_num === formVals?.field_num)[0].vega_key
        };
    };

    const updateProto = () => {
        const reqVals = prepareRequest();
        dispatch(updateProtocol({
            id: match?.params?.id,
            values: reqVals
        }));
    };

    const prepareResponse = (responseValues) => {
        setCurrentFedCalc(responseValues?.fed_calculator);
        const fertilizersCopy = responseValues?.fed_fertilizers;
        fertilizersCopy?.sort((a,b) => b.rate - a.rate)
        setCurrentFertilizers(fertilizersCopy);

        if (responseValues?.owner?.id && responseValues?.owner?.id > 0) {
            dispatch(getFilteredFields({
                userId: responseValues?.owner?.id,
                searchString: '',
                pageSize: 1000
            }));
        }

        return {
            zone_area: responseValues?.zone_area,
            acceptation_date: moment(responseValues?.acceptation_date),
            archived: responseValues?.archived,
            comment: responseValues?.comment,
            culture: responseValues?.culture?.id,
            date_created: moment(responseValues?.date_created),
            examination_date: moment(responseValues?.examination_date),
            fed_fertilizers: responseValues?.fed_fertilizers,
            field_num: responseValues?.field_num,
            field_points: responseValues?.field_points,
            id: responseValues?.id,
            owner: responseValues?.owner?.id,
            productivity_zone: responseValues?.productivity_zone,
            protocol_number: responseValues?.protocol_number,
            public: responseValues?.public,
            sample: responseValues?.sample,
            vega_key: responseValues?.vega_key
        };
    };

    useEffect(() => {
        if (currentProtocol?.field_num) {
            const fieldsListArr = [];
            const fldKeysArr = Object.keys(fields);

            fldKeysArr.forEach(key => {
                if (key !== 'next') {
                    fieldsListArr.push(fields[key]);
                }
            });
            const foundField = fieldsListArr?.filter(x => x.field_num === currentProtocol?.field_num)[0];
            if (foundField) {
                setSelectedField(foundField);
            }
        }

        if (currentProtocol?.owner?.id) {
            dispatch(getFilteredFields({
                userId: currentProtocol.owner.id,
                searchString: '',
                pageSize: 1000
            }));
        }

        if (currentProtocol?.id) {
            setCurrentFedCalc(currentProtocol?.fed_calculator);
            const preparedResp = prepareResponse(currentProtocol);
            form?.setFieldsValue(preparedResp);
        }
    }, [currentProtocol]);

    useEffect(() => {
        const listPortion = users?.results;
        if (listPortion?.length > 0) {
            setFullUserList([...fullUserList, ...listPortion]);
        }
        const nextPage = users?.next;
        if (nextPage?.length > 0) {
            setUserPage(userPage + 1);
        }
    }, [users]);

    useEffect(() => {
        const currentId = match?.params?.id;
        if (currentId && currentId > 0) {
            dispatch(getProtocol({ id: currentId }));
            setActiveTabs([
                'data', 'parameters'
            ]);
        } else {
            setFormInitialValues({
                sample: 'Leaf'
            });
            form?.setFieldsValue({
                sample: 'Leaf'
            });
        }
    }, [match]);

    useEffect(() => {
        setFormInitialValues({
            sample: 'Leaf'
        });
        form?.setFieldsValue({
            sample: 'Leaf'
        });
        if (activeTabs?.length < 1) {
            setActiveTabs(['data']);
        }
        dispatch(getCultures('?page_size=1000&page=1'));
    }, []);

    const createProtocol = () => {
        if (match?.params?.id) {
            updateProto();
        } else {
            const resultFormValues = form?.getFieldsValue();
            const acceptDate = resultFormValues?.acceptation_date?.format('YYYY-MM-DD')?.split('T')[0];
            const examineDate = resultFormValues?.examination_date?.format('YYYY-MM-DD')?.split('T')[0];
            const selectedField = fieldsList?.filter(fieldOpt => fieldOpt?.field_num === resultFormValues?.field_num)[0];

            if (acceptDate?.length > 0) {
                resultFormValues.acceptation_date = acceptDate;
            }
            if (examineDate?.length > 0) {
                resultFormValues.examination_date = examineDate;
            }
            if (!resultFormValues?.fed_fertilizers) {
                resultFormValues.fed_fertilizers = [];
            }

            resultFormValues.field_num = selectedField?.field_num;
            resultFormValues.vega_key = selectedField?.vega_key;

            resultFormValues.date_created = moment().format('YYYY-MM-DD');

            dispatch(createInitialProtocol(resultFormValues));
        }
    };

    const getButtonAvailability = () => {
        if (currentFedCalc === undefined || currentFedCalc === null) {
            return true;
        }

        return Object.keys(currentFedCalc)?.length > 1;
    };

    const isTabBlocked = () => {
        if (!match?.params?.id || currentFedCalc === undefined || currentFedCalc === null) {
            return true;
        }
    };

    const handleDownloadWord = () => {
        setIsReportGenerating(true);

        fetch(`/api/v1/fed_calculator/fed_protocol/${match?.params?.id}/download_protocol/`, {
            method: 'GET',
            headers: {
                Authorization: `JWT ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json'
            },
            // body: JSON.stringify({
            //     analysis_ids: [...filteredSel]
            // })
        }).then((resp) => {
            resp.blob().then((blb) => {
                const url = window.URL.createObjectURL(blb);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = `FED_Registry_${match?.params?.id}`;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
                setIsReportGenerating(false);
            });
        });
    };

    const goToRegistries = () => {
        history.push('/fed-registries/');
    };

    const handleSaveClose = () => {
        setIsProtocolSaving(true);
        createProtocol();
        setTimeout(() => {
            goToRegistries();
            setIsProtocolSaving(false);
        }, 500);
    };

    return (
        <Layout key="page-fed-registry" className={cn()}>
            <PanelLayout>
                <PanelHeader>
                    <Title
                        title={`${t('title item')} ${match?.params?.id ? `№${match?.params?.id}` : ''}`} />
                </PanelHeader>
                <PanelContent>
                    <div className={cn()}>
                        <Form
                            form={form}
                            name="fed-registry"
                            initialValues={formInitialValues}
                            onFinish={onFormFinish}
                            onValuesChange={onFormChange}
                            autoComplete="off">
                            <Collapse
                                activeKey={activeTabs}
                                onChange={onTabsChange}
                                expandIconPosition="right"
                                className={cn('collapse')}>
                                <Panel
                                    header={(
                                        <b>
                                            <Icon component={ListIcon} />
                                            {t('calculator panel data')}
                                        </b>
                                    )}
                                    key="data">
                                    <CalculatorData
                                        showButton={getButtonAvailability}
                                        form={form}
                                        fieldsList={fieldsList}
                                        usersList={fullUserList}
                                        culturesList={cultures}
                                        createProtocol={createProtocol} />
                                </Panel>
                                <Panel
                                    disabled={!match?.params?.id || currentFedCalc === undefined || currentFedCalc === null}
                                    header={(
                                        <b>
                                            <Icon component={CalculatorIcon} />
                                            {t('calculator panel parameters')}
                                        </b>
                                    )}
                                    key="parameters">
                                    <CalculatorParameters
                                        updateInternalCalc={updateInternalCalc}
                                        isCompleted={currentFedCalc?.public} />
                                </Panel>
                                <Panel
                                    disabled={!match?.params?.id || resultsDisabled}
                                    header={(
                                        <b>
                                            <Icon component={ListIcon} />
                                            {t('calculator panel results')}
                                        </b>
                                    )}
                                    key="results">
                                    <CalculatorResults
                                        form={form}
                                        selectedField={selectedField}
                                        calculator={currentFedCalc} />
                                </Panel>
                                <Panel
                                    disabled={!match?.params?.id || currentFedCalc === undefined || currentFedCalc === null || fertilizersLocked}
                                    header={(
                                        <b>
                                            <Icon component={ListIcon} />
                                            {t('calculator panel fertilizer')}
                                        </b>
                                    )}
                                    key="fertilizer">
                                    <CalculatorFertilizers
                                        protocolEntity={currentProtocol}
                                        calculator={currentFedCalc}
                                        fertilizers={currentFertilizers} />
                                </Panel>
                            </Collapse>

                            <Row style={{ marginTop: '15px' }}>
                                <Form.Item name="comment" key="comment" label={t('comment label')}>
                                    <Input.TextArea style={{ width: '100%' }} maxLength={999999999} />
                                </Form.Item>      
                            </Row>
                        </Form>

                        <Row style={{ marginTop: '15px', marginBottom: '25px' }}>
                            <Col span={5} />

                            {match?.params?.id && (
                                <>
                                    <Col span={4}>
                                        <Button
                                            style={{ width: '100%' }}
                                            onClick={handleDownloadWord}
                                            type="primary">
                                            {isReportGenerating ? (
                                                <Spin spinning={isReportGenerating} />
                                            ) : (
                                                <>
                                                    <DownloadOutlined />
                                                    Сгенерировать Word
                                                </>
                                            )}
                                        </Button>
                                    </Col>
                                    <Col span={1} />
                                    <Col span={4}>
                                        <Button
                                            style={{ width: '100%' }}
                                            onClick={handleSaveClose}
                                            type="primary">
                                            {isProtocolSaving ? (
                                                <Spin spinning={isProtocolSaving} />
                                            ) : (
                                                <span>
                                                    Сохранить и закрыть
                                                </span>
                                            )}
                                        </Button>
                                    </Col>

                                    <Col span={1} />
                                </>
                            )}

                            {!match?.params?.id && (
                                <Col span={5} />
                            )}

                            <Col span={4}>
                                <Button
                                    style={{ width: '100%' }}
                                    onClick={goToRegistries}>
                                    Выход
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </PanelContent>
            </PanelLayout>
        </Layout>
    );
};

export default FedRegistry;
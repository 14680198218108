import React, { useEffect, useState, useRef } from 'react';
import {
    Button,
    Col, Form, Input, InputNumber, Modal, Pagination, Row, Select, Table
} from 'antd';

import { useTranslation } from 'react-i18next';

import { useClassName } from 'utils/cn';

import '../style.less';
import { Tooltip } from '../../../components';
import { CheckCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { getFertilizers, updateProtocolFertilizers } from '../../../models/fed-registry/actions';
import { curFertLength, curFertList, curFertListObj } from '../../../models/fed-registry/selectors';
import { toFixed } from '../../../utils/utils';
import { useRouteMatch } from 'react-router-dom';

const FertilizerModal = ({
    setCurrentFertilizerList, currentFertilizerList,
    isModalOpen, setIsModalOpen, addNewFertilizer, handleOpenNewFertilizer, existingFerts
}) => {
    const cn = useClassName('fertilizers');
    const { t } = useTranslation('fed registry');
    const dispatch = useDispatch();
    const match = useRouteMatch();

    const [modalPage, setModalPage] = useState(1);
    const [modalPageSize, setModalPageSize] = useState(10);
    const [totalFertilizers, setTotalFertilizers] = useState(null);
    const [selectedKeysModal, setSelectedKeysModal] = useState([]);
    const [fertSrcStr, setFertSrcStr] = useState(null);

    const fertilizersObject = useSelector(state => curFertListObj(state));
    const fertilizersSize = useSelector(state => curFertLength(state));

    const [fertPage, setFertPage] = useState(1);
    const [fertFullList, setFertFullList] = useState([]);
    const [fertFilteredList, setFertFilteredList] = useState([]);

    useEffect(() => {
        if (fertFullList?.length > 0) {
            setFertFilteredList([...fertFullList]);
        }
    }, [fertFullList]);
    
    useEffect(() => {
        const { results, next } = fertilizersObject;

        if (results?.length > 0) {
            setFertFullList([...fertFullList, ...results]);
        }

        if (next?.length > 0) {
            setFertPage(fertPage + 1);
        }
    }, [fertilizersObject]);
    
    useEffect(() => {
        dispatch(getFertilizers({
            page: fertPage,
            pageSize: 10,
            searchString: null
        }));
    }, [fertPage]);
    
    const onModalSelect = (selKeys, selRows) => {
        setSelectedKeysModal(selKeys);
    };

    const rowSelectionModal = {
        selectedKeysModal,
        onChange: onModalSelect,
    };

    useEffect(() => {
        if (fertilizersSize && fertilizersSize > 0 && fertilizersSize !== totalFertilizers) {
            setTotalFertilizers(fertilizersSize);
        }
    }, [fertilizersSize]);

    useEffect(() => {
        if (modalPage && modalPage > 0) {
            dispatch(getFertilizers({
                page: modalPage,
                pageSize: modalPageSize,
                searchString: fertSrcStr?.length > 0 ? fertSrcStr : null
            }));
        }
    }, [modalPage]);

    const columns = [
        {
            title: t('name'),
            dataIndex: ['name'],
            key: 'name',
            className: cn('name'),
            ellipsis: true,
            width: 150,
            render: (text, record) => (
                <Tooltip
                    placement="bottomLeft"
                    title={text}>
                    {text}
                </Tooltip>
            )

        },
        {
            title: 'N',
            dataIndex: 'n',
            key: 'n',
            ellipsis: true,
            width: 65,
            render: value => (
                <Tooltip
                    placement="bottomLeft"
                    title={value}>
                    {toFixed(value, 2)}
                </Tooltip>
            )
            // render: value => toFixed(value, 2)
        },
        {
            title: 'P',
            dataIndex: 'p',
            key: 'p',
            width: 65,
            render: value => (
                <Tooltip
                    placement="bottomLeft"
                    title={value}>
                    {toFixed(value, 2)}
                </Tooltip>
            )
            // render: value => toFixed(value, 2)
        },
        {
            title: 'KS',
            dataIndex: 'ks',
            key: 'ks',
            width: 65,
            render: value => (
                <Tooltip
                    placement="bottomLeft"
                    title={value}>
                    {toFixed(value, 2)}
                </Tooltip>
            )
            // render: value => toFixed(value, 2)
        },
        {
            title: 'KCl',
            dataIndex: 'kcl',
            key: 'kcl',
            width: 65,
            render: value => (
                <Tooltip
                    placement="bottomLeft"
                    title={value}>
                    {toFixed(value, 2)}
                </Tooltip>
            )
            // render: value => toFixed(value, 2)
        },
        {
            title: 'Ca',
            dataIndex: 'ca',
            key: 'ca',
            width: 65,
            render: value => (
                <Tooltip
                    placement="bottomLeft"
                    title={value}>
                    {toFixed(value, 2)}
                </Tooltip>
            )
            // render: value => toFixed(value, 2)
        },
        {
            title: 'Mg',
            dataIndex: 'mg',
            key: 'mg',
            width: 65,
            render: value => (
                <Tooltip
                    placement="bottomLeft"
                    title={value}>
                    {toFixed(value, 2)}
                </Tooltip>
            )
            // render: value => toFixed(value, 2)
        },
        {
            title: 'B',
            dataIndex: 'b',
            key: 'b',
            width: 65,
            render: value => (
                <Tooltip
                    placement="bottomLeft"
                    title={value}>
                    {toFixed(value, 2)}
                </Tooltip>
            )
            // render: value => toFixed(value, 2)
        },
        {
            title: 'Cu',
            dataIndex: 'cu',
            key: 'cu',
            width: 65,
            render: value => (
                <Tooltip
                    placement="bottomLeft"
                    title={value}>
                    {toFixed(value, 2)}
                </Tooltip>
            )
            // render: value => toFixed(value, 2)
        },
        {
            title: 'Zn',
            dataIndex: 'zn',
            key: 'zn',
            width: 65,
            render: value => (
                <Tooltip
                    placement="bottomLeft"
                    title={value}>
                    {toFixed(value, 2)}
                </Tooltip>
            )
            // render: value => toFixed(value, 2)
        },
        {
            title: 'Mn',
            dataIndex: 'mn',
            key: 'mn',
            width: 65,
            render: value => (
                <Tooltip
                    placement="bottomLeft"
                    title={value}>
                    {toFixed(value, 2)}
                </Tooltip>
            )
            // render: value => toFixed(value, 2)
        },
        {
            title: 'Fe',
            dataIndex: 'fe',
            key: 'fe',
            width: 65,
            render: value => (
                <Tooltip
                    placement="bottomLeft"
                    title={value}>
                    {toFixed(value, 2)}
                </Tooltip>
            )
            // render: value => toFixed(value, 2)
        },
        {
            title: 'Mo',
            dataIndex: 'mo',
            key: 'mo',
            width: 65,
            render: value => (
                <Tooltip
                    placement="bottomLeft"
                    title={value}>
                    {toFixed(value, 2)}
                </Tooltip>
            )
            // render: value => toFixed(value, 2)
        },
        {
            title: 'Co',
            dataIndex: 'co',
            key: 'co',
            width: 65,
            render: value => (
                <Tooltip
                    placement="bottomLeft"
                    title={value}>
                    {toFixed(value, 2)}
                </Tooltip>
            )
            // render: value => toFixed(value, 2)
        },
        {
            title: 'I',
            dataIndex: 'i',
            key: 'i',
            width: 65,
            render: value => (
                <Tooltip
                    placement="bottomLeft"
                    title={value}>
                    {toFixed(value, 2)}
                </Tooltip>
            )
            // render: value => toFixed(value, 2)
        },
        {
            title: t('avg_price'),
            dataIndex: 'price',
            key: 'price'
        }
    ];

    const handlePaginationChange = (newPage, newPageSize) => {
        if (newPageSize !== modalPageSize) {
            setModalPageSize(newPageSize);
            setModalPage(1);
        } else {
            setModalPage(newPage);
        }
    };

    const addFertilizersToSelection = () => {
        dispatch(updateProtocolFertilizers({
            id: match?.params?.id,
            values: {
                fertilizers: [...existingFerts, ...selectedKeysModal],
                result_fertilizers: false
            }
        }));
        setTimeout(() => setIsModalOpen(false), 300);
    };

    useEffect(() => {
        if (fertSrcStr?.length > 0) {
            const filteredList = fertFullList.filter(fert => fert.name.toLowerCase().includes(fertSrcStr.toLowerCase()));
            setFertFilteredList([...filteredList]);
        } else {
            setFertFilteredList([...fertFullList]);
        }
    }, [fertSrcStr]);
    
    const handleFertSrc = (evt) => {
        setFertSrcStr(evt?.target?.value);
    };

    return (
        <Modal
            width={1200}
            footer={false}
            onCancel={() => setIsModalOpen(false)}
            visible={isModalOpen}>

            <div>
                <Row>
                    <Col span={4}>
                        <Button onClick={handleOpenNewFertilizer} type="primary">
                            <PlusCircleOutlined />
                            {t('add fertilizer')}
                        </Button>
                    </Col>
                    <Col span={1} />
                    <Col span={6}>
                        <Button onClick={addFertilizersToSelection} type="primary">
                            <CheckCircleOutlined />
                            {t('add fertilizer to selection')}
                        </Button>
                    </Col>
                    <Col span={2} />
                </Row>
                <Row>
                    <Input placeholder="Поиск по названию" value={fertSrcStr} onChange={handleFertSrc} style={{ marginTop: '15px', width: '100%' }} />
                </Row>
                <Row style={{ marginBottom: '20px', marginTop: '25px' }}>
                    <Col span={24}>
                        <Table
                            rowKey="id"
                            rowSelection={rowSelectionModal}
                            pagination={{
                                defaultCurrent: 1,
                                defaultPageSize: 10,
                                current: modalPage,
                                pageSize: modalPageSize,
                                total: totalFertilizers,
                                onChange: handlePaginationChange,
                                showSizeChanger: true,
                                pageSizeOptions: [
                                    '10', '25', '50'
                                ]
                            }}
                            columns={columns}
                            dataSource={fertFilteredList} />
                            {/* dataSource={[...fertilizersListHolder]} /> */}
                    </Col>
                </Row>
            </div>

        </Modal>
    );
};

export default FertilizerModal;
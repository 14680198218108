import React from 'react';
import { useTranslation } from 'react-i18next';
import { useClassName } from 'utils/cn';
import { Button } from 'antd';
import './style.less';
import { useLocation } from 'react-router-dom';

export default () => {
    const location = useLocation();
    const { pathname } = location;
    const { t, i18n } = useTranslation('change lang');
    const cn = useClassName('change-lang');
    
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    return (
        <div className="laguage-switch">
            <Button className="language-button" onClick={() => changeLanguage('ru')}>ru</Button>
            <Button className="language-button" onClick={() => changeLanguage('en')}>en</Button>
        </div>
    );
};

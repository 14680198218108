import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import cn from '../../utils/cn';
import './style.less';
import { withRouter } from 'react-router-dom';

@withRouter
@withTranslation('logo')
@cn('logo')
export default class Logo extends Component {
    render() {
        const { cn } = this;
        const { t, history } = this.props;

        return (
            <div className={cn()} onClick={() => { window.location.replace('/') }}>
                <img className={cn('image')} src="/images/logo.svg" alt="logo" />
                <span className={cn('text')}>
                    {t('tele')}<b>{t('agronom')}</b>
                </span>
            </div>
        );
    }
}
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Icon from '@ant-design/icons';

import {
    Layout, Spin, Collapse
} from 'antd';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    Title, Tabs, SideBar, PanelLayout
} from '../../components';
import Disease from '../../../public/images/catalog/disease.svg';
import Fertilizer from '../../../public/images/catalog/fertilizer.svg';
import Plant from '../../../public/images/catalog/plant.svg';
import Variety from '../../../public/images/catalog/variety.svg';

import cn from '../../utils/cn';

import './style.less';
import CatalogIcon from '../../../public/images/sidebar/catalog.svg';
import { selectors } from '../../models';

const { PanelHeader, PanelContent } = PanelLayout;
const { Panel } = Collapse;
const {
    getUser
} = selectors;

const mstp = (state) => ({
    user: getUser(state)
});
const mdtp = ({});

@withTranslation('catalog')
@connect(mstp, mdtp)
@cn('catalog')
export default class Catalog extends Component {
    state = {
        isFasie: false
    }

    componentDidMount() {
        const { user } = this.props;
        const { groups = [] } = user;
        this.setState({ isFasie: groups.includes('fasie') });
    }

    render() {
        const { cn } = this;
        const { isFasie } = this.state;

        const { t, history } = this.props;

        return (
            <Layout key="page-info-catalog-route" className={cn()}>
                <PanelLayout>
                    <PanelHeader>
                        <Title title={t('title')} />
                    </PanelHeader>
                    <PanelContent>
                        <div className={cn('item')} onClick={() => history.push('/fertilizers')}>
                            <div className={cn('icon-wrap')}>
                                <Icon component={CatalogIcon} />
                            </div>
                            <span className={cn('text-wrap')}>
                                {t('fertilizer catalog')}
                            </span>
                        </div>
                        <div className={cn('item')} onClick={() => history.push('/diseases')}>
                            <div className={cn('icon-wrap')}>
                                <Icon component={CatalogIcon} />
                            </div>
                            <span className={cn('text-wrap')}>
                                {t('disease directory')}
                            </span>
                        </div>
                        <div className={cn('item')} onClick={() => history.push('/harmful-objects')}>
                            <div className={cn('icon-wrap')}>
                                <Icon component={CatalogIcon} />
                            </div>
                            <span className={cn('text-wrap')}>
                                {t('harmful directory')}
                            </span>
                        </div>
                        {!isFasie && (
                            <div className={cn('item')} onClick={() => history.push('/pesticides')}>
                                <div className={cn('icon-wrap')}>
                                    <Icon component={CatalogIcon} />
                                </div>
                                <span className={cn('text-wrap')}>
                                    {t('plant protection')}
                                </span>
                            </div>
                        )}
                    </PanelContent>
                </PanelLayout>
            </Layout>
        );
    }
}
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Icon from '@ant-design/icons';
import {
    Layout, Spin, Collapse, Menu
} from 'antd';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    Title, Tabs, SideBar, PanelLayout
} from '../../components';
import Determinant from '../../../public/images/sidebar/determinant.svg';

import cn from '../../utils/cn';

import './style.less';
import { actions, selectors } from '../../models';

import { DETERMINANTS } from '../../constants/determinats';

const { PanelHeader, PanelContent } = PanelLayout;
const { Panel } = Collapse;


const {
    getCurDeterminantTypes
} = selectors;

const mstp = state => ({
    determinants: getCurDeterminantTypes(state)
});

const mdtp = {};

@connect(mstp, mdtp)
@withTranslation('determinant-info')
@cn('determinant-info')
export default class Catalog extends Component {
    render() {
        const { cn } = this;

        const { t, history, determinants } = this.props;

        return (
            <Layout key="page-determinants-info" className={cn()}>
                <PanelLayout>
                    <PanelHeader>
                        <Title title={t('title')} />
                    </PanelHeader>
                    <PanelContent>
                        <Collapse expandIconPosition="right">
                            {determinants.map(item => (
                                <Panel header={(
                                    <div className={cn('item')}>
                                        <div className={cn('icon-wrap')}>
                                            <Icon component={Determinant} />
                                        </div>
                                        <span className={cn('text-wrap')}>
                                            {t(DETERMINANTS[item.name])}
                                        </span>
                                    </div>
                                )}
                                key={item.id}>
                                    <div>
                                        {item.description}
                                        <Link to={`/determinant/${item.id}`}>{t('start')}</Link>
                                    </div>
                                </Panel>
                            ))}
                        </Collapse>
                    </PanelContent>
                </PanelLayout>
            </Layout>
        );
    }
}
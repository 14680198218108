import * as constants from '../constants';
import converter from '../converter';

const initialState = [];

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case constants.getSyncedLayers.success: {
            return action.payload.layers;
        }

        case constants.getSyncedLayers.error: {
            return { error: 'Cannot get layers' };
        }

        default:
            return state;
    }
}

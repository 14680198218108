import { combineReducers } from 'redux';

import data from './data';
import list from './list';
import newOrganisation from './org.js'

export default combineReducers({
    newOrganisation,
    data,
    list
});

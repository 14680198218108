import React, { useEffect, useState, useRef } from 'react';
import {
    Table
} from 'antd';

import { useTranslation } from 'react-i18next';

import '../style.less';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

const RegistriesTable = ({ tableData, setSelectedItems }) => {
    const { t } = useTranslation('fed registry');
    const history = useHistory();

    const reformatDate = (inpDate) => {
        const dateSplit = inpDate?.length > 0 ? inpDate?.split('-') : [];
        return dateSplit?.length > 0 ? `${dateSplit[2]}.${dateSplit[1]}.${dateSplit[0]}` : '';
    };

    const onlyUniqieValues = (value, index, array) => {
        return array.indexOf(value) === index;
    };

    const getUserFilters = () => {
        const usrArray = [];
        tableData?.forEach(x => {
            const filteredArr = usrArray.filter(y => y.id === x?.owner?.id)[0];
            if (filteredArr?.length < 1) {
                usrArray.push({
                    text: x?.owner?.name,
                    value: x?.owner?.id
                });
            }
        });

        return usrArray;
    };

    const columns = [
        {
            title: t('protocol id'),
            dataIndex: 'id',
            key: 'id',
            sorter: (a, b) => a.id - b.id
        },
        {
            title: t('protocol creation date'),
            dataIndex: 'date_created',
            key: 'date_created',
            sorter: (a, b) => moment(a.date_created) - moment(b.date_created),
            render: (val) => reformatDate(val)
        },
        {
            title: t('protocol owner'),
            dataIndex: ['owner', 'name'],
            key: 'owner',
            // filters: getUserFilters,
            filterSearch: true,
            onFilter: (value, record) => record?.owner?.name?.toLowerCase().startsWith(value?.toLowerCase())
        },
        {
            title: t('protocol num'),
            dataIndex: 'protocol_number',
            key: 'protocol_number',
            sorter: (a, b) => a.protocol_number - b.protocol_number
        },
        {
            title: t('protocol sample date'),
            dataIndex: 'acceptation_date',
            key: 'acceptation_date',
            sorter: (a, b) => moment(a.acceptation_date) - moment(b.acceptation_date),
            render: (val) => reformatDate(val)
        },
        {
            title: t('protocol examination date'),
            dataIndex: 'examination_date',
            key: 'examination_date',
            sorter: (a, b) => moment(a.examination_date) - moment(b.examination_date),
            render: (val) => reformatDate(val)
        },
        {
            title: t('protocol field num'),
            dataIndex: 'field_num',
            sorter: (a, b) => a.field_num - b.field_num,
            key: 'field_num'
        },
        {
            title: t('protocol culture'),
            dataIndex: ['culture', 'name'],
            sorter: (a, b) => a?.culture?.name - b?.culture?.name,
            key: ''
        },
        {
            title: t('protocol status'),
            dataIndex: '',
            key: 'status',
            render: (value, record) => {
                return record?.archived ? t('status archived') : record?.public ? t('status published') : t('status edited');
            }
        }
    ];

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedItems(selectedRows);
        },
        getCheckboxProps: (record) => ({
            disabled: record?.name === 'test block',
            id: record?.id
        })
    };

    return (
        <Table
            rowKey="id"
            onRow={(record) => {
                return {
                    onClick: () => {
                        history.push(`/fed-registry/${record.id}`);
                        setTimeout(() => window.location.reload(), 200);
                    }
                };
            }}
            rowSelection={{
                type: 'checkbox',
                ...rowSelection
            }}
            dataSource={tableData}
            columns={columns} />
    );
};

export default RegistriesTable;
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
    Col, Collapse, Form, Layout, Row, Spin
} from 'antd';
import { useTranslation } from 'react-i18next';

import { useClassName } from 'utils/cn';
import { ProfileOutlined, MenuOutlined, DownloadOutlined } from '@ant-design/icons';

import {
    Button, PanelLayout, Title
} from '../../components';
import './style.less';
import { actions, selectors } from '../../models';
import { Data } from './components/data';
import { Analysis } from './components/analysis';
import { Results } from './components/results';
import { makeRequest, makeResponse } from './components/utils';
import { getGenusOfMicroCatalogCalculatorsMyco } from '../../models/calculators-myco/selectors';
import { curMycoFields, curUploadFile } from '../../models/mycological-registry/selectors';
import { getFilteredFields } from '../../models/mycological-registry/actions';
import { Azotobacter } from './components/azotobacter';
import { getUsersList } from '../../models/sample-log/actions';
import { usrListResponse } from '../../models/sample-log/selectors';

const {
    getMycologicalRegistry,
    updateMycologicalRegistry,
    addMycologicalRegistry,
    clearMycologicalRegistry
} = actions;

const { curMycoRegistry, curMycoRegistries, isMycoRegistryLoading } = selectors;

const { Panel } = Collapse;
const { PanelHeader, PanelContent } = PanelLayout;

const Calculator = () => {
    const cn = useClassName('calculators');
    const match = useRouteMatch();
    const { params: { type, id } } = match;
    const registry = useSelector(state => curMycoRegistry(state));
    const isLoading = useSelector(state => isMycoRegistryLoading(state));
    const micro = useSelector(state => getGenusOfMicroCatalogCalculatorsMyco(state));
    const users = useSelector(state => usrListResponse(state));
    const uploadFile = useSelector(state => curUploadFile(state));
    const fields = useSelector(state => curMycoFields(state));
    const dispatch = useDispatch();
    const { t } = useTranslation('mycological registry');
    const [form] = Form.useForm();
    const history = useHistory();

    const [usersSearchString, setUsersSearchString] = useState('');
    const [fullUsersList, setFullUsersList] = useState([]);
    const [usersPage, setUsersPage] = useState(1);

    useEffect(() => {
        if (usersSearchString?.length > 0 && usersPage !== 1) {
            dispatch(getUsersList({
                page: 1,
                searchString: usersSearchString
            }));
        } else {
            dispatch(getUsersList({
                page: usersPage,
                searchString: usersSearchString
            }));
        }
    }, [usersPage, usersSearchString]);

    useEffect(() => {
        const listPortion = users?.results;
        const listCopy = [...fullUsersList];
        const idsCopy = listCopy?.map(x => x?.id);
        if (listPortion?.length > 0) {
            listPortion.forEach(newListEntry => {
                if (!idsCopy?.includes(newListEntry?.id)) {
                    listCopy.push(newListEntry);
                    idsCopy.push(newListEntry?.id);
                }
            });

            setFullUsersList([...listCopy]);
        }
        const nextPage = users?.next;
        if (nextPage?.length > 0) {
            setUsersPage(usersPage + 1);
        }
    }, [users]);

    const [mycobiotaType1, setMycobiotaType1] = useState([]);
    const [mycobiotaType2, setMycobiotaType2] = useState([]);
    const [mycobiotaType3, setMycobiotaType3] = useState([]);
    const [uploadedFiles1, setUploadedFiles1] = useState([]);
    const [uploadedFiles2, setUploadedFiles2] = useState([]);
    const [uploadedFiles3, setUploadedFiles3] = useState([]);
    const [isDocLoading, setIsDocLoading] = useState(false);
    const [noLoader, setNoLoader] = useState(false);

    const repetitions = React.useMemo(() =>
        (registry?.microorganisms ? registry?.microorganisms[0].repetitions.length : 0),
    [registry]);

    useEffect(() => {
        if (uploadFile?.id) {
            const groupId = parseInt(uploadFile?.picture_desc?.split('/')[0], 10);

            if (groupId === 1) {
                setUploadedFiles1([...uploadedFiles1, {
                    id: uploadFile.id,
                    name: uploadFile.picture_name,
                    url: uploadFile.picture_file
                }]);
                const fieldValues = form.getFieldsValue();
                fieldValues.pictures1 = [...registry?.pictures1?.map(x => x.id), uploadFile.id];
                onFinish(fieldValues);
            }

            if (groupId === 2) {
                setUploadedFiles2([...uploadedFiles2, {
                    id: uploadFile.id,
                    name: uploadFile.picture_name,
                    url: uploadFile.picture_file
                }]);
                const fieldValues = form.getFieldsValue();
                fieldValues.pictures2 = [...registry?.pictures2?.map(x => x.id), uploadFile.id];
                onFinish(fieldValues);
            }

            if (groupId === 3) {
                setUploadedFiles3([...uploadedFiles3, {
                    id: uploadFile.id,
                    name: uploadFile.picture_name,
                    url: uploadFile.picture_file
                }]);
                const fieldValues = form.getFieldsValue();
                fieldValues.pictures3 = [...registry?.pictures3?.map(x => x.id), uploadFile.id];
                onFinish(fieldValues);
            }
        }
    }, [uploadFile]);

    useEffect(() => {
        if (registry?.id && registry.owner) {
            dispatch(getFilteredFields({ userId: parseInt(registry.owner, 10) }));
        }
    }, [registry]);

    useEffect(() => {
        if (registry?.microorganisms && id) {
            const data = makeResponse(registry);
            if (data?.mycobiota_type_1?.length > 0) {
                const mycobiotasRaw = data?.mycobiota_type_1.map(x => {
                    return {
                        name: x?.genius?.name,
                        mycobiota_type: 1,
                        genius: x.genius?.id
                    };
                });
                setMycobiotaType1(mycobiotasRaw.filter(x => x?.genius !== 40));
            }
            if (data?.mycobiota_type_2?.length > 0) {
                const mycobiotasRaw = data?.mycobiota_type_2.map(x => {
                    return {
                        name: x?.genius?.name,
                        mycobiota_type: 2,
                        genius: x.genius?.id
                    };
                });
                setMycobiotaType2(mycobiotasRaw.filter(x => x?.genius !== 40));
            }
            if (data?.mycobiota_type_3?.length > 0) {
                const mycobiotasRaw = data?.mycobiota_type_3.map(x => {
                    return {
                        name: x?.genius?.name,
                        mycobiota_type: 3,
                        genius: x.genius?.id
                    };
                });
                setMycobiotaType3(mycobiotasRaw.filter(x => x?.genius !== 40));
            }
            const dataBacteriaRemoved = data;
            const typeOneNamed = data?.mycobiota_type_1 ? [...data.mycobiota_type_1].filter(x => x?.genius !== 40).map(y => {
                return {
                    label: y?.genius?.name,
                    value: y?.genius?.id
                };
            }) : [];
            const typeTwoNamed = data?.mycobiota_type_2 ? [...data.mycobiota_type_2].filter(x => x?.genius !== 40).map(y => {
                return {
                    label: y?.genius?.name,
                    value: y?.genius?.id
                };
            }) : [];
            const typeThreeNamed = data?.mycobiota_type_3 ? [...data.mycobiota_type_3].filter(x => x?.genius !== 40).map(y => {
                return {
                    label: y?.genius?.name,
                    value: y?.genius?.id
                };
            }) : [];
            dataBacteriaRemoved.mycobiota_type_1 = typeOneNamed;
            dataBacteriaRemoved.mycobiota_type_2 = typeTwoNamed;
            dataBacteriaRemoved.mycobiota_type_3 = typeThreeNamed;
            dataBacteriaRemoved.owner = parseInt(dataBacteriaRemoved.owner, 10);

            form.setFieldsValue(dataBacteriaRemoved);
        }
    }, [registry, id]);

    useEffect(() => {
        if (id) {
            dispatch(getMycologicalRegistry(id));
        } else {
            dispatch(clearMycologicalRegistry());
        }
    }, [id, type]);

    const onValuesChange = (value, values) => {
        const updatedField = Object.keys(value)[0];
        switch (updatedField) {
            case 'mycobiota_type_1':
                setMycobiotaType1(value[updatedField].map(x => {
                    return {
                        name: x.label,
                        mycobiota_type: 1,
                        genius: x.value
                    };
                }));
                break;
            case 'mycobiota_type_2':
                setMycobiotaType2(value[updatedField].map(x => {
                    return {
                        name: x.label,
                        mycobiota_type: 2,
                        genius: x.value
                    };
                }));
                break;
            case 'mycobiota_type_3':
                setMycobiotaType3(value[updatedField].map(x => {
                    return {
                        name: x.label,
                        mycobiota_type: 3,
                        genius: x.value
                    };
                }));
                break;
            default:
                break;
        }
    };

    const onFinish = (values) => {
        let sendValues = {};
        if (values?.owner) {
            sendValues = makeRequest(values, registry, dispatch, fieldsList);
        } else {
            const formValues = form?.getFieldsValue();
            sendValues = makeRequest(formValues, registry, dispatch, fieldsList);
        }

        const fieldsListArr = [];
        const fldKeysArr = Object.keys(fields);

        fldKeysArr.forEach(key => {
            if (key !== 'next') {
                fieldsListArr.push(fields[key]);
            }
        });

        console.log('SEND VALS', sendValues)
        console.log('fields', fields)
        console.log('fieldsArr', fieldsListArr)
        console.log('fldKeysArr', fldKeysArr)

        if (!sendValues?.vega_key) {
            sendValues.vega_key = fieldsListArr?.filter(x => x?.field_num === sendValues?.field_num)[0]?.vega_key;
        }
        sendValues.owner_user = sendValues?.owner;

        if (id) {
            dispatch(updateMycologicalRegistry({ values: sendValues, id }));
        } else {
            dispatch(addMycologicalRegistry({
                history,
                values: sendValues
            }));
        }
    };

    const generateDoc = async () => {
        setIsDocLoading(true);
        fetch(`/api/v1/mycological_calculator/mycological_calculator_reestr/${id}/download_protocol/`, {
            headers: {
                Authorization: `JWT ${localStorage.getItem('token')}`
            }
        }).then((resp) => {
            resp.blob().then((blb) => {
                const url = window.URL.createObjectURL(blb);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = `MycologicalRegistry-${id}`;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
                setIsDocLoading(false);
            });
        });
    };

    const [fieldsList, setFieldsList] = useState([]);

    if (isLoading && !noLoader) {
        return (
            <Layout key="page-mycological-registry" className={cn()}>
                <PanelLayout>
                    <PanelHeader>
                        <Title title={t('protocol')} />
                    </PanelHeader>
                    <PanelContent>
                        <Spin />
                    </PanelContent>
                </PanelLayout>
            </Layout>
        );
    }


    return (
        <Layout key="page-mycological-registry" className={cn()}>
            <PanelLayout>
                <PanelHeader>
                    <Title title={t('protocol')} />
                </PanelHeader>
                <PanelContent>
                    <Form
                        form={form}
                        // onFinish={onFinish}
                        onValuesChange={onValuesChange}>
                        <Collapse
                            defaultActiveKey={[
                                '1', '2', '3', '4'
                            ]}
                            expandIconPosition="right"
                            className={cn('collapse')}>
                            <Panel header={<><MenuOutlined />{t('data tab')}</>}
                                key={1}>
                                <Data
                                    setFieldSaver={setFieldsList}
                                    setUserSearchString={setUsersSearchString}
                                    registry={registry}
                                    t={t}
                                    form={form}
                                    usersList={fullUsersList} />
                            </Panel>
                            <Panel header={<><ProfileOutlined />{t('table1 tab')}</>}
                                key={2}>
                                <Analysis
                                    onFormFinish={onFinish}
                                    mycobiota1={mycobiotaType1}
                                    mycobiota2={mycobiotaType2}
                                    mycobiota3={mycobiotaType3}
                                    uploadedFiles={uploadedFiles1}
                                    setNoLoader={setNoLoader}
                                    registry={registry}
                                    t={t}
                                    form={form}
                                    rep={repetitions} />
                            </Panel>
                            <Panel
                                header={<><ProfileOutlined />{t('table2 tab')}</>}
                                key={3}>
                                <Results
                                    onFormFinish={onFinish}
                                    uploadedFiles={uploadedFiles2}
                                    registry={registry}
                                    setNoLoader={setNoLoader}
                                    t={t}
                                    form={form} />
                            </Panel>
                            <Panel
                                header={<><ProfileOutlined />{t('table3 tab')}</>}
                                key={4}>
                                <Azotobacter
                                    t={t}
                                    onFormFinish={onFinish}
                                    uploadedFiles={uploadedFiles3}
                                    registry={registry}
                                    form={form}
                                    rep={repetitions} />
                            </Panel>

                        </Collapse>
                        <Row className={cn('button')} style={{ marginTop: '30px' }}>
                            <Col>
                                <Button
                                    onClick={onFinish}
                                    type="primary">
                                    <DownloadOutlined />
                                    {t('calculate')}
                                </Button>
                                {id && (
                                    <Button
                                        onClick={generateDoc}
                                        type="primary">
                                        {isDocLoading && (
                                            <Spin />
                                        )}
                                        {!isDocLoading && (
                                            <>
                                                <DownloadOutlined />
                                                {t('generate')}
                                            </>
                                        )}
                                    </Button>
                                )}
                            </Col>
                        </Row>
                    </Form>
                </PanelContent>
            </PanelLayout>
        </Layout>
    );
};

export default Calculator;

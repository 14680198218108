import React, {
    useEffect, useState, useMemo
} from 'react';
import { useClassName } from 'utils/cn';
import './style.less';
import { useTranslation } from 'react-i18next';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    LineController,
    BarController,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';
import { Line, Chart } from 'react-chartjs-2';
import {
    DatePicker, Typography, Space, Spin
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import {
    analysisIsLoading,
    curAnalysisMeteodata,
    curEffectiveMeteodata,
    curPrecipitationMeteodata, effectiveIsLoading, meteodataIsLoading, precipitationIsLoading
} from '../../../../models/item-meteodata/selectors';
import {
    analysisFactLineColor,
    analysisForecastLineColor,
    analysisPrecipitationLineColor,
    chartLegendSettings,
    dayNames,
    effectiveLineColor,
    monthNames,
    precipitationLineColor,
    tooltipBackgroundColor,
    tooltipBorderColor,
    tooltipBorderWidth,
    tooltipTextColor
} from './constants';
import {
    getAnalysisData, getEffectiveData, getPrecipitationData
} from '../../../../models/item-meteodata/actions';
import { toFixed } from '../../../../utils/utils';

const { Text } = Typography;
const { RangePicker } = DatePicker;

const ChartRepresentation = (props) => {
    const {
        isGeneralDateSet,
        generalDates,
        setGeneralDates
    } = props;
    const dispatch = useDispatch();
    const match = useRouteMatch();
    const cn = useClassName('meteodata');
    const { t } = useTranslation('meteodata');

    const { year } = match.params;

    const [analysisDate, setAnalysisDate] = useState({
        start_date: '',
        end_date: ''
    });
    const [effectiveDate, setEffectiveDate] = useState({
        start_date: '',
        end_date: ''
    });
    const [precipitationDate, setPrecipitationDate] = useState({
        start_date: '',
        end_date: ''
    });

    const analysisData = useSelector(state => curAnalysisMeteodata(state));
    const effectiveData = useSelector(state => curEffectiveMeteodata(state));
    const precipitationData = useSelector(state => curPrecipitationMeteodata(state));

    const isLoadingAll = useSelector(state => meteodataIsLoading(state));
    const isLoadingAnalysis = useSelector(state => analysisIsLoading(state));
    const isLoadingEffective = useSelector(state => effectiveIsLoading(state));
    const isLoadingPrecipitation = useSelector(state => precipitationIsLoading(state));

    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        BarElement,
        LineElement,
        Title,
        Tooltip,
        Legend,
        LineController,
        BarController
    );

    useEffect(() => {
        setAnalysisDate(generalDates);
        setEffectiveDate(generalDates);
        setPrecipitationDate(generalDates);
    }, [generalDates]);

    const analysisMemoised = useMemo(() => {
        const analysisChartDates = [];
        const analysisFactChartDataset = [];
        const analysisForecastChartDataset = [];
        const analysisPrecipitationChartDataset = [];
        analysisData.forEach((item) => {
            if (!analysisChartDates.includes(item.date)) {
                analysisChartDates.push(item.date);
            }
            analysisFactChartDataset.push({
                x: item.date,
                y: item.avg_temperature_fact,
                avg_humidity: item.avg_humidity,
                wind_speed: item.wind_speed
            });
            analysisForecastChartDataset.push({
                x: item.date,
                y: item.avg_temperature_forecast,
                avg_humidity: item.avg_humidity,
                wind_speed: item.wind_speed
            });
            analysisPrecipitationChartDataset.push({
                x: item.date,
                y: item.precipitation,
                avg_humidity: item.avg_humidity,
                wind_speed: item.wind_speed
            });
        });
        return {
            fact: analysisFactChartDataset,
            forecast: analysisForecastChartDataset,
            precipitation: analysisPrecipitationChartDataset,
            dates: analysisChartDates
        };
    }, [analysisData]);

    const effectiveMemoised = useMemo(() => {
        const effectiveChartDates = [];
        const effectiveChartDataset = [];
        effectiveData.forEach((item) => {
            if (!effectiveChartDates.includes(item.date)) {
                effectiveChartDates.push(item.date);
            }
            effectiveChartDataset.push({
                x: item.date,
                y: item.set_fact
            });
        });
        return {
            dataset: effectiveChartDataset,
            dates: effectiveChartDates
        };
    }, [effectiveData]);

    const precipitationMemoised = useMemo(() => {
        const precipitationChartDates = [];
        const precipitationChartDataset = [];
        precipitationData.forEach((item) => {
            if (!precipitationChartDates.includes(item.date)) {
                precipitationChartDates.push(item.date);
            }
            precipitationChartDataset.push({
                x: item.date,
                y: item.sum_of_precipitation
            });
        });
        return {
            dataset: precipitationChartDataset,
            dates: precipitationChartDates
        };
    }, [precipitationData]);

    useEffect(() => {
        if (analysisDate.start_date.length > 0 && analysisDate.end_date.length > 0) {
            const { id } = match.params;

            if (isGeneralDateSet) {
                dispatch(getAnalysisData({
                    vega_key: id,
                    body: { ...analysisDate, year }
                }));
            } else {
                setGeneralDates({
                    start_date: analysisDate.start_date,
                    end_date: analysisDate.end_date
                });
            }
        }
    }, [analysisDate]);

    useEffect(() => {
        if (effectiveDate.start_date.length > 0 && effectiveDate.end_date.length > 0) {
            const { id } = match.params;

            if (isGeneralDateSet) {
                dispatch(getEffectiveData({
                    vega_key: id,
                    body: { ...effectiveDate, year }
                }));
            } else {
                setGeneralDates({
                    start_date: effectiveDate.start_date,
                    end_date: effectiveDate.end_date
                });
            }
        }
    }, [effectiveDate]);

    useEffect(() => {
        if (precipitationDate.start_date.length > 0 && precipitationDate.end_date.length > 0) {
            const { id } = match.params;

            if (isGeneralDateSet) {
                dispatch(getPrecipitationData({
                    vega_key: id,
                    body: { ...precipitationDate, year }
                }));
            } else {
                setGeneralDates({
                    start_date: precipitationDate.start_date,
                    end_date: precipitationDate.end_date
                });
            }
        }
    }, [precipitationDate]);

    const getLabelName = (valueIndex, chartName) => {
        switch (chartName) {
            case 'analysis':
                if (analysisMemoised.dates.length > 1) {
                    const currentItemSplit = analysisMemoised.dates[valueIndex].split('-');
                    return (`${currentItemSplit[2]} ${t(monthNames[currentItemSplit[1] - 1])}`);
                }
                break;
            case 'effective':
                if (effectiveMemoised.dates.length > 1) {
                    const currentItemSplit = effectiveMemoised.dates[valueIndex].split('-');
                    return (`${currentItemSplit[2]} ${t(monthNames[currentItemSplit[1] - 1])}`);
                }
                break;
            case 'precipitation':
                if (precipitationMemoised.dates.length > 1) {
                    const currentItemSplit = precipitationMemoised.dates[valueIndex].split('-');
                    return (`${currentItemSplit[2]} ${t(monthNames[currentItemSplit[1] - 1])}`);
                }
                break;
            default:
                return '';
        }
    };

    const getDayName = (dateString) => {
        const dateObject = new Date(dateString);
        const currentDateSplit = dateString.split('-');
        if (dateObject.getDay() === 0) {
            return dateString.length && dateString.length > 0
                ? `${t(dayNames[6])}, ${currentDateSplit[2]} ${t(monthNames[currentDateSplit[1]])}` : '';
        }
        return dateString.length && dateString.length > 0
            ? `${t(dayNames[dateObject.getDay() - 1])}, ${currentDateSplit[2]} ${t(monthNames[currentDateSplit[1] - 1])}` : '';
    };

    const handleDateChange = (chartName, dateStrings) => {
        switch (chartName) {
            case 'analysis':
                setAnalysisDate({
                    start_date: dateStrings[0],
                    end_date: dateStrings[1]
                });
                break;
            case 'effective':
                setEffectiveDate({
                    start_date: dateStrings[0],
                    end_date: dateStrings[1]
                });
                break;
            case 'precipitation':
                setPrecipitationDate({
                    start_date: dateStrings[0],
                    end_date: dateStrings[1]
                });
                break;
            default:
                break;
        }
    };

    const formatXValue = (xPoint) => `${t('date')}: ${xPoint[0].label}`;
    const formatYValue = (yPoint) => [
        `${yPoint.dataset.label}: ${yPoint.raw.y ? toFixed(parseFloat(yPoint.raw.y), 1) : 0}`,
        `${t('humidity')}: ${yPoint.raw.avg_humidity ? toFixed(parseFloat(yPoint.raw.avg_humidity), 1) : 0}`,
        `${t('wind speed')}: ${yPoint.raw.wind_speed ? toFixed(parseFloat(yPoint.raw.wind_speed), 1) : 0}`
    ];

    const formatYValueFixed = (yPoint) => [
        `${yPoint.dataset.label}: ${yPoint.raw.y ? toFixed(parseFloat(yPoint.raw.y), 1) : 0}`
    ];

    const analysisChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: chartLegendSettings,
            tooltip: {
                callbacks: {
                    title: (xDataPoint) => formatXValue(xDataPoint),
                    label: (yDataPoint) => formatYValue(yDataPoint)
                },
                backgroundColor: tooltipBackgroundColor,
                borderWidth: tooltipBorderWidth,
                bodyColor: tooltipTextColor,
                titleColor: tooltipTextColor,
                borderColor: tooltipBorderColor
            }
        },
        scales: {
            x: {
                ticks: {
                    callback: (val) => getLabelName(val, 'analysis')
                },
                display: true
            },
            y: {
                ticks: {
                    stepSize: 5
                },
                type: 'linear',
                display: true,
                beginAtZero: false,
                max: (Math.max(
                    Math.max(...analysisMemoised.fact.map(o => o.y)),
                    Math.max(...analysisMemoised.forecast.map(o => o.y))
                ) + 30),
                min: (Math.min(
                    Math.min(...analysisMemoised.fact.map(o => o.y)),
                    Math.min(...analysisMemoised.forecast.map(o => o.y))
                ) - 30)
            },
            y2: {
                type: 'linear',
                display: false,
                position: 'right',
                beginAtZero: true,
                max: (Math.max(...analysisMemoised.precipitation.map(o => o.y)) + 30)
            }
        }
    };

    const analysDates = [...analysisMemoised.dates];
    const analysisChartData = {
        analysDates,
        datasets: [
            {
                type: 'line',
                label: t('temperature fact'),
                data: analysisMemoised.fact,
                borderColor: analysisFactLineColor,
                backgroundColor: analysisFactLineColor,
                yAxisID: 'y',
                xAxisID: 'x',
                lineTension: 0.3,
                pointStyle: 'circle',
                showLine: true
            },
            {
                type: 'line',
                label: t('temperature forecast'),
                data: analysisMemoised.forecast,
                borderColor: analysisForecastLineColor,
                backgroundColor: analysisForecastLineColor,
                yAxisID: 'y',
                xAxisID: 'x',
                lineTension: 0.3,
                pointStyle: 'circle'
            },
            {
                type: 'bar',
                label: t('day precipitation sum'),
                data: analysisMemoised.precipitation,
                borderColor: analysisPrecipitationLineColor,
                backgroundColor: analysisPrecipitationLineColor,
                yAxisID: 'y2',
                xAxisID: 'x'
            }
        ]
    };

    const effectiveChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: chartLegendSettings,
            tooltip: {
                callbacks: {
                    title: (xDataPoint) => formatXValue(xDataPoint),
                    label: (yDataPoint) => formatYValueFixed(yDataPoint)
                },
                backgroundColor: tooltipBackgroundColor,
                borderWidth: tooltipBorderWidth,
                bodyColor: tooltipTextColor,
                titleColor: tooltipTextColor,
                borderColor: tooltipBorderColor
            }
        },
        scales: {
            x: {
                ticks: {
                    callback: (val) => getLabelName(val, 'effective')
                },
                display: true
            },
            y: {
                ticks: {
                    stepSize: 50
                },
                type: 'linear',
                display: true,
                max: 4000,
                min: 0
            }
        }
    };

    const effDates = [...effectiveMemoised.dates];

    const effectiveChartData = {
        effDates,
        datasets: [
            {
                label: t('temperature effective sum'),
                data: effectiveMemoised.dataset,
                borderColor: effectiveLineColor,
                backgroundColor: effectiveLineColor,
                yAxisID: 'y',
                xAxisID: 'x',
                lineTension: 0.3,
                pointStyle: 'circle'
            }
        ]
    };

    const precipitationChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: chartLegendSettings,
            tooltip: {
                callbacks: {
                    title: (xDataPoint) => formatXValue(xDataPoint),
                    label: (yDataPoint) => formatYValueFixed(yDataPoint)
                },
                backgroundColor: tooltipBackgroundColor,
                borderWidth: tooltipBorderWidth,
                bodyColor: tooltipTextColor,
                titleColor: tooltipTextColor,
                borderColor: tooltipBorderColor
            }
        },
        scales: {
            x: {
                ticks: {
                    callback: (val) => getLabelName(val, 'precipitation')
                },
                display: true
            },
            y: {
                type: 'linear',
                display: true,
                max: (Math.max(...precipitationMemoised.dataset.map(o => o.y)) + 100),
                min: (Math.min(...precipitationMemoised.dataset.map(o => o.y)) - 100),
                ticks: {
                    stepSize: 50
                }
            }
        }
    };

    const precDates = [...precipitationMemoised.dates];

    const precipitationChartData = {
        precDates,
        datasets: [
            {
                label: t('stacked precipitation'),
                data: precipitationMemoised.dataset,
                borderColor: precipitationLineColor,
                backgroundColor: precipitationLineColor,
                yAxisID: 'y',
                xAxisID: 'x',
                lineTension: 0.3,
                pointStyle: 'circle'
            }
        ]
    };

    return (
        <div>
            <div>
                <Space direction="vertical">
                    <Text className={cn('chart-header')}>
                        { t('analysis chart title') }
                    </Text>
                    <Text type="secondary">
                        { t('analysis chart subtitle') }
                    </Text>
                    <Space>
                        <RangePicker onChange={(date, dateString) => handleDateChange('analysis', dateString)} />
                        <Text style={{ fontWeight: '700' }}>
                            {analysisDate.start_date.length > 0 && analysisDate.end_date.length > 0 && `${getDayName(analysisDate.start_date)} - ${getDayName(analysisDate.end_date)}`}
                        </Text>
                    </Space>
                    <Text style={{ fontWeight: '500' }}>
                        {`${t('avg air humidity')} ${toFixed(analysisData?.[0]?.avg_humidity, 2) ?? 0}`}
                    </Text>
                    <Text style={{ fontWeight: '500' }}>
                        {`${t('avg air speed')} ${toFixed(analysisData?.[0]?.wind_speed, 2) ?? 0}`}
                    </Text>
                </Space>
                <Spin spinning={isLoadingAll || isLoadingAnalysis}>
                    <Chart
                        height="300px"
                        type="bar"
                        data={analysisChartData}
                        options={analysisChartOptions} />
                </Spin>
            </div>
            <div style={{
                display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between'
            }}>
                <div style={{ width: '100%' }}>
                    <Space direction="vertical">
                        <Text className={cn('chart-header')}>
                            { t('effective chart title') }
                        </Text>
                        <Space>
                            <RangePicker onChange={(date, dateString) => handleDateChange('effective', dateString)} />
                            <Text style={{ fontWeight: '700' }}>
                                {effectiveDate.start_date.length > 0 && effectiveDate.end_date.length > 0 && `${getDayName(effectiveDate.start_date)} - ${getDayName(effectiveDate.end_date)}`}
                            </Text>
                        </Space>
                        <Text style={{ fontWeight: '500' }}>
                            {`${t('avg air humidity')} ${toFixed(effectiveData?.[0]?.avg_humidity, 2) ?? 0}`}
                        </Text>
                        <Text style={{ fontWeight: '500' }}>
                            {`${t('avg air speed')} ${toFixed(effectiveData?.[0]?.wind_speed, 2) ?? 0}`}
                        </Text>
                    </Space>
                    <div style={{ width: '100%' }}>
                        <Spin spinning={isLoadingAll || isLoadingEffective}>
                            <Line
                                width="100%"
                                height="300px"
                                options={effectiveChartOptions}
                                data={effectiveChartData} />
                        </Spin>
                    </div>
                </div>

                <div style={{ width: '100%' }}>
                    <Space direction="vertical">
                        <Text className={cn('chart-header')}>
                            { t('precipitation chart title') }
                        </Text>
                        <Space>
                            <RangePicker onChange={(date, dateString) => handleDateChange('precipitation', dateString)} />
                            <Text style={{ fontWeight: '700' }}>
                                {precipitationDate.start_date.length > 0 && precipitationDate.end_date.length > 0 && `${getDayName(precipitationDate.start_date)} - ${getDayName(precipitationDate.end_date)}`}
                            </Text>
                        </Space>
                        <Text style={{ fontWeight: '500' }}>
                            {`${t('avg air humidity')} ${toFixed(precipitationData?.[0]?.avg_humidity, 2) ?? 0}`}
                        </Text>
                        <Text style={{ fontWeight: '500' }}>
                            {`${t('avg air speed')} ${toFixed(precipitationData?.[0]?.wind_speed, 2) ?? 0}`}
                        </Text>
                    </Space>
                    <div style={{ width: '100%' }}>
                        <Spin spinning={isLoadingAll || isLoadingPrecipitation}>
                            <Line
                                width="100%"
                                height="300px"
                                options={precipitationChartOptions}
                                data={precipitationChartData} />
                        </Spin>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ChartRepresentation;
import React, { Component, useState, useEffect } from 'react';
import {
    Modal, Button, Alert, Form, Checkbox, Tooltip, Select, Spin,
    message
} from 'antd';
import {
    CloseOutlined,
    PlusCircleOutlined,
    QuestionCircleOutlined
} from '@ant-design/icons';

import request from '../../../utils/request';
import './setup-modal-window.less';
import { useTranslation } from 'react-i18next';

function SetupModalWindow({
    setupId, closeModal, layerOptions, reset
}) {
    const { t } = useTranslation('sampling points')

    const [isLoading, setIsLoading] = useState(false);
    const [layerInfo, setLayerInfo] = useState(null);

    const [isWaiting, setIsWaiting] = useState();
    const [cards, setCards] = useState([]);

    const [cardKey, setCardKey] = useState(0);

    const getLayerInfo = async () => {
        try {
            setIsLoading(true);
            const res = await request.get({
                url: `/api/v1/mordovia/layer/${setupId}/`
            });
            setLayerInfo(res);

            setCards(res?.linked_layers.map((layer, idx) => ({
                key: idx,
                value: layer.id
            })));
            setCardKey(res.linked_layers.length);
        } catch {
            message.error('Backend error');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (setupId) {
            getLayerInfo();
        }
    }, [setupId]);

    const setupLayer = async () => {
        try {
            setIsWaiting(true);
            await request.post({
                url: '/api/v1/mordovia/upload_object_data/import_geohub_layer/',
                body: {
                    map_id: layerInfo?.map.map_id_ghb,
                    layer_id: layerInfo?.layer_id_ghb,
                    object_type: 'points',
                    point_num_column: layerInfo?.geohub_column,
                    polygons_layer_id: cards.map(card => card.value)
                }
            });
            reset();
        } catch (e) {
            message.error(`Status: ${e.status}. ${e.statusText}`);
        } finally {
            setIsWaiting(false);
            resetForm();
            closeModal();
        }
    };

    const resetForm = () => {
        setCards([]);
    };

    const newCard = (key, value = null) => ({
        key,
        value
    });

    const Card = ({
        value, setValue, number, remove
    }) => (
        <div
            style={{
                padding: '10px',
                border: '1px solid #F5F5F5',
                borderRadius: '4px'
            }}>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginBottom: '8px'
                }}>
                <div>№{number}</div>
                <CloseOutlined
                    onClick={() => remove()}
                    style={{ cursor: 'pointer' }} />
            </div>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '5px',
                    marginBottom: '4px'
                }}>
                <div>{t('setup modal.card title')}</div>
                <Tooltip
                    title={t('setup modal.card title tooltip')}>
                    <QuestionCircleOutlined
                        style={{
                            display: 'grid',
                            placeItems: 'center',
                            color: 'rgba(0,0,0,0.45)'
                        }} />
                </Tooltip>
            </div>
            <Select
                placeholder={t('setup modal.select placeholder')}
                value={value}
                options={layerOptions}
                onChange={(newValue) => {
                    setValue(number - 1, newValue);
                }} />
        </div>
    );

    return (
        <Modal
            visible={setupId !== null}
            bodyStyle={{
                maxHeight: '300px',
                overflowY: 'auto'
            }}
            title={(
                <div style={{ display: 'flex', gap: '5px' }}>
                    <div>{t('setup modal.title')}</div>
                    <Tooltip
                        title={t('setup modal.title tooltip')}>
                        <QuestionCircleOutlined
                            style={{
                                display: 'grid',
                                placeItems: 'center',
                                color: 'rgba(0,0,0,0.45)'
                            }} />
                    </Tooltip>
                </div>
            )}
            footer={[
                <Button
                    disabled={isWaiting}
                    key="back"
                    onClick={() => {
                        resetForm();
                        closeModal();
                    }}>
              {t('setup modal.cancel button')}
                </Button>,
                <Button
                    loading={isWaiting}
                    key="setup"
                    type="primary"
                    onClick={() => setupLayer()}>
              {t('setup modal.apply button')}
                </Button>
            ]}
            onCancel={() => {
                if (!isWaiting) {
                    resetForm();
                    closeModal();
                }
            }}>
            {isLoading ? (
                <div style={{
                    width: '100%', height: '100%', display: 'grid', placeItems: 'center'
                }}><Spin />
                </div>
            ) : (
                <div className="setup-modal-window__content">
                    <div className="setup-modal-window__content-top">
                        <div
                            onClick={() => {
                                setCards([...cards, newCard(cardKey)]);
                                setCardKey(cardKey + 1);
                            }}
                            className="setup-modal-window__add-dep-button">
                            <PlusCircleOutlined />
                            <div>{t('setup modal.add link button')}</div>
                        </div>
                        <div
                            onClick={() => setCards([])}
                            className={`setup-modal-window__remove-deps-button ${
                                cards.length
                                    ? 'setup-modal-window__remove-deps-button--available'
                                    : ''
                            }`}>
              {t('setup modal.reset links button')}
                        </div>
                    </div>
                    {cards
                        .map((card, idx) => (
                            <Card
                                key={card.key}
                                value={card.value}
                                setValue={(id, value) =>
                                    setCards(
                                        cards.map((c, i) => {
                                            if (id === i) {
                                                c.value = value;
                                            }
                                            return c;
                                        })
                                    )}
                                number={idx + 1}
                                remove={() => setCards(cards.filter((_, i) => i !== idx))} />
                        ))
                        .reverse()}
                </div>
            )}
        </Modal>
    );
}

export default SetupModalWindow;

import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    Button,
    Col,
    Layout, Popover, Row, Spin
} from 'antd';
import { CopyOutlined, DeleteOutlined, DownloadOutlined, PlusCircleOutlined } from '@ant-design/icons';

import { useTranslation } from 'react-i18next';

import { useClassName } from '../../utils/cn';

import {
    PanelLayout, Title
} from '../../components';

import RegistriesTable from './components/registries-table';

import {
    createInitialProtocol,
    getProtocolList,
    updateProtocol
} from '../../models/fed-registry/actions';
import { curNewInitialProtocol, curProtocolList } from '../../models/fed-registry/selectors';

import './style.less';
import { publishProtocol } from '../../models/fed-registry/api';
import { deleteProtocolPhyto, getProtocolsPhyto } from '../../models/calculators-phyto/actions';
import { currentProtocolsList } from '../../models/calculators-phyto/selectors';
import { deleteProtocol } from '../../models/fed-registry/constants';

const { PanelHeader, PanelContent } = PanelLayout;

const FsmRegistries = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const cn = useClassName('calculators');
    const { t } = useTranslation('fsm registries');

    const protocolList = useSelector(state => currentProtocolsList(state));
    const newProt = useSelector(state => curNewInitialProtocol(state));

    const [regTableData, setRegTableData] = useState([]);
    const [selectedTableItems, setSelectedTableItems] = useState([]);
    const [protocolPage, setProtocolPage] = useState(1);
    const [protocolPageSize, setProtocolPageSize] = useState(10);
    
    useEffect(() => {
        if (newProt?.id && newProt?.id > 0) {
            setRegTableData([...regTableData, newProt]);
        }
    }, [newProt]);

    useEffect(() => {
        dispatch(getProtocolsPhyto({
            page: protocolPage,
            pageSize: protocolPageSize
        }));
    }, [protocolPage, protocolPageSize]);

    useEffect(() => {
        if (protocolList?.results?.length > 0) {
            setRegTableData(protocolList?.results);
        }
    }, [protocolList]);

    const handlePageChange = (newPage, newSize) => {
        setProtocolPage(newPage);

        if (newSize !== protocolPageSize) {
            setProtocolPageSize(newSize);
        }
    };

    const deleteSelectedProtocols = () => {
        selectedTableItems?.forEach(item => {
            setTimeout(() => {
                dispatch(deleteProtocolPhyto({
                    id: item?.id
                }));
            }, 150);
        });
        setProtocolPage(1);
    };

    return (
        <Layout key="page-fsm-registries" className={cn()}>
            <PanelLayout>
                <PanelHeader>
                    <Title
                        title={t('title')} />
                </PanelHeader>
                <PanelContent>
                    <Row className="controls-row">
                        <Col span={6}>
                            <Link
                                to="/fsm-registry/"
                                onClick={() => {
                                    setTimeout(() => window.location.reload(), 500);
                                }}>
                                <Button
                                    style={{ width: '100%' }}
                                    type="primary">
                                    <PlusCircleOutlined />
                                    {t('add protocol')}
                                </Button>
                            </Link>
                        </Col>
                        <Col span={12} />
                        <Col span={6}>
                            <Button onClick={deleteSelectedProtocols} disabled={selectedTableItems?.length < 1} style={{ width: '100%' }} type="secondary">
                                <DeleteOutlined />
                                {t('delete protocol')}
                            </Button>
                        </Col>
                    </Row>
                    <RegistriesTable
                        page={protocolPage}
                        pageSize={protocolPageSize}
                        paginationUpdate={handlePageChange}
                        setSelectedItems={setSelectedTableItems}
                        dataSize={protocolList?.count}
                        tableData={regTableData} />
                </PanelContent>
            </PanelLayout>
        </Layout>
    );
};

export default FsmRegistries;
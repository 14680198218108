import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Select } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

import './style.less';

import ColoredTable from './colored-table';
import Chart from './chart';
import Results from './results';
import { calculateDiagnostics } from '../../../../../models/leaf-diagnostics/actions';
import {
    getCurCalculatorField,
    getCurrentCalculatorMordovia
} from '../../../../../models/mordovia/selectors';
import { curLeafResults } from '../../../../../models/leaf-diagnostics/selectors';
import { toFixed } from '../../../../../utils/utils';
import { curMycoUsers } from '../../../../../models/mycological-registry/selectors';
import { getCalculatorMordovia } from '../../../../../models/mordovia/actions';

const Diagnostics = ({
    setFieldRequestDate, selectedField = {}, selectedFed = {}, isCalc = false
}) => {
    const { t } = useTranslation('leaf diagnostics');
    const match = useRouteMatch();
    const dispatch = useDispatch();

    const currentCalculator = useSelector(state => getCurrentCalculatorMordovia(state));
    const calcResults = useSelector(state => curLeafResults(state));
    const users = useSelector(state => curMycoUsers(state));

    useEffect(() => {
        if (calcResults?.id) {
            setExcelTableData([calcResults]);
        }
    }, [currentCalculator, calcResults]);

    const [chloroplastActivity, setChloroplastActivity] = useState(0);
    const [dataConstructed, setDataConstructed] = useState([]);
    const [cardText, setCardText] = useState('');
    const [deficiteElements, setDeficiteElements] = useState([]);

    const [selectedMethod, setSelectedMethod] = useState('');
    const [selectedDate, setSelectedDate] = useState('');
    const [selectedZone, setSelectedZone] = useState([]);
    const [selectedPoint, setSelectedPoint] = useState([]);
    const [fedPoints, setFedPoints] = useState([]);

    useEffect(() => {
        if (selectedFed?.id && isCalc) {
            setChloroplastActivity(selectedFed?.stress_level);
            const tempArray = [];

            const elementKeys = [
                'n', 'p', 'ks', 'kcl',
                'ca', 'mg', 'b', 'cu',
                'zn', 'mn', 'fe',
                'mo', 'co', 'i'
            ];

            elementKeys.forEach(elKey => {
                tempArray.push({
                    name: elKey,
                    deviation: selectedFed[`h_${elKey}`],
                    coefficient: selectedFed[`e_${elKey}`]
                });
            });

            setDeficiteElements([...tempArray].filter(itm => {
                return itm.deviation < 0;
            }).sort((a, b) => {
                return a.last_nom - b.last_nom;
            }).map(fin => fin.name));

            let arrSum = 0;
            tempArray.forEach(x => {
                arrSum += x.deviation;
            });
            const middleValue = toFixed(arrSum / tempArray.length);

            const stopMax = Math.max(...[...tempArray].map(x => x.deviation));
            const stopPositiveLow = Math.floor(stopMax / 3);
            const stopPositiveHigh = stopPositiveLow * 2;

            const stopMin = Math.min(...[...tempArray].map(x => x.deviation));
            const stopNegative = Math.floor(stopMin / 2);

            setCardText(selectedFed?.conclusion);

            setDataConstructed([...tempArray].map(x => {
                if (x.deviation > stopPositiveHigh) {
                    return {
                        name: x.name,
                        deviation: x.deviation,
                        coefficient: x.coefficient,
                        status: 'veryhigh'
                    };
                } else if (x.deviation > stopPositiveLow && x.deviation <= stopPositiveHigh) {
                    return {
                        name: x.name,
                        deviation: x.deviation,
                        coefficient: x.coefficient,
                        status: 'high'
                    };
                } else if (x.deviation >= 0 && x.deviation <= stopPositiveLow) {
                    return {
                        name: x.name,
                        deviation: x.deviation,
                        coefficient: x.coefficient,
                        status: 'optimal'
                    };
                } else if (x.deviation >= stopNegative && x.deviation < 0) {
                    return {
                        name: x.name,
                        deviation: x.deviation,
                        coefficient: x.coefficient,
                        status: 'low'
                    };
                } else if (x.deviation >= stopMin && x.deviation < stopNegative) {
                    return {
                        name: x.name,
                        deviation: x.deviation,
                        coefficient: x.coefficient,
                        status: 'verylow'
                    };
                }
            }));
        }
    }, [selectedFed]);

    useEffect(() => {
        if (calcResults?.id && !isCalc) {
            setChloroplastActivity(calcResults?.stress_level);
            const tempArray = [];

            const elementKeys = [
                'n', 'p', 'ks', 'kcl',
                'ca', 'mg', 'b', 'cu',
                'zn', 'mn', 'fe',
                'mo', 'co', 'i'
            ];

            elementKeys.forEach(elKey => {
                tempArray.push({
                    name: elKey,
                    deviation: calcResults[`h_${elKey}`],
                    coefficient: calcResults[`e_${elKey}`]
                });
            });

            setDeficiteElements([...tempArray].filter(itm => {
                return itm.deviation < 0;
            }).sort((a, b) => {
                return a.last_nom - b.last_nom;
            }).map(fin => fin.name));

            let arrSum = 0;
            tempArray.forEach(x => {
                arrSum += x.deviation;
            });
            const middleValue = toFixed(arrSum / tempArray.length);

            const stopMax = Math.max(...[...tempArray].map(x => x.deviation));
            const stopPositiveLow = Math.floor(stopMax / 3);
            const stopPositiveHigh = stopPositiveLow * 2;

            const stopMin = Math.min(...[...tempArray].map(x => x.deviation));
            const stopNegative = Math.floor(stopMin / 2);

            if (middleValue < 0) {
                setCardText(
                    `${t('card text negative start')} ${middleValue} ${t('card text negative end')}`
                );
            } else if (middleValue >= 0 && middleValue <= 10) {
                setCardText(
                    `${t('card text 0-10 start')} ${middleValue} ${t('card text 0-10 end')}`
                );
            } else if (middleValue >= 11 && middleValue <= 70) {
                setCardText(
                    `${t('card text 11-70 start')} ${middleValue} ${t('card text 11-70 end')}`
                );
            } else if (middleValue > 70) {
                setCardText(
                    `${t('card text more 70 start')} ${middleValue} ${t('card text more 70 end')}`
                );
            }

            setDataConstructed([...tempArray].map(x => {
                if (x.deviation > stopPositiveHigh) {
                    return {
                        name: x.name,
                        deviation: x.deviation,
                        coefficient: x.coefficient,
                        status: 'veryhigh'
                    };
                } else if (x.deviation > stopPositiveLow && x.deviation <= stopPositiveHigh) {
                    return {
                        name: x.name,
                        deviation: x.deviation,
                        coefficient: x.coefficient,
                        status: 'high'
                    };
                } else if (x.deviation >= 0 && x.deviation <= stopPositiveLow) {
                    return {
                        name: x.name,
                        deviation: x.deviation,
                        coefficient: x.coefficient,
                        status: 'optimal'
                    };
                } else if (x.deviation >= stopNegative && x.deviation < 0) {
                    return {
                        name: x.name,
                        deviation: x.deviation,
                        coefficient: x.coefficient,
                        status: 'low'
                    };
                } else if (x.deviation >= stopMin && x.deviation < stopNegative) {
                    return {
                        name: x.name,
                        deviation: x.deviation,
                        coefficient: x.coefficient,
                        status: 'verylow'
                    };
                }
            }));
        }
    }, [calcResults]);

    useEffect(() => {
        if (isCalc) {
            const pointsArray = [];
            const datesArr = [];
            selectedFed.year_group?.years?.filter(x => x.year === parseInt(match.params.year, 10)).forEach(year => {
                year?.fed_points?.forEach(fedPoint => {
                    datesArr.push(new Date(fedPoint.date));
                    pointsArray.push({
                        id: fedPoint?.id,
                        point_number: fedPoint?.point_number,
                        date: fedPoint?.date
                    });
                });
            });
            const lastDate = Math.max(...datesArr);
            const lastDateString = new Date(lastDate).toJSON();
            setFieldRequestDate(`${lastDateString?.length > 0 ? lastDateString.split('T')[0] : ''}`);
            setFedPoints(pointsArray);
        }
    }, [selectedFed]);

    useEffect(() => {
        if (!isCalc) {
            const pointsArray = [];
            const datesArr = [];
            currentCalculator.year_group?.years?.filter(x => x.year === parseInt(match.params.year, 10)).forEach(year => {
                year?.fed_points?.forEach(fedPoint => {
                    datesArr.push(new Date(fedPoint.date));
                    pointsArray.push({
                        id: fedPoint?.id,
                        point_number: fedPoint?.point_number,
                        date: fedPoint?.date
                    });
                });
            });
            const lastDate = Math.max(...datesArr);
            const lastDateString = new Date(lastDate).toJSON();
            setFieldRequestDate(`${lastDateString?.length > 0 ? lastDateString.split('T')[0] : ''}`);
            setFedPoints(pointsArray);
        }
    }, [currentCalculator]);

    const methodOptions = [
        {
            value: 'field',
            label: t('method select field')
        },
        {
            value: 'zones',
            label: t('method select zone')
        },
        {
            value: 'points',
            label: t('method select point')
        }
    ];

    const zoneOptions = [
        {
            label: t('zone select high'),
            value: 'high'
        },
        {
            label: t('zone select mid'),
            value: 'middle'
        },
        {
            label: t('zone select low'),
            value: 'low'
        }
    ];

    const handleCalculate = () => {
        const requestBody = {
            type: selectedMethod,
            date: selectedDate
        };

        if (selectedMethod === 'zones') {
            requestBody.zones = selectedZone;
        }
        if (selectedMethod === 'points') {
            requestBody.points = selectedPoint;
        }

        dispatch(calculateDiagnostics({
            vega_key: isCalc ? selectedField?.vega_key : match.params.id,
            body: requestBody
        }));
    };

    // EXCEL GENERATION
    const calculator = useSelector(state => getCurrentCalculatorMordovia(state));

    const [excelTableData, setExcelTableData] = useState([]);
    const elementList = [
        'b', 'ca', 'co', 'cu', 'fe', 'i', 'kcl', 'ks', 'mg', 'mn', 'mo', 'n', 'p', 'zn'
    ];

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const handleDataRestructure = () => {
        const dataCopy = [...excelTableData].map(dataItem => {
            const beforeData = Object.fromEntries(elementList.map(elem => [`${t(`table element ${elem}`)} [${t('before lighting')}]`, dataItem[`c_${elem}`]]));
            const afterData = Object.fromEntries(elementList.map(elem => [`${t(`table element ${elem}`)} [${t('after lighting')}]`, dataItem[`d_${elem}`]]));
            const coefficientData = Object.fromEntries(elementList.map(elem => [`${t(`table element ${elem}`)} [${t('colored table coefficient header')}]`, dataConstructed?.filter(x => x.name === elem)[0].coefficient]));
            const deviationData = Object.fromEntries(elementList.map(elem => [`${t(`table element ${elem}`)} [${t('colored table dev header')}]`, dataConstructed?.filter(x => x.name === elem)[0].deviation]));

            const remadeDataItem = {
                [t('excel header owner')]: calculator?.owner || '-',
                [t('excel header culture')]: currentCalculator?.culture?.name,
                [t('excel header field')]: currentCalculator?.vega_key,
                ...beforeData,
                ...afterData,
                ...coefficientData,
                ...deviationData
            };

            return remadeDataItem;
        });

        return dataCopy?.length > 0 ? dataCopy : [];
    };

    const exportToCsv = (apiData, fileName) => {
        const ws = XLSX.utils.json_to_sheet(apiData);
        const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    };

    const handleGenerateExcel = () => {
        const { name: fileName } = calcResults;
        const resultData = handleDataRestructure();
        exportToCsv(resultData, fileName);
    };
    //

    const dateOptions = () => {
        const resultArray = [];

        fedPoints.forEach(fedPoint => {
            if (!resultArray.includes(fedPoint.date)) {
                resultArray.push(fedPoint.date);
            }
        });

        return resultArray.map(x => {
            return {
                label: x,
                value: x
            };
        });
    };

    const pointOptions = () => {
        const resultArray = [];

        if (selectedDate?.length > 0) {
            fedPoints.forEach(x => {
                if (x.date === selectedDate) {
                    resultArray.push({
                        label: x?.point_number,
                        value: x?.point_number
                    });
                }
            });

            return resultArray;
        }

        return fedPoints.map(x => {
            return {
                label: x?.point_number,
                value: x?.point_number
            };
        });
    };

    return (
        <div className="diagnostics">
            <div className="diagnostics__header">
                {!isCalc && (
                    <div className="diagnostics__header__controls">
                        <div className="diagnostics__header__controls__item">
                            <div>
                                {t('date label')}
                            </div>
                            <Select
                                style={{ width: '100%' }}
                                onChange={(newDate) => setSelectedDate(newDate)}
                                options={dateOptions()}
                                placeholder={t('date placeholder')} />
                        </div>

                        <div className="diagnostics__header__controls__item">
                            <div>
                                {t('method label')}
                            </div>
                            <Select
                                style={{ width: '100%' }}
                                onChange={(newMethod) => setSelectedMethod(newMethod)}
                                options={methodOptions}
                                placeholder={t('method placeholder')} />
                        </div>

                        {selectedMethod === 'zones' && (
                            <div className="diagnostics__header__controls__item">
                                <div>
                                    {t('zone label')}
                                </div>
                                <Select
                                    mode="multiple"
                                    allowClear
                                    style={{ width: 'fit-content' }}
                                    onChange={(newZone) => setSelectedZone(newZone)}
                                    options={zoneOptions}
                                    placeholder={t('zone placeholder')} />
                            </div>
                        )}

                        {selectedMethod === 'points' && (
                            <div className="diagnostics__header__controls__item">
                                <div>
                                    {t('point label')}
                                </div>
                                <Select
                                    mode="multiple"
                                    allowClear
                                    style={{ width: '100%' }}
                                    onChange={(newPoint) => setSelectedPoint(newPoint)}
                                    options={pointOptions()}
                                    placeholder={t('point placeholder')} />
                            </div>
                        )}
                    </div>
                )}

                {!isCalc && (
                    <div>
                        {calcResults?.id && (
                            <Button
                                style={{ marginRight: '15px' }}
                                onClick={handleGenerateExcel}
                                type="primary">
                                <DownloadOutlined />
                                {t('generate excel')}
                            </Button>
                        )}
                        <Button
                            style={{ alignSelf: 'flex-end' }}
                            onClick={handleCalculate}
                            type="primary">
                            {t('calculate button')}
                        </Button>
                    </div>
                )}
            </div>

            {(calcResults?.id || selectedFed?.id) && (
                <>
                    <ColoredTable
                        chloroplastActivity={chloroplastActivity}
                        tableData={dataConstructed} />

                    <Chart
                        chartData={dataConstructed} />

                    <div className="diagnostics__card">
                        <div className="diagnostics__card__header">
                            {t('card header')}
                        </div>
                        <div className="diagnostics__card__text">
                            {cardText}
                        </div>
                    </div>

                    <Results
                        deficiteElements={deficiteElements}
                        chloroplastActivity={chloroplastActivity} />
                </>
            )}
        </div>
    );
};

export default Diagnostics;
import options from './options';

const { name } = options;

export const getToken = (state, props) => state.models[name].token;
export const isAuth = (state, props) => state.models[name].isAuth;
export const isLoading = (state, props) => state.models[name].isLoading;
export const isError = (state, props) => state.models[name].isError;
export const isRegister = (state, props) => state.models[name].isRegister;
export const isActivate = (state, props) => state.models[name].isActivate;
export const getUserName = (state, props) => state.models[name].user.username;
export const getUserId = (state, props) => state.models[name].user.id;
export const getUser = (state, props) => state.models[name].user;
export const mapLinker = (state, props) => state.models[name].mapLinked;

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { useClassName } from 'utils/cn';

import '../style.less';
import {
    Button, Col, Collapse, InputNumber, Row, Form
} from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { toFixed } from '../../../utils/utils';
import QualityCard from './quality-card';
import {
    currentCulturesPhyto,
    currentVarietiesPhyto
} from '../../../models/calculators-phyto/selectors';
import { getCalculatorPhyto, updateCalculatorPhyto } from '../../../models/calculators-phyto/actions';
import { useRouteMatch } from 'react-router-dom';

const { Panel } = Collapse;

const SeedQualityIndicator = ({ form, culturesList, varietiesList, calculatorId }) => {
    const dispatch = useDispatch();
    const cn = useClassName('all-calculators');
    const { t } = useTranslation('phyto calculator');
    const match = useRouteMatch();

    const handleFinishCalculation = () => {
        const formVals = form?.getFieldsValue();
        formVals.seed_collection_date = formVals.seed_collection_date?.format('YYYY-MM-DD');
        dispatch(updateCalculatorPhyto({
            calcId: calculatorId,
            values: formVals
        }));

        setTimeout(() => {
            dispatch(getCalculatorPhyto(match?.params?.id || calculatorId));
        }, 1500);
    }

    return (
        <>
            <Row className="mt-25">
                <Col span={7}>
                    <QualityCard
                        cardHeader={t('seed quality card header 1')}
                        cardText={form?.getFieldValue('germination_desc')}
                        cardColor="blue" />
                </Col>
                <Col span={1} />
                <Col span={7}>
                    <QualityCard
                        cardHeader={t('seed quality card header 2')}
                        cardText={form?.getFieldValue('purity_desc')}
                        cardColor="red" />
                </Col>
                <Col span={1} />
                <Col span={7}>
                    <QualityCard
                        cardHeader={t('seed quality card header 3')}
                        cardText={form?.getFieldValue('humidity_desc')}
                        cardColor="blue" />
                </Col>
            </Row>

            <Collapse
                style={{ backgroundColor: 'white' }}
                bordered={false}
                defaultActiveKey={['quality']}
                className={cn('collapse')}>
                <Panel
                    showArrow={false}
                    disabled
                    header={<div className="mt-25"><b>{t('calculator quality collapse header')}</b></div>}
                    key="quality">
                    <Row className="seed-quality-table">
                        <Col span={11}>
                            <Row className="seed-quality-table__row__dark">
                                <Col span={14} className="seed-quality-table__row__text">
                                    {t('seed quality table header agriculture')}
                                </Col>
                                <Col span={2} />
                                <Col span={8} className="seed-quality-table__row__text__bold">
                                    {culturesList?.filter(clt => clt.id === parseInt(form?.getFieldValue('culture'), 10))[0]?.name || '-'}
                                </Col>
                            </Row>
                            <Row className="seed-quality-table__row">
                                <Col span={14} className="seed-quality-table__row__text">
                                    {t('seed quality table header variety')}
                                </Col>
                                <Col span={2} />
                                <Col span={8} className="seed-quality-table__row__text__bold">
                                    {varietiesList?.filter(srt => srt?.id === parseInt(form?.getFieldValue('sort'), 10))[0]?.sort_name || '-'}
                                </Col>
                            </Row>
                            <Row className="seed-quality-table__row__dark">
                                <Col span={14} className="seed-quality-table__row__text">
                                    {t('seed quality table header purity')}
                                </Col>
                                <Col span={2} />
                                <Col span={8} className="seed-quality-table__row__text__bold">
                                    {form?.getFieldValue('purity') ? toFixed(form?.getFieldValue('purity'), 1) : '-'}
                                </Col>
                            </Row>
                            <Row className="seed-quality-table__row">
                                <Col span={14} className="seed-quality-table__row__text">
                                    {t('seed quality table header germination')}
                                </Col>
                                <Col span={2} />
                                <Col span={8} className="seed-quality-table__row__text__bold">
                                    {form?.getFieldValue('germination') ? toFixed(form?.getFieldValue('germination'), 1) : '-'}
                                </Col>
                            </Row>
                            <Row className="seed-quality-table__row__dark">
                                <Col span={14} className="seed-quality-table__row__text">
                                    {t('seed quality table header goodness')}
                                </Col>
                                <Col span={2} />
                                <Col span={8} className="seed-quality-table__row__text__bold">
                                    {form?.getFieldValue('sowing_suitability') ? toFixed(form?.getFieldValue('sowing_suitability'), 1) : '-'}
                                </Col>
                            </Row>
                        </Col>

                        <Col span={2} />

                        <Col span={11}>
                            <Row className="seed-quality-table__row__dark">
                                <Col span={14} className="seed-quality-table__row__text">
                                    {t('seed quality table header mass')}
                                </Col>
                                <Col span={2} />
                                <Col span={8} className="seed-quality-table__row__text__bold">
                                    {form?.getFieldValue('hundred_seeds_weight') ? toFixed(form?.getFieldValue('hundred_seeds_weight'), 1) : '-'}
                                </Col>
                            </Row>
                            <Row className="seed-quality-table__row">
                                <Col span={14} className="seed-quality-table__row__text">
                                    {t('seed quality table header planning')}
                                </Col>
                                <Col span={2} />
                                <Col span={8} className="seed-quality-table__row__text__bold">
                                    <Form.Item name="germinating_seeds_plan">
                                        <InputNumber
                                            // value={form?.getFieldValue('germinating_seeds_plan') ? toFixed(form?.getFieldValue('germinating_seeds_plan'), 1) : '-'}
                                            onChange={(newValue) => form?.setFieldsValue({ germinating_seeds_plan: newValue })} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className="seed-quality-table__row__dark">
                                <Col span={14} className="seed-quality-table__row__text">
                                    {t('seed quality table header seeding norm')}
                                </Col>
                                <Col span={2} />
                                <Col span={8} className="seed-quality-table__row__text__bold">
                                    {form?.getFieldValue('calc_seeding_rate') ? toFixed(form?.getFieldValue('calc_seeding_rate'), 1) : '-'}
                                </Col>
                            </Row>
                            <Row className="seed-quality-table__row">
                                <Col span={14} className="seed-quality-table__row__text">
                                    {t('seed quality table header delta')}
                                </Col>
                                <Col span={2} />
                                <Col span={8} className="seed-quality-table__row__text__bold">
                                    {form?.getFieldValue('field_germination') ? toFixed(form?.getFieldValue('field_germination'), 1) : '-'}
                                </Col>
                            </Row>
                            <Row className="seed-quality-table__row__dark">
                                <Col span={14} className="seed-quality-table__row__text">
                                    {t('seed quality table header delta norm')}
                                </Col>
                                <Col span={2} />
                                <Col span={8} className="seed-quality-table__row__text__bold">
                                    {form?.getFieldValue('seeding_rate_germination') ? toFixed(form?.getFieldValue('seeding_rate_germination'), 1) : '-'}
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={4} />
                        <Col span={16}>
                            <div className="quality-notification">
                                <ExclamationCircleOutlined style={{ marginRight: '16px' }} />
                                {t('quality notification')}
                            </div>
                        </Col>
                        <Col span={4} />
                    </Row>

                    <Row>
                        <Col span={10} />
                        <Col span={4}>
                            <Button type="primary" className="mt-25" onClick={handleFinishCalculation}>
                                {t('finish calculation')}
                            </Button>
                        </Col>
                        <Col span={10} />
                    </Row>
                </Panel>
            </Collapse>
        </>
    );
};

export default SeedQualityIndicator;

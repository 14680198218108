import options from './options';
import request from '../../utils/request';

const url = options.apiUrl;
const urlPath = path => `${url}${path}`;

export const getUsers = filter => request.get({
    url
});

export const resetPassword = email => request.post({
    url: urlPath(`resetPassword?email=${email}`)
});

export const savePassword = body => request.post({
    url: urlPath('savePassword'),
    body
});

export const updateUser = body => request.patch({
    url: '/api/v1/teleagronom_user/users/me/',
    body
});

export const updateOrg = body => request.patch({
    url: `/api/v1/teleagronom_user/owners/${body?.orgId}/`,
    body: body?.values
});

export const createOrg = body => {
    console.log('BODY', body);
    return request.post({
    url: `/api/v1/teleagronom_user/owners/`,
    body
});}
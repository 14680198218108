import React, { Component, useEffect, useState } from 'react';
import { withRouter, Redirect } from 'react-router';
import { connect } from 'react-redux';
import { Spin } from 'antd';
import cn from '../../utils/cn';
import { getProfile, refresh } from '../../models/account/actions';
import { isAuth, isLoading, getUser } from '../../models/account/selectors';
import { isValid } from '../../utils/utils';
import './style.less';
import { MapsSideBar, SideBar } from '../../components';
import ChangeLang from '../change-lang';
import { useKeycloak } from '@react-keycloak/web';

const AuthLayout = ({ children }) => {
    const { keycloak, initialized } = useKeycloak();

    const [isLoading, setIsLoading] = useState(true);
    const [isAuth, setIsAuth] = useState(false);

    useEffect(() => {
        setIsLoading(!initialized);
        setIsAuth(keycloak.authenticated);
    }, [initialized]);
    
    return isLoading ? (
        <div>
            <Spin />
        </div>
    ) : isAuth ? (
        <>
            {!location.pathname.includes('/representation/') && !(location.pathname === '/') && (
                <>
                    {location?.pathname === '/' ? (<MapsSideBar isMordovia />) : (<SideBar isMordovia />)} 
                </>
            )}
            {children}
        </>
    ) : (
        <div>
            Please Log in
        </div>
    );
};

export default AuthLayout;
import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import {
    Button,
    Col, Collapse, Form, Image, Input, InputNumber, Popover, Radio, Row, Select, Table, Upload
} from 'antd';
import { useClassName } from '../../../utils/cn';
import { toFixed } from '../../../utils/utils';

import '../style.less';
import { TextArea } from '../../../components';
import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import {
    getMycologicalRegistry, updateFileDescription,
    uploadRegistryFile
} from '../../../models/mycological-registry/actions';
import { curNewFilename } from '../../../models/mycological-registry/selectors';
import moment from 'moment';

export const Azotobacter = ({
    registry, t, form, rep, onFormFinish
}) => {
    const dispatch = useDispatch();
    const match = useRouteMatch();
    const cn = useClassName('calculator-detail-params');
    const cn_info = useClassName('calculator');
    const [repetitions, setRepetitions] = useState(new Array(registry?.azotobacter_repetitions?.length > 0 ? registry.azotobacter_repetitions.length : 1).fill(0));
    const repetitionsArr = new Array(6).fill(0);
    const { params: { id } } = match;

    const onRepetitionsChange = (value) => {
        setRepetitions(new Array(value).fill(0));
    };

    const removeUploadedFile = (val1) => {
        const formValues = form.getFieldsValue();
        formValues.pictures3 = registry?.pictures3?.filter(x => x.id !== val1.id).map(y => y.id);
        onFormFinish(formValues);
    };

    const uploadProps = {
        name: 'file',
        customRequest: (reqParams) => {
            if (id) {
                const { file } = reqParams;
                return dispatch(uploadRegistryFile({
                    values: {
                        uploaded: moment().format('YYYY-MM-DDThh:mm'),
                        picture_file: file,
                        picture_desc: `3/File №${registry?.pictures3?.length + 1}`
                    },
                    type: 'formdata'
                }));
            }
        }
    };

    return (
        <div>
            <Row className={cn('row-title')} gutter={20}>
                <Col span={4} className="field">
                    <Form.Item label={t('count')} name="repetitions_azotobacter">
                        <Select
                            defaultValue={repetitions?.length > 0 ? repetitions?.length : 1}
                            onChange={onRepetitionsChange}>
                            {repetitionsArr.map((item, index) => (
                                <Select.Option value={index + 1}>{index + 1}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col>
                    <div className={cn_info('info')}>
                        <div className={cn_info('info__inner')}>
                            {t('count info')}
                        </div>
                    </div>
                </Col>
            </Row>
            <Row className={cn('row-head')}>
                <Col span={6} className={cn('row-table__label')}>
                    {t('mycobiota repetition')}
                </Col>
                {
                    repetitions.map((item, index) => (
                        <Col
                            span={2}
                            className={cn('row-table__label')}>
                            {index + 1}
                        </Col>
                    ))
                }

                <Col span={3} className={cn('row-table__label')}>
                    {t('average')}
                </Col>
            </Row>
            <Row
                className={cn('row-table__withborder')}
                style={{ padding: '0px', alignItems: 'center' }}>
                <Col span={6}>
                    {t('azotobacter table head')}
                </Col>
                {repetitions?.map((item, index) => (
                    <Col
                        span={2}
                        className={cn('row-table__label')}>
                        <Form.Item
                            name={`azotobacter/${index}`}
                            style={{ marginTop: '-17px', marginBottom: '5px' }}>
                            <InputNumber
                                decimalSeparator=","
                                style={{
                                    maxWidth: '120px',
                                    width: '95%',
                                    marginTop: '16px',
                                    marginBottom: '-30px'
                                }} />
                        </Form.Item>
                    </Col>
                ))}
                <Col
                    span={3}
                    className={cn('input')}>
                    {toFixed(registry?.azotobacter_mean)}
                </Col>
            </Row>
            <Row style={{ backgroundColor: 'white', border: 'none', marginTop: '10px' }}>
                <p>{t('comment')}</p>
                <Form.Item name="comment3">
                    <TextArea maxLength={99999} />
                </Form.Item>
            </Row>

            {registry?.id && (
                <Row>
                    <Col>
                        <p>{t('upload file')}</p>
                        <Upload {...uploadProps}>
                            <Button icon={<UploadOutlined />}>
                                {t('press to upload')}
                            </Button>
                        </Upload>
                    </Col>
                </Row>
            )}

            {registry?.pictures3?.length > 0 && (
                <Row style={{ backgroundColor: 'white', border: 'none', marginTop: '15px' }}>
                    <Col span={24}>
                        <p>{t('uploaded files')}</p>
                        <Table
                            dataSource={registry?.pictures3}
                            pagination={false}
                            columns={[
                                {
                                    dataIndex: 'picture_name',
                                    title: t('pic table filename'),
                                    key: 'picture_name',
                                    render: (val, rec) => {
                                        const [isImgVisible, setIsImgVisible] = useState(false);
                                        return (
                                            <>
                                                <Image
                                                    style={{
                                                        width: '100%',
                                                        maxHeight: '100px'
                                                    }}
                                                    src={rec?.picture_file}
                                                    preview={{
                                                        isImgVisible,
                                                        src: rec?.picture_file,
                                                        onVisibleChange: (value) => {
                                                            setIsImgVisible(value);
                                                        }
                                                    }} />
                                                <div>
                                                    {val}
                                                </div>
                                            </>
                                        );
                                    }
                                },
                                {
                                    dataIndex: 'uploaded',
                                    title: t('pic table date'),
                                    key: 'uploaded',
                                    render: (value) => {
                                        const dateStr = value?.split('T')[0];
                                        const dateArray = dateStr ? dateStr.split('-') : [];
                                        return dateArray?.length > 0 ? `${dateArray[2]}.${dateArray[1]}.${dateArray[0]}` : value;
                                    }
                                },
                                {
                                    dataIndex: 'picture_desc',
                                    title: t('pic table description'),
                                    key: 'picture_desc',
                                    render: (val, rec) => {
                                        const [isRenameOpen, setIsRenameOpen] = useState(false);
                                        const [photoComment, setPhotoComment] = useState(val);

                                        const newFileName = useSelector(state => curNewFilename(state));

                                        useEffect(() => {
                                            if (newFileName?.id === rec.id && newFileName?.picture_desc !== photoComment && id) {
                                                dispatch(getMycologicalRegistry(id));
                                            }
                                        }, [newFileName]);

                                        const hide = () => {
                                            setIsRenameOpen(false);
                                            setPhotoComment(val);
                                        };
                                        const handleOpenChange = (newOpen) => {
                                            setIsRenameOpen(newOpen);
                                        };
                                        const saveNewComment = () => {
                                            if (photoComment !== val) {
                                                dispatch(updateFileDescription({
                                                    pictureId: rec.id,
                                                    values: {
                                                        picture_desc: photoComment
                                                    }
                                                }));
                                            }
                                        };

                                        return (
                                            <Popover
                                                content={(
                                                    <>
                                                        <TextArea
                                                            style={{ width: '350px', height: '150px' }}
                                                            maxLength={99999}
                                                            onChange={(evt) => setPhotoComment(evt?.target?.value)}
                                                            defaultValue={photoComment} />
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                justifyContent: 'space-around'
                                                            }}>
                                                            <a onClick={hide}>
                                                                Отмена
                                                            </a>
                                                            <a onClick={() => {
                                                                saveNewComment();
                                                                hide();
                                                            }}>
                                                                {t('save')}
                                                            </a>
                                                        </div>
                                                    </>
                                                )}
                                                trigger="click"
                                                visible={isRenameOpen}
                                                onVisibleChange={handleOpenChange}>
                                                {val}
                                            </Popover>
                                        );
                                    }
                                },
                                {
                                    key: 'actions',
                                    dataIndex: 'actions',
                                    render: (value, record) => (
                                        <DeleteOutlined
                                            onClick={() => removeUploadedFile(record)}
                                            style={{ cursor: 'pointer' }} />
                                    )
                                }
                            ]} />
                    </Col>
                </Row>
            )}
        </div>
    );
};
import * as constants from './constants';
import request from '../../utils/request';

export const getMeteodataRegistry = payload => ({
    type: constants.getMeteodataRegistry.toString(),
    payload
});

export const getMeteodataStations = payload => ({
    type: constants.getMeteodataStations.toString(),
    payload
});
import request from '../../utils/request';
import options from './options';

const url = options.apiUrl;
const usersUrl = options.userUrl;
const fieldsUrl = options.fieldUrl;
const uploadFileUrl = options.fileUploadUrl;
const urlPath = path => `${url}${path}`;

export const getMycologicalRegistry = id => request.get({
    url: urlPath(id),
    id
});

export const getMycologicalRegistries = () => request.get({
    url
});

export const addMycologicalRegistry = body => request.post({
    url,
    body
});

export const updateMycologicalRegistry = body => request.patch({
    url: urlPath(`${body.id}/`),
    body: body.values,
    type: body.type
});

export const uploadRegistryFile = body => request.post({
    url: uploadFileUrl,
    body: body.values,
    type: body.type
});

export const updateFileDescription = body => request.patch({
    url: `${uploadFileUrl}${body.pictureId}/`,
    body: body.values
});

export const generateMycologicalRegistryDoc = body => request.get({
    url: urlPath(`${body.id}/download_protocol/`)
});

export const getUsersList = body => request.get({
    url: `${usersUrl}${body?.searchString ? `?filter={"name.icontains": "${body.searchString}" }` : ''}`
});

export const getFilteredFields = body => request.get({
    url: `${fieldsUrl}?filter={${typeof body?.userId === 'number' ? `"owner_user": ${body?.userId}` : `"owner_user": [${body?.userId?.length > 0 && body?.userId?.join(',')}]`}${body.searchString ? `, "field_num.icontains": "${body.searchString}"` : ''}}&page=${body?.pageNum ? body?.pageNum : 1}${body?.pageSize ? `&page_size=${body?.pageSize}`: ''}`
});

export const deleteMycologicalRegistry = id => request.remove({
    url: urlPath(id)
});

export const publishMycologicalRegistry = body => request.post({
    url: `api/v1/mycological_calculator/mycological_calculator_reestr/${body.protocolId}/publish_protocol/`,
    body: body.values
});
import {
    all, call, put, takeLatest
} from 'redux-saga/effects';
import { defaultSaga, getActions } from '../utils';

import * as constants from './constants';
import * as api from './api';
import converter from './converter';
import { updateCalculate } from '../item-calculate/actions';
import { getCalculatorMordovia } from '../mordovia/api';

function* addDistributionSaga({ payload }) {
    const [start, success, failure] = getActions(
        constants.addDistribution.try,
        constants.addDistribution.success,
        constants.addDistribution.error
    );
    try {
        const { calculate, values } = payload;
        yield put(start());
        const dataRaw = yield call(api.addDistribution, values);
        const data = converter.toClient(dataRaw);
        yield put(updateCalculate({ id: calculate.id, values: { distribution: data.id, ...calculate } }));
        yield put(success(data));
    } catch (error) {
        yield put(failure(error));
    }
}

function* updateFeedingDistributionSaga({ payload }) {
    const [start, success, failure] = getActions(
        constants.updateFeedingDistribution.try,
        constants.updateFeedingDistribution.success,
        constants.updateFeedingDistribution.error
    );
    try {
        yield put(start());
        const dataRaw = yield call(api.updateFeedingDistribution, payload);
        yield put(success(dataRaw));
    } catch (error) {
        yield put(failure(error));
    }
}

export default function* rootSaga() {
    yield all([
        yield takeLatest(
            constants.getDistribution.toString(),
            defaultSaga({
                constants: constants.getDistribution,
                api: api.getDistribution,
                after: converter.toClient
            })
        ),
        yield takeLatest(
            constants.getDistributions.toString(),
            defaultSaga({
                constants: constants.getDistributions,
                api: api.getDistributions
            })
        ),
        yield takeLatest(
            constants.addDistribution.toString(),
            addDistributionSaga
        ),
        yield takeLatest(
            constants.updateDistribution.toString(),
            defaultSaga({
                constants: constants.updateDistribution,
                api: api.updateDistribution,
                after: converter.toClient
            })
        ),
        yield takeLatest(
            constants.updateFeedingDistribution.toString(),
            updateFeedingDistributionSaga
        )
    ]);
}
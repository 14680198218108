import {
    put, call, all, takeLatest, select
} from 'redux-saga/effects';
import { defaultSaga, getActions } from '../utils';

import * as constants from './constants';
import * as api from './api';
import * as actions from './actions';

export default function* rootSaga() {
    yield all([
        yield takeLatest(
            constants.getProtocol.toString(),
            defaultSaga({
                constants: constants.getProtocol,
                api: api.getProtocol
            })
        ),
        yield takeLatest(
            constants.getFedPoints.toString(),
            defaultSaga({
                constants: constants.getFedPoints,
                api: api.getFedPoints
            })
        ),
        yield takeLatest(
            constants.getAgroPoints.toString(),
            defaultSaga({
                constants: constants.getAgroPoints,
                api: api.getAgroPoints
            })
        )
    ]);
}
import React, { Component, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, withRouter, useHistory, useRouteMatch, useLocation } from 'react-router-dom';
import { Button, Form, Input, Layout, Modal, Row, Select } from 'antd';
import PanelHeader from '../../components/panel-layout';
import ProfileDetail from './components/profile-detail';
import { getRegionsCatalog } from '../../models/regions/selectors';
import { getRegions } from '../../models/regions/actions';
import { getProfile } from '../../models/account/actions';
import { getUser } from '../../models/account/selectors';
import { createOrg, updateOrg, updateUser } from '../../models/user/actions';
import { getCreatedOrg } from '../../models/user/selectors';


export default ({ curPoint }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation('welcome modal');
    const [form] = Form.useForm();

    const regions = useSelector(state => getRegionsCatalog(state));
    const currentUser = useSelector(state => getUser(state));
    const createdOrg = useSelector(state => getCreatedOrg(state));

    useEffect(() => {
        if (createdOrg?.id) {
            dispatch(getProfile());
        }
    }, [createdOrg]);
    
    useEffect(() => {
        orgForm?.current?.setFieldsValue({
            name: currentUser?.owners?.[0]?.name,
            region: currentUser?.owners?.[0]?.region?.id,
            inn: currentUser?.owners?.[0]?.inn,
        });
        meForm?.current?.setFieldsValue({
            first_name: currentUser?.first_name,
            last_name: currentUser?.last_name,
            email: currentUser?.email,
        });
    }, [currentUser]);
    

    useEffect(() => {
      dispatch(getRegions('?page_size=100'));
      dispatch(getProfile());
    }, []);
    
    const handleCheckFields = () => {
        // form.validateFields();
        form.submit();
    };

    const handleFormSubmit = (values) => {
        values.inn = Number(values?.inn);
        values.user = currentUser?.id;
        dispatch(createOrg(values));
    };

    const [orgForm, setOrgForm] = useState(null);
    const [meForm, setMeForm] = useState(null);

    const handleSave = () => {
        const isMe = meForm?.current?.getFieldsValue();
        const isOrg = orgForm?.current?.getFieldsValue();

        dispatch(updateUser(isMe));
        dispatch(updateOrg({ orgId: currentUser?.owners?.[0]?.id, values: isOrg }));

        setTimeout(() => {
            dispatch(getProfile());
            // window.location.reload();
        }, 1000);
    };

    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    return (
        <Layout>
            <ProfileDetail setIsButtonDisabled={setIsButtonDisabled} isButtonDisabled={isButtonDisabled} setMeForm={setMeForm} handleSaving={handleSave} setOrgForm={setOrgForm} />
            <Modal
                closable={false}
                width={448}
                visible={!(currentUser?.owners?.length > 0) && currentUser?.id}
                footer={null}
                title={null}>
                    <Row style={{ color: '#27AE60', fontSize: '30px', fontWeight: '800' }} justify="center">{t('header text')}</Row>
                    <Form labelCol={{ span: 24 }} form={form} onFinish={handleFormSubmit}>
                        <Row style={{ marginTop: '43px' }}>
                            <Form.Item label={t('name label')} name="name" rules={[{ required: true, message: t('field error') }]}>
                                <Input style={{ width: '100%' }} placeholder={t('name placeholder')} />
                            </Form.Item>
                        </Row>
                        <Row>
                            <Form.Item label={t('inn label')} name="inn" rules={[{ required: true, message: t('field error') }]}>

                                <Input style={{ width: '100%' }} placeholder={t('inn placeholder')} />
                            </Form.Item>
                        </Row>
                        <Row style={{ marginBottom: '43px' }}>
                            <Form.Item label={t('region label')} name="region" rules={[{ required: true, message: t('field error') }]}>
                                <Select
                                    showSearch
                                    allowClear
                                    filterOption={(input, option) => (option?.label ?? '')?.toLowerCase().includes(input?.toLowerCase())}
                                    placeholder={t('region placeholder')} style={{ width: '100%' }}
                                    options={regions?.map(x => {
                                        return {
                                            label: x?.name,
                                            value: x?.id
                                        }
                                    })} />
                            </Form.Item>
                        </Row>
                    </Form>
                    <Row style={{ color: '#27AE60', fontSize: '30px', fontWeight: '800' }} justify="center">
                        <Button onClick={handleCheckFields} type="primary">{t('button text')}</Button>
                    </Row>
            </Modal>
        </Layout>
    );
};
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row, Table } from 'antd';

import './style.less';
import { toFixed } from '../../../../../utils/utils';

const statusColor = {
    veryhigh: '#27AE60',
    high: '#6FCF97',
    optimal: '#D9D9D9',
    low: '#FF7E77',
    verylow: '#FF4D4F'
};

const ColoredTable = ({ tableData, chloroplastActivity }) => {

    const { t } = useTranslation('leaf diagnostics');

    const columns = [
        {
            title: t('colored table name header'),
            dataIndex: 'name',
            key: 'name',
            render: (text) => t(`table element ${text}`),
            width: '25%'
        },
        {
            title: t('colored table coefficient header'),
            dataIndex: 'coefficient',
            key: 'coefficient',
            render: (value) => toFixed(value, 1),
            width: '25%'
        },
        {
            title: t('colored table deviation header'),
            dataIndex: 'deviation',
            key: 'deviation',
            render: (text, record) => {
                return (
                    <div
                        style={{ background: statusColor[record.status] }}
                        className="diagnostics__colored-table__frame">
                        {toFixed(text, 0)}
                    </div>
                );
            },
            width: '25%'
        },
        {
            title: t('colored table status header'),
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => {
                return (
                    <div
                        className="diagnostics__colored-table__status">
                        <div
                            style={{ background: statusColor[record.status] }}
                            className="diagnostics__colored-table__status__indicator" />
                        <div
                            className="diagnostics__colored-table__status__text">
                            {t(`chloroplast status ${text}`)}
                        </div>
                    </div>
                );
            },
            width: '25%'
        }
    ];

    return (
        <Table
            pagination={false}
            rowKey="name"
            className="diagnostics__colored-table"
            dataSource={tableData}
            footer={() => (
                <Row>
                    <Col span={6}>
                        {t('table element all')}
                    </Col>
                    <Col span={18}>
                        {toFixed(chloroplastActivity, 1)}
                    </Col>
                </Row>
            )}
            columns={columns} />
    );
};

export default ColoredTable;
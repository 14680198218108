import React, { useEffect, useState } from 'react';
import { Popover, Table } from 'antd';
import { useClassName } from '../../../utils/cn';
import { InfoMessage, TableFooter, Tooltip } from '../../../components';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { removeCalculatorPhyto, getCalculatorsPhyto } from '../../../models/calculators-phyto/actions';
import {
    curCalculatorsPhyto,
    deletionResult
} from '../../../models/calculators-phyto/selectors';
import { useHistory } from 'react-router-dom';

const ROW_DIVIDER = 10;
const MAX_RECORDS_IN_PAGINATOR = 40;

const CalculatorsTablePhyto = () => {
    const cn = useClassName();
    const { t } = useTranslation('calculators table');

    const dispatch = useDispatch();
    const history = useHistory();

    const [tableData, setTableData] = useState([]);
    const [infoMessageVisible, setInfoMessageVisible] = useState(false);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [deletableId, setDeletableId] = useState(null);

    const deletedItem = useSelector(state => deletionResult(state));
    const calculatorsList = useSelector(state => curCalculatorsPhyto(state));

    useEffect(() => {
        dispatch(getCalculatorsPhyto());
    }, []);

    useEffect(() => {
        calculatorsList?.length > 0 && setTableData(calculatorsList);
    }, [calculatorsList]);

    const showDeleteModal = id => {
        setDeletableId(id);
        setInfoMessageVisible(true);
    };

    const hideDeleteModal = () => {
        setInfoMessageVisible(false);
    };

    const removeCalculator = () => {
        dispatch(removeCalculatorPhyto(deletableId));
        hideDeleteModal();
    };

    const filterItems = (dataSize) => {
        const items = [];

        for (let i = 1; i < dataSize; i += 1) {
            if (i % ROW_DIVIDER === 0) {
                items.push({ value: `${i}`, title: `${i}` });
            }

            if (i > MAX_RECORDS_IN_PAGINATOR) break;
        }

        return items;
    };

    const columns = [
        {
            title: t('title'),
            dataIndex: 'name',
            key: 'name',
            className: cn('title'),
            sorter: (a, b) => a.name.localeCompare(b.name),
            sortDirections: ['ascend', 'descend'],
            render: (text, record) => (
                <Tooltip
                    placement="bottomLeft"
                    title={text}>
                    <a onClick={() => history.push(`/calculator-phyto/${record.id}`)}>{text}</a>
                </Tooltip>
            )

        },
        {
            title: t('create date'),
            dataIndex: 'creation_date',
            key: 'creation_date',
            className: cn('createDate'),
            sorter: (a, b) => moment(a.createDate).unix() - moment(b.createDate).unix(),
            sortDirections: ['descend', 'ascend'],
            render: (text) => moment(text).format('DD.MM.YYYY')
        },
        {
            title: t('status'),
            dataIndex: 'is_done',
            key: 'is_done',
            className: cn('status'),
            render: (text) => (
                <>
                    {text && t('COMPLETED')}
                    {!text && t('IN_PROGRESS')}
                </>
            )
        },
        {
            title: '',
            dataIndex: 'action',
            key: 'action',
            // className: cn('action'),
            fixed: 'right',
            render: (text, record) => (
                <div className={cn('button')}>
                    <Popover
                        status={record.status}
                        id={record.id}
                        placement="bottomRight"
                        trigger="click"
                        url="calculator-myco"
                        deleteClick={() => showDeleteModal(record.id)} />
                </div>
            )
        }
    ];

    return (
        <div className={cn()}>
            <Table
                dataSource={[...tableData]}
                columns={columns}
                pagination={false} />
            {tableData?.length > 1 && (
                <TableFooter
                    filterItems={[
                        {
                            label: '15',
                            value: 15
                        },
                        {
                            label: '25',
                            value: 25
                        },
                        {
                            label: '50',
                            value: 50
                        }
                    ]}
                    showSizeChanger={false}
                    onSelect={setPageSize}
                    onChange={setPage}
                    pageSize={pageSize}
                    current={page}
                    rowDivider={ROW_DIVIDER.toString()}
                    dataSize={tableData.length} />
            )}
            <InfoMessage
                visible={infoMessageVisible}
                title={t('delete title')}
                okBtn={t('delete')}
                cancelBtn={t('cancel')}
                onOk={removeCalculator}
                onCancel={hideDeleteModal} />
        </div>
    );
};

export default CalculatorsTablePhyto;
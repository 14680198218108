export default {
    name: 'calculatorMordovia',
    serverName: '/api/v1/mordovia/field_info/',
    apiMordoviaUrl: '/api/v1/mordovia/field_info/',
    apiMordoviaPointsUrl: '/api/v1/mordovia/field_points/',
    apiMordoviaShapeUrl: '/api/v1/mordovia/field_info/',
    apiAgroUrl: '/api/v1/agrochemical_calculator/agrochemical_calculator/',
    registryObjectUrl: '/api/v1/mordovia/registry_object/',
    geocodeFeatureUrl: '/geocode/layers/'
    // agrohubUrl: '//v1'
};

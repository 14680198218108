import options from './options';
import registryDoc from './reducers/registry-doc';

const { name } = options;

export const curMycoRegistry = state => state.models[name].data;
export const curMycoRegistries = state => state.models[name].list.results;
export const curMycoRegistriesCount = state => state.models[name].list.count;
export const isMycoRegistryLoading = state => state.models[name].loading;
export const downloadableRegistryDoc = state => state.models[name].registryDoc;
export const curMycoUsers = state => state.models[name].userList;
export const curMycoFields = state => state.models[name].filteredFields;
export const curUploadFile = state => state.models[name].uploadedFile;
export const curNewFilename = state => state.models[name].newFilename;

import { createSelector } from 'reselect';

/**
 * Получение элемента по id
 * @param id
 * @returns {function(*): *}
 */
export const getUserById = id => state => state.models.users.data[id];
/**
 * Получение списка id элементов
 * @param state
 * @returns {Components.list|string|null|reducer|HTMLElement}
 */
export const getUserList = state => state.models.users.list;

/**
 * Получение объекта с элементами
 * @param state
 * @returns {*}
 */
export const getUsersObject = state => state.models.users.data;

/**
 * Получение списка элементов
 */
export const getDivisions = createSelector(
    [getUserList, getUsersObject],
    (ids, objects) => (ids.map(id => objects[id]))
);

export const getCreatedOrg = state => state.models.users.newOrganisation;
import React, { useEffect, useState, useMemo } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Icon from '@ant-design/icons';

import { useClassName } from 'utils/cn';

import { Button, Collapse, Form } from 'antd';
import CalculatorName from './calculator-name';
import CalculatorData from './calculator-data';
import SeedQualityIndicator from './seed-quality-indicator';
import ExternalContamination from './external-contamination';
import InternalContamination from './internal-contamination';
import ListIcon from '../../../../public/images/calculator/list2_stroke.svg';

import '../style.less';
import {
    createCalculatorPhyto,
    getCausativeAgents,
    getVarietiesPhyto,
    updateRegistryPhyto
} from '../../../models/calculators-phyto/actions';
import {
    updatedProtocolPhyto,
    newlyCreatedProtocolPhyto,
    curCalculatorPhyto,
    currentCausativeAgentsPhyto,
    updatedPhotoPhyto
} from '../../../models/calculators-phyto/selectors';

import { useDispatch, useSelector } from 'react-redux';
import { getUser } from '../../../models/account/selectors';

import LaborantQualityIndicator from './laborant-quality-indicator';

const { Panel } = Collapse;

const CalculatorDetails = ({
    curProto, showRecalculateButtons,
    qualityRepetitions, setQualityRepetitions,
    internalPathogenStage, setInternalPathogenStage,
    externalPathogens, internalPathogens, fertilizersFullList,
    form, detailsNextStepDisabled, seedImages, externalImages, internalImages, calculatorId
}) => {
    const cn = useClassName('calculator-detail');
    const { t } = useTranslation('phyto calculator');
    const match = useRouteMatch();
    const dispatch = useDispatch();
    const history = useHistory();

    const currentUser = useSelector(state => getUser(state));
    const createdProtocol = useSelector(state => newlyCreatedProtocolPhyto(state));
    const updatedProtocol = useSelector(state => updatedProtocolPhyto(state));
    const createdCalculator = useSelector(state => curCalculatorPhyto(state));
    const causativeAgents = useSelector(state => currentCausativeAgentsPhyto(state));

    const [causativeAgentsList, setCausativeAgentsList] = useState([]);
    const [agentsPage, setAgentsPage] = useState(1);
    const [sortList, setSortList] = useState([]);
    const [cultureList, setCultureList] = useState([]);
    const [selectedCulture, setSelectedCulture] = useState('-');
    const [selectedSort, setSelectedSort] = useState('-');
    const [requestedSorts, setRequestedSorts] = useState(false);

    useEffect(() => {
        const { fito_calculator } = curProto;
        if (fito_calculator?.id) {
            const { culture, sort } = fito_calculator;
            const foundCulture = cultureList?.filter(x => x?.id === culture?.id);
            const foundSort = sortList?.filter(x => x?.id === sort?.id);
            if (foundCulture[0]) {
                setSelectedCulture(foundCulture[0]?.name);
                if (sortList?.length === 0 && !requestedSorts) {
                    dispatch(getVarietiesPhyto({ page: 1, culturesList: [foundCulture[0]?.id] }));
                }
            }
        }
    }, [curProto, sortList]);

    useEffect(() => {
        const { next, results } = causativeAgents;

        if (agentsPage === 1) {
            setCausativeAgentsList(results);
        } else {
            setCausativeAgentsList([...causativeAgentsList, ...results]);
        }

        if (next?.length > 0) {
            setAgentsPage(agentsPage + 1);
        }
    }, [causativeAgents])
    
    useEffect(() => {
        dispatch(getCausativeAgents({ page: 1 }));
    }, [])
    

    useEffect(() => {
        if (createdProtocol?.id > 0) {
            preparedProtocol.protocol_number = createdProtocol?.id,
            dispatch(createCalculatorPhyto(preparedProtocol));
        }
    }, [createdProtocol])
    
    useEffect(() => {
        if (createdProtocol?.id > 0) {
            dispatch(updateRegistryPhyto({
                calcId: createdProtocol?.id,
                values: {
                    fito_calculator: createdCalculator?.id
                }
            }))
        }
    }, [createdCalculator]);

    useEffect(() => {
        if (updatedProtocol?.id > 0 && updatedProtocol?.fito_calculator?.id > 0) {
            history.push(`/fsm-registry/${updatedProtocol?.id}`);
        }
    }, [updatedProtocol]);

    const [seedsMassOne, setSeedsMassOne] = useState(0);
    const [seedsMassTwo, setSeedsMassTwo] = useState(0);
    const [seedsNumOne, setSeedsNumOne] = useState(500);
    const [seedsNumTwo, setSeedsNumTwo] = useState(500);
    const [preparedProtocol, setPreparedProtocol] = useState({});

    const [activeTabs, setActiveTabs] = useState(match?.params?.id > 0 ? [
        'calculator_data',
        'calculator_name'
    ] : [
        'calculator_name'
    ]);

    const calculatorNameMemo = useMemo(() => {
        const formVals = form?.getFieldsValue();
        return formVals?.name;
    }, [form?.getFieldsValue()]);

    const confirmCalculatorName = () => {
        calculatorNameMemo?.length > 0 && setActiveTabs(['calculator_data']);
    };

    const panelHeader = (headerName) => (
        <div className="collapse-header">
            <b>
                <Icon component={ListIcon} />
                {t(`panel header ${headerName}`)}
            </b>
        </div>
    );

    const handleToQuality = () => {
        if (!activeTabs?.includes('calculator_quality')) {
            setActiveTabs([...activeTabs, 'calculator_quality']);
        }
    };

    return (
        <Collapse
            onChange={setActiveTabs}
            bordered={false}
            activeKey={activeTabs}
            defaultActiveKey={activeTabs}
            expandIconPosition="right"
            className={cn('collapse')}>
            <Panel
                header={panelHeader('name')}
                key="calculator_name">
                <CalculatorName confirmName={confirmCalculatorName} />
            </Panel>
            <Panel
                disabled={!calculatorNameMemo?.length > 0}
                header={panelHeader('data')}
                key="calculator_data">
                <CalculatorData
                    calculatorId={calculatorId}
                    fertilizersFullList={fertilizersFullList}
                    goNext={handleToQuality}
                    setCultures={setCultureList}
                    setSorts={setSortList}
                    buttonDisabled={detailsNextStepDisabled}
                    form={form} />
            </Panel>
            <Panel
                disabled={!(match?.params?.id > 0)}
                header={panelHeader('quality')}
                key="calculator_quality">
                    <LaborantQualityIndicator
                        protocolCulture={selectedCulture}
                        protocolSort={selectedSort}
                        showRecalculateButtons={showRecalculateButtons}
                        qualityRepetitions={qualityRepetitions}
                        setQualityRepetitions={setQualityRepetitions}
                        images={seedImages}
                        calculatorId={calculatorId}
                        activeTabs={activeTabs}
                        setActiveTabs={setActiveTabs}
                        setPreparedProtocol={setPreparedProtocol}
                        seedsMass={((seedsMassOne + seedsMassTwo) * 1000) / (seedsNumOne + seedsNumTwo)}
                        setSeedsMassOne={setSeedsMassOne}
                        setSeedsMassTwo={setSeedsMassTwo}
                        seedsMassOne={seedsMassOne}
                        seedsNumOne={seedsNumOne}
                        seedsMassTwo={seedsMassTwo}
                        seedsNumTwo={seedsNumTwo}
                        culturesList={cultureList}
                        varietiesList={sortList}
                        form={form} />
                {/* {isLaborant() ? (
                    <LaborantQualityIndicator
                        protocolCulture={selectedCulture}
                        protocolSort={selectedSort}
                        showRecalculateButtons={showRecalculateButtons}
                        qualityRepetitions={qualityRepetitions}
                        setQualityRepetitions={setQualityRepetitions}
                        images={seedImages}
                        calculatorId={calculatorId}
                        activeTabs={activeTabs}
                        setActiveTabs={setActiveTabs}
                        setPreparedProtocol={setPreparedProtocol}
                        seedsMass={((seedsMassOne + seedsMassTwo) * 1000) / (seedsNumOne + seedsNumTwo)}
                        setSeedsMassOne={setSeedsMassOne}
                        setSeedsMassTwo={setSeedsMassTwo}
                        seedsMassOne={seedsMassOne}
                        seedsNumOne={seedsNumOne}
                        seedsMassTwo={seedsMassTwo}
                        seedsNumTwo={seedsNumTwo}
                        culturesList={cultureList}
                        varietiesList={sortList}
                        form={form} />
                ) : (
                    <SeedQualityIndicator
                        images={seedImages}
                        culturesList={cultureList}
                        varietiesList={sortList}
                        form={form} />
                )} */}
            </Panel>
            <Panel
                disabled={!(match?.params?.id > 0)}
                header={panelHeader('external')}
                key="calculator_external">
                <ExternalContamination
                    fertilizersFullList={fertilizersFullList}
                    activeTabs={activeTabs}
                    setActiveTabs={setActiveTabs}
                    pathogens={externalPathogens}
                    calculatorId={calculatorId}
                    images={externalImages}
                    form={form} />
            </Panel>
            <Panel
                disabled={!(match?.params?.id > 0)}
                header={panelHeader('internal')}
                key="calculator_internal">
                <InternalContamination
                    showRecalculateButtons={showRecalculateButtons}
                    curProto={curProto}
                    fertilizersFullList={fertilizersFullList}
                    internalPathogenStage={internalPathogenStage}
                    setInternalPathogenStage={setInternalPathogenStage}
                    activeTabs={activeTabs}
                    setActiveTabs={setActiveTabs}
                    pathogens={internalPathogens}
                    calculatorId={calculatorId}
                    images={internalImages}
                    form={form} />
            </Panel>
        </Collapse>
    );
};

export default CalculatorDetails;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Tabs } from 'antd';


import './style.less';
import {
    calculateDiagnostics,
    getLeafFieldPoints
} from '../../../../models/leaf-diagnostics/actions';

import Diagnostics from './diagnostics';
import Comparison from './comparison';
import { addGroupCalculate } from '../../../../models/mordovia/actions';
import { getCurrentCalculatorMordovia } from '../../../../models/mordovia/selectors';

const { TabPane } = Tabs;

const LeafDiagnostics = ({ selectedField = {}, isCalc = false }) => {

    const match = useRouteMatch();
    const dispatch = useDispatch();

    const { t } = useTranslation('leaf diagnostics');

    const [fieldRequestDate, setFieldRequestDate] = useState('');

    useEffect(() => {
        if (isCalc) {
            dispatch(getLeafFieldPoints({
                vega_key: selectedField?.vega_key
            }));
            dispatch(addGroupCalculate({
                vega_key: selectedField?.vega_key,
                body: {
                    // year: selectedField?.years?.length > 0 ? selectedField?.years[0] : 2021,
                    year: selectedField?.year_group,
                    method: 'kirsanov',
                    type: 'field'
                }
            }));
        }
    }, [selectedField]);

    useEffect(() => {
        if (fieldRequestDate && fieldRequestDate.length > 0) {
            dispatch(calculateDiagnostics({
                vega_key: isCalc ? selectedField?.vega_key : match.params.id,
                body: {
                    type: 'field',
                    date: fieldRequestDate
                }
            }));
        }
    }, [fieldRequestDate]);

    useEffect(() => {
        if (!isCalc) {
            dispatch(getLeafFieldPoints({
                vega_key: match.params.id
            }));
            dispatch(addGroupCalculate({
                vega_key: match.params.id,
                body: {
                    year: match.params.year,
                    method: 'kirsanov',
                    type: 'field'
                }
            }));
        }
    }, []);

    return (
        <div>
            <Tabs defaultActiveKey="1">
                <TabPane
                    tab={t('diagnostics tab')}
                    key="1">
                    <Diagnostics
                        isCalc={isCalc}
                        selectedField={selectedField}
                        setFieldRequestDate={setFieldRequestDate} />
                </TabPane>
                <TabPane
                    tab={t('comparison tab')}
                    key="2">
                    <Comparison
                        isCalc={isCalc}
                        selectedField={selectedField} />
                </TabPane>
            </Tabs>
        </div>
    );
};

export default LeafDiagnostics;
import React, { Component, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Row, Col, Modal, DatePicker } from 'antd';

import '../style.less';

export default ({
    modalDanger = false,
    isOpened = false,
    handleOk = () => {},
    handleCancel = () => {}
}) => {
    const { t } = useTranslation('layer objects');

    const [currentYear, setCurrentYear] = useState(null);

    const modalFooter = (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button onClick={handleCancel}>
                {t('modal cancel')}
            </Button>
            <Button type="primary" onClick={() => handleOk(currentYear)}>
                {t('modal save')}
            </Button>
        </div>
    );

    return (
        <Modal
            closable
            destroyOnClose
            width={400}
            title={t('add year modal title')}
            footer={modalFooter}
            visible={isOpened}
            onCancel={handleCancel}>
                <Row gutter={24}>
                    <Col span={16}>{t('dear user please choose year label for one select in modal')}</Col>
                    <Col span={8}><DatePicker style={modalDanger ? { border: '1px solid red' } : {}} onChange={val => setCurrentYear(val?.format('YYYY'))} picker="year" /></Col>
                </Row>
                {modalDanger && (
                    <div style={{ color: 'red' }}>{t('mod txt')}</div>
                )}
        </Modal>
    );
};
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useClassName } from 'utils/cn';
import { Row, Col } from 'antd';

import { Tabs, PanelLayout } from '../../../components';


import { selectors } from '../../../models';
import { toFixed } from '../../../utils/utils';

const { TabPane } = Tabs;

const {
    getCurrentCalculatorMordovia,
    getCurrentForecastCalculatorMordovia
} = selectors;

export default () => {
    const cn = useClassName('field-info');
    const { t } = useTranslation('mordovia');
    const calculator = useSelector(state => getCurrentCalculatorMordovia(state));
    const forecast = useSelector(state => getCurrentForecastCalculatorMordovia(state));
    const [show, setShow] = useState(true);

    const handleShow = () => {
        setShow(!show);
    };

    return (
        <div className={cn()} id="field-info">
            <div className={cn('header')} onClick={handleShow}>
                {t('field info')}
                <span className={cn('show')}>{show ? t('expanded') : t('expand')}</span>
            </div>
            <div className={`${cn('tabs')} ${show && 'field-info__show'}`}>
                <Tabs defaultActiveKey="1">
                    <TabPane tab={t('common info')} key="1">
                        <div className={cn('body')}>
                            <Row>
                                <Col span={12}>{t('selection region')}</Col>
                                <Col span={12}>{calculator.region?.name}</Col>
                            </Row>
                            <Row>
                                <Col span={12}>{t('selection area')}</Col>
                                <Col span={12}>{calculator.district}</Col>
                            </Row>
                            <Row>
                                <Col span={12}>{t('OKTMO')}</Col>
                                <Col span={12}>{calculator.oktmo}</Col>
                            </Row>
                            {calculator.area && (
                                <Row>
                                    <Col span={12}>{t('field area')}</Col>
                                    <Col span={12}>{toFixed(calculator.area, 1)}</Col>
                                </Row>
                            )}
                            {calculator.vega_key && (
                                <Row>
                                    <Col span={12}>{t('vega key')}</Col>
                                    <Col span={12}>{calculator.vega_key}</Col>
                                </Row>
                            )}
                            {calculator.field_num && (
                                <Row>
                                    <Col span={12}>{t('field num')}</Col>
                                    <Col span={12}>{calculator.field_num}</Col>
                                </Row>
                            )}
                            <Row>
                                <Col span={12}>{t('agricultural land')}</Col>
                                <Col span={12}>{calculator.farmland_type}</Col>
                            </Row>
                            <Row>
                                <Col span={12}>{t('soil type')}</Col>
                                <Col span={12}>{calculator.soil_type}</Col>
                            </Row>
                            <Row>
                                <Col span={12}>{t('acidity')}</Col>
                                <Col span={12}>{calculator?.ph_salt}</Col>
                            </Row>
                            <Row>
                                <Col span={12}>{t('preceding')}</Col>
                                <Col span={12}>{calculator?.preceding_culture?.name}</Col>
                            </Row>
                            <Row>
                                <Col span={12}>{t('culture')}</Col>
                                <Col span={12}>{calculator?.culture?.name}</Col>
                            </Row>
                            <Row>
                                <Col span={12}>{t('planned yield')}</Col>
                                <Col span={12}>{calculator?.planned_yield}</Col>
                            </Row>
                            { calculator.google_disk_url && (
                                <Row style={{ marginTop: '30px' }}>
                                    <Col span={24}>
                                        <a className={cn('link-to')}
                                            target="_blank"
                                            rel="noreferrer noopener"
                                            href={calculator.google_disk_url}>
                                            {t('link to')}
                                        </a>
                                    </Col>
                                </Row>
                            )}
                        </div>
                    </TabPane>
                    <TabPane tab={t('micro info')} key="2">
                        <div className={cn('body')}>
                            {calculator.no3 && (
                                <Row>
                                    <Col span={12}>NO<sub>3</sub></Col>
                                    <Col span={12}>{calculator.no3}</Col>
                                </Row>
                            )}
                            {calculator.nh4 && (
                                <Row>
                                    <Col span={12}>NH<sub>4</sub></Col>
                                    <Col span={12}>{calculator.nh4}</Col>
                                </Row>
                            )}
                            {calculator.p2o5 && (
                                <Row>
                                    <Col span={12}>P<sub>2</sub>O<sub>5</sub></Col>
                                    <Col span={12}>{calculator.p2o5}</Col>
                                </Row>
                            )}
                            {calculator.k2o && (
                                <Row>
                                    <Col span={12}>K<sub>2</sub>O</Col>
                                    <Col span={12}>{calculator.k2o}</Col>
                                </Row>
                            )}

                            {calculator.s && (
                                <Row>
                                    <Col span={12}>S</Col>
                                    <Col span={12}>{calculator.s}</Col>
                                </Row>
                            )}
                            {calculator.ca && (
                                <Row>
                                    <Col span={12}>Ca</Col>
                                    <Col span={12}>{calculator.ca}</Col>
                                </Row>
                            )}
                            {calculator.mg && (
                                <Row>
                                    <Col span={12}>Mg</Col>
                                    <Col span={12}>{calculator.mg}</Col>
                                </Row>
                            )}
                            {calculator.na && (
                                <Row>
                                    <Col span={12}>Na</Col>
                                    <Col span={12}>{calculator.na}</Col>
                                </Row>
                            )}
                            {calculator.so4 && (
                                <Row>
                                    <Col span={12}>SO<sub>4</sub></Col>
                                    <Col span={12}>{calculator.so4}</Col>
                                </Row>
                            )}
                            {calculator.cl && (
                                <Row>
                                    <Col span={12}>Cl</Col>
                                    <Col span={12}>{calculator.cl}</Col>
                                </Row>
                            )}
                            {calculator.hg && (
                                <Row>
                                    <Col span={12}>Hg</Col>
                                    <Col span={12}>{calculator.hg}</Col>
                                </Row>
                            )}
                            {calculator.v && (
                                <Row>
                                    <Col span={12}>V</Col>
                                    <Col span={12}>{calculator.v}</Col>
                                </Row>
                            )}
                            {calculator.b && (
                                <Row>
                                    <Col span={12}>B</Col>
                                    <Col span={12}>{calculator.b}</Col>
                                </Row>
                            )}
                            {calculator.mn && (
                                <Row>
                                    <Col span={12}>Mn</Col>
                                    <Col span={12}>{calculator.mn}</Col>
                                </Row>
                            )}
                            {calculator.cu && (
                                <Row>
                                    <Col span={12}>Cu</Col>
                                    <Col span={12}>{calculator.cu}</Col>
                                </Row>
                            )}
                            {calculator.mo && (
                                <Row>
                                    <Col span={12}>Mo</Col>
                                    <Col span={12}>{calculator.mo}</Col>
                                </Row>
                            )}
                            {calculator.co && (
                                <Row>
                                    <Col span={12}>Co</Col>
                                    <Col span={12}>{calculator.co}</Col>
                                </Row>
                            )}
                            {calculator.zn && (
                                <Row>
                                    <Col span={12}>Zn</Col>
                                    <Col span={12}>{calculator.zn}</Col>
                                </Row>
                            )}
                            {calculator.pb && (
                                <Row>
                                    <Col span={12}>Pb</Col>
                                    <Col span={12}>{calculator.pb}</Col>
                                </Row>
                            )}
                            {calculator.ni && (
                                <Row>
                                    <Col span={12}>Ni</Col>
                                    <Col span={12}>{calculator.ni}</Col>
                                </Row>
                            )}
                        </div>
                    </TabPane>
                    <TabPane tab={t('history info')} key="3">
                        <div className={cn('body')}>
                            <Row>
                                <Col span={12}>{t('created')}</Col>
                                <Col span={12}>{calculator.created}</Col>
                            </Row>
                            <Row>
                                <Col span={12}>{t('updated')}</Col>
                                <Col span={12}>{calculator.updated}</Col>
                            </Row>
                        </div>
                    </TabPane>
                </Tabs>
            </div>
        </div>
    );
};
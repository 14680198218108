import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Button, Col, Collapse, Form, Layout, Row, Spin
} from 'antd';
import Icon, { DownloadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { useClassName } from '../../utils/cn';
import Agrochemical from './components/agrochemical';
import Fed from './components/fed';
import Imap from './components/imap';

import './style.less';

import { PanelLayout, Title } from '../../components';
import ListIcon from '../../../public/images/calculator/list2_stroke.svg';
import CalculatorIcon from '../../../public/images/calculator/calculator2_stroke.svg';
import { curGeneralProtocol } from '../../models/general-protocol/selectors';
import { getUser } from '../../models/account/selectors';
import { getProtocol } from '../../models/general-protocol/actions';
import Parameters from './components/parameters';
import PreviewModal from './components/preview-modal';

const { Panel } = Collapse;
const { PanelHeader, PanelContent } = PanelLayout;

const GeneralProtocol = () => {
    const dispatch = useDispatch();
    const cn = useClassName('general-protocol');
    const { t } = useTranslation('general protocol');

    const generalProto = useSelector(state => curGeneralProtocol(state));
    const currentUser = useSelector(state => getUser(state));

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedOwner, setSelectedOwner] = useState(null);

    const [selectedAgros, setSelectedAgros] = useState([]);
    const [selectedFeds, setSelectedFeds] = useState([]);
    const [selectedImaps, setSelectedImaps] = useState([]);
    const [usersList, setUsersList] = useState([]);
    const [ownerName, setOwnerName] = useState(null);
    const [selectedProd, setSelectedProd] = useState([]);
    const [selectedYear, setSelectedYear] = useState([]);
    const [selectedField, setSelectedField] = useState([]);

    const [currentAgrochemicalData, setCurrentAgrochemicalData] = useState([]);
    const [currentFedData, setCurrentFedData] = useState([]);
    const [currentImapData, setCurrentImapData] = useState([]);
    const [triedProtocol, setTriedProtocol] = useState(false);

    useEffect(() => {
        setCurrentAgrochemicalData(generalProto?.agros ? generalProto?.agros : []);
        setCurrentImapData(generalProto?.imaps ? generalProto?.imaps : []);
        setCurrentFedData(generalProto?.feds ? generalProto?.feds : []);
    }, [generalProto]);

    const [activeTabs, setActiveTabs] = useState([
        'parameters'
    ]);

    const handleDownloadProtocol = () => {
        const requestBody = {
            imaps: selectedImaps?.length > 0 ? selectedImaps?.map(item => item.id) : [],
            feds: selectedFeds?.length > 0 ? selectedFeds?.map(item => item.id) : [],
            agros: selectedAgros?.length > 0 ? selectedAgros?.map(item => item.id) : []
        };

        fetch(`/api/v1/teleagronom_user/owners/${selectedOwner}/download_general_protocol/`, {
            method: 'POST',
            headers: {
                Authorization: `JWT ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestBody)
        }).then((resp) => {
            resp.blob().then((blb) => {
                const url = window.URL.createObjectURL(blb);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = `General_Protocol_${selectedOwner}.xlsx`;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
                // setIsRecordGenerating(false);
            });
        });
    };

    return (
        <Layout key="page-general-protocol" className={cn()}>
            <PanelLayout>
                <PanelHeader>
                    <Title
                        title={t('title')} />
                </PanelHeader>
                <PanelContent>
                    <div className={cn()}>
                        <Collapse
                            onChange={setActiveTabs}
                            activeKey={activeTabs}
                            expandIconPosition="right"
                            className={cn('collapse')}>
                            <Panel
                                header={(
                                    <b>
                                        <Icon component={ListIcon} />
                                        {t('protocol panel parameters')}
                                    </b>
                                )}
                                key="parameters">
                                <Parameters
                                    triedProtocol={triedProtocol}
                                    setTriedProtocol={setTriedProtocol}
                                    setSelectedProd={setSelectedProd}
                                    setSelectedYear={setSelectedYear}
                                    setSelectedField={setSelectedField}
                                    setOwnerName={setOwnerName}
                                    updateParentUsers={setUsersList}
                                    setSelectedOwner={setSelectedOwner}
                                    activeTabs={activeTabs}
                                    setActiveTabs={setActiveTabs} />
                            </Panel>

                            <Panel
                                disabled={generalProto?.agros?.length === 0}
                                header={(
                                    <b>
                                        <Icon component={CalculatorIcon} />
                                        {t('protocol panel agrochemical')}
                                    </b>
                                )}
                                key="agro">
                                <Agrochemical
                                    triedProtocol={triedProtocol}
                                    ownerName={ownerName}
                                    selectedAgros={selectedAgros}
                                    setSelectedAgros={setSelectedAgros}
                                    calculatorData={currentAgrochemicalData} />
                            </Panel>

                            <Panel
                                disabled={generalProto?.feds?.length === 0}
                                header={(
                                    <b>
                                        <Icon component={ListIcon} />
                                        {t('protocol panel fed')}
                                    </b>
                                )}
                                key="fed">
                                <Fed
                                    triedProtocol={triedProtocol}
                                    selectedFeds={selectedFeds}
                                    setSelectedFeds={setSelectedFeds}
                                    calculatorData={currentFedData} />
                            </Panel>

                            <Panel
                                disabled={generalProto?.imaps?.length === 0}
                                header={(
                                    <b>
                                        <Icon component={ListIcon} />
                                        {t('protocol panel imap')}
                                    </b>
                                )}
                                key="imap">
                                <Imap
                                    triedProtocol={triedProtocol}
                                    ownerName={ownerName}
                                    selectedImaps={selectedImaps}
                                    setSelectedImaps={setSelectedImaps}
                                    calculatorData={currentImapData} />
                            </Panel>
                        </Collapse>

                        <Row style={{ marginTop: '25px' }} align="center">
                            <Button onClick={() => setIsModalOpen(true)} type="primary">
                                {t('preview protocol')}
                            </Button>
                        </Row>

                        <PreviewModal
                            selectedProd={selectedProd}
                            selectedYear={selectedYear}
                            selectedField={selectedField}
                            ownerName={ownerName}
                            usersList={usersList}
                            selectedFeds={selectedFeds}
                            selectedAgros={selectedAgros}
                            selectedImaps={selectedImaps}
                            generateProtocol={handleDownloadProtocol}
                            setIsOpen={setIsModalOpen}
                            isOpen={isModalOpen} />
                    </div>
                </PanelContent>
            </PanelLayout>
        </Layout>
    );
};

export default GeneralProtocol;
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { Table, TableFooter, Tooltip } from '../../../components';
import cn from '../../../utils/cn';

const ROW_DIVIDER = 10;

@withRouter
@withTranslation('pesticides')
@cn('pesticides')
export default class PesticidesTable extends Component {
    state = {
        value: undefined
    };

    get requestsColumns() {
        const { cn } = this;
        const {
            t, history
        } = this.props;

        return [
            {
                title: t('pesticide title'),
                dataIndex: 'plant_protection_product',
                key: 'plant_protection_product',
                className: cn('name'),
                render: (text, record) => (
                    <Tooltip
                        placement="bottomLeft"
                        title={text && text.name}>
                        {text && text.name}
                    </Tooltip>
                )

            },
            {
                title: t('norm'),
                dataIndex: 'rate_of_use_min',
                key: 'rate_of_use_min',
                className: cn('norm'),
                render: (text, record) => (
                    <Tooltip
                        placement="bottomLeft"
                        title={text}>
                        { (record.rate_of_use_min === record.rate_of_use_max) && (
                            <>{record.rate_of_use_min}</>)}
                        { (record.rate_of_use_min !== record.rate_of_use_max)
                            && (<>{record.rate_of_use_min}-{record.rate_of_use_max}</>)}
                    </Tooltip>
                )
            },
            {
                title: t('unit'),
                dataIndex: 'unit_of_measurement',
                key: 'unit_of_measurement',
                className: cn('unit'),
                render: (text, record) => (
                    <Tooltip
                        placement="bottomLeft"
                        title={text && text.name}>
                        {text && text.name}
                    </Tooltip>
                )
            }
        ];
    }

    render() {
        const { cn } = this;
        const {
            history,
            onCurrentPageChange,
            onPageSizeChange,
            filterItems,
            pageSize,
            pageNum,
            dataSource,
            dataSize
        } = this.props;

        return (
            <div>

                <Table
                    onRow={(record, rowIndex) => ({
                        onClick: () => history.push(`/pesticide/${record.id}`)
                    })}
                    dataSource={dataSource}
                    columns={this.requestsColumns}
                    pagination={false} />
                {dataSize > 1 && (
                    <TableFooter
                        filterItems={filterItems}
                        showSizeChanger={false}
                        onSelect={onPageSizeChange}
                        onChange={onCurrentPageChange}
                        pageSize={pageSize}
                        current={pageNum}
                        rowDivider={ROW_DIVIDER.toString()}
                        dataSize={dataSize} />
                )}
            </div>
        );
    }
}
import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { Space, Button, Icon, Tooltip } from "antd";
import {
    Title, PanelLayout
} from '../../../components';

import Map from './map';
import FieldInfo from './field';

import CalculatorDetail from './calculator';
import { history } from '../../../index';
import { useHistory } from 'react-router-dom';
import ArrowLeft from '../../../../public/images/arrow-left.svg';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getCurrentCalculatorMordovia } from '../../../models/mordovia/selectors';

const { PanelHeader, PanelContent } = PanelLayout;


export default ({ vegaKey }) => {
    const match = useRouteMatch();
    const history = useHistory();
    const { t } = useTranslation('mordovia');
    const [curPoint, setCurPoint] = useState(undefined);

    const calculator = useSelector(state => getCurrentCalculatorMordovia(state));
    
    const setPoint = (id) => {
        setCurPoint(id);
    };

    return (
        <PanelLayout>
            <PanelHeader>
                <Title title={t('calculator name')} />
            </PanelHeader>
            <PanelContent>
                <div>
                    <Space style={{ marginBottom: '25px' }} direction="horizontal">
                        <>
                            <Tooltip title={t('back to registries popup')}>
                                <Button onClick={() => {
                                        const lastId = localStorage.getItem('teleagronom-last-opened-map');
                                        if (lastId) {
                                            history.push("/field-registry" + "/" + lastId);
                                        }
                                    }}>
                                    <ArrowLeftOutlined />
                                </Button>
                            </Tooltip>
                        </>
                        
                        <>
                            <Space style={{ marginLeft: '20px' }} direction="vertical">
                                <Space direction="horizontal">
                                    <div style={{ color: '#979797' }}>
                                        <span style={{ fontSize: '20px' }}>{t('current field vega')}:</span>
                                    </div>
                                    <div style={{ fontSize: '20px' }}>
                                        {calculator?.field_num}
                                    </div>
                                </Space>
                                <Space direction="horizontal">
                                    <Space direction="horizontal">
                                        <div style={{ color: '#979797' }}>
                                            <span style={{ fontSize: '15px' }}>{t('current field year')}:</span>
                                        </div>
                                        <div style={{ fontSize: '15px' }}>
                                            {match?.params?.year}
                                        </div>
                                    </Space>
                                    <Space direction="horizontal">
                                        <div style={{ color: '#979797' }}>
                                            <span style={{ fontSize: '15px' }}>{t('current field culture')}:</span>
                                        </div>
                                        <div style={{ fontSize: '15px' }}>
                                            {calculator?.culture?.name}
                                        </div>
                                    </Space>
                                </Space>
                            </Space>
                        </>
                    </Space>
                </div>
                <div id="map-mordovia">
                    <Map curPoint={curPoint} />
                    {/* <FieldInfo /> */}
                </div>
                <CalculatorDetail vegaKey={vegaKey} setCurPoint={setPoint} />
            </PanelContent>
        </PanelLayout>
    );
};
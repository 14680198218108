import * as constants from '../constants';

const initialState = [];

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case constants.getProtocolsPhyto.success: {
            return action.payload;
        }

        case constants.getProtocolsPhyto.error: {
            return [];
        }

        case constants.getProtocolsPhyto.try: {
            return initialState;
        }

        default:
            return state;
    }
}
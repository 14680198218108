import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Logo from '../logo';
import VMenu from '../verticalmenu';
import VMenuMordovia from '../verticalmenu-mordovia';

import cn from '../../utils/cn';
import './style.less';


@withTranslation('side-bar')
@cn('side-bar')
export default class SideBar extends Component {
    render() {
        const { cn } = this;
        const { isMordovia = false, username = '' } = this.props;

        return (
            <div className={cn()}>
                <Logo />
                {/* {user?.id ? <VMenuMordovia username={user?.id} /> : <VMenu />} */}
                <VMenu />
            </div>
        );
    }
}
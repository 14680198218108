import React from 'react';
import Private from '../../containers/private-route';
import Page from './component';

const Routes = [
    <Private
        exact
        key="layer-objects"
        path="/layer-objects/:id?"
        component={Page} />
];

export default Routes;
import React, { useEffect, useState, useRef } from 'react';

import { useTranslation } from 'react-i18next';

// import { useClassName } from 'utils/cn';

import '../style.less';
import { Button, Col, Collapse, Modal, Row, Table } from 'antd';
import moment from 'moment';
import Icon from '@ant-design/icons';
import ListIcon from '../../../../public/images/calculator/list2_stroke.svg';
import Parameters from './parameters';
import CalculatorIcon from '../../../../public/images/calculator/calculator2_stroke.svg';
import Agrochemical from './agrochemical';
import Fed from './fed';
import Imap from './imap';

const { Panel } = Collapse;
const PreviewModal = ({
    ownerName,
    usersList,
    setIsOpen, isOpen,
    generateProtocol,
    selectedFeds, selectedImaps, selectedAgros,
    selectedField, selectedProd, selectedYear
}) => {
    // const cn = useClassName('calculators');
    const { t } = useTranslation('general protocol');

    const [ownersList, setOwnersList] = useState([]);
    const [fieldsList, setFieldsList] = useState([]);
    const [yearsList, setYearsList] = useState([]);
    const [productivityZoneList, setProductivityZoneList] = useState([]);

    useEffect(() => {
        const newOwnerList = [];
        const newFieldList = [];
        const newYearList = [];
        const newProductivityZoneList = [];

        selectedFeds?.forEach(protocolItem => {
            const { productivity_zone, vega_key, owner } = protocolItem;

            if (vega_key && !newFieldList.includes(vega_key)) {
                newFieldList.push(vega_key);
            }
            if (owner?.name && !newOwnerList.includes(owner?.name)) {
                newOwnerList.push(owner?.name);
            }
            if (productivity_zone) {
                const zoneName = t(`productivity zone ${productivity_zone}`);
                if (!newProductivityZoneList.includes(zoneName)) {
                    newProductivityZoneList.push(zoneName);
                }
            }
        });

        selectedAgros?.forEach(protocolItem => {
            const { productivity_zone, vega_key, owner } = protocolItem;

            if (vega_key && !newFieldList.includes(vega_key)) {
                newFieldList.push(vega_key);
            }
            if (owner) {
                const userName = usersList?.find(user => user.id === owner)?.name;
                if (!newOwnerList.includes(userName)) {
                    newOwnerList.push(userName);
                }
            }
            if (productivity_zone) {
                const zoneName = t(`productivity zone ${productivity_zone}`);
                if (!newProductivityZoneList.includes(zoneName)) {
                    newProductivityZoneList.push(zoneName);
                }
            }
        });

        selectedImaps?.forEach(protocolItem => {
            const { vega_key, owner } = protocolItem;

            if (vega_key && !newFieldList.includes(vega_key)) {
                newFieldList.push(vega_key);
            }
            if (owner) {
                const userName = usersList?.find(user => user.id === owner)?.name;
                if (!newOwnerList.includes(userName)) {
                    newOwnerList.push(userName);
                }
            }
        });

        setFieldsList(newFieldList);
        setOwnersList(newOwnerList);
        setProductivityZoneList(newProductivityZoneList);
        setYearsList(newYearList);
    }, [selectedFeds, selectedImaps, selectedAgros]);

    // useEffect(() => {
    //     setFieldsList([]);
    //     setYearsList([]);
    //     setProductivityZoneList([]);
    //     setOwnersList([]);
    // }, []);

    const handleOk = () => {
        generateProtocol();
        setIsOpen(false);
    };

    const handleCancel = () => {
        setIsOpen(false);
    };

    return (
        <Modal
            footer={[
                <Button key="back" onClick={handleCancel}>
                    {t('modal close')}
                </Button>,
                <Button key="submit" type="primary" onClick={handleOk}>
                    {t('modal ok')}
                </Button>
            ]}
            style={{ overflowY: 'auto' }}
            width={800}
            title={t('general protocol modal title')}
            onCancel={handleCancel}
            onOk={handleOk}
            visible={isOpen}>

            <Row>
                <Col span={8}>
                    {t('modal field owner')}:
                </Col>
                <Col span={16}>
                    {ownerName ? ownerName : ownersList?.join(', ')}
                </Col>
            </Row>
            <Row>
                <Col span={8}>
                    {t('modal field field')}:
                </Col>
                <Col span={16}>
                    {selectedField?.join(', ')}
                </Col>
            </Row>
            <Row>
                <Col span={8}>
                    {t('modal field year')}:
                </Col>
                <Col span={16}>
                    {selectedYear?.join(', ')}
                </Col>
            </Row>
            <Row>
                <Col span={8}>
                    {t('modal field productivity_zone')}:
                </Col>
                <Col span={16}>
                    {selectedProd?.map(x => t(`productivity zone ${x}`)).join(', ')}
                </Col>
            </Row>

            <Collapse
                ghost
                activeKey={[
                    selectedAgros?.length > 0 ? 'agrochemical' : '',
                    selectedFeds?.length > 0 ? 'fed' : '',
                    selectedImaps?.length > 0 ? 'imap' : ''
                ]}
                expandIconPosition="right">
                <Panel
                    disabled
                    header={(
                        <b>
                            <Icon component={CalculatorIcon} />
                            {t('protocol panel agrochemical')}
                        </b>
                    )}
                    key="agrochemical">
                    {selectedAgros?.map(protocolItem => (
                        <div style={{ border: '1px solid black', padding: '15px 15px' }}>
                            <Row gutter={12}>
                                <Col span={12}>
                                    {moment(protocolItem?.date).format('DD.MM.YYYY')}
                                </Col>
                                <Col span={12}>
                                    {`${t('protocol number')}: ${protocolItem?.num}`}
                                </Col>
                            </Row>
                            <Row gutter={12}>
                                <Col span={12}>
                                    {t('protocol year')}
                                </Col>
                                <Col span={12}>
                                    {protocolItem?.date?.split('-')[0]}
                                </Col>
                            </Row>
                            <Row gutter={12}>
                                <Col span={12}>
                                    {t(`productivity zone ${protocolItem?.productivity_zone}`)}
                                </Col>
                            </Row>
                        </div>
                    ))}
                </Panel>

                <Panel
                    disabled
                    header={(
                        <b>
                            <Icon component={ListIcon} />
                            {t('protocol panel fed')}
                        </b>
                    )}
                    key="fed">
                    {selectedFeds?.map(protocolItem => (
                        <div style={{ border: '1px solid black', padding: '15px 15px' }}>
                            <Row gutter={12}>
                                <Col span={12}>
                                    {moment(protocolItem?.acceptation_date).format('DD.MM.YYYY')}
                                </Col>
                                <Col span={12}>
                                    {`${t('protocol number')}: ${protocolItem?.protocol_number}`}
                                </Col>
                            </Row>
                            <Row gutter={12}>
                                <Col span={12}>
                                    {t('protocol points')}
                                </Col>
                                <Col span={12}>
                                    {protocolItem?.field_points?.join(', ')}
                                </Col>
                            </Row>
                            <Row gutter={12}>
                                <Col span={12}>
                                    {t(`productivity zone ${protocolItem?.productivity_zone}`)}
                                </Col>
                            </Row>
                        </div>
                    ))}
                </Panel>

                <Panel
                    disabled
                    header={(
                        <b>
                            <Icon component={ListIcon} />
                            {t('protocol panel imap')}
                        </b>
                    )}
                    key="imap">
                    {selectedImaps?.map(protocolItem => (
                        <div style={{ border: '1px solid black', padding: '15px 15px' }}>
                            <Row gutter={12}>
                                <Col span={12}>
                                    {moment(protocolItem?.sample_date).format('DD.MM.YYYY')}
                                </Col>
                                <Col span={12}>
                                    {`${t('protocol number')}: ${protocolItem?.num}`}
                                </Col>
                            </Row>
                            <Row gutter={12}>
                                <Col span={12}>
                                    {t('protocol points')}
                                </Col>
                                <Col span={12}>
                                    {protocolItem?.points?.join(', ')}
                                </Col>
                            </Row>
                            <Row gutter={12}>
                                <Col span={12}>
                                    {protocolItem?.productivity_zone ? t(`productivity zone ${protocolItem?.productivity_zone}`) : null}
                                </Col>
                            </Row>
                        </div>
                    ))}
                </Panel>
            </Collapse>
        </Modal>
    );
};

export default PreviewModal;
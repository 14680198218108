import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import {
    Button, Col, Collapse, Form, Image, Input, InputNumber,
    Popover, Row, Select, Table, Upload
} from 'antd';
import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import { selectors, actions } from '../../../models';
import { useClassName } from '../../../utils/cn';
import { TextArea } from '../../../components';
import {
    uploadRegistryFile,
    updateFileDescription, getMycologicalRegistry
} from '../../../models/mycological-registry/actions';
import { toFixed } from '../../../utils/utils';

import '../style.less';
import {
    curNewFilename
} from '../../../models/mycological-registry/selectors';
import moment from 'moment';

const {
    getGenusOfMicroCatalogCalculatorsMyco
} = selectors;

const { getGenusOfMicroCalculatorsMyco } = actions;

const { Panel } = Collapse;

export const Analysis = ({
    registry, t, form, rep,
    mycobiota1,
    mycobiota2,
    mycobiota3,
    onFormFinish
}) => {
    const micro = useSelector(state => getGenusOfMicroCatalogCalculatorsMyco(state));
    const cn = useClassName('calculator-detail-params');
    const cn_info = useClassName('calculator');
    const repetitionsArr = new Array(6).fill(0);
    const match = useRouteMatch();
    const { params: { id } } = match;

    const [repetitions, setRepetitions] = useState(rep ? new Array(rep).fill(0) : [0]);
    const [mycobiotaOptions, setMycobiotaOptions] = useState([]);

    const dispatch = useDispatch();

    useEffect(() => {
        const allMyco = [...mycobiota1, ...mycobiota2, ...mycobiota3].map(x => x.genius);
        const filteredMicro = micro.filter(x => !allMyco.includes(x.id) && x.id !== 40);
        setMycobiotaOptions(filteredMicro.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)));
    }, [micro, mycobiota1, mycobiota2, mycobiota3]);

    useEffect(() => {
        dispatch(getGenusOfMicroCalculatorsMyco('?page_size=1000'));
    }, []);

    const onRepetitionsChange = (value) => {
        setRepetitions(new Array(value).fill(0));
    };

    const removeUploadedFile = (val1) => {
        const formValues = form.getFieldsValue();
        formValues.pictures1 = registry?.pictures1?.filter(x => x.id !== val1.id).map(y => y.id);
        onFormFinish(formValues);
    };

    const uploadProps = {
        name: 'file',
        customRequest: (reqParams) => {
            if (id) {
                const { file } = reqParams;
                return dispatch(uploadRegistryFile({
                    values: {
                        uploaded: moment().format('YYYY-MM-DDThh:mm'),
                        picture_file: file,
                        picture_desc: `1/File №${registry?.pictures1?.length + 1}`
                    },
                    type: 'formdata'
                }));
            }
        }
    };

    return (
        <div className={cn('row-table')}>
            <Row className={cn('row-title')} gutter={20}>
                <Col span={4} className="field">
                    <Form.Item label={t('count')} name="repetitions">
                        <Select onChange={onRepetitionsChange}>
                            {repetitionsArr.map((item, index) => (
                                <Select.Option value={index + 1}>{index + 1}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col>
                    <div className={cn_info('info')}>
                        <div className={cn_info('info__inner')}>
                            {t('count info')}
                        </div>
                    </div>
                </Col>
            </Row>
            <Row className={cn('row-head')}>
                <Col span={6} className={cn('row-table__label')}>
                    {t('mycobiota genus')}
                </Col>
                {
                    repetitions.map((item, index) => (
                        <Col span={2}
                            className={cn('row-table__label')}>
                            {index + 1}
                        </Col>
                    ))
                }

                <Col span={3} className={cn('row-table__label')}>
                    {t('average')}
                </Col>
                {/*<Col span={3} className={cn('row-table__label')}>*/}
                {/*    {t('mycobiote')}*/}
                {/*</Col>*/}
            </Row>
            <Collapse defaultActiveKey={['1', '2', '3']} ghost className={cn('collapse-panel')}>

                <Row className="bacteria-first-row">
                    <Col style={{ marginLeft: '5px', marginRight: '5px' }} span={5}>
                        {t('bacteria general name')}
                    </Col>
                    {repetitions.map((item, index) => (
                        <Col
                            span={2}
                            className={cn('row-table__label')}>
                            <Form.Item name={`mycobiota_type_0/40/${index}`}>
                                <InputNumber decimalSeparator="," style={{ width: '95%' }} />
                            </Form.Item>
                        </Col>

                    ))}
                    <Col
                        style={{ marginLeft: '35px' }}
                        span={3}
                        className={cn('input')}>
                        {registry?.result_imap?.[' '] !== undefined ? toFixed(registry?.result_imap?.[' '][40]?.mean) : 0}
                    </Col>
                </Row>

                <Panel
                    header={(
                        <Row
                            className={cn('row-table__withborder')}
                            style={{ marginTop: '-18px' }}>
                            <Col span={8} className={cn('row-table__label')}>{t('neutral')}</Col>
                            <Col span={8} style={{ textAlign: 'center' }} />
                                {/*<div>*/}
                                {/*    {t('average')}*/}
                                {/*</div>*/}
                                {/*<div>*/}
                                {/*    {toFixed(registry?.result_imap?.neutral?.mean_sum)}*/}
                                {/*</div>*/}
                            {/*</Col>*/}
                            <Col span={8} style={{ textAlign: 'center' }}>
                                <div>
                                    {t('mycobiote')}
                                </div>
                                <div>
                                    {toFixed(registry?.result_imap?.neutral?.mycobiota_precentage)}
                                </div>
                            </Col>
                        </Row>
                    )}
                    key="1">
                    <Row className={cn('row-table__withborder')} style={{ padding: '0px' }}>
                        <Col span={6}>
                            <Form.Item name="mycobiota_type_1">
                                <Select
                                    mode="multiple"
                                    labelInValue
                                    showSearch
                                    filterOption={(input, option) => (option?.label ?? '')?.toLowerCase().includes(input?.toLowerCase())}
                                    allowClear>
                                    {mycobiotaOptions?.map(item => (
                                        <Select.Option value={item.id} key={item.id} label={item.name}>
                                            {item.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row className={cn('row-head')}>
                        <Col span={6} className={cn('row-table__label')}>
                            {t('mycobiota genus')}
                        </Col>
                        {
                            repetitions.map((item, index) => (
                                <Col span={2}
                                     className={cn('row-table__label')}>
                                    {index + 1}
                                </Col>
                            ))
                        }

                        <Col span={3} className={cn('row-table__label')}>
                            {t('average')}
                        </Col>
                        {/*<Col span={3} className={cn('row-table__label')}>*/}
                        {/*    {t('mycobiote')}*/}
                        {/*</Col>*/}
                    </Row>

                    {mycobiota1?.length > 0 && mycobiota1.map(x => (
                        <Row
                            className={cn('row-table__withborder')}
                            style={{ padding: '0px', alignItems: 'center' }}>
                            <Col span={6}>
                                {x.name}
                            </Col>
                            {repetitions.map((item, index) => (
                                <Col
                                    span={2}
                                    className={cn('row-table__label')}>
                                    <Form.Item
                                        name={`mycobiota_type_1/${x?.genius}/${index}`}
                                        style={{ marginTop: '-17px', marginBottom: '5px' }}>
                                        <InputNumber
                                            decimalSeparator=","
                                            style={{
                                                maxWidth: '120px',
                                                width: '95%',
                                                marginTop: '16px',
                                                marginBottom: '-35px'
                                            }} />
                                    </Form.Item>
                                </Col>

                            ))}
                            <Col
                                span={3}
                                className={cn('input')}>
                                {toFixed(registry?.result_imap?.neutral ? registry?.result_imap?.neutral[x.genius]?.mean : null)}
                            </Col>
                        </Row>
                    ))}
                </Panel>
                <Panel
                    header={(
                        <Row className={cn('row-table__withborder')} style={{ marginTop: '-18px' }}>
                            <Col span={8} className={cn('row-table__label')}>{t('suppressive')}</Col>
                            <Col span={8} style={{ textAlign: 'center' }} />
                            {/*    <div>*/}
                            {/*        {t('average')}*/}
                            {/*    </div>*/}
                            {/*    <div>*/}
                            {/*        {toFixed(registry?.result_imap?.suppressive?.mean_sum)}*/}
                            {/*    </div>*/}
                            {/*</Col>*/}
                            <Col span={8} style={{ textAlign: 'center' }}>
                                <div>
                                    {t('mycobiote')}
                                </div>
                                <div>
                                    {toFixed(registry?.result_imap?.suppressive?.mycobiota_precentage)}
                                </div>
                            </Col>
                        </Row>
                    )}
                    key="2">
                    <Row className={cn('row-table__withborder')} style={{ padding: '0px' }}>
                        <Col span={6}>
                            <Form.Item name="mycobiota_type_2">
                                <Select
                                    mode="multiple"
                                    labelInValue
                                    showSearch
                                    filterOption={(input, option) => (option?.label ?? '')?.toLowerCase().includes(input?.toLowerCase())}
                                    allowClear>
                                    {mycobiotaOptions?.map(item => (
                                        <Select.Option value={item.id} key={item.id} label={item.name}>
                                            {item.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row className={cn('row-head')}>
                        <Col span={6} className={cn('row-table__label')}>
                            {t('mycobiota genus')}
                        </Col>
                        {
                            repetitions.map((item, index) => (
                                <Col span={2}
                                     className={cn('row-table__label')}>
                                    {index + 1}
                                </Col>
                            ))
                        }

                        <Col span={3} className={cn('row-table__label')}>
                            {t('average')}
                        </Col>
                        {/*<Col span={3} className={cn('row-table__label')}>*/}
                        {/*    {t('mycobiote')}*/}
                        {/*</Col>*/}
                    </Row>

                    {mycobiota2?.length > 0 && mycobiota2.map(x => (
                        <Row
                            className={cn('row-table__withborder')}
                            style={{ padding: '0px', alignItems: 'center' }}>
                            <Col span={6}>
                                {x.name}
                            </Col>
                            {repetitions.map((item, index) => (
                                <Col
                                    span={2}
                                    className={cn('row-table__label')}>
                                    <Form.Item
                                        name={`mycobiota_type_2/${x?.genius}/${index}`}
                                        style={{ marginTop: '-17px', marginBottom: '5px' }}>
                                        <InputNumber
                                            decimalSeparator=","
                                            style={{
                                                maxWidth: '120px',
                                                width: '95%',
                                                marginTop: '16px',
                                                marginBottom: '-35px'
                                            }} />
                                    </Form.Item>
                                </Col>
                            ))}
                            <Col
                                span={3}
                                className={cn('input')}>
                                {toFixed(registry?.result_imap?.suppressive ? registry?.result_imap?.suppressive[x.genius]?.mean : null)}
                            </Col>
                        </Row>
                    ))}
                </Panel>
                <Panel
                    header={(
                        <Row className={cn('row-table__withborder')} style={{ marginTop: '-18px' }}>
                            <Col span={8} className={cn('row-table__label')}>{t('phytopathogenic')}</Col>
                            <Col span={8} style={{ textAlign: 'center' }} />
                            {/*    <div>*/}
                            {/*        {t('average')}*/}
                            {/*    </div>*/}
                            {/*    <div>*/}
                            {/*        {toFixed(registry?.result_imap?.phytopathogenic?.mean_sum)}*/}
                            {/*    </div>*/}
                            {/*</Col>*/}
                            <Col span={8} style={{ textAlign: 'center' }}>
                                <div>
                                    {t('mycobiote')}
                                </div>
                                <div>
                                    {toFixed(registry?.result_imap?.phytopathogenic?.mycobiota_precentage)}
                                </div>
                            </Col>
                        </Row>
                    )}
                    key="3">
                    <Row className={cn('row-table__withborder')} style={{ padding: '0px' }}>
                        <Col span={6}>
                            <Form.Item name="mycobiota_type_3">
                                <Select
                                    mode="multiple"
                                    labelInValue
                                    showSearch
                                    filterOption={(input, option) => (option?.label ?? '')?.toLowerCase().includes(input?.toLowerCase())}
                                    allowClear>
                                    {mycobiotaOptions?.map(item => (
                                        <Select.Option value={item.id} key={item.id} label={item.name}>
                                            {item.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row className={cn('row-head')}>
                        <Col span={6} className={cn('row-table__label')}>
                            {t('mycobiota genus')}
                        </Col>
                        {
                            repetitions.map((item, index) => (
                                <Col span={2}
                                     className={cn('row-table__label')}>
                                    {index + 1}
                                </Col>
                            ))
                        }

                        <Col span={3} className={cn('row-table__label')}>
                            {t('average')}
                        </Col>
                        <Col span={3} className={cn('row-table__label')}>
                            {t('mycobiote')}
                        </Col>
                    </Row>

                    {mycobiota3?.length > 0 && mycobiota3.map(x => (
                        <Row
                            className={cn('row-table__withborder')}
                            style={{ padding: '0px', alignItems: 'center' }}>
                            <Col span={6}>
                                {x.name}
                            </Col>

                            {repetitions.map((item, index) => (
                                <Col
                                    span={2}
                                    className={cn('row-table__label')}>
                                    <Form.Item
                                        name={`mycobiota_type_3/${x?.genius}/${index}`}
                                        style={{ marginTop: '-17px', marginBottom: '5px' }}>
                                        <InputNumber
                                            decimalSeparator=","
                                            style={{
                                                maxWidth: '120px',
                                                width: '95%',
                                                marginTop: '16px',
                                                marginBottom: '-35px'
                                            }} />
                                    </Form.Item>
                                </Col>
                            ))}
                            <Col
                                span={3}
                                className={cn('input')}>
                                {toFixed(registry?.result_imap?.phytopathogenic ? registry?.result_imap?.phytopathogenic[x.genius]?.mean : null)}
                            </Col>
                        </Row>
                    ))}
                </Panel>
                <Row style={{ backgroundColor: 'white', border: 'none' }}>
                    <p>{t('comment')}</p>
                    <Form.Item name="comment">
                        <TextArea
                            maxLength={99999}
                            rows={4} />
                    </Form.Item>
                </Row>
                {registry?.id && (
                    <Row style={{ backgroundColor: 'white', border: 'none', marginTop: '15px' }}>
                        <Col>
                            <p>{t('upload file')}</p>
                            <Upload {...uploadProps}>
                                <Button icon={<UploadOutlined />}>
                                    {t('press to upload')}
                                </Button>
                            </Upload>
                        </Col>
                    </Row>
                )}
                {registry?.pictures1?.length > 0 && (
                    <Row style={{ backgroundColor: 'white', border: 'none', marginTop: '15px' }}>
                        <Col span={24}>
                            <p>{t('uploaded files')}</p>
                            <Table
                                dataSource={registry?.pictures1}
                                pagination={false}
                                columns={[
                                    {
                                        dataIndex: 'picture_name',
                                        title: t('pic table filename'),
                                        key: 'picture_name',
                                        render: (val, rec) => {
                                            const [isImgVisible, setIsImgVisible] = useState(false);
                                            return (
                                                <>
                                                    <Image
                                                        style={{
                                                            width: '100%',
                                                            maxHeight: '100px'
                                                        }}
                                                        src={rec?.picture_file}
                                                        preview={{
                                                            isImgVisible,
                                                            src: rec?.picture_file,
                                                            onVisibleChange: (value) => {
                                                                setIsImgVisible(value);
                                                            }
                                                        }} />
                                                    <div>
                                                        {val}
                                                    </div>
                                                </>
                                            );
                                        }
                                    },
                                    {
                                        dataIndex: 'uploaded',
                                        title: t('pic table date'),
                                        key: 'uploaded',
                                        render: (value) => {
                                            const dateStr = value?.split('T')[0];
                                            const dateArray = dateStr ? dateStr.split('-') : [];
                                            return dateArray?.length > 0 ? `${dateArray[2]}.${dateArray[1]}.${dateArray[0]}` : value;
                                        }
                                    },
                                    {
                                        dataIndex: 'picture_desc',
                                        title: t('pic table description'),
                                        key: 'picture_desc',
                                        render: (val, rec) => {
                                            const [isRenameOpen, setIsRenameOpen] = useState(false);
                                            const [photoComment, setPhotoComment] = useState(val);

                                            const newFileName = useSelector(state => curNewFilename(state));

                                            useEffect(() => {
                                                if (newFileName?.id === rec.id && newFileName?.picture_desc !== photoComment && id) {
                                                    dispatch(getMycologicalRegistry(id));
                                                }
                                            }, [newFileName]);

                                            const hide = () => {
                                                setIsRenameOpen(false);
                                                setPhotoComment(val);
                                            };
                                            const handleOpenChange = (newOpen) => {
                                                setIsRenameOpen(newOpen);
                                            };
                                            const saveNewComment = () => {
                                                if (photoComment !== val) {
                                                    dispatch(updateFileDescription({
                                                        pictureId: rec.id,
                                                        values: {
                                                            picture_desc: photoComment
                                                        }
                                                    }));
                                                }
                                            };

                                            return (
                                                <Popover
                                                    content={(
                                                        <>
                                                            <TextArea
                                                                style={{ width: '350px', height: '150px' }}
                                                                maxLength={99999}
                                                                onChange={(evt) => setPhotoComment(evt?.target?.value)}
                                                                defaultValue={photoComment} />
                                                            <div
                                                                style={{
                                                                    display: 'flex',
                                                                    flexDirection: 'row',
                                                                    justifyContent: 'space-around'
                                                                }}>
                                                                <a onClick={hide}>
                                                                    Отмена
                                                                </a>
                                                                <a onClick={() => {
                                                                    saveNewComment();
                                                                    hide();
                                                                }}>
                                                                    {t('save')}
                                                                </a>
                                                            </div>
                                                        </>
                                                    )}
                                                    trigger="click"
                                                    visible={isRenameOpen}
                                                    onVisibleChange={handleOpenChange}>
                                                    {val}
                                                </Popover>
                                            );
                                        }
                                    },
                                    {
                                        key: 'actions',
                                        render: (value, record) => (
                                            <DeleteOutlined
                                                onClick={() => removeUploadedFile(record)}
                                                style={{ cursor: 'pointer' }} />
                                        )
                                    }
                                ]} />
                        </Col>
                    </Row>
                )}
            </Collapse>
        </div>
    );
};
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import {
    Collapse, Form, Layout, Spin
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useClassName } from 'utils/cn';
import './style.less';

import { PanelLayout, Title } from '../../components';
import PanelHeader from '../../components/panel-layout/panel-header';
import PanelContent from '../../components/panel-layout/panel-content';
import CalculatorDetails from './components/calculator-details';
import { getCalculatorPhyto } from '../../models/calculators-phyto/actions';
import {
    curCalculatorPhyto
} from '../../models/calculators-phyto/selectors';
import { makeResponse } from './components/utils';
import { getUser } from '../../models/account/selectors';

export default () => {
    const cn = useClassName('all-calculators');
    const { t } = useTranslation('phyto calculator');

    const [calculationDetailsContinueDisabled, setCalculationDetailsContinueDisabled] = useState(false);
    const [formInitialValues, setFormInitialValues] = useState({});
    const [currentCalculatorId, setCurrentCalculatorId] = useState(0);
    const [calculatorName, setCalculatorName] = useState(null);
    const [externalPathogensList, setExternalPathogensList] = useState([]);
    const [internalPathogensList, setInternalPathogensList] = useState([]);
    const [externalImages, setExternalImages] = useState([]);
    const [internalImages, setInternalImages] = useState([]);
    const [internalPathogenStage, setInternalPathogenStage] = useState(1);
    const [qualityRepetitions, setQualityRepetitions] = useState(1);

    const [form] = Form.useForm();

    const dispatch = useDispatch();
    const match = useRouteMatch();

    const currentCalculator = useSelector(state => curCalculatorPhyto(state));
    const currentUser = useSelector(state => getUser(state));

    useEffect(() => {
        form.resetFields();
    }, []);

    const handleExistingCalc = (calcValues) => {
        const formVals = makeResponse(calcValues);
        const internalPathogens = formVals?.internal_pathogens;
        setInternalPathogensList(internalPathogens);
        const externalPathogens = formVals?.external_pathogens;
        setExternalPathogensList(externalPathogens);

        const internalKinds = [];
        const externalKinds = [];

        formVals?.internal_pathogens?.map(x => x?.causative_agent?.id).forEach((val) => {
            if (!internalKinds.includes(val)) {
                internalKinds.push(val);
            }
        });
        formVals?.external_pathogens?.map(x => x?.causative_agent?.id).forEach((val) => {
            if (!externalKinds.includes(val)) {
                externalKinds.push(val);
            }
        });

        formVals.internal_pathogen_ids = internalKinds;
        formVals.external_pathogen_ids = externalKinds;
        (formVals?.plant_protection || match?.params?.id) && setCalculationDetailsContinueDisabled(false);
        if (formVals?.total_pathogens_percentage) {
            setInternalPathogenStage(3);
        }
        form.setFieldsValue(formVals);
    };

    useEffect(() => {
        currentCalculator?.id && handleExistingCalc(currentCalculator);
    }, [currentCalculator]);

    useEffect(() => {
        match?.params?.id > 0 && !(currentCalculatorId > 0) && setCurrentCalculatorId(parseInt(match.params.id, 10));
    }, [match]);

    useEffect(() => {
        if (currentCalculatorId > 0) {
            dispatch(getCalculatorPhyto(currentCalculatorId));
        }
    }, [currentCalculatorId]);

    const onFormFinish = (val1, val2, val3) => {
        console.log('Form finish', val1, val2, val3);
    };

    const checkNextStepAvailability = (formVals) => {
        return formVals?.address?.length > 0 && formVals?.culture > 0 && formVals?.year > 0 && formVals?.reproduction > 0 && formVals?.region > 0 && formVals?.seed_collection_date?.format('yyyy')?.length > 0;
    };

    const onFormValuesChange = (changedVal, newVals) => {
        const stepAvailability = checkNextStepAvailability(newVals);
        !stepAvailability && setCalculationDetailsContinueDisabled(true);
        stepAvailability && setCalculationDetailsContinueDisabled(false);

        const keyToChange = Object.keys(changedVal)[0];

        keyToChange === 'name' && setCalculatorName(changedVal[keyToChange]);
    };

    return (
        <Layout key="page-calculator-phyto-route" className={cn()}>
            <PanelLayout>
                <PanelHeader>
                    <Title title={calculatorName?.length > 0 ? calculatorName : t('calculator name')} />
                </PanelHeader>
                <PanelContent>
                    <Form
                        form={form}
                        layout="vertical"
                        name="basic"
                        initialValues={formInitialValues}
                        onValuesChange={onFormValuesChange}
                        onFinish={onFormFinish}
                        autoComplete="off">
                        <CalculatorDetails
                            calculatorId={currentCalculator?.id > 0 ? currentCalculator?.id : currentCalculatorId}
                            internalImages={internalImages}
                            externalImages={externalImages}
                            internalPathogenStage={internalPathogenStage}
                            setInternalPathogenStage={setInternalPathogenStage}
                            externalPathogensList={externalPathogensList}
                            internalPathogensList={internalPathogensList}
                            form={form}
                            detailsNextStepDisabled={calculationDetailsContinueDisabled} />
                    </Form>
                </PanelContent>
            </PanelLayout>
        </Layout>
    );
};
import { combineReducers } from 'redux';

import calculator from './calculator';
import calculators from './calculators';
import shape from './shape';
import probabilityData from './probability-data';
import points from './points';
import loading from './loading';
import fieldLoading from './field-loading';
import forecastLoading from './forecast-loading';
import registryObject from './registry-object';
import geocodeFeature from './geocode-feature';
import registryObjectsList from './registry-objects-list';
import syncedField from './synced-field';
import objectByLayer from './object-by-layer';
import syncedLayers from './synced-layers';
import updatedObjects from './updated-objects';
import userProbability from './user-probability';
import layersByMap from './layers-by-map';
import districts from './districts';
import soils from './soils';
import namedPest from './named-pest';

export default combineReducers({
    namedPest,
    soils,
    districts,
    layersByMap,
    userProbability,
    updatedObjects,
    syncedLayers,
    objectByLayer,
    syncedField,
    registryObjectsList,
    geocodeFeature,
    registryObject,
    calculator,
    calculators,
    shape,
    forecastLoading,
    probabilityData,
    fieldLoading,
    points,
    loading
});

import React, { useEffect, useState } from 'react';
import { Layout, Spin } from 'antd';
import { useClassName } from 'utils/cn';

import './style.less';
import { useKeycloak } from '@react-keycloak/web';
import AgrohubMaps from './components/agrohub-maps';
import { MapsSideBar, PanelLayout, Title } from '../../components';
import PanelHeader from '../../components/panel-layout/panel-header';
import PanelContent from '../../components/panel-layout/panel-content';
import { useTranslation } from 'react-i18next';
import { GEOCODE_MAP_URL_BASE } from '../../environment';

const { Content } = Layout;

export default () => {
    const cn = useClassName('');
    const { keycloak } = useKeycloak();
    const { t } = useTranslation('agrohub maps');


    const [isSecondLogin, setIsSecondLogin] = useState(false);

    useEffect(() => {
        setMapsIframeUrl(GEOCODE_MAP_URL_BASE + '/maps/');
        const isSecond = localStorage.getItem('agrohub-is-second-login', true);
        if (isSecond) {
            setIsSecondLogin(true);
        } else {
            window.location.replace('/profile')
        }
    }, []);

    const [mapsIframeUrl, setMapsIframeUrl] = useState();

    const onMenuChange = (menukey) => {
        if (menukey === 'owned') {
            setMapsIframeUrl(GEOCODE_MAP_URL_BASE + '/maps/');
        }

        if (menukey === 'shared') {
            setMapsIframeUrl(GEOCODE_MAP_URL_BASE + '/maps/');
        }

        if (menukey === 'deleted') {
            setMapsIframeUrl(GEOCODE_MAP_URL_BASE + '/maps/');
        }
    }

    const [mapsList, setMapsList] = useState([]);

    useEffect(() => {
        fetch(`${GEOCODE_MAP_URL_BASE}/api/maps?limit=500`, {
            mode: 'cors',
            method: 'GET',
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
                // 'Access-Control-Allow-Origin':'localhost:3000',
                'Content-Type': 'application/json'
            },
        }).then((res) => {
            return res.json()
        })
        .then((data) => {
            // console.log('Response data!', data)
            setMapsList(data);
            // let extentArray = [];
            // data?.extent?.xMin > 0 && extentArray.push(data?.extent?.xMin);
            // data?.extent?.yMin > 0 && extentArray.push(data?.extent?.yMin);
            // data?.extent?.xMax > 0 && extentArray.push(data?.extent?.xMax);
            // data?.extent?.yMax > 0 && extentArray.push(data?.extent?.yMax);

            // if (extentArray?.length > 0) {
            //     setMapExtent(extentArray);
            // }
            // setFullCurrentLayer(data);
            // setColumnOptions(data?.columns?.map(dataCol => {
            //     return {
            //         label: dataCol?.name,
            //         value: dataCol?.name
            //     }
            // }));
        });
    }, [])

    return isSecondLogin ? (
        <>
        {/* <Layout key="page-calculator" className={cn()}> */}
        <MapsSideBar mapsList={mapsList} onMenuChange={onMenuChange}  />
            <PanelLayout>
                <PanelHeader>
                    <Title title={t('title')} />
                </PanelHeader>
                <PanelContent>
                    <div className="choose-map-class">
                        {t('choose map')}
                    </div>
                    <AgrohubMaps mapsIframeUrl={mapsIframeUrl} />
                </PanelContent>
            </PanelLayout>
        {/* </Layout> */}
        </>
        // <Layout key="page-home" className={cn()}>
        //     <Content className={cn('content')}>
        //         <AgrohubMaps />
        //     </Content>
        // </Layout>
    ) : (
        <Layout key="page-home" className={cn()}>
            <Content className={cn('content')}>
                <Spin />
            </Content>
        </Layout>
    );
};

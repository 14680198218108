import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    Button,
    Col,
    Layout, Popover, Row, Spin
} from 'antd';
import { CopyOutlined, DownloadOutlined, PlusCircleOutlined } from '@ant-design/icons';

import { useTranslation } from 'react-i18next';

import { useClassName } from 'utils/cn';

import {
    PanelLayout, Title
} from '../../components';

import RegistriesTable from './components/registries-table';

import {
    createInitialProtocol,
    deleteProtocol,
    getProtocolList,
    updateProtocol
} from '../../models/fed-registry/actions';
import { curNewInitialProtocol, curProtocolList } from '../../models/fed-registry/selectors';

import './style.less';
import { publishProtocol } from '../../models/fed-registry/api';

const { PanelHeader, PanelContent } = PanelLayout;

const FedRegistries = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const cn = useClassName('calculators');
    const { t } = useTranslation('fed registry');
    const protocolList = useSelector(state => curProtocolList(state));
    const newProt = useSelector(state => curNewInitialProtocol(state));

    const [regTableData, setRegTableData] = useState([]);
    const [selectedTableItems, setSelectedTableItems] = useState([]);

    useEffect(() => {
        if (newProt?.id && newProt?.id > 0) {
            setRegTableData([...regTableData, newProt]);
        }
    }, [newProt]);

    useEffect(() => {
        dispatch(getProtocolList());
    }, []);

    useEffect(() => {
        if (protocolList?.results?.length > 0) {
            setRegTableData(protocolList?.results);
        }
    }, [protocolList]);

    const managePublicationStatus = (statusType) => {
        switch (statusType) {
            case 'publish':
                selectedTableItems?.forEach(selectedRow => {
                    dispatch(publishProtocol({
                        id: selectedRow?.id,
                        values: {
                            public: true,
                            // archived: false
                        }
                    }));
                });
                setTimeout(() => dispatch(getProtocolList()), 1500);
                break;
            case 'edit':
                selectedTableItems?.forEach(selectedRow => {
                    if (selectedRow?.public) {
                        dispatch(publishProtocol({
                            id: selectedRow?.id,
                            values: {
                                public: false
                            }
                        }));
                    } else {
                        dispatch(updateProtocol({
                            id: selectedRow?.id,
                            values: {
                                // archived: false
                            }
                        }));
                    }
                });
                setTimeout(() => dispatch(getProtocolList()), 1500);
                break;
            case 'archive':
                selectedTableItems?.forEach(selectedRow => {
                    dispatch(deleteProtocol(selectedRow?.id));
                });
                setTimeout(() => dispatch(getProtocolList()), 1500);
                break;
            default:
                break;
        }
    };

    const statusManagementButtons = (
        <div className="status-management-popover">
            <Button
                onClick={() => managePublicationStatus('publish')}
                className="status-management-popover__top"
                type="primary">
                {t('status button publish')}
            </Button>
            <Button
                onClick={() => managePublicationStatus('edit')}
                className="status-management-popover__mid">
                {t('status button edit')}
            </Button>
            <Button
                onClick={() => managePublicationStatus('archive')}
                className="status-management-popover__bot"
                danger>
                {t('status button archive')}
            </Button>
        </div>
    );

    const copyRecord = () => {
        const recordToCopy = selectedTableItems[0];
        const resultRecord = {
            date_created: recordToCopy?.date_created,
            acceptation_date: recordToCopy?.acceptation_date,
            comment: recordToCopy?.comment,
            culture: recordToCopy?.culture?.id,
            examination_date: recordToCopy?.examination_date,
            fed_calculator: recordToCopy?.fed_calculator,
            fed_fertilizers: recordToCopy?.fed_fertilizers,
            field_num: recordToCopy?.field_num,
            field_point: recordToCopy?.field_point,
            owner: recordToCopy?.owner?.id,
            productivity_zone: recordToCopy?.productivity_zone,
            protocol_number: recordToCopy?.protocol_number,
            sample: 'Leaf',
            vega_key: recordToCopy?.vega_key
        };
        dispatch(createInitialProtocol(resultRecord));
    };

    const [isRecordGenerating, setIsRecordGenerating] = useState(false);

    const handleDownload = (recordId) => {
        setIsRecordGenerating(true);

        fetch(`/api/v1/fed_calculator/fed_protocol/${recordId}/download_protocol/`, {
            method: 'GET',
            headers: {
                Authorization: `JWT ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json'
            }
        }).then((resp) => {
            resp.blob().then((blb) => {
                const url = window.URL.createObjectURL(blb);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = `FED_Registry_${recordId}`;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
                setIsRecordGenerating(false);
            });
        });
    };

    const handleDownloadRecord = () => {
        selectedTableItems.forEach(x => {
            setTimeout(() => handleDownload(x.id), 100);
        });
    };

    return (
        <Layout key="page-fed-registries" className={cn()}>
            <PanelLayout>
                <PanelHeader>
                    <Title
                        title={t('title')} />
                </PanelHeader>
                <PanelContent>
                    <Row className="controls-row">
                        <Col span={6}>
                            <Link
                                to="/fed-registry/"
                                onClick={() => {
                                    setTimeout(() => window.location.reload(), 500);
                                }}>
                                <Button
                                    style={{ width: '100%' }}
                                    type="primary">
                                    <PlusCircleOutlined />
                                    {t('add protocol')}
                                </Button>
                            </Link>
                        </Col>

                        <Col span={1} />

                        <Col span={4}>
                            {selectedTableItems?.length < 1 ? (
                                <Button style={{ width: '100%' }} disabled type="secondary">
                                    {t('manage status')}
                                </Button>
                            ) : (
                                <Popover content={statusManagementButtons} trigger="click">
                                    <Button style={{ width: '100%' }} type="secondary">
                                        {t('manage status')}
                                    </Button>
                                </Popover>
                            )}
                        </Col>

                        <Col span={1} />

                        <Col span={1}>
                            <Button
                                disabled={selectedTableItems?.length !== 1}
                                onClick={copyRecord}>
                                <CopyOutlined />
                            </Button>
                        </Col>

                        <Col span={1} />

                        <Col span={1}>
                            <Button
                                disabled={selectedTableItems?.length < 1}
                                onClick={handleDownloadRecord}>
                                {isRecordGenerating ? (
                                    <Spin spinning={isRecordGenerating} />
                                ) : (
                                    <DownloadOutlined />
                                )}
                            </Button>
                        </Col>
                    </Row>
                    <RegistriesTable
                        setSelectedItems={setSelectedTableItems}
                        tableData={regTableData} />
                </PanelContent>
            </PanelLayout>
        </Layout>
    );
};

export default FedRegistries;
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import {
    Layout, Spin, Icon, Row, Col
} from 'antd';
import { Link, withRouter } from 'react-router-dom';
import { Title, Tabs, SideBar, PanelLayout } from '../../components';
import PesticideDetail from './components/pesticide-detail';

import cn from '../../utils/cn';

import './style.less';


const { PanelHeader, PanelContent } = PanelLayout;

@withTranslation('pesticides')
@cn('pesticides')
export default class Pesticides extends Component {

    render() {
        const { cn } = this;

        const { t, history } = this.props;

        return (
            <Layout key="page-pesticide-route" className={cn()}>
                <PesticideDetail />
            </Layout>
        );
    }
}